import React from "react";
import { Card, CardBody } from "reactstrap";

const CardComponent = ({ children, className, bodyClass }) => {
  return (
    <React.Fragment>
      <Card className={`border-0 ${className}`}>
        <CardBody className={bodyClass}> {children}</CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
