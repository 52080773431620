import React from "react";
import { useSelector } from "react-redux";
import QuesFooter from "../components/ReadingExam/QuesFooter";
import QuesHeader from "../components/ReadingExam/QuesHeader";

const QuestionLayout = ({ children, handleScroll }) => {
  const { answerId } = useSelector((state) => ({
    answerId: state.QuesReducer.answerId,
    isRouteChange: state.QuesReducer.isRouteChange,
  }));
  return (
    <React.Fragment>
      <div>
        <QuesHeader />
        <div>{children}</div>
        {answerId ? <QuesFooter handleScroll={handleScroll} /> : null}
      </div>
    </React.Fragment>
  );
};

export default QuestionLayout;
