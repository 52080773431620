import React, { forwardRef } from "react";
import { Alert } from "react-bootstrap";
const PopUpChat = forwardRef(({ setShow, chatSound }, ref) => {
  //   const [play] = useSound(chatSound);
  //   useEffect(() => {
  //     play();
  //   }, []);

  // useEffect(() => {
  //   const promise = new AudioContext();

  //   console.log("popup render", promise);

  //   promise.resume().then(() => {
  //     ref?.current?.play();
  //   });
  // }, [ref]);

  return (
    <div>
      <>
        <div className="alert-popup-always-show">
          <Alert onClose={() => setShow(false)} dismissible>
            <Alert.Heading>We are here to help you</Alert.Heading>
          </Alert>
        </div>
      </>
    </div>
  );
});
export default PopUpChat;
