import {
  ADD_COURSE_TYPE,
  ADD_COURSE_TYPE_FAIL,
  ADD_COURSE_TYPE_SUCCESS,
  GET_COURSE_TYPE,
  GET_COURSE_TYPE_FAIL,
  GET_COURSE_TYPE_SUCCESS,
  GET_SINGLE_COURSE_TYPE,
  GET_SINGLE_COURSE_TYPE_FAIL,
  GET_SINGLE_COURSE_TYPE_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  loading: false,
  error: '',
  data: [],
  adding: false,
  singleCourseType: {},
};

const CourseType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COURSE_TYPE:
      return {
        ...state,
        adding: true,
      };
    case ADD_COURSE_TYPE_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_COURSE_TYPE_FAIL:
      return {
        ...state,
        adding: false,
        error: action.payload.data,
      };
    case GET_COURSE_TYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: '',
      };
    case GET_COURSE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        data: [],
      };
    case GET_SINGLE_COURSE_TYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_COURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCourseType: action.payload.data,
        error: '',
      };
    case GET_SINGLE_COURSE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        singleCourseType: {},
      };

    default:
      return state;
  }
};

export default CourseType;
