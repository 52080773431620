import {
  EDIT_PROFILE,
  GET_DASHBOARD_PROFILE,
  GET_DASHBOARD_PROFILE_FAIL,
  GET_DASHBOARD_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  GET_UNIVERSITY,
  GET_UNIVERSITY_FAIL,
  GET_UNIVERSITY_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  data: {},
  loading: false,
  editing: false,
  dashboard: {},
  universityList: [],
  universityLoading: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, editing: true };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, editing: false };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, editing: false };
      break;
    case GET_PROFILE:
      state = { ...state, loading: true };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        loading: false,
        error: "",
        editing: false,
      };
      break;
    case GET_PROFILE_FAIL:
      state = {
        ...state,
        error: action.payload.data,
        data: {},
        loading: false,
        editing: false,
      };
      break;

    case GET_DASHBOARD_PROFILE:
      state = { ...state, loading: true };
      break;
    case GET_DASHBOARD_PROFILE_SUCCESS:
      state = {
        ...state,
        dashboard: action.payload.data,
        loading: false,
        error: "",
        editing: false,
      };
      break;
    case GET_DASHBOARD_PROFILE_FAIL:
      state = {
        ...state,
        error: action.payload.data,
        dashboard: {},
        loading: false,
        editing: false,
      };
      break;

    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case GET_UNIVERSITY:
      state = {
        ...state,
        universityLoading: true,
      };
      break;
    case GET_UNIVERSITY_SUCCESS:
      state = {
        ...state,
        universityLoading: false,
        universityList: action.payload.data,
      };
      break;
    case GET_UNIVERSITY_FAIL:
      state = {
        ...state,
        error: action.payload,
        universityLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
