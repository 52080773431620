import React from "react";
import { Link } from "react-router-dom";
import emptyImage from "./img/emptyImage.png";
const EmptyCommonComp = ({ link, children, linkText }) => {
  return (
    <div className="empty-package-page-view-area">
      <div>
        <img src={emptyImage} alt="" />
        <p>
          {children}
          <Link to={link}>{linkText}</Link>
        </p>
      </div>
    </div>
  );
};

export default EmptyCommonComp;
