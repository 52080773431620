/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import longLeft from "../../../assets/images/Icon/arrow-left.svg";
import longRight from "../../../assets/images/Icon/arrow-long-right.svg";
import Loader from "../../../components/Atoms/Loader";
import LeftSide from "../../../components/ReadingExam/LeftSide";
import RightSide from "../../../components/ReadingExam/RightSide";
import QuestionLayout from "../../../Layouts/QuestionLayout";
import {
  getReadingQues,
  resetAll,
  storeQuestion,
  submitExam,
} from "../../../store/Question/actions";

const ReadingExam = ({ history }) => {
  const { packageId, id } = useParams();

  const dispatch = useDispatch();
  const { authtoken, quesLoading, questions, currentIndex } = useSelector(
    (state) => ({
      currentPassage: state.QuesReducer.currentPassage,
      authtoken: state.Login.token,
      quesLoading: state.QuesReducer.quesLoading,
      questions: state.QuesReducer.questions,
      id: state.Login.id,
      currentIndex: state.QuesReducer.currentIndex,
      answerId: state.QuesReducer.answerId,
    })
  );

  useEffect(() => {
    dispatch(resetAll());
    const prevQuestionData = JSON.parse(localStorage.getItem("xampro-xam"));
    if (
      prevQuestionData?.id &&
      prevQuestionData?.ans &&
      prevQuestionData?.time > -1
    ) {
      dispatch(storeQuestion("isRouteChange", false));
      const { authToken, ans, ...rest } = prevQuestionData;
      dispatch(submitExam(ans, authToken, { ...rest, history }));
    } else {
      dispatch(getReadingQues(authtoken, id, false, packageId));
    }
    return () => {
      dispatch(storeQuestion("ans", {}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const currentData = questions?.[currentIndex];

  // const fill = (value) => {
  //   let displayText = _clone(value);
  //   const tags = value?.match(/\$(.*?)\$+/gi) || [];
  //   if (tags) {
  //     tags.forEach((myTag, idx) => {
  //       // const tagData = myTag?.slice(1, -1);
  //       // data.push({ id: idx, value: tagData });
  //       displayText = displayText?.replace(
  //         new RegExp(_escapeRegExp(myTag), 'gi'),
  //         `<input type="text" value="${
  //           data[idx]?.value || ''
  //         }" id="question" data="${idx}" />`
  //       );
  //     });
  //   }
  //   return displayText;
  // };
  // const question = document.querySelectorAll('#question');

  // question.forEach((dt, idx) => {
  //   // setData([...data, { id: dt?.data || '', value: dt?.value || '' }]);
  //   data.push({ id: idx, value: dt?.value || '' });
  // });

  const [iconFixed, setIconFixed] = useState(true);
  function handleScroll(element, number) {
    const ele = document.getElementById(element);
    let question = questions;
    let passage = 0;
    question?.forEach((data, index) => {
      let questionPosition = 0;
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          const previous = question?.[i];
          const lastQuestion =
            previous?.questions?.[previous?.questions?.length - 1];
          questionPosition +=
            lastQuestion?.Que?.question?.[
              lastQuestion?.Que?.question?.length - 1
            ]?.questionNumber;
        }
      }
      data?.questions?.forEach((ques) => {
        ques?.Que?.question?.forEach((list) => {
          const questonNum = list?.questionNumber + questionPosition;
          if (questonNum == number) {
            passage = index;
          }
        });
      });
    });
    dispatch(storeQuestion("currentIndex", passage));

    ele?.scrollIntoView();
  }
  // const writing_exam = document.getElementById('writing_exam');
  const input = document.querySelectorAll("input");

  useEffect(() => {
    // writing_exam?.on('contextmenu', function (e) {
    //   return false;
    // });
    input.onpaste = (e) => e.preventDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <QuestionLayout handleScroll={handleScroll}>
        <div className={"question-right-area-hidden-show-class"}>
          <div
            className={
              iconFixed
                ? "exam-body-main"
                : "exam-body-main click-icon-fixed-side"
            }
          >
            <div className="container-fluid p-0">
              <Row className="g-0">
                <div
                  className={
                    toggle2 ? "question-right-area-hidden-class" : "col-lg-6"
                  }
                >
                  <div className="passage-left-area-main-wrap">
                    <div className="reading-passage-wrap left">
                      <img
                        src={longLeft}
                        alt=""
                        onClick={() => {
                          if (toggle) {
                            setToggle(false);
                            setIconFixed(true);
                          } else {
                            setToggle2(true);
                            setToggle(false);
                            setIconFixed(false);
                          }
                        }}
                      />
                      {quesLoading ? (
                        <div className="text-center pt-5">
                          <Loader />
                        </div>
                      ) : currentData?.questions?.length ? (
                        <LeftSide
                          passage={currentData}
                          question={currentData?.questions}
                        />
                      ) : (
                        <div className="text-center">No passage available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggle ? "question-right-area-hidden-class" : "col-lg-6"
                  }
                >
                  <div>
                    <div className="passage-right-area-main-wrap">
                      <div className="reading-passage-wrap right mb-5">
                        <img
                          onClick={() => {
                            if (toggle2) {
                              setToggle2(false);
                              setIconFixed(true);
                            } else {
                              setToggle(true);
                              setToggle2(false);
                              setIconFixed(false);
                            }
                          }}
                          src={longRight}
                          alt=""
                        />
                        {quesLoading ? (
                          <div className="text-center  pt-5">
                            <Loader />
                          </div>
                        ) : currentData?.questions?.length ? (
                          <RightSide question={currentData?.questions} />
                        ) : (
                          <div className="text-center">
                            No passage available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </QuestionLayout>
    </div>
  );
};

export default withRouter(ReadingExam);
