import { call, put, takeEvery } from "redux-saga/effects";
import { getData, postData } from "../../helpers/backend_helper";

//for main
import { toaster } from "../../helpers/custom/Toast";
import {
  addFaqInfoFail,
  addFaqInfoSuccess,
  addFaqTypeFail,
  addFaqTypeSuccess,
  getFAQInfoFail,
  getFAQInfoSuccess,
  getFAQtypeFail,
  getFAQtypeSuccess,
  getSingleFaqFail,
  getSingleFaqSuccess,
  getSingleFaqTypeFail,
  getSingleFaqTypeSuccess,
} from "./actions";
import {
  ADD_FAQ_INFO,
  ADD_FAQ_TYPE,
  GET_FAQ_INFO,
  GET_FAQ_TYPE,
  GET_SINGLE_FAQ,
  GET_SINGLE_FAQ_TYPE,
} from "./actionTypes";

function* getFaqTypeList({ payload: { token } }) {
  try {
    const url = "/faq/get-all-faq-type";
    const response = yield call(getData, url, token);
    yield put(getFAQtypeSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getFAQtypeFail(message));
  }
}
function* getFaqInfoList({ payload: { token } }) {
  try {
    const url = "/faq/get-all-faq";
    const response = yield call(getData, url, token);
    yield put(getFAQInfoSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getFAQInfoFail(message));
  }
}
function* getSingleFaq({ payload: { token, faqId } }) {
  try {
    const url = `/faq/user/get-single-faq/${faqId}`;
    const response = yield call(getData, url, token);
    yield put(getSingleFaqSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getSingleFaqFail(message));
  }
}
function* getSingleFaqType({ payload: { token, faqId } }) {
  try {
    const url = `faq/user/get-questions-of-same-type/${faqId}`;
    const response = yield call(getData, url, token);
    yield put(getSingleFaqTypeSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getSingleFaqTypeFail(message));
  }
}
function* workerAddFAQType({ payload: { values, history, token } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values);
  try {
    const url = `/faq/create-faq-type`;
    const response = yield call(postData, url, values, token);
    // toaster("success", `Slot Added Successfully`)
    yield put(addFaqTypeSuccess(response?.message));
    toaster("success", `FAQ type added Successfully`);
    history.push("/faqType");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(addFaqTypeFail(message));
  }
}
function* workerAddFAQInfo({ payload: { values, history, token } }) {
  try {
    const url = `faq/create-faq`;
    const response = yield call(postData, url, values, token);
    // toaster("success", `Slot Added Successfully`)
    yield put(addFaqInfoSuccess(response?.message));
    toaster("success", `FAQ added Successfully`);
    history.push("/faqList");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(addFaqInfoFail(message));
  }
}
function* FaqSaga() {
  yield takeEvery(ADD_FAQ_TYPE, workerAddFAQType);
  yield takeEvery(GET_FAQ_TYPE, getFaqTypeList);
  yield takeEvery(ADD_FAQ_INFO, workerAddFAQInfo);
  yield takeEvery(GET_FAQ_INFO, getFaqInfoList);
  yield takeEvery(GET_SINGLE_FAQ, getSingleFaq);
  yield takeEvery(GET_SINGLE_FAQ_TYPE, getSingleFaqType);
}
export default FaqSaga;
