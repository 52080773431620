import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData, postData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addReviewFail,
  addReviewSuccess,
  getAllAnswerFail,
  getAllAnswerSuccess,
  getParticularSetFail,
  getParticularSetSuccess,
} from "./actions";
//for main
import { ADD_REVIEW, GET_ALL_ANSWER, GET_PARTICULAR_SET } from "./actionTypes";

function* getAllAnswer({ payload: { token, type } }) {
  try {
    const url = `/package/writting/get-submitted-answer-by-admin`;
    const response = yield call(getData, url, token);
    yield put(getAllAnswerSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Load answer failed";
    yield put(getAllAnswerFail(message));
    toaster("error", message);
  }
}

function* onGetParticularSet({
  payload: {
    token,
    options: { studentId, setId },
  },
}) {
  try {
    const url = `/package/writting/get-answer-of/${studentId}/in/${setId}`;
    const response = yield call(postData, url, {}, token);

    yield put(getParticularSetSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load particular set failed";
    yield put(getParticularSetFail(message));
    toaster("error", message);
  }
}

function* onAddReview({
  payload: {
    data,
    options: { authToken, studentId, setId, questionId, history },
  },
}) {
  try {
    const url = `/package/writting/admin/submit-mark-of/${studentId}/in/${setId}/${questionId}`;
    const response = yield call(patchData, url, data, authToken);
    yield put(addReviewSuccess(response));
    toaster("success", `Review added successfully!`);
    history.goBack();
  } catch (error) {
    const message = error?.response?.data?.message || `Add review failed!`;
    yield put(addReviewFail(message));
    toaster("error", message);
  }
}

function* ReviewAnswerSaga() {
  yield takeEvery(ADD_REVIEW, onAddReview);
  yield takeEvery(GET_ALL_ANSWER, getAllAnswer);
  yield takeEvery(GET_PARTICULAR_SET, onGetParticularSet);
}
export default ReviewAnswerSaga;
