import { GET_RESULT, GET_RESULT_FAIL, GET_RESULT_SUCCESS } from './actionTypes';

export const getResult = (authtoken, url) => ({
  type: GET_RESULT,
  payload: { authtoken, url },
});

export const getResultSuccess = (data) => ({
  type: GET_RESULT_SUCCESS,
  payload: data,
});

export const getResultFail = (error) => ({
  type: GET_RESULT_FAIL,
  payload: error,
});
