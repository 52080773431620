import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Alert, Button, Col, Row } from "reactstrap";
import { loginUserError, socialLogin } from "../../../store/auth/login/actions";
import {
  registerUser,
  registerUserFailed,
} from "../../../store/auth/register/actions";
import "../Login/acoount.scss";
import SocialLoginNew from "../SocialLogin/SocialLoginNew";
import authPageBg from "./Frame.svg";
import longArrow from "./icon/ForwardArrow.svg";
import shadowIcon from "./icon/leftshadho.svg";
import infoImage from "./icon/loginImage.png";
import logo from "./icon/logo.svg";
import mobileBg from "./icon/mobile-bg.svg";
const SignUp2 = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading, message, socialError } = useSelector((state) => ({
    error: state.Account.registrationError,
    loading: state.Account.loading,
    message: state.Account.message,
    socialError: state.Login.error,
  }));
  // const data = useSelector((state) => state.Account);

  function handleSubmit(event, errors, values) {
    event.preventDefault();
    let obj = {};
    if (values.name) {
      obj.fullName = values.name;
    }
    if (values.email) {
      obj.email = values.email;
    }
    if (values.password) {
      obj.password = values.password;
    }
    if (values.confirmPassword) {
      obj.confirmPassword = values.confirmPassword;
    }
    if (values.phoneNumber) {
      obj.phoneNumber = values.phoneNumber;
    }
    if (errors?.length === 0) {
      if (values) dispatch(registerUser(obj, history));
    }
  }

  useEffect(() => {
    dispatch(registerUserFailed(""));
    dispatch(loginUserError(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [passShow, setPassShow] = useState(true);
  const [passShow2, setPassShow2] = useState(true);

  const signIn = (res, type) => {
    if (res?.error) return;
    if (type === "google" && res) {
      const values = {
        fullName: res.profileObj.name,
        email: res.profileObj.email,
      };
      const url = "/google-login";
      dispatch(socialLogin(values, history, url));
      // dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      // const postData = {
      //   name: res.name,
      //   email: res.email,
      // };
      // dispatch(socialLogin(postData, props.history, type))
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Registration</title>
      </MetaTags>
      <div
        className="student-login-main-wrap signup-page"
        style={{ backgroundImage: `url(${authPageBg})` }}
      >
        <div className="account-profile-main-area">
          <div className="account-main-left-part">
            <img src={shadowIcon} alt="" className="shadow-icon-account-page" />
            <img src={infoImage} alt="" className="account-page-info-image" />
          </div>
          <div className="account-main-right-submit-info">
            <div
              className="mobile-bg-show-account"
              style={{ backgroundImage: `url(${mobileBg})` }}
            />
            <div className="project-logo-name-right-top">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="student-account-form-wrap">
              <div className="login-form">
                <div className="account-form-title-wrap mb-3">
                  <h3>Registration</h3>
                </div>
                <div className="acoount-email-password-area">
                  <div className="mb-3">
                    {error || socialError ? (
                      <Alert color="danger">{error || socialError}</Alert>
                    ) : null}
                    {message ? <Alert color="success">{message}</Alert> : null}
                  </div>
                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="name"
                            label="Full Name"
                            type="text"
                            errorMessage="Enter full name"
                            placeholder="Enter Full Name"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="email"
                            label="Email"
                            type="email"
                            errorMessage="Enter Email"
                            placeholder="Enter Email"
                            validate={{
                              required: { value: true },
                              email: {
                                value: true,
                                errorMessage: "Invalid Email format",
                              },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="phoneNumber"
                            label="Phone Number"
                            placeholder="Enter Phone"
                            type="text"
                            errorMessage="Enter Phone Number"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="password"
                            type={viewPassword ? "text" : "password"}
                            label="Password"
                            placeholder="Enter Your Password"
                            errorMessage="Enter Your Password"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 8,
                                errorMessage: "Password must be 8 characters",
                              },
                            }}
                          />
                          <span
                            className="password-show-btn"
                            onClick={() => setViewPassword(!viewPassword)}
                          >
                            {viewPassword ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="confirmPassword"
                            label="Confirm Password"
                            type={passShow2 ? "password" : "text"}
                            placeholder="Enter Confirm Password"
                            errorMessage="Enter confirm password"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 8,
                                errorMessage: "Password must be 8 characters",
                              },
                            }}
                          />
                          <span
                            className="password-show-btn"
                            onClick={() => setPassShow2(!passShow2)}
                          >
                            {passShow2 ? (
                              <i className="fa-solid fa-eye"></i>
                            ) : (
                              <i className="fa-solid fa-eye-slash"></i>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <React.Fragment>
                        <div className="account-form-submit-button">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className=""
                          >
                            {loading ? (
                              <section className="custom-spiner-main-wrap">
                                <span className="loader-11"> </span>
                              </section>
                            ) : (
                              <>
                                <div className="account-access-submit-button">
                                  <span> Register</span>
                                  <img src={longArrow} alt="" />
                                </div>
                              </>
                            )}
                          </Button>
                        </div>
                      </React.Fragment>
                    </Row>
                  </AvForm>
                </div>
                <div className="new-account-link-area-wrap">
                  <p>
                    Already have an account ?
                    <Link
                      to={{ pathname: "/login", state: location?.state || "" }}
                    >
                      {" "}
                      Sign In
                    </Link>
                  </p>
                </div>
                <SocialLoginNew
                  googleResponse={googleResponse}
                  facebookResponse={facebookResponse}
                  signUp
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SignUp2);
