export const GET_BLOG_POST = "GET_BLOG_POST";
export const GET_BLOG_POST_SUCCESS = "GET_BLOG_POST_SUCCESS";
export const GET_BLOG_POST_FAIL = "GET_BLOG_POST_FAIL";

export const GET_ONGOING_PACKAGE = "GET_ONGOING_PACKAGE";
export const GET_ONGOING_PACKAGE_SUCCESS = "GET_ONGOING_PACKAGE_SUCCESS";
export const GET_ONGOING_PACKAGE_FAIL = "GET_ONGOING_PACKAGE_FAIL";

export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAIL = "GET_SINGLE_BLOG_FAIL";

export const GET_LAST_THREE_SPEAKING = "GET_LAST_THREE_SPEAKING";
export const GET_LAST_THREE_SPEAKING_SUCCESS =
  "GET_LAST_THREE_SPEAKING_SUCCESS";
export const GET_LAST_THREE_SPEAKING_FAIL = "GET_LAST_THREE_SPEAKING_FAIL";

export const GET_LAST_THREE_READING = "GET_LAST_THREE_READING";
export const GET_LAST_THREE_READING_SUCCESS = "GET_LAST_THREE_READING_SUCCESS";
export const GET_LAST_THREE_READING_FAIL = "GET_LAST_THREE_READING_FAIL";

export const GET_LAST_THREE_LISTENING = "GET_LAST_THREE_LISTENING";
export const GET_LAST_THREE_LISTENING_SUCCESS =
  "GET_LAST_THREE_LISTENING_SUCCESS";
export const GET_LAST_THREE_LISTENING_FAIL = "GET_LAST_THREE_LISTENING_FAIL";

export const GET_LAST_THREE_WRITING = "GET_LAST_THREE_WRITING";
export const GET_LAST_THREE_WRITING_SUCCESS = "GET_LAST_THREE_WRITING_SUCCESS";
export const GET_LAST_THREE_WRITING_FAIL = "GET_LAST_THREE_WRITING_FAIL";

export const GET_RESULT_DASHBOARD = "GET_RESULT_DASHBOARD";
export const GET_RESULT_DASHBOARD_SUCCESS = "GET_RESULT_DASHBOARD_SUCCESS";
export const GET_RESULT_DASHBOARD_FAIL = "GET_RESULT_DASHBOARD_FAIL";
export const GET_STUDENT_PERFORMANCE = "GET_STUDENT_PERFORMANCE";
export const GET_STUDENT_PERFORMANCE_SUCCESS =
  "GET_STUDENT_PERFORMANCE_SUCCESS";
export const GET_STUDENT_PERFORMANCE_FAIL = "GET_STUDENT_PERFORMANCE_FAIL";

export const GET_STUDENT_BANNER = "GET_STUDENT_BANNER";
export const GET_STUDENT_BANNER_SUCCESS = "GET_STUDENT_BANNER_SUCCESS";
export const GET_STUDENT_BANNER_FAIL = "GET_STUDENT_BANNER_FAIL";

export const GET_STUDENT_SCREEN = "GET_STUDENT_SCREEN";
export const GET_STUDENT_SCREEN_SUCCESS = "GET_STUDENT_SCREEN_SUCCESS";
export const GET_STUDENT_SCREEN_FAIL = "GET_STUDENT_SCREEN_FAIL";

export const GET_TOP_THREE_PACKAGE = "GET_TOP_THREE_PACKAGE";
export const GET_TOP_THREE_PACKAGE_SUCCESS = "GET_TOP_THREE_PACKAGE_SUCCESS";
export const GET_TOP_THREE_PACKAGE_FAIL = "GET_TOP_THREE_PACKAGE_FAIL";

export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA";
