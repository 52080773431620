import React from 'react';
import StudentFooter from '../components/StudentFooter/StudentFooter';
import StudentNavbar from '../components/StudentNavbar/StudentNavbar';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <StudentNavbar />
      <div>{children}</div>
      <StudentFooter />
    </React.Fragment>
  );
};

export default Layout;
