import {
  GET_LISTENING_QUES_QUES_FAIL,
  GET_LISTENING_QUES_SUCCESS,
  GET_READING_QUES_QUES_FAIL,
  GET_READING_QUES_SUCCESS,
  GET_REMAINING_QUES_SUCCESS,
  GET_SPEAKING_SET_LINK_QUES_FAIL,
  GET_SPEAKING_SET_LINK_SUCCESS,
  GET_WRITING_QUES_QUES_FAIL,
  GET_WRITING_QUES_SUCCESS,
  RESET_ALL,
  STORE_QUESTION,
  STORE_QUESTION_WITH_CLUES,
  SUBMIT_ANSWER_ONE_BY_ONE,
  SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL,
  SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS,
  SUBMIT_EXAM,
  SUBMIT_EXAM_QUES_FAIL,
  SUBMIT_EXAM_SUCCESS,
  SUBMIT_LISTENING_EXAM,
  SUBMIT_WRITING_EXAM,
} from "./actionTypes";

const initialState = {
  readingQues: {},
  listeningQues: {},
  writingQues: {},
  currentPassage: {},
  quesLoading: true,
  adding: false,
  questions: [],
  currentIndex: 0,
  ansQuestion: [],
  remainingQues: [],
  speakingQuesSet: {},
  remainingQuesLoading: true,
  isDone: false,
  isRouteChange: true,
  answerId: "",
  saving: false,
  writingAns: {
    1: { answer: "", photo: "", havePhoto: 0, isImage: false },
    2: { answer: "", photo: "", havePhoto: 0, isImage: false },
  },
  listeningAudio: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  },
  clues: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  },
  ans: {},
};
// 1: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 2: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 3: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 4: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 5: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 6: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 7: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 8: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 9: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 10: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 11: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 12: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 13: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 14: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 15: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 16: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 17: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 18: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 19: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 20: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 21: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 22: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 23: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 24: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 25: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 26: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 27: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 28: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 29: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 30: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 31: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 32: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 33: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 34: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 35: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 36: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 37: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 38: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 39: { answer: [], mark: 0, explanation: '', rightAnswer: [] },
// 40: { answer: [], mark: 0, explanation: '', rightAnswer: [] },

const QuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_ANSWER_ONE_BY_ONE:
      return {
        ...state,
        saving: true,
      };
    case SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS:
      return {
        ...state,
        saving: false,
      };
    case SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL:
      return {
        ...state,
        saving: false,
      };

    case GET_READING_QUES_SUCCESS:
      return {
        ...state,
        readingQues: action.payload,
        quesLoading: false,
      };

    case GET_READING_QUES_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        quesLoading: false,
      };

    case GET_SPEAKING_SET_LINK_SUCCESS:
      return {
        ...state,
        speakingQuesSet: action.payload.data,
        quesLoading: false,
      };

    case GET_SPEAKING_SET_LINK_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        quesLoading: false,
      };

    case GET_LISTENING_QUES_SUCCESS:
      return {
        ...state,
        listeningQues: action.payload.data,
        quesLoading: false,
      };

    case GET_LISTENING_QUES_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        quesLoading: false,
      };

    case GET_WRITING_QUES_SUCCESS:
      return {
        ...state,
        writingQues: action.payload.data,
        quesLoading: false,
      };

    case GET_WRITING_QUES_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        quesLoading: false,
      };

    case STORE_QUESTION:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    case SUBMIT_EXAM:
      return {
        ...state,
        adding: true,
        isRouteChange: false,
      };

    case SUBMIT_LISTENING_EXAM:
      return {
        ...state,
        adding: true,
        isRouteChange: false,
      };
    case SUBMIT_WRITING_EXAM:
      return {
        ...state,
        adding: true,
        isRouteChange: false,
      };

    //
    case SUBMIT_EXAM_SUCCESS:
      return {
        ...state,
        adding: false,
        isRouteChange: true,
      };

    case SUBMIT_EXAM_QUES_FAIL:
      return {
        ...state,
        adding: false,
        isRouteChange: true,
      };

    case STORE_QUESTION_WITH_CLUES:
      return {
        ...state,
        ans: action.payload.ans,
        clues: action.payload.clues,
      };
    case GET_REMAINING_QUES_SUCCESS:
      return {
        ...state,
        remainingQues: action.payload.data,
        remainingQuesLoading: false,
      };

    case RESET_ALL:
      return {
        ...state,
        readingQues: {},
        listeningQues: {},
        writingQues: {},
        currentPassage: {},
        quesLoading: true,
        adding: false,
        questions: [],
        currentIndex: 0,
        ansQuestion: [],
        speakingQuesSet: {},
        isRouteChange: true,
        answerId: "",
        saving: false,
        writingAns: {
          1: { answer: "", photo: "", havePhoto: 0, isImage: false },
          2: { answer: "", photo: "", havePhoto: 0, isImage: false },
        },
        listeningAudio: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
        },
        clues: {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
        },
        ans: {},
      };
    default:
      return state;
  }
};

export default QuesReducer;
