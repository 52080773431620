import React, { useEffect } from "react";
import { AudioPlayer } from "../../../components/Audio/AudioPlayer";

const AudioComponent = ({
  data,
  timeJump,
  currentData,
  currentIndex,
  listeningAudioControl,
  audioPlayer,
}) => {
  console.log(
    `audioPlayer_component`,
    audioPlayer,
    audioPlayer.current?.duration,
    audioPlayer?.current?.onloadedmetadata,
    audioPlayer?.current?.readyState
  );
  useEffect(() => {
    if (
      listeningAudioControl[currentIndex] === false &&
      currentData &&
      audioPlayer?.current
    ) {
      if (currentData?.questions?.length > 0) {
        // console.log("time");
        timeJump(0);
      }
    }
    // audioPlayer?.current?.addEventListener("progress", function () {
    //   var loadedPercentage = this.buffered.end(0) / this.duration;
    //   if (loadedPercentage) {
    //   }
    //   console.log(
    //     `loadedPercentage`,
    //     loadedPercentage,
    //     this.buffered.end(0),
    //     this.duration
    //   );
    // });
    // suggestion: don't use this, use what's below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, currentData, audioPlayer?.current]);
  return (
    <div className="listening-media-player-wrap">
      <div className="audio-player-ques">
        <AudioPlayer data={data} />
      </div>
    </div>
  );
};

export default AudioComponent;
