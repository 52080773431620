import {
  GET_LISTENING_QUES,
  GET_LISTENING_QUES_QUES_FAIL,
  GET_LISTENING_QUES_SUCCESS,
  GET_READING_QUES,
  GET_READING_QUES_QUES_FAIL,
  GET_READING_QUES_SUCCESS,
  GET_REMAINING_QUES,
  GET_REMAINING_QUES_FAIL,
  GET_REMAINING_QUES_SUCCESS,
  GET_SPEAKING_SET_LINK,
  GET_SPEAKING_SET_LINK_QUES_FAIL,
  GET_SPEAKING_SET_LINK_SUCCESS,
  GET_WRITING_QUES,
  GET_WRITING_QUES_QUES_FAIL,
  GET_WRITING_QUES_SUCCESS,
  RESET_ALL,
  STORE_QUESTION,
  STORE_QUESTION_WITH_CLUES,
  SUBMIT_ANSWER_ONE_BY_ONE,
  SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL,
  SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS,
  SUBMIT_EXAM,
  SUBMIT_EXAM_QUES_FAIL,
  SUBMIT_EXAM_SUCCESS,
  SUBMIT_LISTENING_EXAM,
  SUBMIT_WRITING_EXAM,
} from "./actionTypes";

export const getReadingQues = (authtoken, serviceID, result, packageId) => ({
  type: GET_READING_QUES,
  payload: { authtoken, serviceID, packageId, result },
});

export const getReadingQuesSuccess = (data) => ({
  type: GET_READING_QUES_SUCCESS,
  payload: { data },
});

export const getReadingQuesFail = (error) => ({
  type: GET_READING_QUES_QUES_FAIL,
  payload: error,
});

export const getListeningQues = (authtoken, serviceID, result, packageId) => ({
  type: GET_LISTENING_QUES,
  payload: { authtoken, serviceID, result, packageId },
});

export const getListeningQuesSuccess = (data) => ({
  type: GET_LISTENING_QUES_SUCCESS,
  payload: { data },
});

export const getListeningQuesFail = (error) => ({
  type: GET_LISTENING_QUES_QUES_FAIL,
  payload: error,
});

export const getWritingQues = (authtoken, serviceID, result, packageId) => ({
  type: GET_WRITING_QUES,
  payload: { authtoken, serviceID, result, packageId },
});

export const getWritingQuesSuccess = (data) => ({
  type: GET_WRITING_QUES_SUCCESS,
  payload: { data },
});

export const getWritingQuesFail = (error) => ({
  type: GET_WRITING_QUES_QUES_FAIL,
  payload: error,
});

export const getSpeakingSet = (authtoken, serviceId) => ({
  type: GET_SPEAKING_SET_LINK,
  payload: { authtoken, serviceId },
});

export const getSpeakingSetSuccess = (data) => ({
  type: GET_SPEAKING_SET_LINK_SUCCESS,
  payload: { data },
});

export const getSpeakingSetFail = (error) => ({
  type: GET_SPEAKING_SET_LINK_QUES_FAIL,
  payload: error,
});

export const submitExam = (data, authtoken, options) => ({
  type: SUBMIT_EXAM,
  payload: { data, authtoken, options },
});

export const submitListeningExam = (data, authtoken, options) => ({
  type: SUBMIT_LISTENING_EXAM,
  payload: { data, authtoken, options },
});

export const submitWritingExam = (data, authtoken, options) => ({
  type: SUBMIT_WRITING_EXAM,
  payload: { data, authtoken, options },
});

export const submitExamSuccess = (data) => ({
  type: SUBMIT_EXAM_SUCCESS,
  payload: { data },
});

export const submitExamFail = (error) => ({
  type: SUBMIT_EXAM_QUES_FAIL,
  payload: error,
});

export const storeQuestion = (name, data) => ({
  type: STORE_QUESTION,
  payload: { name, data },
});

export const resetAll = () => ({
  type: RESET_ALL,
});

export const storeQuestionWithClues = (ans, clues) => ({
  type: STORE_QUESTION_WITH_CLUES,
  payload: { ans, clues },
});

export const submitAnswerOneByOne = (data, authtoken, options) => ({
  type: SUBMIT_ANSWER_ONE_BY_ONE,
  payload: { data, authtoken, options },
});

export const submitAnswerOneByOneSuccess = (data) => ({
  type: SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS,
  payload: { data },
});

export const submitAnswerOneByOneFail = (error) => ({
  type: SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL,
  payload: error,
});
export const getRemainingQues = (authtoken) => ({
  type: GET_REMAINING_QUES,
  payload: { authtoken },
});

export const getRemainingQuesSuccess = (data) => ({
  type: GET_REMAINING_QUES_SUCCESS,
  payload: { data },
});

export const getRemainingQuesFail = (error) => ({
  type: GET_REMAINING_QUES_FAIL,
  payload: error,
});
