import {
  ADD_FACILITATOR_SLOT,
  ADD_FACILITATOR_SLOT_FAIL,
  ADD_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_REVIEW,
  GET_FACILITATOR_REVIEW_FAIL,
  GET_FACILITATOR_REVIEW_SUCCESS,
  GET_FACILITATOR_SERVICE,
  GET_FACILITATOR_SERVICE_FAIL,
  GET_FACILITATOR_SERVICE_SUCCESS,
  GET_PARTICULAR_SLOT,
  GET_PARTICULAR_SLOT_FAIL,
  GET_PARTICULAR_SLOT_SUCCESS,
  GET_REVIEW_ATTENDANCE,
  GET_REVIEW_ATTENDANCE_FAIL,
  GET_REVIEW_ATTENDANCE_SUCCESS,
  GET_STUDENT_FACILITATOR_SLOT,
  GET_STUDENT_FACILITATOR_SLOT_FAIL,
  GET_STUDENT_FACILITATOR_SLOT_SUCCESS,
  GET_VERIFY_FACILITATOR,
  GET_VERIFY_FACILITATOR_FAIL,
  GET_VERIFY_FACILITATOR_SUCCESS,
} from "./actionTypes";

export const getVerifyFacilitator = (authtoken, id) => ({
  type: GET_VERIFY_FACILITATOR,
  payload: { authtoken, id },
});

export const getVerifyFacilitatorSuccess = (data, authtoken) => ({
  type: GET_VERIFY_FACILITATOR_SUCCESS,
  payload: { data, authtoken },
});

export const getVerifyFacilitatorFail = (error) => ({
  type: GET_VERIFY_FACILITATOR_FAIL,
  payload: error,
});

export const getFacilitatorService = (authtoken, packageId) => ({
  type: GET_FACILITATOR_SERVICE,
  payload: { authtoken, packageId },
});

export const getFacilitatorServiceSuccess = (data) => ({
  type: GET_FACILITATOR_SERVICE_SUCCESS,
  payload: { data },
});

export const getFacilitatorServiceFail = (error) => ({
  type: GET_FACILITATOR_SERVICE_FAIL,
  payload: error,
});
export const getStudentFacilitatorSlot = (token, options) => {
  return {
    type: GET_STUDENT_FACILITATOR_SLOT,
    payload: { token, options },
  };
};

export const getStudentFacilitatorSlotSuccess = (values) => {
  return {
    type: GET_STUDENT_FACILITATOR_SLOT_SUCCESS,
    payload: { values },
  };
};

export const getStudentFacilitatorSlotFail = (message) => {
  return {
    type: GET_STUDENT_FACILITATOR_SLOT_FAIL,
    payload: { message },
  };
};
export const addFacilitator = (values, options) => {
  return {
    type: ADD_FACILITATOR_SLOT,
    payload: { values, options },
  };
};
export const addFacilitatorSuccess = (message) => {
  return {
    type: ADD_FACILITATOR_SLOT_SUCCESS,
    payload: { message },
  };
};

export const addFacilitatorFail = (message) => {
  return {
    type: ADD_FACILITATOR_SLOT_FAIL,
    payload: { message },
  };
};
export const getFacilitatorReview = (token, setId, studentId, type) => {
  return {
    type: GET_FACILITATOR_REVIEW,
    payload: { token, setId, studentId, type },
  };
};

export const getFacilitatorReviewSuccess = (values) => {
  return {
    type: GET_FACILITATOR_REVIEW_SUCCESS,
    payload: { values },
  };
};

export const getFacilitatorReviewFail = (message) => {
  return {
    type: GET_FACILITATOR_REVIEW_FAIL,
    payload: { message },
  };
};
export const getReviewAttendance = (token, value, examId, options) => {
  return {
    type: GET_REVIEW_ATTENDANCE,
    payload: { token, value, examId, options },
  };
};

export const getReviewAttendanceSuccess = (values) => {
  return {
    type: GET_REVIEW_ATTENDANCE_SUCCESS,
    payload: { values },
  };
};

export const getReviewAttendanceFail = (message) => {
  return {
    type: GET_REVIEW_ATTENDANCE_FAIL,
    payload: { message },
  };
};
export const getParticularSlot = (token, examId) => {
  return {
    type: GET_PARTICULAR_SLOT,
    payload: { token, examId },
  };
};

export const getParticularSlotSuccess = (values) => {
  return {
    type: GET_PARTICULAR_SLOT_SUCCESS,
    payload: { values },
  };
};

export const getParticularSlotFail = (message) => {
  return {
    type: GET_PARTICULAR_SLOT_FAIL,
    payload: { message },
  };
};
