import React from "react";
import { Link } from "react-router-dom";

const DashboardFeedback = () => {
  return (
    <div className="dashboard-feedback-main-area-wrap">
      <div className="dashboard-ask-feedback-title">
        <h3>You can ask feedback</h3>
      </div>
      <div className="dashboard-feedback-area-bottom">
        <h3>
          You can ask for <br /> feedback from <br /> your teacher
        </h3>
        <Link to={"/create-package"} className="feedback-ask-from-teacher">
          Ask for feedback
        </Link>
      </div>
    </div>
  );
};

export default DashboardFeedback;
