import React from "react";
import { Prompt } from "react-router-dom";

const PromptMessage = ({ dependent, header, content, submit }) => {
  // console.log("hello submit", submit);
  return (
    <Prompt
      when={dependent}
      message={JSON.stringify({
        header: header,
        content: content,
        submit: submit,
      })}
    />
  );
};

export default PromptMessage;
