import React from "react";

const DoubleQuotesDown = () => {
  return (
    <div className="student-slider-quotes-icon-down">
      <svg
        width="158"
        height="132"
        viewBox="0 0 158 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.527832 131.9V101.558H9.86375C17.3844 101.558 23.349 100.391 27.7576 98.0572C32.1663 95.7232 35.4079 91.7036 37.4825 85.9983C39.5572 80.5523 40.5945 73.1613 40.5945 63.8254L40.5945 35.0396L51.8754 56.8235C50.0601 58.1201 47.4668 59.1575 44.0955 59.9354C40.7242 60.9728 37.2232 61.4914 33.5926 61.4914C25.0346 61.4914 17.903 58.7685 12.1977 53.3225C6.49245 48.1359 3.6398 40.8746 3.6398 31.5387C3.6398 22.7214 6.36279 15.2008 11.8087 8.97684C17.2547 3.01222 25.0346 0.0299072 35.1486 0.0299072C41.6318 0.0299072 47.7261 1.45625 53.4314 4.3089C58.8774 7.42088 63.286 12.6075 66.6573 19.8688C70.0286 27.3894 71.7143 37.633 71.7143 50.5995V57.2125C71.7143 75.625 69.2506 90.2772 64.3233 101.169C59.1367 112.061 51.7458 119.841 42.1505 124.509C32.5553 129.436 20.8853 131.9 7.14078 131.9H0.527832ZM86.4962 131.9L86.4962 101.558H95.8321C103.353 101.558 109.317 100.391 113.726 98.0572C118.135 95.7232 121.376 91.7036 123.451 85.9983C125.526 80.5523 126.563 73.1613 126.563 63.8254L126.563 35.0396L137.844 56.8235C136.028 58.1201 133.435 59.1575 130.064 59.9354C126.693 60.9728 123.192 61.4914 119.561 61.4914C111.003 61.4914 103.871 58.7685 98.1661 53.3225C92.4608 48.1359 89.6081 40.8746 89.6081 31.5387C89.6081 22.7214 92.3311 15.2008 97.7771 8.97684C103.223 3.01222 111.003 0.0299072 121.117 0.0299072C127.6 0.0299072 133.694 1.45625 139.4 4.3089C144.846 7.42088 149.254 12.6075 152.626 19.8688C155.997 27.3894 157.683 37.633 157.683 50.5995V57.2125C157.683 75.625 155.219 90.2772 150.292 101.169C145.105 112.061 137.714 119.841 128.119 124.509C118.524 129.436 106.854 131.9 93.1091 131.9H86.4962Z"
          fill="#E5EEFA"
        />
      </svg>
    </div>
  );
};

export default DoubleQuotesDown;
