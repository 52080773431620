import _clone from 'lodash/clone';
import _escapeRegExp from 'lodash/escapeRegExp';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const ShortAnswer = ({
  questions,
  handleOnChange,
  ans,
  questionNumber,
  view,
  notePad,
  handleChangeNotePad,
  idx,
  newIdx,
  question,
}) => {
  const fillTab = (value) => {
    let displayText = _clone(value);
    const tags = value?.match(/\t/gi) || [];
    if (tags?.length) {
      tags.forEach((myTag, idx) => {
        displayText = displayText?.replace(
          new RegExp(_escapeRegExp(myTag), 'gi'),
          `<i style="padding-left : 5px"></i>`
        );
      });
    }
    return displayText;
  };

  const replaceGap = (word, serial, list) => {
    const word2 = fillTab(word);
    const newWord = word2.split('$gap$');
    const [fist, ...rest] = newWord?.[0].split('<p>');
    return (
      <div className="question-fill-inThe-gap-wrap d-flex align-items-center">
        <span className="number me-2">
          <span className="d-inline">{serial}</span>
        </span>
        <span dangerouslySetInnerHTML={{ __html: fist }}></span>
        <p>
          {rest
            ? rest?.map((data, idx) => (
                <span
                  dangerouslySetInnerHTML={{ __html: data }}
                  key={idx}
                ></span>
              ))
            : null}
          {/* {newWord?.[0]} */}
          <input
            type="text"
            className="gapsInput"
            disabled={view}
            style={{
              border: '0',
              borderBottom: '2px solid',
              maxWidth: '70px',
              display: 'inline-block',
              background: 'transparent',
            }}
            onChange={(e) =>
              handleOnChange(e, list, serial, null, null, 'fill')
            }
            value={ans?.[serial]?.answer?.[0] || ''}
          />
          {/* {newWord?.[newWord?.length - 1]} */}
          <span
            dangerouslySetInnerHTML={{ __html: newWord?.[newWord?.length - 1] }}
          ></span>
        </p>
      </div>
    );
  };

  const findQuestionsNumberRange = `${
    questions?.[0]?.questionNumber + questionNumber
  }-
          ${
            questions?.[questions?.length - 1]?.questionNumber + questionNumber
          }`;
  const [viewExplain, setViewExplain] = useState('');
  const [toggle, setToggle] = useState();
  return (
    <React.Fragment>
      <div className="ques-right-part-container p-2 w-100">
        <h5>Question {findQuestionsNumberRange}</h5>
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle
                  ? 'navbar_mobile_menu show my-2'
                  : 'navbar_mobile_menu hide my-2'
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        <div className="ques-section-main-wrapper mb-5">
          <h4 className="mt-2" style={{ fontSize: '20px' }}>
            Complete the Sentences below
          </h4>
          <h5 className="mb-3" style={{ fontSize: '20px' }}>
            Write <span className="text-danger">{question?.answerType}</span>{' '}
            for each answer.
          </h5>
          <div className="fill-the-gaps-comp-main-wrap">
            {questions?.map((list, idx) => (
              <React.Fragment key={list?.questionNumber + questionNumber}>
                <p
                  className="pb-2"
                  key={list?.questionNumber + questionNumber}
                  id={`question${list?.questionNumber + questionNumber}`}
                >
                  {replaceGap(
                    list?.q,
                    list?.questionNumber + questionNumber,
                    list
                  )}
                </p>
                {view ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center py-2">
                      <spansa>
                        Answer :{' '}
                        <strong>
                          {ans?.[
                            list.questionNumber + questionNumber
                          ]?.answer?.map(
                            (data, idx) => `${data}  ${idx === 1 ? '' : ','}`
                          )}
                        </strong>
                      </spansa>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              viewExplain !==
                              `view${list?.questionNumber + questionNumber}`
                            ) {
                              setViewExplain(
                                `view${list?.questionNumber + questionNumber}`
                              );
                            } else {
                              setViewExplain('');
                            }
                          }}
                          style={{
                            backgroundColor: '#cce1ff',
                            color: '#485ec4',
                            border: 'none',
                          }}
                          className="btn btn-sm m-1 gapsInput"
                        >
                          {viewExplain ===
                          `view${list?.questionNumber + questionNumber}`
                            ? 'Hide'
                            : 'See Explanation'}
                        </button>
                      </div>
                      {ans?.[list.questionNumber + questionNumber]?.mark ===
                      1 ? (
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      ) : (
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      )}
                    </div>
                    {viewExplain ===
                    `view${list?.questionNumber + questionNumber}` ? (
                      <div className="border rounded-2 p-3 my-2">
                        {list?.explanation}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShortAnswer;
