import {
  GET_BLOG_POST,
  GET_BLOG_POST_FAIL,
  GET_BLOG_POST_SUCCESS,
  GET_LAST_THREE_LISTENING,
  GET_LAST_THREE_LISTENING_FAIL,
  GET_LAST_THREE_LISTENING_SUCCESS,
  GET_LAST_THREE_READING,
  GET_LAST_THREE_READING_FAIL,
  GET_LAST_THREE_READING_SUCCESS,
  GET_LAST_THREE_SPEAKING,
  GET_LAST_THREE_SPEAKING_FAIL,
  GET_LAST_THREE_SPEAKING_SUCCESS,
  GET_LAST_THREE_WRITING,
  GET_LAST_THREE_WRITING_FAIL,
  GET_LAST_THREE_WRITING_SUCCESS,
  GET_ONGOING_PACKAGE,
  GET_ONGOING_PACKAGE_FAIL,
  GET_ONGOING_PACKAGE_SUCCESS,
  GET_RESULT_DASHBOARD,
  GET_RESULT_DASHBOARD_FAIL,
  GET_RESULT_DASHBOARD_SUCCESS,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_FAIL,
  GET_SINGLE_BLOG_SUCCESS,
  GET_STUDENT_BANNER,
  GET_STUDENT_BANNER_FAIL,
  GET_STUDENT_BANNER_SUCCESS,
  GET_STUDENT_PERFORMANCE,
  GET_STUDENT_PERFORMANCE_FAIL,
  GET_STUDENT_PERFORMANCE_SUCCESS,
  GET_STUDENT_SCREEN,
  GET_STUDENT_SCREEN_FAIL,
  GET_STUDENT_SCREEN_SUCCESS,
  GET_TOP_THREE_PACKAGE,
  GET_TOP_THREE_PACKAGE_FAIL,
  GET_TOP_THREE_PACKAGE_SUCCESS,
  STORE_DASHBOARD_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  adding: false,
  blogList: [],
  singleBlog: [],
  onGoingProject: [],
  lastThreeSpeaking: [],
  lastThreeReading: [],
  lastThreeListening: [],
  lastThreeWriting: [],
  lastReadingScore: [],
  resultLeaderDashboard: [],
  studentPerformance: [],
  studentLoading: false,
  studentBannerList: [],
  screenList: [],

  topThreePackageData: [],
  studentPerformanceLoading: false,
  resultLeaderDashboardLoading: false,
  singleBlogLoading: false,
  onGoingProjectLoading: false,
  topThreePackageDataLoading: false,
  screenLoading: false,
  screen: true,
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG_POST:
      return {
        ...state,
        loading: true,
      };
    case GET_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogList: action.payload.data,
      };
    case GET_BLOG_POST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ONGOING_PACKAGE:
      return {
        ...state,
        onGoingProjectLoading: true,
      };
    case GET_ONGOING_PACKAGE_SUCCESS:
      return {
        ...state,
        onGoingProjectLoading: false,
        onGoingProject: action.payload.data,
      };
    case GET_ONGOING_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        onGoingProjectLoading: false,
      };
    case GET_SINGLE_BLOG:
      return {
        ...state,
        singleBlogLoading: true,
      };
    case GET_SINGLE_BLOG_SUCCESS:
      return {
        ...state,
        singleBlogLoading: false,
        singleBlog: action.payload.data,
      };
    case GET_SINGLE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        singleBlogLoading: false,
      };
    case GET_LAST_THREE_SPEAKING:
      return {
        ...state,
        loading: true,
      };
    case GET_LAST_THREE_SPEAKING_SUCCESS:
      return {
        ...state,
        loading: false,
        lastThreeSpeaking: action.payload.data,
      };
    case GET_LAST_THREE_SPEAKING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LAST_THREE_READING:
      return {
        ...state,
        loading: true,
      };
    case GET_LAST_THREE_READING_SUCCESS:
      return {
        ...state,
        loading: false,
        lastThreeReading: action.payload.data,
      };
    case GET_LAST_THREE_READING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LAST_THREE_LISTENING:
      return {
        ...state,
        loading: true,
      };
    case GET_LAST_THREE_LISTENING_SUCCESS:
      return {
        ...state,
        loading: false,
        lastThreeListening: action.payload.data,
      };
    case GET_LAST_THREE_LISTENING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LAST_THREE_WRITING:
      return {
        ...state,
        loading: true,
      };
    case GET_LAST_THREE_WRITING_SUCCESS:
      return {
        ...state,
        loading: false,
        lastThreeWriting: action.payload.data,
      };
    case GET_LAST_THREE_WRITING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_RESULT_DASHBOARD:
      return {
        ...state,
        resultLeaderDashboardLoading: true,
      };
    case GET_RESULT_DASHBOARD_SUCCESS:
      return {
        ...state,
        resultLeaderDashboardLoading: false,
        resultLeaderDashboard: action.payload.data,
      };
    case GET_RESULT_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        resultLeaderDashboardLoading: false,
      };

    case GET_STUDENT_PERFORMANCE:
      return {
        ...state,
        studentPerformanceLoading: true,
      };
    case GET_STUDENT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        studentPerformanceLoading: false,
        studentPerformance: action.payload.data,
      };
    case GET_STUDENT_PERFORMANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        studentPerformanceLoading: false,
      };
    case GET_STUDENT_BANNER:
      return {
        ...state,
        studentLoading: true,
      };
    case GET_STUDENT_BANNER_SUCCESS:
      return {
        ...state,
        studentLoading: false,
        studentBannerList: action.payload.data,
      };
    case GET_STUDENT_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        studentLoading: false,
      };
    case GET_STUDENT_SCREEN:
      return {
        ...state,
        screenLoading: true,
      };
    case GET_STUDENT_SCREEN_SUCCESS:
      return {
        ...state,
        screenLoading: false,
        screenList: action.payload.data,
      };
    case GET_STUDENT_SCREEN_FAIL:
      return {
        ...state,
        error: action.payload,
        screenLoading: false,
      };
    case GET_TOP_THREE_PACKAGE:
      return {
        ...state,
        topThreePackageDataLoading: true,
      };
    case GET_TOP_THREE_PACKAGE_SUCCESS:
      return {
        ...state,
        topThreePackageDataLoading: false,
        topThreePackageData: action.payload.data,
      };
    case GET_TOP_THREE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        topThreePackageDataLoading: false,
      };
    case STORE_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
