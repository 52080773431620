import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { AudioPlayer } from "../../../components/Audio/AudioPlayer";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { useAudioPlayer } from "../../../Hooks/AudioPlayerHooks";
import { storeQuestion } from "../../../store/Question/actions";
import Questions from "./Questions";
const track =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/1649152157808-audio.ogg";

const ViewListeningResult = ({ ans }) => {
  const {
    audioPlayer,
    backward,
    changeRange,
    currentTime,
    duration,
    forward,
    isPlaying,
    // play,
    progressBar,
    mute,
    // setIsPlaying,
    // timeTravel,
    togglePlayPause,
    toggleMute,
    reinitialize,
    // timeJump,
  } = useAudioPlayer();
  const dispatch = useDispatch();

  const { listeningQuestion, currentIndex } = useSelector((state) => ({
    currentPassage: state.QuesReducer.currentPassage,
    authtoken: state.Login.token,
    QuesReducer: state.QuesReducer,
    quesLoading: state.QuesReducer.quesLoading,
    listeningQues: state.QuesReducer,
    listeningAudio: state.QuesReducer.listeningQues?.listeningOnlyAudioModel,
    listeningQuestion: state.resultReducer.results?.setId?.listeningAudioModel,
    id: state.Login.id,
    currentIndex: state.QuesReducer.currentIndex,
  }));

  const currentData = listeningQuestion?.[currentIndex];

  const getAudioUrl = (audio) => {
    if (audio) {
      return audio?.startsWith("h") ? audio : imageBaseUrl + audio;
    }
    return track;
  };
  const data = {
    audioPlayer,
    backward: () => backward(5),
    changeRange,
    currentTime,
    duration,
    forward: () => forward(5),
    isPlaying,
    progressBar,
    togglePlayPause,
    track: getAudioUrl(currentData?.audioUrl),
    mute,
    toggleMute,
  };

  // const handleScroll = (element, number) => {
  //   const el = document.getElementById(element);
  //   // window.scrollTo(ele.offsetLeft, ele.offsetTop);
  //   // ele?.scrollIntoView({ offset: { top: -250 } });
  //   // ele.scrollTop -= 10;
  //   let passage = 0;
  //   listeningQuestion?.forEach((data, index) => {
  //     let questionPosition = 0;
  //     if (index > 0) {
  //       for (let i = 0; i < index; i++) {
  //         const previous = listeningQuestion?.[i];
  //         const lastQuestion =
  //           previous?.questions?.[previous?.questions?.length - 1];
  //         questionPosition +=
  //           lastQuestion?.Que?.question?.[
  //             lastQuestion?.Que?.question?.length - 1
  //           ]?.questionNumber;
  //       }
  //     }
  //     data?.questions?.forEach((ques) => {
  //       ques?.Que?.question?.forEach((list) => {
  //         const questonNum = list?.questionNumber + questionPosition;
  //         if (questonNum == number) {
  //           passage = index;
  //         }
  //       });
  //     });
  //   });
  //
  //   dispatch(storeQuestion('currentIndex', passage));
  //   var offset = 100;
  //   window.scroll({ top: el?.offsetTop - offset, left: 0, behavior: 'smooth' });
  // };
  // useEffect(() => {
  //   if (currentData && currentIndex > -1) {
  //     timeJump(0);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentIndex, currentData]);

  const handleNext = () => {
    reinitialize();
    dispatch(storeQuestion("currentIndex", currentIndex + 1));
  };
  const handleBack = () => {
    reinitialize();
    dispatch(storeQuestion("currentIndex", currentIndex - 1));
  };

  return (
    <React.Fragment>
      <Container>
        <div className="listening-exam-inner-wrap">
          <Row className="g-0 justify-content-center">
            <Col lg={10}>
              <div className="listening-ques-main-area-left-part">
                <div
                  className="listening-media-player-wrap "
                  style={{ position: "initial" }}
                >
                  <div className="audio-player-ques">
                    <AudioPlayer data={data} />
                  </div>
                </div>
                {/* list */}
                <div className="px-3">
                  <Questions
                    ans={ans}
                    view
                    question={currentData?.questions}
                    data={currentData}
                    index={currentIndex}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="exam-ques-footer-main-wrap position-relative">
        <div className="ques-footer-top">
          <div className="exam-f-left">
            <h5>Section {currentIndex + 1}</h5>
          </div>
          <div className="exam-f-right">
            <div className="exam-h-marks exam-h-t-right-button d-flex justify-content-center align-items-center">
              {currentIndex > 0 ? (
                <button
                  className="btn me-2"
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  onClick={handleBack}
                >
                  <i className="fa fa-angle-left"></i> Previous
                </button>
              ) : null}
              {currentIndex < listeningQuestion?.length - 1 ? (
                <button
                  className="btn btn-primary"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleNext}
                >
                  Next Section <i className="fa fa-angle-right"></i>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewListeningResult;
