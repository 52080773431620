import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import NoData from "../../../components/Common/NoData";
import PackageCard from "../../../components/Package/PackageCard";
import { getVerifyFacilitator } from "../../../store/Facilitator/actions";
import { getUserService } from "../../../store/Service/actions";

const ViewSinglePackage = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const { userService, authtoken, userServiceLoading } = useSelector(
    (state) => ({
      userService: state.serviceReducer.userService,
      authtoken: state.Login.token,
      userServiceLoading: state.serviceReducer.userServiceLoading,
      verifyFacilitator: state.facilitator.verifyFacilitator,
    })
  );
  // console.log("verifyFacilitator", verifyFacilitator);
  // console.log(`ViewSinglePackage ~ userService`, userService);

  useEffect(() => {
    dispatch(getUserService(authtoken, id, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getVerifyFacilitator(authtoken, id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="">
              <h4>Full Package Vo: 1</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          {userServiceLoading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : userService?.length ? (
            <PackageCard userService={userService} />
          ) : (
            <div>
              <NoData>
                <h6>You have no Services</h6>
              </NoData>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewSinglePackage;
