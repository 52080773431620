import React, { useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { Col, Row } from "reactstrap";
import gamatPageImage from "./gamatPageImage.png";
const GmatPackage = () => {
  useEffect(() => {
    window?.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="gmat-package-main-wrap-entry ">
      <MetaTags>
        <title>Gmat package</title>
      </MetaTags>
      <Container>
        <Row>
          <Col lg={4} className="my-auto me-5">
            <div className="gmat-imageleft-wrap">
              <img src={gamatPageImage} alt="" />
            </div>
          </Col>
          <Col lg={6} className="my-auto ms-5 ps-5">
            <div className="gmat-content-right-wrap">
              <p className="me-5 my-4" style={{ color: "#74788D" }}>
                Currently in progress, however, if you would like to hear from
                us regarding any upcoming news, events and changes, please share
                your email address.
              </p>
              <Form className="me-5">
                <Form.Group className="mb-3 notify-content-hjbdkf">
                  <Form.Label style={{ color: "#74788D" }}>
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Type your email address"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="notify-main-btn"
                >
                  <i className="fa-solid fa-bell"></i>
                  Notify me
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GmatPackage;
