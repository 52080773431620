import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDashboardProfile } from "../../store/auth/profile/actions";
import { removeAllCart } from "../../store/cart/actions";
import "./dashboard.scss";
import DashboardBody2 from "./DashboardBody2";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Dashboard2 = () => {
  let query = useQuery();
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => ({
    error: state.Login.error,
    ordering: state.OrderReducer.ordering,
    id: state.Login.id,
    authToken: state.Login.token,
    data: state.Profile.dashboard,
  }));

  useEffect(() => {
    const data = query.get("cart");
    if (data === "1") {
      dispatch(removeAllCart());
    }
    dispatch(getDashboardProfile(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <DashboardBody2 />
    </React.Fragment>
  );
};

export default Dashboard2;
