import React from "react";

const FacilitatorIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99935 0.166992C2.77935 0.166992 0.166016 2.78033 0.166016 6.00033C0.166016 9.22033 2.77935 11.8337 5.99935 11.8337C9.21935 11.8337 11.8327 9.22033 11.8327 6.00033C11.8327 2.78033 9.21935 0.166992 5.99935 0.166992ZM9.06185 6.47283C9.06185 6.57199 9.05602 6.66533 9.04435 6.75866C8.95685 7.79116 8.34435 8.30449 7.22435 8.30449H7.07268C6.97935 8.30449 6.88602 8.35116 6.82768 8.42699L6.36685 9.03949C6.16268 9.31366 5.83602 9.31366 5.63185 9.03949L5.17102 8.42699C5.11852 8.36283 5.00768 8.30449 4.92602 8.30449H4.77435C3.55518 8.30449 2.93685 8.00116 2.93685 6.46699V4.93283C2.93685 3.81283 3.45602 3.20033 4.48268 3.11283C4.57602 3.10699 4.67518 3.10699 4.77435 3.10699H7.22435C8.44352 3.10699 9.06185 3.71949 9.06185 4.94449V6.47283Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default FacilitatorIcon;
