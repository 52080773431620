import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import star from "../../assets/images/Icon/star.svg";
import image from "../../assets/images/Image.png";
import Loader from "../../components/Atoms/Loader";
import AddLoader from "../../components/Common/AddLoader";
import { imageBaseUrl } from "../../helpers/api_helper";
import { addWishList, getWishList } from "../../store/Wishlist/actions";
import emptyBlogImg from "../Package/MyPackage/img/emptyBlog.png";
import FacilitatorIcon from "./PackageIcon/FacilitatorIcon";
import ListeningIcon from "./PackageIcon/ListeningIcon";
import ReadingIcon from "./PackageIcon/ReadingIcon";
import SpeakingIcon from "./PackageIcon/SpeakingIcon";
import WritingIcon from "./PackageIcon/WritingIcon";
import "./student-package.scss";
const FavoriteCourse = () => {
  const dispatch = useDispatch();
  const { authtoken, loading, wishList, adding } = useSelector((state) => ({
    userPackage: state.PackageReducer.userPackage,
    authtoken: state.Login.token,
    loading: state.wishlist.loading,
    id: state.Login.id,
    wishList: state.wishlist.wishList,
    adding: state.wishlist.adding,
  }));

  //   const wishListId = wishList.map((data) => data?.package?._id);

  useEffect(() => {
    dispatch(getWishList(authtoken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddWishlist = (data) => {
    dispatch(addWishList(authtoken, data?._id, true));
    // }
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return image;
  };

  return (
    <React.Fragment>
      {adding ? <AddLoader /> : null}
      <div className="dashboard-main-wrap my-course-page">
        <Container>
          <div className="ieltspackage-main-wrap-all-package pt-3">
            <Container>
              <div className="package-area-title-wrap all-package">
                <div className="package-title-area-left-part">
                  <h4>My favorite package</h4>
                </div>
              </div>
              {loading ? (
                <div className="text-center  py-5">
                  <Loader />
                </div>
              ) : (
                <Row>
                  {wishList?.length > 0 ? (
                    (wishList || []).map((data, key) => (
                      <Col lg={4} key={key}>
                        <div className="student-courses-card">
                          <Card className=" position-relative">
                            <div
                              className="bookmark-button-wrap position-absolute"
                              style={{
                                background: "#fff",
                                padding: "5px",
                                lineHeight: "5px",
                                borderRadius: "5px",
                                top: "2%",
                                right: "2%",
                                fontSize: "18px",
                              }}
                              onClick={() => handleAddWishlist(data)}
                            >
                              <i
                                className="bx bxs-bookmark"
                                style={{ color: "#004AB8" }}
                              ></i>
                            </div>
                            <Card.Img
                              variant="top"
                              src={`${getPhotoUrl(data?.package?.photo)}`}
                            />
                            <Card.Body>
                              <Link
                                to={`/package-details/${data?.package?.id}`}
                              >
                                <div className="course-review--card-area-top">
                                  <div className="student-courses-review">
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <span> 5.00(5)</span>
                                  </div>
                                  <Card.Text className="student-courses-timeline header-right-timeline-header">
                                    <i className="bx bx-time"></i>
                                    {/* {data?.validity} */}
                                    Days
                                  </Card.Text>
                                </div>
                                <Card.Title>
                                  {data.package.packageName}
                                </Card.Title>
                                <div className="package-subject-name-list-item">
                                  {data?.package?.packageService.map(
                                    (service, idx) => (
                                      <>
                                        {service?.serviceName.toLowerCase() ===
                                          "listening" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <ListeningIcon />
                                            <span>
                                              {data?.package?.listeningQ}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "reading" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <ReadingIcon />
                                            <span>
                                              {data?.package?.readingQ}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "writing" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <WritingIcon />
                                            <span>
                                              {data?.package?.writingQ}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "speaking" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <SpeakingIcon />
                                            <span>
                                              {data?.package?.speakingQ}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                  {data?.package?.totalFacilitator ? (
                                    <div className="package-subject-name-list-item-single-item facilitator-review">
                                      <FacilitatorIcon />
                                      <span>
                                        {data?.totalFacilitator} One to One
                                        Review
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Link>

                              <div className="student-courses-card-button">
                                <Link
                                  to={`/package-details/${data?.package?.id}`}
                                  className="btn btn-primary"
                                >
                                  View Details
                                </Link>
                                <p>
                                  {data?.package?.discount > 0 ? (
                                    <div>
                                      TK. <del>{data?.package?.price}</del>{" "}
                                      <b>{data?.package?.discount}</b>
                                    </div>
                                  ) : (
                                    <b>TK. {data?.package?.price}</b>
                                  )}
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div>
                      <div className="empty-package-area-wrap">
                        <div>
                          <img src={emptyBlogImg} alt="" />
                          <h6>Ops! currently you have no packages</h6>
                          <Link to="/packages/Ielts">Buy Package</Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FavoriteCourse;
