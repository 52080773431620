import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import xamProLogo from "../../assets/images/XamproLogo.svg";
import "./footer.scss";
import insight from "./insight.svg";
import "./student-footer.css";
import sslImage from "./xamproSSL.png";

const StudentFooter = () => {
  return (
    <div>
      <div className="footer-main-area">
        <div className="container">
          <div className="footer-inner-wrap">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="footer-single-item">
                  <div className="footer-single-item-title">
                    <Link to="/">
                      <img src={xamProLogo} alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-single-item-content">
                    <p>
                      Need a solution for mockup exams? <br /> Or perhaps exam
                      supervision? XamPro <br /> is the answer.
                    </p>

                    {/* <Row>
                  <Col lg={6} sm={6}>
                    <div className="footer-left-copyright-area">
                      <p className="footer-mobile-hide mt-3">
                        © CourseWare 2022
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} sm={6}>
                    <div className="footer-right-copyright-area">
                      <ul>
                        <li>
                          <Link to="privecy-policy" className="nav-link">
                            Privecy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="privecy-policy" className="nav-link">
                            Privecy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="privecy-policy" className="nav-link">
                            Privecy Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-12">
                <div className="footer-single-item footer-item-get-in-touch  mobile-hide-footer">
                  <div className="footer-single-item-title">
                    <h5>Get in Touch</h5>
                  </div>
                  <div className="footer-single-item-content">
                    <span>
                      <span>
                        <i className="bx bx-map"></i>
                      </span>
                      <span>
                        Registered Address: Ta-143/A, Moddho Badda (3rd Floor),
                        <br />
                        Gulshan, Badda, Dhaka - 1212
                      </span>
                    </span>
                    <span>
                      <span>
                        <i className="bx bx-map"></i>
                      </span>
                      <span>House 15, Road 24, Gulshan - 2 Dhaka - 1212</span>
                    </span>
                    <span>
                      <span>
                        <i className="bx bx-envelope"></i>
                      </span>
                      <span>solutionsmaac@gmail.com</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="footer-single-item">
                  <div className="footer-single-item-title">
                    <h5>Want some insights?</h5>
                  </div>
                  <div className="footer-single-item-content insights-image">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://maacsolutions.com/insights"
                    >
                      <img src={insight} alt="" />
                    </a>
                    {/* <label>Your Email</label>
                <input type="email" placeholder="Enter Your Email" />
                <button>Subscribe</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-main-content-wrap">
        <Container>
          <div className="ssl-commerce-payment-bank-list">
            <img src={sslImage} alt="" />
          </div>
        </Container>
        <hr />
        <div className="footer-copyright-area-bottom">
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-left">
                  <p>© {new Date().getFullYear()} XamPro</p>
                </div>
              </Col>
              <Col lg={8}>
                <div className="footer-right">
                  <ul>
                    <li>
                      <Link to="/term-and-condition">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/refound-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
    // <div>
    //   <div className="ssl-commerce-image-bg-wrap">
    //     {" "}
    //     <Container>
    //       <Row>
    //         <div className="ssl-commerce-image-wrap">
    //           <img src={sslImage} alt="" />
    //         </div>
    //       </Row>{" "}
    //     </Container>
    //   </div>
    //   <span className="ssl-commerce-image-devider" />
    //   <div className="footer-main-wrap">
    //     <Container>
    //       <Row>
    //         <Col lg={6}>
    //           <div className="footer-left">
    //             {/* <p>© {new Date().getFullYear()} MAAC all right reserve</p> */}
    //           </div>
    //         </Col>
    //         <Col lg={6}>
    //           <div className="footer-right">
    //             <ul>
    //               <li>
    //                 <Link to="/term-and-condition">Terms & Condition</Link>
    //               </li>
    //               <li>
    //                 <Link to="/privacy-policy">Privacy Policy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/refound-policy">Refund Policy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/about-us">About us</Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </div>
  );
};

export default StudentFooter;
