import {
  UPDATE_PROFILE_PASS,
  UPDATE_PROFILE_PASS_ERROR,
  UPDATE_PROFILE_PASS_SUCCESS,
} from "./actionTypes"

export const updateProfilePass = values => {
  return {
    type: UPDATE_PROFILE_PASS,
    payload: { values },
  }
}

export const updateProfilePassSuccess = message => {
  return { type: UPDATE_PROFILE_PASS_SUCCESS, payload: { message } }
}
export const updateProfilePassError = message => {
  return {
    type: UPDATE_PROFILE_PASS_ERROR,
    payload: { message },
  }
}
