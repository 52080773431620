import React from "react";
import { Container } from "react-bootstrap";
import aboutHeroBanner from "./img/heroBanner.png";
const AboutHero = () => {
  return (
    <div>
      <Container>
        <div className="about-hero-main-wrap text-center">
          <h5>ABOUT US</h5>
          <h2 className="mb-5">
            Learn Your Most Favourite <br /> Couse with us
          </h2>
          {/* <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The Maker
            is a decentralized.{" "}
          </p> */}
          <img src={aboutHeroBanner} alt="" />
        </div>
      </Container>
    </div>
  );
};

export default AboutHero;
