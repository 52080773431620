import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import defaultLeaderImg from "../../assets/images/leader.png";
import Loader from "../../components/Atoms/Loader";
import { imageBaseUrl } from "../../helpers/api_helper";
import { getLeaderBoardResult } from "../../store/Dashboard/actions";
import EmptyLeaderboard from "./EmptyLeaderboard";
import bgStarImagre from "./img/starImage.png";
import "./leaderboard.scss";
const Leaderboard = () => {
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState("reading");
  const [month, setMonth] = React.useState(moment().format("MM"));
  const [year, setYear] = React.useState(moment().format("YYYY"));
  const { authToken, resultLeaderDashboard, resultLeaderDashboardLoading } =
    useSelector((state) => ({
      loading: state.DashboardReducer.loading,
      authToken: state.Login.token,
      resultLeaderDashboard: state.DashboardReducer.resultLeaderDashboard,
      resultLeaderDashboardLoading:
        state.DashboardReducer.resultLeaderDashboardLoading,
    }));
  console.log("resultLeaderDashboard", resultLeaderDashboard);
  let serialNum = 1;
  useEffect(() => {
    dispatch(
      getLeaderBoardResult(authToken, {
        serviceName,
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-31`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName, month, year]);

  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return defaultLeaderImg;
  };
  const handleService = (e) => {
    setServiceName(e.target.value);
  };
  const handleChangeMonth = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setMonth(value);
    }
  };
  const handleChangeYear = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setYear(value);
    }
  };
  return (
    <div>
      {resultLeaderDashboardLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Loader />
        </div>
      ) : (
        <Container>
          <div className="leaderboard-main-area-wrap">
            <div className="leaderboard-top-area-title">
              <div className="leaderboard-top-area-left">
                <h3>Leaderboard</h3>
              </div>
              <div className="leaderboard-top-area-right">
                <div className="leaderboard-filter-single">
                  <Form.Select
                    onChange={(e) => handleService(e)}
                    value={serviceName}
                  >
                    <option value="all">Overall</option>
                    <option value="reading">Reading</option>
                    <option value="writing">Writing</option>
                    <option value="listening">Listening</option>
                    <option value="speaking">Speaking</option>
                  </Form.Select>
                </div>{" "}
                <div className="leaderboard-filter-single">
                  <Form.Select onChange={handleChangeMonth} value={month}>
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Select>
                </div>
                <div className="leaderboard-filter-single">
                  <Form.Select onChange={handleChangeYear} value={year}>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </Form.Select>
                </div>
              </div>
            </div>
            {resultLeaderDashboard?.length ? (
              <div className="leaderboard-inner-area-wrap">
                <div
                  className="leaderboard-top-three"
                  style={{ backgroundImage: `url(${bgStarImagre})` }}
                >
                  <div className="leaderboard-top-three-inner">
                    <>
                      <>
                        <div className="leaderboard-single-persion">
                          <img
                            src={`${getPhotoUrl(
                              serviceName === "all"
                                ? ""
                                : resultLeaderDashboard?.[1]?.studentInfo[0]
                                    ?.photo
                            )}`}
                            alt=""
                          />
                          <h6>2nd</h6>
                          <div className="leaderboard-profile">
                            <h5>
                              {" "}
                              {serviceName === "all"
                                ? resultLeaderDashboard?.[1]?.fullName
                                : resultLeaderDashboard?.[1]?.studentInfo[0]
                                    ?.fullName}
                            </h5>
                            <h3>
                              {serviceName === "all"
                                ? Math.round(
                                    resultLeaderDashboard?.[1]?.examTotal / 4
                                  ) || "N/A"
                                : resultLeaderDashboard?.[1]?.score}
                            </h3>
                          </div>
                        </div>
                      </>
                      <>
                        <div className="leaderboard-single-persion score-topper">
                          <img
                            src={`${getPhotoUrl(
                              serviceName === "all"
                                ? ""
                                : resultLeaderDashboard?.[0]?.studentInfo[0]
                                    ?.photo
                            )}`}
                            alt=""
                          />
                          <h6>1st</h6>
                          <div className="leaderboard-profile">
                            <h5 className="mt-4">
                              {serviceName === "all"
                                ? resultLeaderDashboard?.[0]?.fullName
                                : resultLeaderDashboard?.[0]?.studentInfo[0]
                                    ?.fullName}
                            </h5>
                            <h3>
                              {" "}
                              {serviceName === "all"
                                ? Math.round(
                                    resultLeaderDashboard?.[0]?.examTotal / 4
                                  )
                                : resultLeaderDashboard?.[0]?.score}
                            </h3>
                          </div>
                        </div>
                      </>
                      <>
                        <div className="leaderboard-single-persion">
                          <img
                            src={`${getPhotoUrl(
                              serviceName === "all"
                                ? ""
                                : resultLeaderDashboard?.[2]?.studentInfo[0]
                                    ?.photo
                            )}`}
                            alt=""
                          />
                          <h6>3rd</h6>
                          <div className="leaderboard-profile">
                            <h5>
                              {" "}
                              {serviceName === "all"
                                ? resultLeaderDashboard?.[2]?.fullName
                                : resultLeaderDashboard?.[2]?.studentInfo[0]
                                    ?.fullName}
                            </h5>
                            <h3>
                              {" "}
                              {serviceName === "all"
                                ? Math.round(
                                    resultLeaderDashboard?.[2]?.examTotal / 4
                                  ) || "N/A"
                                : resultLeaderDashboard?.[2]?.score}
                            </h3>
                          </div>
                        </div>
                      </>
                    </>
                  </div>
                </div>
                <div className="leaderboard-all-list">
                  <Table striped borderless hover>
                    <thead className="leaderboard-all-list-header">
                      <tr>
                        <th>Rank.</th>
                        <th>Photo</th>
                        <th>Name</th>

                        <th>Subject</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultLeaderDashboard?.length ? (
                        resultLeaderDashboard.slice(0, 10).map((user, idx) => (
                          <tr>
                            <td>
                              {idx == 0
                                ? 1
                                : user.score !==
                                  resultLeaderDashboard?.[idx - 1]?.score
                                ? (serialNum = serialNum + 1)
                                : serialNum}
                            </td>
                            <td>
                              <div className="user-profile-img">
                                <img
                                  src={`${getPhotoUrl(
                                    serviceName === "all"
                                      ? ""
                                      : user?.studentInfo?.[0]?.photo
                                  )}`}
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              {serviceName === "all"
                                ? user?.fullName
                                : user?.studentInfo?.[0]?.fullName}
                            </td>

                            <td>{serviceName}</td>
                            <td>
                              {serviceName === "all"
                                ? Math.round(user?.examTotal / 4) || "N/A"
                                : user?.score}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>
                          <p>Result List is Empty</p>
                        </div>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : (
              <EmptyLeaderboard />
            )}
          </div>
        </Container>
      )}
    </div>
  );
};

export default Leaderboard;
