import React from "react";
import { Col, Row } from "react-bootstrap";
import BlogPostArea from "./BlogPostArea";
import DashboardFeedback from "./DashboardFeedback";
import Graph from "./Graph/Graph";

import OngoingPackageSlider from "./OngoingPackageSlider";
const DashboardContentBody = () => {
  return (
    <div className="content-new-body-main-content-items">
      <Row>
        <Col lg={"8"}>
          <div className="dashboard--new-main-content-area-wrap">
            <OngoingPackageSlider />
            <Graph />
          </div>
        </Col>
        <Col lg={"4"}>
          <aside>
            <div className="dashboard-right-sidebar-wrap">
              <BlogPostArea />
              <DashboardFeedback />
            </div>
          </aside>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardContentBody;
