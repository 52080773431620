import React from "react";
import { Container } from "react-bootstrap";
import "./style/new-hero-info.scss";
const NewHeroInfo = () => {
  return (
    <div>
      <Container>
        <div className="new-hero-info-inner-area">
          <div className="single-new-info-item">
            <h2>10+</h2>
            <p>Packages available</p>
          </div>{" "}
          <div className="single-new-info-item-divider"></div>
          <div className="single-new-info-item">
            <h2>250+</h2>
            <p>Students Joined</p>
          </div>{" "}
          <div className="single-new-info-item-divider"></div>
          <div className="single-new-info-item">
            <h2>10+</h2>
            <p>Expert Facilitators</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewHeroInfo;
