import {
  ADD_WISHLIST,
  ADD_WISHLIST_FAIL,
  ADD_WISHLIST_SUCCESS,
  GET_WISHLIST,
  GET_WISHLIST_FAIL,
  GET_WISHLIST_SUCCESS,
} from './actionTypes';

export const addWishList = (authtoken, id, isRemove) => ({
  type: ADD_WISHLIST,
  payload: { authtoken, id, isRemove },
});

export const addWishListSuccess = (data) => ({
  type: ADD_WISHLIST_SUCCESS,
  payload: data,
});

export const addWishListFail = (error) => ({
  type: ADD_WISHLIST_FAIL,
  payload: error,
});

export const getWishList = (authtoken) => ({
  type: GET_WISHLIST,
  payload: { authtoken },
});

export const getWishListSuccess = (data) => ({
  type: GET_WISHLIST_SUCCESS,
  payload: { data },
});

export const getWishListFail = (error) => ({
  type: GET_WISHLIST_FAIL,
  payload: error,
});
