import {
  ADD_FACILITATOR_SLOT,
  ADD_FACILITATOR_SLOT_FAIL,
  ADD_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_REVIEW_FAIL,
  GET_FACILITATOR_REVIEW_SUCCESS,
  GET_FACILITATOR_SERVICE,
  GET_FACILITATOR_SERVICE_FAIL,
  GET_FACILITATOR_SERVICE_SUCCESS,
  GET_PARTICULAR_SLOT_FAIL,
  GET_PARTICULAR_SLOT_SUCCESS,
  GET_REVIEW_ATTENDANCE,
  GET_REVIEW_ATTENDANCE_FAIL,
  GET_REVIEW_ATTENDANCE_SUCCESS,
  GET_STUDENT_FACILITATOR_SLOT_FAIL,
  GET_STUDENT_FACILITATOR_SLOT_SUCCESS,
  GET_VERIFY_FACILITATOR_FAIL,
  GET_VERIFY_FACILITATOR_SUCCESS,
} from "./actionTypes";

const initialState = {
  verifyFacilitator: {},
  verifyFacilitatorLoading: true,
  error: "",
  speakingSuccess: {},
  loading: false,
  message: "",
  facililatorSlot: {},
  facilitatorService: {},
  adding: false,
  facilitatorReview: [],
  particularSlot: [],
  FacilitatorReviewLoading: false,
  particularSlotLoading: true,
  facilitatorServiceLoading: false,
};

const FacilitatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FACILITATOR_SLOT:
      state = {
        ...state,
        adding: true,
      };
      break;
    case ADD_FACILITATOR_SLOT_SUCCESS:
      state = {
        ...state,
        speakingSuccess: action.payload.message,
        adding: false,
      };
      break;
    case ADD_FACILITATOR_SLOT_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case GET_VERIFY_FACILITATOR_SUCCESS:
      state = {
        ...state,
        verifyFacilitator: action.payload,
        verifyFacilitatorLoading: false,
      };
      break;
    case GET_VERIFY_FACILITATOR_FAIL:
      state = {
        ...state,
        error: action.payload,
        verifyFacilitatorLoading: true,
      };
      break;
    case GET_STUDENT_FACILITATOR_SLOT_SUCCESS:
      state = {
        ...state,
        loading: false,
        facililatorSlot: action.payload.values,
      };
      break;
    case GET_STUDENT_FACILITATOR_SLOT_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;
    case GET_FACILITATOR_SERVICE:
      state = {
        ...state,
        facilitatorServiceLoading: true,
      };
      break;
    case GET_FACILITATOR_SERVICE_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorService: action.payload.data,
        facilitatorServiceLoading: false,
      };
      break;
    case GET_FACILITATOR_SERVICE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        facilitatorServiceLoading: false,
      };
      break;
    case GET_FACILITATOR_REVIEW_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorReview: action.payload,
        FacilitatorReviewLoading: false,
      };
      break;
    case GET_FACILITATOR_REVIEW_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        FacilitatorReviewLoading: false,
      };
      break;
    case GET_REVIEW_ATTENDANCE:
      state = {
        ...state,

        attendanceLoading: true,
      };
      break;
    case GET_REVIEW_ATTENDANCE_SUCCESS:
      state = {
        ...state,

        attendanceLoading: false,
      };
      break;
    case GET_REVIEW_ATTENDANCE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        attendanceLoading: false,
      };
      break;

    case GET_PARTICULAR_SLOT_SUCCESS:
      state = {
        ...state,
        error: "",
        particularSlot: action.payload,
        particularSlotLoading: false,
      };
      break;
    case GET_PARTICULAR_SLOT_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        particularSlotLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FacilitatorReducer;
