import React from "react";
import emptyImage from "./img/empty-leaderboard.svg";
const EmptyLeaderboard = () => {
  return (
    <div className="empty-leader-board-container-area">
      <div className="empty-leader-board-main-area-wrap">
        <div className="empty-content-area">
          <img src={emptyImage} alt="" />
          <p>No one has participated in the test so far</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyLeaderboard;
