export const GET_VERIFY_FACILITATOR = "GET_VERIFY_FACILITATOR";
export const GET_VERIFY_FACILITATOR_SUCCESS = "GET_VERIFY_FACILITATOR_SUCCESS";
export const GET_VERIFY_FACILITATOR_FAIL = "GET_VERIFY_FACILITATOR_FAIL";

export const GET_FACILITATOR_SERVICE = "GET_FACILITATOR_SERVICE";
export const GET_FACILITATOR_SERVICE_SUCCESS =
  "GET_FACILITATOR_SERVICE_SUCCESS";
export const GET_FACILITATOR_SERVICE_FAIL = "GET_FACILITATOR_SERVICE_FAIL";

export const GET_STUDENT_FACILITATOR_SLOT = "GET_STUDENT_FACILITATOR_SLOT";
export const GET_STUDENT_FACILITATOR_SLOT_SUCCESS =
  "GET_STUDENT_FACILITATOR_SLOT_SUCCESS";
export const GET_STUDENT_FACILITATOR_SLOT_FAIL =
  "GET_STUDENT_FACILITATOR_SLOT_FAIL";
export const ADD_FACILITATOR_SLOT = "ADD_FACILITATOR_SLOT";
export const ADD_FACILITATOR_SLOT_SUCCESS = "ADD_FACILITATOR_SLOT_SUCCESS";
export const ADD_FACILITATOR_SLOT_FAIL = "ADD_FACILITATOR_SLOT_FAIL";

export const GET_FACILITATOR_REVIEW = "GET_FACILITATOR_REVIEW";
export const GET_FACILITATOR_REVIEW_SUCCESS = "GET_FACILITATOR_REVIEW_SUCCESS";
export const GET_FACILITATOR_REVIEW_FAIL = "GET_FACILITATOR_REVIEW_FAIL";

export const GET_REVIEW_ATTENDANCE = "GET_REVIEW_ATTENDANCE";
export const GET_REVIEW_ATTENDANCE_SUCCESS = "GET_REVIEW_ATTENDANCE_SUCCESS";
export const GET_REVIEW_ATTENDANCE_FAIL = "GET_REVIEW_ATTENDANCE_FAIL";

export const GET_PARTICULAR_SLOT = "GET_PARTICULAR_SLOT";
export const GET_PARTICULAR_SLOT_SUCCESS = "GET_PARTICULAR_SLOT_SUCCESS";
export const GET_PARTICULAR_SLOT_FAIL = "GET_PARTICULAR_SLOT_FAIL";
