import React from "react";

const SpeakingIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9.625C5.71843 9.625 4.67578 8.58236 4.67578 7.30078V4.01953C4.67578 2.73796 5.71843 1.69531 7 1.69531C8.28157 1.69531 9.32422 2.73796 9.32422 4.01953V7.30078C9.32422 8.58236 8.28157 9.625 7 9.625Z"
        fill="#0052CC"
      />
      <path
        d="M10.8281 5.25H10.1445V6.07031H10.418V7.30078C10.418 9.18545 8.88467 10.7188 7 10.7188C5.11533 10.7188 3.58203 9.18545 3.58203 7.30078V6.07031H3.85547V5.25H3.17188C2.94536 5.25 2.76172 5.43364 2.76172 5.66016V7.30078C2.76172 9.63777 4.66298 11.5391 7 11.5391C9.33702 11.5391 11.2383 9.63777 11.2383 7.30078V5.66016C11.2383 5.43364 11.0546 5.25 10.8281 5.25Z"
        fill="#0052CC"
      />
      <path
        d="M8.43555 13.1798H7.41016V12.3428C7.27483 12.3537 7.13809 12.3595 7 12.3595C6.86191 12.3595 6.72517 12.3537 6.58984 12.3428V13.1798H5.56445C5.33794 13.1798 5.1543 13.3634 5.1543 13.5899C5.1543 13.8164 5.33794 14.0001 5.56445 14.0001H8.43555C8.66206 14.0001 8.8457 13.8164 8.8457 13.5899C8.8457 13.3634 8.66206 13.1798 8.43555 13.1798Z"
        fill="#0052CC"
      />
      <path
        d="M10.3086 3.14062C10.5351 3.14062 10.7188 2.95699 10.7188 2.73047C10.7188 2.50395 10.5351 2.32031 10.3086 2.32031C10.0821 2.32031 9.89844 2.50395 9.89844 2.73047C9.89844 2.95699 10.0821 3.14062 10.3086 3.14062Z"
        fill="#0052CC"
      />
      <path
        d="M11.4687 4.30064C11.3638 4.30064 11.2588 4.2606 11.1787 4.18051C11.0185 4.02033 11.0185 3.76065 11.1787 3.60047C11.6585 3.12072 11.6585 2.34006 11.1787 1.86031C11.0185 1.70013 11.0185 1.44045 11.1787 1.28027C11.3389 1.12012 11.5986 1.12012 11.7588 1.28027C12.5583 2.07988 12.5583 3.3809 11.7588 4.18051C11.6787 4.26058 11.5737 4.30064 11.4687 4.30064Z"
        fill="#0052CC"
      />
      <path
        d="M12.6289 5.46069C12.5239 5.46069 12.419 5.42066 12.3389 5.34055C12.1787 5.18037 12.1787 4.92068 12.3389 4.7605C12.8812 4.21822 13.1798 3.49725 13.1798 2.73034C13.1798 1.96346 12.8812 1.24246 12.3389 0.700177C12.1787 0.540025 12.1787 0.280314 12.3389 0.120135C12.499 -0.0400449 12.7588 -0.0400449 12.9189 0.120135C13.6161 0.817345 14.0001 1.74433 14.0001 2.73034C14.0001 3.71636 13.6161 4.64334 12.9189 5.34055C12.8388 5.42064 12.7339 5.46069 12.6289 5.46069Z"
        fill="#0052CC"
      />
      <path
        d="M3.69141 3.14062C3.91793 3.14062 4.10156 2.95699 4.10156 2.73047C4.10156 2.50395 3.91793 2.32031 3.69141 2.32031C3.46488 2.32031 3.28125 2.50395 3.28125 2.73047C3.28125 2.95699 3.46488 3.14062 3.69141 3.14062Z"
        fill="#0052CC"
      />
      <path
        d="M2.5303 4.30067C2.42533 4.30067 2.32036 4.26064 2.24029 4.18052C1.44074 3.38091 1.44074 2.07989 2.24029 1.28028C2.40045 1.12013 2.66016 1.1201 2.82034 1.28028C2.98052 1.44046 2.98052 1.70014 2.82034 1.86032C2.34059 2.34007 2.34059 3.12074 2.82034 3.60048C2.98052 3.76066 2.98052 4.02034 2.82034 4.18052C2.74025 4.26061 2.63525 4.30067 2.5303 4.30067Z"
        fill="#0052CC"
      />
      <path
        d="M1.37123 5.46069C1.26626 5.46069 1.16129 5.42066 1.08123 5.34054C0.383988 4.64333 0 3.71635 0 2.73033C0 1.74432 0.383988 0.817336 1.0812 0.120125C1.24138 -0.0400552 1.50109 -0.0400279 1.66124 0.120125C1.82142 0.280304 1.82142 0.539988 1.66124 0.700168C1.11896 1.24245 0.820312 1.96342 0.820312 2.73033C0.820312 3.49724 1.11896 4.21824 1.66124 4.76053C1.82142 4.92068 1.82142 5.18039 1.66124 5.34057C1.58115 5.42063 1.47618 5.46069 1.37123 5.46069Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default SpeakingIcon;
