import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useParams, withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Loader from "../../components/Atoms/Loader";
import CardComponent from "../../components/Common/CardComponent";
import { toCapitalize } from "../../helpers/custom/capitalize";
import { getWritingServiceSet } from "../../store/Service/actions";
import alartIcon from "./alert-circle.svg";
import RatingComponent from "./RatingComponent";
import "./services.scss";

const WritingService = ({ history }) => {
  const { packageId, serviceId, time, type, courseId } = useParams();
  const dispatch = useDispatch();
  const { authtoken, writingSet, loading } = useSelector((state) => ({
    userService: state.serviceReducer.userService,
    authtoken: state.Login.token,
    userServiceLoading: state.serviceReducer.userServiceLoading,
    loading: state.serviceReducer.loading,
    writingSet: state.serviceReducer.writingSet,
    profile: state.Profile?.data,
    serviceReducer: state.serviceReducer,
  }));

  useEffect(() => {
    localStorage.removeItem("xampro-xam");
    dispatch(
      getWritingServiceSet(
        authtoken,
        serviceId,
        time,
        packageId,
        type,
        courseId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [startXam, setStartXam] = useState(false);
  const [xam, setXam] = useState("");
  return (
    <React.Fragment>
      <RatingComponent />
      <Modal
        open={startXam}
        classNames={{
          overlay: "customOverlay",
          modal: "Xam-Confirm-Modal-main-wrap",
        }}
        center
      >
        <div className="start-xam-popup-inner-wrap">
          <div className="start-xam-pro-content-wrap">
            <i>
              <img src={alartIcon} alt="" />
            </i>
            <div className="start-xam-popup-content">
              <span>Attention, this is a limited time exam!!</span>
              <p>
                Are you ready to start the exam? You will be given 60 minutes to
                complete it.
              </p>
            </div>
          </div>
          <div className="popup-form-btn-container">
            <button
              onClick={() => setStartXam(false)}
              className="btn button-popup-background-1"
            >
              Cancel
            </button>
            <button
              onClick={() => history.push(`/writing-exam/${xam}/${packageId}`)}
              className="btn button-popup-background-2"
            >
              Start
            </button>
          </div>
        </div>
      </Modal>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="">
              <h4>Package Set</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          {loading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : (
            <div className="single-package-inner-main-wrap">
              <Row>
                {(writingSet?.getData || []).map((data, key) => (
                  <Col lg={4} md={6} key={key}>
                    <div className="card-active-deactive-content">
                      <div className="active-pending-top-right-massage">
                        <div className="course-active-btn">
                          <span className="color-success-text">Active</span>
                        </div>
                      </div>
                      <CardComponent className={"text-center py-4"}>
                        <div className="mx-auto">
                          <img
                            src={
                              require(`../../components/Package/img/writing.svg`)
                                .default
                            }
                            alt="examImage"
                            className="mb-3"
                          />
                        </div>
                        <div>
                          <h4>{toCapitalize(data?.setName)}</h4>
                          {writingSet?.examData?.[data?._id] > -1 ? (
                            <button
                              className="btn btn-primary ms-1"
                              onClick={() =>
                                history.push(
                                  `/package/${packageId}/result/${data?._id}/writing`
                                )
                              }
                            >
                              Result
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              // disabled={profile?.examDone?.reading !== key}
                              // onClick={() =>
                              //   history.push(`/reading-exam/${data._id}`)
                              // }
                              onClick={() => {
                                setStartXam(true);
                                setXam(data._id);
                              }}
                            >
                              Start Your Exam
                            </button>
                          )}
                          {/* </Link> */}
                        </div>
                      </CardComponent>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(WritingService);
