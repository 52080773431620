import React from "react";
// import TwitterLogin from "react-twitter-auth"
//Social Media Imports
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import googleIcon from "./Google__G__Logo.png";
const SocialLoginNew = ({ facebookResponse, googleResponse, signUp }) => {
  const facebookaAlert = () => {
    alert("Facebook Login Feature is under deployment");
  };
  return (
    <React.Fragment>
      <div className="account-with-social-media-link-bx-area">
        <p>
          <>OR</>
        </p>
        <div className="account-with-social-media-single-link-items-wrap">
          <GoogleLogin
            clientId="7356593061-46dtda224dduvh22um6f6nfqicp1trcj.apps.googleusercontent.com"
            render={(renderProps) => (
              <Link
                to="#"
                onClick={renderProps.onClick}
                className="account-with-social-media-google-plus"
              >
                <img src={googleIcon} alt="" />
                Google
              </Link>
            )}
            onSuccess={googleResponse}
            onFailure={googleResponse}
          />
          {/* <FacebookLogin
            appId="584271439434723"
            autoLoad={false}
            callback={facebookResponse}
            render={(renderProps) => (
              <Link
                to="#"
                onClick={() => facebookaAlert()}
                className="social-list-item bg-primary text-white border-primary"
                // onClick={renderProps.onClick}
              >
                <i className="bx bxl-facebook-circle"></i>
                facebook
              </Link>
            )}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SocialLoginNew;
