import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
//Include Both Helper File with needed methods
import {
  getDashboardProfileFail,
  getDashboardProfileSuccess,
  getProfile,
  getProfileFail,
  getProfileSuccess,
  getUniversityListFail,
  getUniversityListSuccess,
  profileError,
  profileSuccess,
} from "./actions";
// Login Redux States
import {
  EDIT_PROFILE,
  GET_DASHBOARD_PROFILE,
  GET_PROFILE,
  GET_UNIVERSITY,
} from "./actionTypes";

function* editProfile({ payload: { user, token } }) {
  try {
    const url = "/update-profile";
    const response = yield call(patchData, url, user, token);
    toaster("success", "Profile update successful");
    yield put(profileSuccess(response));
    yield put(getProfile(token));
  } catch (err) {
    const message = err?.response?.data?.message;
    toaster("error", message);
    yield put(profileError(message));
  }
}

function* onGetProfile({ payload: { token } }) {
  try {
    const url = "/get-me";
    const response = yield call(getData, url, token);
    yield put(getProfileSuccess(response));
    // toaster('success')
  } catch (err) {
    const message = err?.response?.data?.message;
    toaster("error", message);
    yield put(getProfileFail(message));
  }
}

function* onGetDashboardProfile({ payload: { token } }) {
  try {
    const url = "/order/get-user-own-package";
    const response = yield call(getData, url, token);

    yield put(getDashboardProfileSuccess(response?.getData));
    // toaster('success')
  } catch (err) {
    const message = err?.response?.data?.message;
    toaster("error", message);
    yield put(getDashboardProfileFail(message));
  }
}
function* onGetUniversityList({ payload: { token } }) {
  try {
    const url = `university/get-all`;
    const response = yield call(getData, url, token);
    yield put(getUniversityListSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed";
    yield put(getUniversityListFail(message));
    toaster("error", message);
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(GET_PROFILE, onGetProfile);
  yield takeEvery(GET_DASHBOARD_PROFILE, onGetDashboardProfile);
  yield takeEvery(GET_UNIVERSITY, onGetUniversityList);
}

export default ProfileSaga;
