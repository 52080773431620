import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../components/Atoms/Loader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { getBlogPost } from "../../../store/Dashboard/actions";
const BlogPostArea = () => {
  const dispatch = useDispatch();
  const { blogList, loading, authToken } = useSelector((state) => ({
    loading: state.DashboardReducer.loading,
    blogList: state.DashboardReducer.blogList,
    authToken: state.Login.token,
  }));

  useEffect(() => {
    dispatch(getBlogPost(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };
  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="sidebar-blog-show-area-right">
          <div className="blog-right-area-title">
            <h3>Blog list</h3>
          </div>
          <div className="single-blog-area-wrap-right">
            {blogList?.length ? (
              blogList?.map((data, key) => (
                <Link to={`/blog-details/${data._id}`} key={key}>
                  <div className="r-single-blog-item">
                    <div className="r-single-blog-item-left">
                      <img src={`${getPhotoUrl(data?.photo)}`} alt="" />
                    </div>
                    <div className="r-single-blog-item-right">
                      <h6>{data?.postTitle}</h6>
                      <p>{data?.postDescription[0].slice(0, 30)}...</p>
                    </div>
                  </div>
                  <hr />
                </Link>
              ))
            ) : (
              <div>
                <p>Empty Blog List</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPostArea;
