import {
    ADD_QUESTION_ANSWER, ADD_QUESTION_ANSWER_FAIL, ADD_QUESTION_ANSWER_SUCCESS, GET_QUESTION_ANSWER, GET_QUESTION_ANSWER_FAIL, GET_QUESTION_ANSWER_SUCCESS
} from "./actionTypes.js";
  
  export const addAnswer = (data, history, authtoken) => ({
    type: ADD_QUESTION_ANSWER,
    payload: { data, history, authtoken },
  });
  
  export const addAnswerSuccess = (data) => ({
    type: ADD_QUESTION_ANSWER_SUCCESS,
    payload: data,
  });
  
  export const addAnswerFail = (error) => ({
    type: ADD_QUESTION_ANSWER_FAIL,
    payload: error,
  });
  export const getAnswer = (authtoken) => ({
    type: GET_QUESTION_ANSWER,
    payload: { authtoken },
  });
  
  export const getAnswerSuccess = (data, authtoken) => ({
    type: GET_QUESTION_ANSWER_SUCCESS,
    payload: { data, authtoken },
  });
  
  export const getAnswerFail = (error) => ({
    type: GET_QUESTION_ANSWER_FAIL,
    payload: error,
  });
  