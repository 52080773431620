import React from "react";

const DraftPackIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0505 8.69992L17.2338 12.1833C16.5338 15.1916 15.1505 16.4083 12.5505 16.1583C12.1338 16.1249 11.6838 16.0499 11.2005 15.9333L9.80048 15.5999C6.32548 14.7749 5.25048 13.0583 6.06714 9.57493L6.88381 6.08326C7.05048 5.37493 7.25048 4.75826 7.50048 4.24993C8.47548 2.23326 10.1338 1.69159 12.9171 2.34993L14.3088 2.67493C17.8005 3.49159 18.8671 5.21659 18.0505 8.69992Z"
          stroke="#74788D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5503 16.1583C12.0336 16.5083 11.3836 16.8 10.592 17.0583L9.27528 17.4917C5.96695 18.5583 4.22528 17.6667 3.15028 14.3583L2.08362 11.0667C1.01695 7.75833 1.90028 6.00833 5.20862 4.94167L6.52528 4.50833C6.86695 4.4 7.19195 4.30833 7.50028 4.25C7.25028 4.75833 7.05028 5.375 6.88362 6.08333L6.06695 9.575C5.25028 13.0583 6.32528 14.775 9.80029 15.6L11.2003 15.9333C11.6836 16.05 12.1336 16.125 12.5503 16.1583Z"
          stroke="#74788D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.5332 7.1084L14.5749 8.1334"
          stroke="#74788D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.7168 10.3335L12.1335 10.9502"
          stroke="#74788D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default DraftPackIcon;
