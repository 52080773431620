import React from "react";

const DoubleQuotesUp = () => {
  return (
    <div className="student-slider-quotes-icon-up">
      <svg
        width="158"
        height="132"
        viewBox="0 0 158 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M157.155 0V30.3418H147.819C140.298 30.3418 134.334 31.5087 129.925 33.8427C125.516 36.1767 122.275 40.1963 120.2 45.9016C118.125 51.3476 117.088 58.7386 117.088 68.0745V96.8603L105.807 75.0764C107.623 73.7798 110.216 72.7424 113.587 71.9645C116.958 70.9271 120.459 70.4085 124.09 70.4085C132.648 70.4085 139.78 73.1314 145.485 78.5774C151.19 83.764 154.043 91.0253 154.043 100.361C154.043 109.178 151.32 116.699 145.874 122.923C140.428 128.888 132.648 131.87 122.534 131.87C116.051 131.87 109.956 130.444 104.251 127.591C98.8052 124.479 94.3966 119.292 91.0253 112.031C87.654 104.511 85.9683 94.2669 85.9683 81.3004V74.6874C85.9683 56.2749 88.432 41.6227 93.3593 30.7308C98.5459 19.8389 105.937 12.0589 115.532 7.39094C125.127 2.46365 136.797 0 150.542 0H157.155ZM71.1865 0V30.3418H61.8505C54.3299 30.3418 48.3653 31.5087 43.9567 33.8427C39.548 36.1767 36.3064 40.1963 34.2317 45.9016C32.1571 51.3476 31.1198 58.7386 31.1198 68.0745V96.8603L19.8388 75.0764C21.6542 73.7798 24.2475 72.7424 27.6188 71.9645C30.9901 70.9271 34.4911 70.4085 38.1217 70.4085C46.6796 70.4085 53.8112 73.1314 59.5165 78.5774C65.2218 83.764 68.0745 91.0253 68.0745 100.361C68.0745 109.178 65.3515 116.699 59.9055 122.923C54.4596 128.888 46.6796 131.87 36.5657 131.87C30.0824 131.87 23.9881 130.444 18.2829 127.591C12.8369 124.479 8.42827 119.292 5.05696 112.031C1.68565 104.511 0 94.2669 0 81.3004V74.6874C0 56.2749 2.46365 41.6227 7.39094 30.7308C12.5776 19.8389 19.9685 12.0589 29.5638 7.39094C39.159 2.46365 50.8289 0 64.5735 0H71.1865Z"
          fill="#E5EEFA"
        />
      </svg>
    </div>
  );
};

export default DoubleQuotesUp;
