import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Input } from "reactstrap";
import logoImage from "../../assets/images/Package/Group 97875.svg";
import emptyImage from "../../assets/images/Package/undraw_not_found_re_44w9 1.svg";
import Loader from "../../components/Atoms/Loader";
import CardComponent from "../../components/Common/CardComponent";
import MenuIcon from "../../components/Package/MenuIcon";
import { toCapitalize } from "../../helpers/custom/capitalize";
import {
  getItemStyle,
  getListStyle,
  move,
  reorder,
} from "../../helpers/custom/DragAndDrop";
import { toaster } from "../../helpers/custom/Toast";
import { storeOwnPackageData } from "../../store/OwnPackage/actions";
import { createCustomPackage } from "../../store/Package/actions";
import { getService, storeServiceData } from "../../store/Service/actions";
import "./student-package.scss";

const id2List = {
  droppable: "services",
  droppable2: "selectedOwnService",
};
const imageHelper = (data) => {
  switch (data.serviceName?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg";
    case "speaking":
      return "speaking.svg";
    case "writing":
      return "writing.svg";
    case "listening":
      return "listening.svg";
    default:
      return "reading.svg";
  }
};
// const courseType = [
//   {
//     courseTypeName: "IELTS GENERAL",
//     _id: "622da53a2c53bc0920df950d",
//   },
//   {
//     courseTypeName: "IELTS ACADEMIC",
//     _id: "622da5da17df3b3e54203202",
//   },
// ];

const CreateYourPackage = ({ history, location }) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [isDraft, setIsDraft] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const {
    authToken,
    adding,
    loginId,
    services,
    selectedOwnService,
    loading,
    packageName,
    courseType,
    countryName,
    dollarRate,
  } = useSelector((state) => ({
    authToken: state.Login.token,
    loginId: state.Login.id,
    adding: state.PackageReducer.adding,
    loading: state.serviceReducer.loading,
    services: state.serviceReducer.services,
    selectedOwnService: state.OwnPackageReducer.selectedOwnService,
    packageName: state.OwnPackageReducer.packageName,
    courseType: state.OwnPackageReducer.courseType,
    countryName: state.PackageReducer.countryName,
    dollarRate: state.PackageReducer.dollarRate,
  }));

  const newService = services ? [...services] : [];

  useEffect(() => {
    dispatch(getService(authToken, selectedOwnService));
    // dispatch(getServiceSuccess(data, selectedOwnService));
    // dispatch(storeOwnPackageData("selectedOwnService", []));
    setQuantity(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedOwnService?.length) {
      const facilitatorName = selectedOwnService?.find(
        (data) => data?.serviceName === "One to one review"
      );
      if (
        facilitatorName?.serviceName === "One to one review" &&
        selectedOwnService?.length === 1
      ) {
        const result = move(
          selectedOwnService,
          services,
          { index: 0, droppableId: "droppable2" },
          { index: services?.length + 1, droppableId: "droppable" }
        );
        if (!!result?.droppable) {
          dispatch(storeServiceData("services", result?.droppable));
        }
        if (!!result?.droppable2) {
          dispatch(
            storeOwnPackageData("selectedOwnService", result?.droppable2)
          );
        }
        toaster(
          "warning",
          "You need to add other services to avail facilitator"
        );
      }
    }
  }, [isChanging]);

  const totalPrice =
    selectedOwnService?.reduce((total, el) => total + el.newPrice, 0) || 0;

  const handleSubmit = (event, errors, values) => {
    event.preventDefault();
    if (errors?.length > 0) {
      return toaster("warning", "You can't submit empty data!");
    }
    if (selectedOwnService?.length === 0)
      return toaster("warning", "Your service can't be empty!");

    const features = [];
    const tags = [];
    const id = [];
    let listening = 0;
    let speaking = 0;
    let writing = 0;
    let reading = 0;
    let totalFacilitator = 0;
    selectedOwnService.forEach((service) => {
      if (service._id !== "63527236236") {
        id.push(service._id);
      }

      features.push(
        `${service?.quantity} Set ${toCapitalize(service?.serviceName)} ${
          service?.quantity > 1 ? "Questions" : "Question"
        }`
      );

      switch (service?.serviceName?.toLowerCase().trim()) {
        case "listening":
          listening = service?.quantity * 1;
          break;
        case "speaking":
          speaking = service?.quantity * 1;
          break;
        case "writing":
          writing = service?.quantity * 1;
          break;
        case "reading":
          reading = service?.quantity * 1;
          break;
        case "one to one review":
          totalFacilitator = service?.quantity * 1;
          break;
        default:
          break;
      }
      tags.push(toCapitalize(service?.serviceName));
    });

    const data = {
      visible: false,
      packageService: id,
      features,
      tags,
      packageName: values.packageName,
      price: totalPrice,
      courseType: values.courseType,
      expire: 1,
      aboutPackage: [
        {
          question: "Make Your Own",
          answer:
            "This package gives power to the test takers. XamPro understands that everyone is not from the same plate. Some might face challenges in Listening module while some may face challenges with Speaking. So why not create your own package, prioritizing on the modules that you face more challenges in. The test takers can also add one-to-one review sessions in order to get guidance from the experts of the trade. ",
        },
        {
          question: "Payment Method",
          answer:
            "After choosing a course you have to add the course to cart. In the top right corner of your screen, just beside your name and image, you will find your cart. Click on the cart in order to view your cart or you may directly view your cart from the pop up that comes to your screen after adding a package in the cart. Type in your coupon code if you have any. Tick the checkbox after reading the privacy policy, return and refund policy, Terms & conditions. After ticking the checkbox you can click onto the Payment button which will take you to your choice of payment method from where you can choose your payment method and complete you payment. After completing payment you can find your purchased courses in My Package option in the Profile dropdown menu. ",
        },
      ],
      eligible:
        "This package is eligible for anyone who is aspiring to go abroad. It will also be helpful for anyone who is applying for permanent residency in other countries. This course is eligible for anyone who is unable to attain desired band score and wants to increase their score. This package is eligible for anyone who is starting their preparation for IELTS or has already started preparation but to avoid fright and better exam preparation. This package is also fitting for those who has taken IELTS preparation and to ensure the perfect preparation.",
      description:
        "The test takers will be able to develop strategies on how to finish each of the modules in given time. They will be getting an actual simulation of IELTS. The test takers will be able to understand which module to focus more on.",
    };
    if (listening > 0) {
      data.listening = listening;
    }
    if (speaking > 0) {
      data.speaking = speaking;
    }
    if (writing > 0) {
      data.writing = writing;
    }
    if (reading > 0) {
      data.reading = reading;
    }
    if (totalFacilitator > 0) {
      data.totalFacilitator = totalFacilitator;
      data.includedFacilitator = true;
    }

    dispatch(storeOwnPackageData("packageName", values?.packageName));
    dispatch(storeOwnPackageData("courseType", values?.courseType));
    if (loginId !== "") {
      dispatch(
        createCustomPackage(data, { authtoken: authToken, history, isDraft })
      );
    } else {
      localStorage.setItem("isOwnPackageAvailable", "true");
      localStorage.setItem("isServicePackageAvailable", "false");
      history.push("/no-register", { from: location });
    }

    // if (values) dispatch(loginUser(values, history));
  };

  const data = {
    services,
    selectedOwnService,
  };
  const getList = (id) => data[id2List[id]];

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    const isFacilitator = services.find((data) => data?._id === draggableId);
    if (
      isFacilitator?.serviceName === "One to one review" &&
      selectedOwnService?.length === 0
    ) {
      toaster("warning", "You need to add other services to avail facilitator");
      return;
    }

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // if (source.droppableId === "droppable2") {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );
      if (source.droppableId === "droppable") {
        dispatch(storeServiceData("services", items));
      }
      if (source.droppableId === "droppable2") {
        dispatch(storeOwnPackageData("selectedOwnService", items));
      }
      // }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      if (!!result?.droppable) {
        dispatch(storeServiceData("services", result?.droppable));
      }
      if (!!result?.droppable2) {
        dispatch(storeOwnPackageData("selectedOwnService", result?.droppable2));
      }
    }
    setIsChanging(!isChanging);
  };

  const handleQuantity = (e, idx) => {
    const { value } = e.target;
    const data = selectedOwnService?.map((el, index) => {
      if (idx === index) {
        return { ...el, quantity: value, newPrice: value * 1 * el.price };
      }
      return el;
    });

    dispatch(storeOwnPackageData("selectedOwnService", data));
  };
  const convertToDollar = (data) => {
    console.log("data", data);
    return (data / dollarRate?.BDT).toFixed(2);
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Create Your Package</title>
      </MetaTags>
      {/* <CouponMessage /> */}
      {/* {adding ? <AddLoader /> : null} */}
      <Container>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "90vh" }}
          >
            <Loader />
          </div>
        ) : (
          <div className="create_package">
            <div className="d-flex justify-content-between align-items-center create_package_title">
              <h4>Create Your Own Package</h4>
              <Link to="/draft-package" className="button btn_outer_primary">
                Draft package
              </Link>
            </div>
            <AvForm onSubmit={handleSubmit}>
              <CardComponent bodyClass="py-0">
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="packageName"
                        label="Package Name"
                        type="text"
                        value={packageName || ""}
                        errorMessage="Enter Package Name"
                        placeholder="Enter Package Name"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3 new-create-package-list">
                      <AvField
                        name="courseType"
                        label="Course Type"
                        type="select"
                        value={courseType || ""}
                        errorMessage="Enter Course Type"
                        validate={{
                          required: { value: true },
                        }}
                      >
                        <option value="">Select...</option>
                        <option value="622da53a2c53bc0920df950d">
                          IELTS GENERAL
                        </option>
                        <option value="622da5da17df3b3e54203202">
                          IELTS ACADEMIC
                        </option>
                      </AvField>
                    </div>
                  </Col>
                </Row>
              </CardComponent>
              <DragDropContext onDragEnd={onDragEnd}>
                <Row className="mt-4">
                  <Col md="6">
                    <CardComponent
                      bodyClass={"pb-0 mobile-padding-remove-class"}
                    >
                      <h4>List of the service</h4>
                      <div className="service-list height">
                        <Droppable
                          droppableId="droppable"
                          style={{ width: "100%" }}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {loading ? (
                                <Loader />
                              ) : (
                                newService?.map((item, index) => (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                    style={{ width: "100%" }}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                          true
                                        )}
                                      >
                                        <div className="single-service">
                                          <div className="single-service_details">
                                            <MenuIcon />
                                            <img
                                              src={
                                                require(`../../components/Package/img/${imageHelper(
                                                  item
                                                )}`).default
                                              }
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                objectFit: "contain",
                                              }}
                                              alt="examImage"
                                            />
                                            <h5>
                                              {toCapitalize(item.serviceName)}
                                            </h5>
                                          </div>
                                          <div>
                                            {countryName === "Bangladesh" ? (
                                              <h5>{item?.price}Tk</h5>
                                            ) : (
                                              <h5>
                                                {convertToDollar(item?.price)}{" "}
                                                &#36;
                                              </h5>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                      <div className="bottomImage">
                        <img src={logoImage} width="100%" alt="" />
                      </div>
                    </CardComponent>
                  </Col>
                  <Col md="6" className="">
                    <CardComponent
                      className="your-service-drop-and-create-new-pack"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      bodyClass={"p-0 pb-4 drag-drop-height-control-container-"}
                    >
                      <div className="p-3 mobile-padding-control-area">
                        <h4>Your Service</h4>
                        <div className={`service-list height2`}>
                          <Droppable
                            droppableId="droppable2"
                            style={{ width: "100%" }}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {selectedOwnService.length > 0 ? (
                                  selectedOwnService.map((item, index) => (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                      style={{ width: "100%" }}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            true
                                          )}
                                        >
                                          <div className="single-service">
                                            <div className="single-service_details">
                                              <MenuIcon />
                                              <img
                                                src={
                                                  require(`../../components/Package/img/${imageHelper(
                                                    item
                                                  )}`).default
                                                }
                                                style={{
                                                  width: "60px",
                                                  height: "60px",
                                                  objectFit: "contain",
                                                }}
                                                alt="examImage"
                                              />
                                              <h5>
                                                {toCapitalize(item.serviceName)}
                                              </h5>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center gap-2">
                                              <Input
                                                type="select"
                                                value={item?.quantity}
                                                onChange={(e) =>
                                                  handleQuantity(e, index)
                                                }
                                              >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                              </Input>

                                              {countryName === "Bangladesh" ? (
                                                <h5>{item?.newPrice}Tk</h5>
                                              ) : (
                                                <h5>
                                                  {convertToDollar(
                                                    item?.newPrice
                                                  )}{" "}
                                                  &#36;
                                                </h5>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))
                                ) : (
                                  <div className="empty-list">
                                    <img src={emptyImage} alt="" />
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {/* <div className="empty-list">
                <img src={emptyImage} alt="" />
            </div> */}
                        </div>
                      </div>
                      <div className="">
                        <div className="set_question padding_30">
                          <h5 className="mb-0 d-none">Set of questions</h5>
                          <div className="question_field">
                            <Input
                              name="expire"
                              type="number"
                              className="mb-0 d-none"
                              errorMessage="Enter set"
                              placeholder="Enter set"
                              value={quantity}
                              onChange={(e) => {
                                const { value } = e.target;
                                setQuantity(value);
                              }}
                              onBlur={(e) => {
                                const { value } = e.target;
                                if (value * 1 === 0) {
                                  setQuantity(1);
                                } else if (!Number.isInteger(quantity * 1)) {
                                  setQuantity(parseInt(value * 1));
                                } else {
                                  setQuantity(Math.abs(value * 1));
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center padding_30 py-4 mt-4">
                          <h5>Total price</h5>

                          {countryName === "Bangladesh" ? (
                            <h5>
                              {totalPrice *
                                Math.abs(
                                  quantity * 1 && Number.isInteger(quantity * 1)
                                    ? quantity
                                    : 1
                                )}
                              Tk
                            </h5>
                          ) : (
                            <h5>
                              {convertToDollar(
                                totalPrice *
                                  Math.abs(
                                    quantity * 1 &&
                                      Number.isInteger(quantity * 1)
                                      ? quantity
                                      : 1
                                  )
                              )}
                              &#36;
                            </h5>
                          )}
                        </div>
                        <div className="px-4 py-2 buttons mt-3">
                          <button
                            type="submit"
                            disabled={loading || adding}
                            className="button draft"
                            onClick={() => setIsDraft(true)}
                          >
                            {adding && isDraft ? "Submitting..." : "Save Draft"}
                          </button>
                          <button
                            disabled={loading || adding}
                            className="button createPackage"
                            onClick={() => setIsDraft(false)}
                          >
                            {adding && !isDraft
                              ? "Submitting..."
                              : "Create Package"}
                          </button>
                        </div>
                      </div>
                    </CardComponent>
                  </Col>
                </Row>
              </DragDropContext>
            </AvForm>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default withRouter(CreateYourPackage);
