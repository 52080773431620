import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./email-verify.scss";
import emailVerify from "./emailVerify.png";
const EmailVerified = ({ history }) => {
  let isOwnPackage = localStorage.getItem("exmPro-ownPackage");
  let newData = JSON.parse(isOwnPackage);
  let ownPackageStatus = localStorage.getItem("isOwnPackageAvailable");

  let systemPackage = localStorage.getItem("exmPro-cart");
  let systemPackageConvert = JSON.parse(systemPackage);
  let servicePackageStatus = localStorage.getItem("isServicePackageAvailable");

  return (
    <div className="after-email-verified-page">
      <MetaTags>
        <title>Email Verity</title>
      </MetaTags>
      <Container>
        <div className="after-email-verified-inner-page">
          <Row>
            <Col lg={6}>
              <div className="email-verification-page-left">
                <img src={emailVerify} alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="">
                <h4>
                  <b>Email Verified!</b>
                </h4>
                <p style={{ color: "#74788D" }}>
                  Your email is successfully verified. To proceed further,
                  please click below!
                </p>
                <div className="mt-4">
                  <button
                    onClick={
                      newData?.packageName !== "" && ownPackageStatus === "true"
                        ? () => history.push("/create-package")
                        : systemPackageConvert?.cart?.length !== 2 &&
                          servicePackageStatus === "true"
                        ? () => history.push("/cart")
                        : () => history.push("/")
                    }
                    className="btn btn-primary"
                  >
                    Exam package
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(EmailVerified);
