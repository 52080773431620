import React from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import mainImage from "./Frame.png";
const NotRegister = ({ location }) => {
  return (
    <div>
      <MetaTags>
        <title>Not register</title>
      </MetaTags>
      <div className="package-empty-main-wrap">
        <Container>
          <div className="package-inner-items-wrap">
            <div className="">
              <img src={mainImage} alt="" />
              <h6 className="no-register-redirect-page">
                Please{" "}
                <Link to={{ pathname: "/login", state: location?.state || "" }}>
                  login
                </Link>{" "}
                before check out, If you haven’t registered <br /> already
                please{" "}
                <Link
                  to={{ pathname: "/signup", state: location?.state || "" }}
                >
                  register
                </Link>
              </h6>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default NotRegister;
