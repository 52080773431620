import {
  CHAT_INFO_USER,
  CHAT_INFO_USER_FAIL,
  CHAT_INFO_USER_SUCCESS,
  GET_CHAT_USER,
  GET_CHAT_USER_FAIL,
  GET_CHAT_USER_SUCCESS,
  GET_SINGLE_USER_MESSAGE,
  GET_SINGLE_USER_MESSAGE_FAIL,
  GET_SINGLE_USER_MESSAGE_SUCCESS,
  REPLY_USER,
  REPLY_USER_FAIL,
  REPLY_USER_SUCCESS,
  SEEN_MESSAGE,
  SEEN_MESSAGE_FAIL,
  SEEN_MESSAGE_SUCCESS,
} from "./actionTypes";

const initialState = {
  adding: false,
  message: "",
  error: "",
  userListChat: [],
  nonUserId: "",
  nonUserEmail: "",
  singleUserMessage: [],
  userListChatLoading: false,
  singleUserMessageLoading: false,
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPLY_USER:
      state = {
        ...state,
        adding: true,
      };
      break;
    case REPLY_USER_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      };
      break;
    case REPLY_USER_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case CHAT_INFO_USER:
      state = {
        ...state,
        adding: true,
      };
      break;
    case CHAT_INFO_USER_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        nonUserId: action.payload.nonUserId,
        nonUserEmail: action.payload.nonUserEmail,
        adding: false,
      };
      break;
    case CHAT_INFO_USER_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case SEEN_MESSAGE:
      state = {
        ...state,
        adding: true,
      };
      break;
    case SEEN_MESSAGE_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      };
      break;
    case SEEN_MESSAGE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;

    case GET_CHAT_USER:
      state = {
        ...state,
        userListChatLoading: true,
      };
      break;
    case GET_CHAT_USER_SUCCESS:
      state = {
        ...state,
        userListChatLoading: false,
        userListChat: action.payload.values,
      };
      break;
    case GET_CHAT_USER_FAIL:
      state = {
        ...state,
        userListChatLoading: false,
        message: action.payload.message,
      };
      break;

    case GET_SINGLE_USER_MESSAGE:
      state = {
        ...state,
        singleUserMessageLoading: true,
      };
      break;
    case GET_SINGLE_USER_MESSAGE_SUCCESS:
      state = {
        ...state,
        singleUserMessageLoading: false,
        singleUserMessage: action.payload.values,
      };
      break;
    case GET_SINGLE_USER_MESSAGE_FAIL:
      state = {
        ...state,
        singleUserMessageLoading: false,
        message: action.payload.message,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default ChatReducer;
