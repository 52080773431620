import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import greIcon from "../img/grep.png";
import checkIcon from "./img/Base.svg";
import packageBg from "./img/packagebg.png";
import "./student-package2.scss";

const GREPackage = ({ history }) => {
  return (
    <div
      className="gmat-package-main-wrap gre"
      style={{ backgroundImage: `url(${packageBg})` }}
    >
      <Container>
        <Row>
          <Col lg={7}>
            <div className="course-single-part-right">
              <h3>
                What about exams <br /> at home?
              </h3>
              <ul>
                <li>
                  <img src={checkIcon} alt="img" />
                  <p>Take home exams</p>
                </li>
                <li>
                  <img src={checkIcon} alt="img" />
                  <p>Take home assignments</p>
                </li>
                <li>
                  <img src={checkIcon} alt="img" />
                  <p>Proctored exams almost like exam environment</p>
                </li>
                <li>
                  <img src={checkIcon} alt="img" />
                  <p>Self-regulated exam management</p>
                </li>
              </ul>
              <Button
                onClick={() =>
                  history.push("/packages/622c6437b122d93e541b44bb")
                }
                className="btn package-connectbutton"
              >
                Connect
              </Button>
            </div>
          </Col>
          <Col lg={5}>
            <div className="course-single-part-left">
              <img src={greIcon} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(GREPackage);
