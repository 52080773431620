import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import "./about-us.scss";
import AboutHero from "./AboutHero";
import OurExpertise from "./OurExpertise";
const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>About Us </title>
      </MetaTags>

      <Container>
        <div className="about-us-innder-wrap">
          <AboutHero />
        </div>
      </Container>
      <OurExpertise />
      {/* <Faq /> */}
    </div>
  );
};

export default AboutUs;
