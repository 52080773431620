import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { patchNonAuth } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { loginSuccess } from "../login/actions";
import { verifyEmailFail, verifyEmailSuccess } from "./actions";
//VerifyEmail Redux state
import { VERIFY_EMAILS } from "./actionTypes";

function* workerVerifyEmail({ payload: { values, history } }) {
  try {
    const url = "/verifyaccount";
    const response = yield call(patchNonAuth, url, values);

    if (response.status === "success") {
      yield put(
        loginSuccess(
          response.user.findUserOfEmail.fullName,
          response.token,
          response.user.findUserOfEmail._id,
          response.user.findUserOfEmail.email,
          response.user.findUserOfEmail.role,
          response?.user
        )
      );
      toaster("success", "Account verified successful.");
      yield put(verifyEmailSuccess("Account Verified"));
      history.push("/email-verified");
    } else {
      yield put(verifyEmailFail(response.message));
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Failed to verify";
    toaster("error", message);
    yield put(verifyEmailFail(message));
  }
}

export function* watchEmailSaga() {
  yield takeEvery(VERIFY_EMAILS, workerVerifyEmail);
}

function* verifyEmailSaga() {
  yield all([fork(watchEmailSaga)]);
}
export default verifyEmailSaga;
