import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import celpIcon from "../img/celp.svg";
import aptitudeLeft from "../img/gmat.png";
import gmatIcon from "../img/gmat.svg";
import greIcon from "../img/gre.svg";
import ieltsIcon from "../img/ielts.svg";
import pteIcon from "../img/pte.svg";
import toefelIcon from "../img/toefel2.png";
import "./student-package2.scss";

const GmatPackage = ({ history }) => {
  return (
    <div className="gmat-package-main-wrap">
      <Container>
        <Row>
          <Col lg={4} className="my-auto col-md-12">
            <div className="course-single-part-left">
              <img src={aptitudeLeft} alt="" />
            </div>
          </Col>
          <Col lg={8} className="my-auto col-md-12">
            <div className="course-single-part-right ps-5">
              <h4 className="mb-5">
                Interested in an <br /> aptitude test?
              </h4>
              <Row>
                <Col className="col-lg-4 col-md-4 col-sm-4 col-6">
                  <div className="test-single-item">
                    <span>
                      <img src={gmatIcon} alt="" />
                    </span>
                    <h6>GMAT</h6>
                  </div>
                </Col>{" "}
                <Col className="col-lg-4 col-md-4 col-sm-4 col-6">
                  <div className="test-single-item">
                    <span>
                      <img src={greIcon} alt="" />
                    </span>
                    <h6>GRE</h6>
                  </div>
                </Col>{" "}
                <Col className="col-lg-4 col-md-4 col-sm-4 col-6">
                  <div className="test-single-item">
                    <span>
                      <img src={celpIcon} alt="" />
                    </span>
                    <h6>CELPIP</h6>
                  </div>
                </Col>{" "}
                <Col className="col-lg-4 col-md-4 col-sm-4 col-6">
                  <div className="test-single-item">
                    <span>
                      <img src={ieltsIcon} alt="" />
                    </span>
                    <h6>IELTS</h6>
                  </div>
                </Col>{" "}
                <Col className="col-lg-4 col-md-4">
                  <div className="test-single-item">
                    <span>
                      <img src={pteIcon} alt="" />
                    </span>
                    <h6>PTE</h6>
                  </div>
                </Col>{" "}
                <Col className="col-lg-4 col-md-4">
                  <div className="test-single-item">
                    <span>
                      <img src={toefelIcon} alt="" />
                    </span>
                    <h6>TOEFEL</h6>
                  </div>
                </Col>
              </Row>
              <Button
                onClick={() =>
                  history.push("/packages/622c6437b122d93e541b44bb")
                }
                className="btn package-connectbutton"
              >
                Connect
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(GmatPackage);
