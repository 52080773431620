import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useParams } from "react-router-dom";
import { addReview } from "../../store/Package/actions";
import { storeQuestion } from "../../store/Question/actions";

const RatingComponent = () => {
  const [ratingValue, setRatingValue] = useState(4);
  const { packageId } = useParams();
  const dispatch = useDispatch();
  const { authToken, isDone, addingRating, id } = useSelector((state) => ({
    userService: state.serviceReducer.userService,
    authToken: state.Login.token,
    userServiceLoading: state.serviceReducer.userServiceLoading,
    loading: state.serviceReducer.loading,
    isDone: state.QuesReducer.isDone,
    addingRating: state.PackageReducer.adding,
    review: state.PackageReducer.reviewData,
    id: state.Login.id,
  }));

  const handleSubmit = (event, errors, values) => {
    event.preventDefault();
    if (errors.length === 0) {
      const data = {
        packageId: packageId,
        rating: ratingValue,
        ...values,
      };
      dispatch(addReview(authToken, data, id));
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={isDone}
        onClose={() => dispatch(storeQuestion("isDone", false))}
        classNames={{
          overlay: "ratingOverlay",
          modal: "ratingModal",
        }}
        center
      >
        <div className="rating-exam-main-area-wrap">
          <div className="rating-header">
            <h3>Rating Exam</h3>
          </div>
          <AvForm onSubmit={handleSubmit}>
            <div className="rating-exam-main-body">
              <div className="rating-stars">
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="simple-controlled"
                    value={ratingValue}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      setRatingValue(newValue);
                    }}
                  />
                </Box>
              </div>
              <div className="rating-comment-box">
                <AvField
                  label="Comments (optional)"
                  type="textarea"
                  className="form-control"
                  name="comment"
                  id="rating-comment"
                  rows="3"
                />
              </div>
              <div className="rating-bottom-wrap">
                <button
                  className="btn"
                  onClick={() => dispatch(storeQuestion("isDone", false))}
                >
                  Skip for now
                </button>
                <button className="btn" type="submit" disabled={addingRating}>
                  {addingRating ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RatingComponent;
