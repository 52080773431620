import {
  ADD_FAQ_INFO,
  ADD_FAQ_INFO_FAIL,
  ADD_FAQ_INFO_SUCCESS,
  ADD_FAQ_TYPE,
  ADD_FAQ_TYPE_FAIL,
  ADD_FAQ_TYPE_SUCCESS,
  GET_FAQ_INFO,
  GET_FAQ_INFO_FAIL,
  GET_FAQ_INFO_SUCCESS,
  GET_FAQ_TYPE,
  GET_FAQ_TYPE_FAIL,
  GET_FAQ_TYPE_SUCCESS,
  GET_SINGLE_FAQ,
  GET_SINGLE_FAQ_FAIL,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_TYPE,
  GET_SINGLE_FAQ_TYPE_FAIL,
  GET_SINGLE_FAQ_TYPE_SUCCESS,
} from "./actionTypes";

export const addFaqType = (values, history, token) => {
  return {
    type: ADD_FAQ_TYPE,
    payload: { values, history, token },
  };
};
export const addFaqTypeSuccess = (message) => {
  return {
    type: ADD_FAQ_TYPE_SUCCESS,
    payload: { message },
  };
};

export const addFaqTypeFail = (message) => {
  return {
    type: ADD_FAQ_TYPE_FAIL,
    payload: { message },
  };
};
export const addFaqInfo = (values, history, token) => {
  return {
    type: ADD_FAQ_INFO,
    payload: { values, history, token },
  };
};
export const addFaqInfoSuccess = (message) => {
  return {
    type: ADD_FAQ_INFO_SUCCESS,
    payload: { message },
  };
};

export const addFaqInfoFail = (message) => {
  return {
    type: ADD_FAQ_INFO_FAIL,
    payload: { message },
  };
};

export const getFAQtype = (token) => {
  return {
    type: GET_FAQ_TYPE,
    payload: { token },
  };
};

export const getFAQtypeSuccess = (values) => {
  return {
    type: GET_FAQ_TYPE_SUCCESS,
    payload: { values },
  };
};

export const getFAQtypeFail = (message) => {
  return {
    type: GET_FAQ_TYPE_FAIL,
    payload: { message },
  };
};

export const getFAQInfo = (token) => {
  return {
    type: GET_FAQ_INFO,
    payload: { token },
  };
};

export const getFAQInfoSuccess = (values) => {
  return {
    type: GET_FAQ_INFO_SUCCESS,
    payload: { values },
  };
};

export const getFAQInfoFail = (message) => {
  return {
    type: GET_FAQ_INFO_FAIL,
    payload: { message },
  };
};
export const getSingleFaq = (token, faqId) => {
  return {
    type: GET_SINGLE_FAQ,
    payload: { token, faqId },
  };
};

export const getSingleFaqSuccess = (values) => {
  return {
    type: GET_SINGLE_FAQ_SUCCESS,
    payload: { values },
  };
};

export const getSingleFaqFail = (message) => {
  return {
    type: GET_SINGLE_FAQ_FAIL,
    payload: { message },
  };
};
export const getSingleFaqType = (token, faqId) => {
  return {
    type: GET_SINGLE_FAQ_TYPE,
    payload: { token, faqId },
  };
};

export const getSingleFaqTypeSuccess = (values) => {
  return {
    type: GET_SINGLE_FAQ_TYPE_SUCCESS,
    payload: { values },
  };
};

export const getSingleFaqTypeFail = (message) => {
  return {
    type: GET_SINGLE_FAQ_TYPE_FAIL,
    payload: { message },
  };
};
