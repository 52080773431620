import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Atoms/Loader";
import CouponMessage from "../../../components/Common/CouponMessage";
import {
  getBuyPackageList,
  getRecommendedPackage,
  getSinglePackage,
} from "../../../store/Package/actions";
import PackageDetailsBody from "./PackageDetailsBody";

const PackageDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const {
    authToken,
    singlePackageLoading,
    singlePackage,
    loading,
    buyPackageList,
    // recommendedPackage,
  } = useSelector((state) => ({
    singlePackage: state.PackageReducer.singlePackage,
    authToken: state.Login.token,
    singlePackageLoading: state.PackageReducer.singlePackageLoading,
    loading: state.PackageReducer.loading,
    buyPackageList: state.PackageReducer.buyPackageList,
    recommendedPackage: state.PackageReducer.recommendedPackage,
  }));

  useEffect(() => {
    if (authToken && id) {
      dispatch(getBuyPackageList(authToken));
    }
    if (id) {
      dispatch(getSinglePackage(authToken, id));
      dispatch(getRecommendedPackage(authToken, id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Package details</title>
      </MetaTags>
      <CouponMessage />
      {singlePackageLoading || loading ? (
        <div className="text-center pt-5" style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <PackageDetailsBody
          singlePackageData={singlePackage?.singlePackage}
          singlePackage={singlePackage}
          buyPackageList={buyPackageList}
        />
      )}
    </div>
  );
};

export default PackageDetails;
