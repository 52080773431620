import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./privecy-policy.scss";
const PrivecyPolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>Privacy Policy </title>
      </MetaTags>
      {/* {token ? <LoginHeader /> : <Header />} */}

      <Container>
        <div className="return-policy-main-wrap">
          <div className="return-policy-inner-content-wrap">
            <div className="return-policy-header">
              <h3>Privacy Policy</h3>
              <p>Last Updated on 28th October 2022</p>
            </div>
            <div className="return-policy-content-bottom">
              <div className="return-policy-single-item">
                <h4>Key Information</h4>
                <ul>
                  <li>
                    Unless you are a part of a degree, specific MasterTrack
                    programs, or other conditions that have been conveyed to
                    you, in which case XamPro is the data processor, XamPro is
                    the entity that decides the means and purposes of
                    collecting, using, and disclosing the personal information.
                  </li>
                  <li>
                    We gather the personal data listed in the "What Information
                    We Collect" section of this Privacy Notice, which includes
                    information about how you register for an account (such as
                    your name and email), information about the Content
                    Offerings you participate in, survey information (where you
                    provide it), identity verification data, and data about how
                    you use our site and Services.
                  </li>
                  <li>
                    We use your personal information for the reasons listed in
                    the "How We Use the Information" section of this Privacy
                    Notice below, which include giving you access to our site
                    and Services, maintaining the security and functionality of
                    our site, conducting research into Content Offerings,
                    sharing information with our Content Providers and our
                    suppliers, conducting direct marketing, and performing
                    statistical analysis of how our site and Services are used.
                  </li>
                  <li>
                    You have several rights regarding how we use your personal
                    information, as described in the section below under
                    "Updating or Deleting Your Personally Identifiable
                    Information."
                  </li>
                </ul>
              </div>
              <div className="return-policy-single-item">
                <h4>Purpose and who we are</h4>
                <p>
                  This privacy notice explains how XamPro, Inc., our affiliates,
                  and our international divisions (collectively, "XamPro," "us,"
                  "we," or "our") gather, use, and share information about you
                  through our online interfaces (such as websites and mobile
                  applications) that we own and control, including but not
                  limited to rhyme.com and XamPro.org (collectively referred to
                  herein as the "Site"). Please carefully read this privacy
                  notice to understand what we do. Please feel free to contact
                  us at info@maacsolutions.com if you have any questions about
                  our Privacy Notice. Your usage of our site is subject to our
                  Terms of Use as well. Our Terms of Use contain a list of terms
                  that are used but not defined in this privacy notice. A
                  Delaware corporation, XamPro, Inc. has its main office at 381
                  E. Evelyn Ave. in Mountain View, California, 94041. As stated
                  in this Privacy Notice, XamPro is the data controller of all
                  personal data (as defined below) collected through the Site
                  and of some personal data obtained from third parties if you
                  reside or are located in the European Economic Area ("EEA"),
                  unless you are enrolled in a degree program, a MasterTrack
                  program, or you are subject to other circumstances that have
                  been made known to you, in which case XamPro is the data
                  processor.
                </p>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>What Information this Privacy Notice Covers</h4>
                <p>
                  This Privacy Notice applies to the data we obtain about you
                  through our website. While some of our site's functionality
                  can be accessed without disclosing any personal information,
                  such information is necessary for features or services
                  associated with the Content Offerings. You might be required
                  to provide "personal data" (also known as "personally
                  identifiable information" or "personal information") to access
                  certain features and benefits on our site, or we could ask for
                  it from you. Among other things, personal data can include
                  details like your name, email address, IP address, and device
                  identification. The accuracy of the personal information you
                  provide to XamPro is your responsibility to ensure. Your
                  ability to use the site, the information you receive while
                  using the site, and our ability to contact you may all be
                  impacted by inaccurate information. For instance, since email
                  is one of the main ways we contact you, it should be kept
                  up-to-date.
                </p>
              </div>
              <div className="return-policy-single-item">
                <h4>What You Agree to by Using Our Site</h4>
                <ul>
                  <li>
                    According to our assessment, the following constitutes the
                    legal justification for our use of your personal information
                    as described in this Privacy Notice: Our use of your
                    personal information is necessary to carry out our
                    obligations under any contract with you (for instance, to
                    adhere to the Terms of Use of our Site, which you accept by
                    using our website, and/or to adhere to our contract to
                    provide services to you, your academic institution, your
                    employer, or other organization that may be providing you
                    with services.
                  </li>
                  <li>
                    If we must utilize your personal information to uphold our
                    legal commitments; Use of your personal information is
                    required to further our or others' legitimate interests. Our
                    legitimate business interests include managing, expanding,
                    and developing our company; operating our Site and offering
                    our Services; vetting suppliers for competence and
                    qualifications; establishing partnerships with businesses
                    and academic institutions; conducting research and
                    statistical analysis; marketing and business development;
                    and internal administrative and auditing needs.
                  </li>
                  <li>
                    Consent, to send you certain communications when you provide
                    us with specific information, or where we are compelled by
                    local law to rely on your consent.
                  </li>
                  <li>
                    Depending on the type of personal information utilized and
                    the situation, a certain activity will have one or more
                    legal grounds.
                  </li>
                  <li>
                    If we rely on our (or another party's) legitimate interests
                    to use your personal information, we will conduct a
                    balancing test to make sure that your interests or
                    fundamental rights and freedoms that call for the protection
                    of the personal information do not outweigh our (or the
                    other party's) legitimate interests.
                  </li>
                  <li>
                    Based on your consent, we may occasionally process your
                    personal information for marketing reasons (which you may
                    withdraw at any time as described below).
                  </li>
                  <li>
                    You can withdraw your consent at any time by visiting your
                    profile page and using the "remove consent" checkbox. If we
                    are relying on your consent to use your personal information
                    in a certain way, we will stop doing so. If you refuse to
                    give us your consent, it can be difficult for us to continue
                    offering you certain of our services.
                  </li>
                </ul>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>What Information We Collect</h4>
                <p>
                  Through the site, we learn the following things about users:
                </p>
                <ul>
                  <li>
                    <b> Information on how you used our website.</b> When users
                    visit our site, we may track, gather, and combine data about
                    their visit, including information about which pages were
                    seen, when they were accessed, in what sequence, and which
                    links were clicked. We also gather data from the URLs you
                    used to access our website. It may be necessary to log each
                    user's IP address, operating system, and browser to collect
                    this data. A user's Internet Service Provider and the
                    location of their point of connexion may be known from an IP
                    address. Your traffic may be directed to the closest
                    regional server using location data to reduce latency and
                    enhance your experience. When you visit our site, we might
                    potentially employ web beacons and cookies. Please see our
                    Cookies Policy for additional details on how we utilize web
                    beacons and cookies.
                  </li>
                  <li>
                    <b>
                      You voluntarily submit personal data either directly or
                      through third parties.
                    </b>{" "}
                    When you create an account, update or modify account
                    information, buy goods or services, respond to a survey,
                    sign up for email updates, engage in one of our open forums,
                    send us emails, and/or make use of other services or content
                    offerings on our site, we gather personal data from you. To
                    reply to your inquiries, offer you the particular content
                    offering and/or services you choose, send you updates about
                    content offerings or other XamPro events, and send you
                    emails about site maintenance or upgrades, we may utilize
                    the personal data that you supply.
                  </li>
                </ul>
                <ul>
                  <li>
                    Registering an account. You might be required to give us
                    certain Personal Data, including your name and email address
                    if you create an account on our website. Additionally, you
                    have the option to voluntarily give us more Personal Data.
                  </li>
                  <li>
                    Updates. You might be able to subscribe to updates from
                    XamPro through email or by posting on areas of the website
                    that are only accessible to registered users. You might be
                    You will be asked to give us personal data, such as your
                    name and email address, to subscribe to these services.
                  </li>
                  <li>
                    Forums. XamPro may occasionally provide public forums (the
                    "Forums") where you can express your opinions. You might
                    need to register with us and/or give us Personal Data,
                    including your name and email address, to participate in the
                    Forums. Please remember that any information you post or
                    make accessible in forums will be exposed to the general
                    public. In a Forum post, you shouldn't provide any personal
                    information about you or another individual, or any other
                    sensitive or confidential information. For further
                    information on how to use our Forums properly, kindly refer
                    to our Terms of Use and Code of Conduct.
                  </li>
                  <li>
                    Participation in Content Offerings. Users have the option to
                    take part in Content Offerings on or through the Site thanks
                    to XamPro. You will be required to give us specific
                    information for us to conduct a Content Offering if you wish
                    to take part. Your name and email address could be among the
                    details included in this information.
                  </li>
                </ul>
                <p>
                  If you take part in a content offering, we might gather
                  certain user-generated content from you, such as assignments
                  you submit, assignments that are scored by peers, and comments
                  from peer-graded assignments. Additionally, we gather course
                  information, including replies to tests, surveys, and
                  responses to in-video and stand-alone quizzes. Except for
                  information necessary to participate in or submit such
                  assignments, tests, or surveys, you should not include any
                  personal data or other information of a personal or sensitive
                  nature, whether relating to you or another person.
                </p>
                <ul>
                  <li>
                    Verification of identity. For a few Services, XamPro might
                    let you authenticate your identity. You might be needed to
                    give us or our third-party identity verification vendor
                    Personal Data, such as your name, address, date of birth, a
                    headshot was taken with a webcam, and a photo ID document,
                    to sign up for these Services. Furthermore, you might be
                    asked for information about your income if you apply for
                    financial aid in connection with these Services.
                  </li>
                  <li>
                    Correspondence with XamPro. When you email us or otherwise
                    get in touch with us, we might get personal information from
                    you.
                  </li>
                  <li>
                    Third-party websites. When you use our Sites to visit or log
                    in to a third-party website, such as Facebook, we might get
                    personal information about you. The text and/or pictures of
                    your data that are available on the third-party website may
                    fall under this category.
                  </li>
                  <li>
                    Surveys. When you respond to a survey that is run by us or a
                    Content Provider, we might get personal data from you.
                  </li>
                  <li>
                    partner websites. While offering those tools and services to
                    XamPro users, partner sites that are involved in the Content
                    Offering may also gather non-financial individual-level user
                    data on how each user interacts with those partner sites. To
                    enhance our services, the services of the partner site, and
                    the student's educational experience, the partner sites may
                    share that data with XamPro. This data consists of details
                    like the duration spent on the partner site and the pages
                    browsed.
                  </li>
                  <li>
                    Processing of Third-Party Payments. Through a third-party
                    payment processing service provider, XamPro offers you the
                    option to pay for Content Offerings and other Services with
                    a credit card or another means. Please be aware that your
                    financial information is gathered and processed by our
                    service partner and not by XamPro.
                  </li>
                </ul>
              </div>
              <div className="return-policy-single-item">
                <h4>How We Use the Information</h4>

                <ul>
                  <li>
                    <b> Information on how you used our website.</b> By
                    undertaking statistical analyses of the collective traits
                    and behavior of our users, as well as by assessing
                    demographics and interests about certain areas of our site,
                    we use information about your use of the site to develop
                    higher quality, more helpful services. To protect the safety
                    of our services and the site, we might also use your
                    information.
                  </li>
                  <li>
                    <b>
                      Information that can be used to identify you, either
                      directly from you or through third parties.
                    </b>{" "}
                    XamPro will not release any of your Personal Data unless
                    expressly permitted by you or as specified in this Privacy
                    Notice. We may disclose and utilize Personal Data in the
                    ways listed below, in addition to the other ways outlined in
                    this Privacy Notice.
                  </li>
                </ul>
                <ul>
                  <li>
                    Providing the Site and our Services. We use the Personal
                    Data you give us to give you access to and use the Site and
                    to fulfill any requests you make for information, goods, or
                    services.
                  </li>
                  <li>
                    technical assistance and safety. When necessary, we may
                    utilize personal data to assist you with technical issues
                    while also preserving the safety of our services and the
                    site.
                  </li>
                  <li>
                    Updates. To send you information regarding the Site or
                    Content Offerings, we use Personal Data that is acquired
                    when you sign up for one of our many email or update
                    services. If we have a legal right to do so, we may also use
                    your information to contact you in the future and/or archive
                    it.
                  </li>
                  <li>
                    Forums. You shouldn't include any personally identifying
                    information, sensitive information, or any other information
                    in a Forum post, whether it pertains to you or another
                    individual. During your usage of the Forums, if you choose
                    to post personally identifying information, this information
                    may be obtained. We may make this information available
                    through platform extensions that integrate third-party
                    services, such as mobile applications. When developing new
                    content offerings, we reserve the right to reuse and improve
                    Forum postings that contain personally identifiable
                    information. We might keep a record of this information, use
                    it to get in touch with you and/or your designee(s) in the
                    future, or give it to the company that provides the content,
                    our partners in business, or the teacher(s) of the courses
                    you've taken. We may also make use of or publish comments
                    made on the forums without utilizing any personally
                    identifying information.
                  </li>
                  <li>
                    participation in content offerings. We process the Personal
                    Data you provide to us when you engage in a Content Offering
                    through the Site, including but not limited to keeping track
                    of participation, advancement, and completion of the Content
                    Offerings. We may also disclose your Personal Data and your
                    performance in a specific Content Offering to the
                    instructor(s) who taught it, to teaching assistants, or to
                    other people the instructor(s) designated to help with the
                    development, modification, or operation of the Content
                    Offering, and to the Content Provider(s) with whom they are
                    associated. Using the With the information gathered when you
                    use the Services or take a Content Offering, we may also
                    perform predictive analysis of your success in the Content
                    Offerings.
                  </li>
                  <li>
                    Identity confirmation. We may use the Personal Data we
                    collect to confirm your identity for Services that call for
                    this, as well as to confirm that inputs made to the Site
                    came from you. A vendor for identity verification on the
                    side of a third party could offer this service. After your
                    profile information has been successfully verified, your
                    photo identification will be erased.
                  </li>
                  <li>
                    communications from or to XamPro. If you send us an email or
                    contact us in any way, we may use the information you supply
                    to reply to your email and/or for the purposes outlined in
                    this privacy notice. If we can do so lawfully, we may also
                    use your information to contact you in the future by
                    archiving it or using it in other communications. in which
                    we send We may monitor how you interact with emails when you
                    send them (such as when you open an email or click on a link
                    inside an email). We use this data to improve and more
                    effectively target our communications to you.
                  </li>
                  <li>
                    Messages sent to and from XamPro Business Partners. In cases
                    where they are legally permitted to do so, we might divulge
                    your personal information to XamPro's content providers and
                    other business partners so that they can notify you about
                    their goods and services that might be of interest to you.
                  </li>
                  <li>
                    Research. For our Content Providers and other business
                    partners to research online education, we may share general
                    course data (such as quiz or assignment submissions, grades,
                    and forum discussions), information about your activity on
                    our Site, and demographic data from surveys we run with
                    them.
                  </li>
                  <li>
                    Contractors for XamPro's operations and maintenance are
                    informed. To help us deliver our Services to you, we work
                    with a variety of service providers, vendors, and
                    contractors (collectively, "Contractors"). In the course of
                    supplying their goods or services to us so that we can
                    provide our Services to you, our Contractors may have
                    restricted access to your Personal Data. Vendors and
                    suppliers who give us technology, services, and/or content
                    for the upkeep and operation of the Site or the Content
                    Offerings may be considered among these Contractors. These
                    Contractors only have access to the Personal Data that is
                    legitimately required for them to carry out their specific
                    job duties for us.
                  </li>
                  <li>
                    Governmental powers; legal rights and options. In response
                    to subpoenas, court orders, or other legal processes, to
                    establish or assert our legal rights or to safeguard our
                    property, to respond to legal claims, or as otherwise
                    required by law, XamPro may share your Personal Data with
                    various government agencies. We reserve the right to assert
                    or waive any legal defenses we may have in such
                    circumstances. Additionally, we might divulge your Data when
                    we think it's necessary to look into, stop, or take other
                    appropriate action regarding illegal or potentially illegal
                    activity; to safeguard the rights, safety, or security of
                    XamPro, the Site, our users, clients, or others; or in
                    connection with our Terms of Use and other agreements.
                  </li>
                  <li>
                    Acquirers are informed. When XamPro sells, merges, or
                    reorganizes all or nearly all of the stock, business, or
                    assets to which your personal information belongs, it may
                    disclose and/or transfer your personal information to an
                    acquirer, assignee, or other successor entity.
                  </li>
                  <li>
                    e-Readers. If you use specific e-Readers or other devices to
                    access XamPro resources, we may acquire Personal Data about
                    that use. We may archive the Personal Data and use it for
                    research, business, or other purposes.
                  </li>
                </ul>
                <p>
                  If you take part in a content offering, we might gather
                  certain user-generated content from you, such as assignments
                  you submit, assignments that are scored by peers, and comments
                  from peer-graded assignments. Additionally, we gather course
                  information, including replies to tests, surveys, and
                  responses to in-video and stand-alone quizzes. Except for
                  information necessary to participate in or submit such
                  assignments, tests, or surveys, you should not include any
                  personal data or other information of a personal or sensitive
                  nature, whether relating to you or another person.
                </p>
                <ul>
                  <li>
                    Verification of identity. For a few Services, XamPro might
                    let you authenticate your identity. You might be needed to
                    give us or our third-party identity verification vendor
                    Personal Data, such as your name, address, date of birth, a
                    headshot was taken with a webcam, and a photo ID document,
                    to sign up for these Services. Furthermore, you might be
                    asked for information about your income if you apply for
                    financial aid in connection with these Services.
                  </li>
                  <li>
                    Correspondence with XamPro. When you email us or otherwise
                    get in touch with us, we might get personal information from
                    you.
                  </li>
                  <li>
                    Third-party websites. When you use our Sites to visit or log
                    in to a third-party website, such as Facebook, we might get
                    personal information about you. The text and/or pictures of
                    your data that are available on the third-party website may
                    fall under this category.
                  </li>
                  <li>
                    Surveys. When you respond to a survey that is run by us or a
                    Content Provider, we might get personal data from you.
                  </li>
                  <li>
                    partner websites. While offering those tools and services to
                    XamPro users, partner sites that are involved in the Content
                    Offering may also gather non-financial individual-level user
                    data on how each user interacts with those partner sites. To
                    enhance our services, the services of the partner site, and
                    the student's educational experience, the partner sites may
                    share that data with XamPro. This data consists of details
                    like the duration spent on the partner site and the pages
                    browsed.
                  </li>
                  <li>
                    Processing of Third-Party Payments. Through a third-party
                    payment processing service provider, XamPro offers you the
                    option to pay for Content Offerings and other Services with
                    a credit card or another means. Please be aware that your
                    financial information is gathered and processed by our
                    service partner and not by XamPro.
                  </li>
                </ul>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>Updating or Deleting Your Personal Data</h4>
                <p>
                  Regarding your data, you are entitled to several rights. You
                  can request that we delete or give you a copy of your personal
                  data by logging into the site, choosing "Settings" from the
                  dropdown menu in the top right, and scrolling down to the
                  relevant option. You can also access your personal data by
                  confirming that it is accurate and up-to-date, choosing
                  whether or not you want to receive information from us or some
                  of our partners, and choosing whether or not to receive
                  material from us or some of our partners.
                </p>
                <ul>
                  <li>
                    You can also get in touch with us at info@maacsolutions.com
                    if you need more information about your rights or want to
                    exercise any of them. If you live or are located in the
                    European Economic Area (EEA), the United Kingdom,
                    Switzerland, or another area that provides similar
                    protections, you have the right to request that we: provide
                    access to any Personal Data we hold about you; stop
                    processing your Personal Data for direct marketing purposes;
                    amend any Personal Data that is inaccurate or out-of-date;
                    delete any Personal Data that we are holding about you;
                    restrict how we process your Personal Data, and provide your
                    Per
                  </li>
                  <li>
                    Give you a copy of any personal information we have on you.
                  </li>
                </ul>
                <p>
                  When feasible, we attempt to respond to every request right
                  away, and we always do so within the legal deadlines. However,
                  keep in mind that even after your personal data has been
                  deleted, residual data may still exist in our databases,
                  access logs, and other records. This residual data may or may
                  not contain your personal information. Please be aware that
                  under certain conditions, Your personal data may be exempt
                  from such requests, such as when we must keep your personal
                  information to satisfy a legal requirement. We may ask for the
                  information required to verify your identity when you email us
                  with a request.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* <MainFooter /> */}
    </div>
  );
};

export default PrivecyPolicy;
