import { call, put, takeEvery } from 'redux-saga/effects';
import { deleteData, getData, postData } from '../../helpers/backend_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addWishListFail,
  addWishListSuccess,
  getWishList,
  getWishListFail,
  getWishListSuccess,
} from './actions';
import { ADD_WISHLIST, GET_WISHLIST } from './actionTypes';

function* onAddWishList({ payload: { authtoken, id, isRemove } }) {
  try {
    const url = isRemove
      ? `/wishlist/remove-from-wish-list/${id}`
      : `/wishlist/add-into-wishlist/${id}`;
    const response = isRemove
      ? yield call(deleteData, url, authtoken)
      : yield call(postData, url, {}, authtoken);
    yield put(addWishListSuccess(response));
    yield put(getWishList(authtoken));
    toaster('success', `${isRemove ? 'Remove' : 'Add'} wishlist success`);
  } catch (error) {
    const message =
      error.response?.data?.message ||
      `${isRemove ? 'Remove' : 'Add'} wishlist failed`;
    yield put(addWishListFail(message));
    toaster('error', message);
  }
}

function* onGetWishList({ payload: { authtoken } }) {
  try {
    const url = '/wishlist/get-data-from-wishlist';
    const response = yield call(getData, url, authtoken);
    yield put(getWishListSuccess(response?.getData));
    // toaster('success', 'Add wishlist success');
  } catch (error) {
    const message = error.response?.data?.message || 'get wishlist failed';
    yield put(getWishListFail(message));
    toaster('error', message);
  }
}

function* wishlistSaga() {
  yield takeEvery(ADD_WISHLIST, onAddWishList);
  yield takeEvery(GET_WISHLIST, onGetWishList);
  // yield takeEvery(GET_SINGLE_PACKAGE, fetchSinglePackage);
}

export default wishlistSaga;
