import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./create-our-service.scss";
import img1 from "./img/1.svg";
import img2 from "./img/2.svg";
import img3 from "./img/3.svg";
import img4 from "./img/4.svg";
import rightBannerImg from "./img/right-image.svg";
const studentExamCard = [
  {
    icon: img1,
    title: `Mock exam Services `,
  },
  {
    icon: img2,
    title: "Test Prep",
  },
  {
    icon: img3,
    title: "Exam Consultancy",
  },
  {
    icon: img4,
    title: "Immigration Support",
  },
];
const StudentCreatePackage = () => {
  return (
    <div className="student-create-package-main-area-wrap">
      <Container>
        <div className="herp-bottom-item">
          <div className="student-create-package-inner-wrap">
            <div className="student-create-title">
              <h3>Test Your Knowledge</h3>
              <p>
                Get prepared to ace the competitive exams. It’s just so easy,
                you choose <br /> your path to score your destined one.
              </p>
            </div>
            <Row>
              <Col lg="5 mb-3">
                <Row>
                  {studentExamCard.map((sCard, idx) => (
                    <Col sm={"6 mb-3"}>
                      <div className="student-hero-left-b-card">
                        <div className="student-hero-left-b-inner">
                          <img src={sCard.icon} alt="" />
                          <div className="student-hero-left-b-inner-image-wrap">
                            <h6>{sCard.title}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg="7 mb-3">
                <div className="create-right-banner-image">
                  <img src={rightBannerImg} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default StudentCreatePackage;
