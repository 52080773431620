import moment from "moment";
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import Loader from "../../components/Atoms/Loader";
import CardComponent from "../../components/Common/CardComponent";
import EmptyCommonComp from "../../components/Common/EmptyCommonComp";
import { getSpeakingServiceSet } from "../../store/Service/actions";
const time = {
  10: `10:00 - 10:30`,
  1030: `10:30 - 11:00`,
  11: `11:00 - 11:30`,
  1130: `11:30 - 12:00`,
  12: `12:00 - 12:30`,
  1230: `12:30 - 01:00`,
  1: `01:00 - 01:30`,
  130: `01:30 - 02:00`,
  2: `02:00 - 02:30`,
  230: `02:30 - 03:00`,
  3: `03:00 - 03:30`,
  330: `03:30 - 04:00`,
  4: `04:00 - 04:30`,
  430: `04:30 - 05:00`,
  5: `05:00 - 05:30`,
  530: `05:30 - 06:00`,
  6: `06:00 - 06:30`,
  630: `06:30 - 07:00`,
  7: `07:00 - 07:30`,
  730: `07:30 - 08:00`,
};

const SpeakingService = ({ history }) => {
  const { packageId, serviceId, type } = useParams();

  const dispatch = useDispatch();
  const { authtoken, speakingSet, loading } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.serviceReducer.loading,
    speakingSet: state.serviceReducer.speakingSet,
    profile: state.Profile?.data,
  }));

  // console.log(`profile`, speakingSet);
  useEffect(() => {
    localStorage.removeItem("xampro-xam");
    dispatch(getSpeakingServiceSet(authtoken, packageId, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("speakingSet", speakingSet);
  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="">
              <h4>Package Set </h4>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div>
                {/* <span>Time Expire</span> */}
                {/* <span>30D : 24H : 58M : 56S</span> */}
              </div>
              <div>
                <button
                  onClick={() =>
                    history.push(
                      `/my-package/${packageId}/service/${serviceId}/set/speaking/pick-time`
                    )
                  }
                  disabled={
                    speakingSet?.expire === speakingSet?.getData?.length
                  }
                  className="btn btn-primary"
                >
                  Set Time
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : (
            <div className="single-package-inner-main-wrap">
              {speakingSet?.getData?.length > 0 ? (
                <>
                  <Row>
                    {(speakingSet?.getData || []).map((data, key) => (
                      <Col lg={4} md={6} key={key}>
                        <div className="card-active-deactive-content">
                          <div className="active-pending-top-right-massage">
                            <div className="course-active-btn">
                              <span className="color-success-text">Active</span>
                            </div>
                          </div>
                          <CardComponent className={"text-center py-4"}>
                            <div className="mx-auto">
                              <img
                                src={
                                  require(`../../components/Package/img/writing.svg`)
                                    .default
                                }
                                alt="examImage"
                                className="mb-3"
                              />
                            </div>
                            <div>
                              <h4 className="mb-1">
                                {time?.[data?.meetingTime]}
                              </h4>
                              <p className="mb-2">
                                {moment(data?.date?.split("T")?.[0])?.format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              {data?.complete === 3 ||
                              (data?.complete === 2 &&
                                (data?.attandance === 0 ||
                                  data?.attandance === 1)) ? (
                                <button
                                  className="btn btn-primary ms-1"
                                  onClick={() =>
                                    history.push(
                                      `/package/${packageId}/speaking-result/${data?._id}`
                                    )
                                  }
                                >
                                  Result
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    history.push(`/speaking-exam/${data._id}`)
                                  }
                                >
                                  Start Your Exam
                                </button>
                              )}
                              {/* </Link> */}
                            </div>
                          </CardComponent>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <EmptyCommonComp
                  link={`/my-package/${packageId}/service/${serviceId}/set/speaking/pick-time`}
                  linkText={" Set Time"}
                >
                  {" "}
                  Opps! There are no selected slot for you. To set <br /> the
                  slot click{" "}
                </EmptyCommonComp>
              )}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SpeakingService);
