import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import mainMockbg from "../img/main-bg.png";
import mainMockbg2 from "../img/mock-bg-2.svg";
import rightMockImage from "../img/mock-interview-right.png";
const MockInterview = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      {/* top-area start */}
      <div
        className="dashboard-mock-interview-top-area"
        style={{
          backgroundImage:
            dimensions?.width < 992
              ? `url(${mainMockbg2})`
              : `url(${mainMockbg})`,
        }}
      >
        <Row>
          <Col lg={"8 my-auto"}>
            <div className="dashboard-mock-start-left-area">
              <h2>Start your mock exam </h2>
              <Link to="/my-package" className="mock-interview-start-button">
                Start now
              </Link>
            </div>
          </Col>
          <Col lg={"4 my-auto"}>
            <div className="dashboard-mock-start-right-area">
              <img src={rightMockImage} alt="" />
            </div>
          </Col>
        </Row>
      </div>
      {/* top-area start */}
    </div>
  );
};

export default MockInterview;
