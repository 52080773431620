import moment from "moment";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getLastThreeListening,
  getLastThreeReading,
  getLastThreeSpeaking,
  getLastThreeWriting,
} from "../../../store/Dashboard/actions";
import ListeningIcon from "../../Package/PackageIcon/ListeningIcon";
import ReadingIcon from "../../Package/PackageIcon/ReadingIcon";
import SpeakingIcon from "../../Package/PackageIcon/SpeakingIcon";
import WritingIcon from "./../../Package/PackageIcon/WritingIcon";

const studentResult = [
  {
    title: "Reading",
    icon: <ReadingIcon />,
    score: "7.5",
    button: "",
    className: "reading-result-card",
  },
  {
    title: "Speaking",
    icon: <SpeakingIcon />,
    score: "7.5",
    button: "",
    className: "speaking-result-card",
  },
  {
    title: "Listening",
    icon: <ListeningIcon />,
    score: "7.5",
    button: "",
    className: "listening-result-card",
  },

  {
    title: "Writing",
    icon: <WritingIcon />,
    score: "7.5",
    button: "",
    className: "writing-result-card",
  },
];

const StudentRasult = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    lastThreeReading,
    loading,
    authToken,
    lastThreeSpeaking,
    lastThreeWriting,
    lastThreeListening,
  } = useSelector((state) => ({
    loading: state.DashboardReducer.loading,
    lastThreeReading: state.DashboardReducer.lastThreeReading,
    lastThreeSpeaking: state.DashboardReducer.lastThreeSpeaking,
    lastThreeWriting: state.DashboardReducer.lastThreeWriting,
    lastThreeListening: state.DashboardReducer.lastThreeListening,
    authToken: state.Login.token,
  }));

  useEffect(() => {
    dispatch(getLastThreeReading(authToken));
    dispatch(getLastThreeWriting(authToken));
    dispatch(getLastThreeListening(authToken));
    dispatch(getLastThreeSpeaking(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student-result-main-area-wrap">
      <div className="studet-result-title">
        <h3>Results</h3>
      </div>
      <Row>
        <Col lg={"3 mb-3"} md="6 mb-3" sm="6 mb-3">
          <div className={`result-single-card reading-result-card`}>
            <div className="select-date-top-right-area">
              <Form.Select name="date" id="">
                <option value="">
                  {moment(
                    lastThreeReading[0]?.createdAt?.split("T")?.[0]
                  )?.format("DD MMM")}
                </option>
              </Form.Select>
            </div>

            <div className="student-result-single-item-wrap">
              <div className="s-result-icon">
                <ReadingIcon />
              </div>
              <div className="s-result-content">
                <p>Reading</p>
                <h4>
                  {lastThreeReading?.length ? lastThreeReading[0]?.mark : "0"}
                </h4>
                <a
                  href={
                    lastThreeReading?.length
                      ? `package-details/${lastThreeReading[0]?.packageId}`
                      : ""
                  }
                  className="student-result-request"
                >
                  Ask for feedback
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={"3 mb-3"} md="6 mb-3" sm="6 mb-3">
          <div className={`result-single-card speaking-result-card`}>
            <div className="select-date-top-right-area">
              <Form.Select name="date" id="">
                <option value="">
                  {" "}
                  {moment(
                    lastThreeSpeaking[0]?.createdAt?.split("T")?.[0]
                  )?.format("DD MMM")}
                </option>
              </Form.Select>
            </div>

            <div className="student-result-single-item-wrap">
              <div className="s-result-icon">
                <SpeakingIcon />
              </div>
              <div className="s-result-content">
                <p>Speaking</p>
                <h4>
                  {lastThreeSpeaking?.length
                    ? lastThreeSpeaking[0]?.score
                      ? lastThreeSpeaking[0]?.score
                      : "Not Reviewed"
                    : "0"}
                </h4>
                <a
                  href={
                    lastThreeSpeaking?.length
                      ? `package-details/${lastThreeSpeaking[0]?.packageId}`
                      : ""
                  }
                  className="student-result-request"
                >
                  Ask for feedback
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={"3 mb-3"} md="6 mb-3" sm="6 mb-3">
          <div className={`result-single-card listening-result-card`}>
            <div className="select-date-top-right-area">
              <Form.Select name="date" id="">
                <option value="">
                  {" "}
                  {moment(
                    lastThreeListening[0]?.createdAt?.split("T")?.[0]
                  )?.format("DD MMM")}
                </option>
              </Form.Select>
            </div>

            <div className="student-result-single-item-wrap">
              <div className="s-result-icon">
                <ListeningIcon />
              </div>
              <div className="s-result-content">
                <p>Listening</p>
                <h4>
                  {lastThreeListening?.length
                    ? lastThreeListening[0]?.mark
                    : "0"}{" "}
                </h4>
                <a
                  href={
                    lastThreeListening?.length
                      ? `package-details/${lastThreeListening[0]?.packageId}`
                      : ""
                  }
                  className="student-result-request"
                >
                  Ask for feedback
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={"3 mb-3"} md="6 mb-3" sm="6 mb-3">
          <div className={`result-single-card writing-result-card`}>
            <div className="select-date-top-right-area">
              <Form.Select name="date" id="">
                <option value="">
                  {" "}
                  {moment(
                    lastThreeWriting[0]?.createdAt?.split("T")?.[0]
                  )?.format("DD MMM")}
                </option>
              </Form.Select>
            </div>

            <div className="student-result-single-item-wrap">
              <div className="s-result-icon">
                <WritingIcon />
              </div>
              <div className="s-result-content">
                <p>Writing</p>
                <h4>
                  {lastThreeWriting?.length
                    ? lastThreeWriting[0]?.score
                      ? lastThreeWriting[0]?.score
                      : "Not Reviewed"
                    : "0"}
                </h4>
                <a
                  href={
                    lastThreeWriting?.length
                      ? `package-details/${lastThreeWriting[0]?.packageId}`
                      : ""
                  }
                  className="student-result-request"
                >
                  Ask for feedback
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StudentRasult;
