import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStudentPerformance } from "../../../../store/Dashboard/actions";
import GraphBgPart from "./GraphBgPart";
import GraphMainDataPart from "./GraphMainDataPart";
const examTitleData = [
  {
    name: "Reading",
  },
  {
    name: "Speaking",
  },
  {
    name: "Writing",
  },
  {
    name: "Listening",
  },
];

const Graph = () => {
  const [selectNav, setSelectNav] = useState("Reading");
  const [year, setYear] = React.useState(moment().format("YYYY"));
  const dispatch = useDispatch();
  const { studentPerformance, authToken, studentPerformanceLoading } =
    useSelector((state) => ({
      studentPerformance: state.DashboardReducer.studentPerformance,
      studentPerformanceLoading:
        state.DashboardReducer.studentPerformanceLoading,

      authToken: state.Login.token,
    }));

  useEffect(() => {
    dispatch(
      getStudentPerformance(authToken, {
        serviceName: selectNav.toLowerCase(),
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
      })
    );
  }, [selectNav, year]);

  const handleNavAddClass = (data) => {
    switch (data) {
      case "Overall":
        return "active";
      case "Speaking":
        return "active";
      case "Reading":
        return "active";
      case "Writing":
        return "active";
      case "Listening":
        return "active";

      default:
        return "";
    }
  };
  const handleChangeYear = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setYear(value);
    }
  };

  return (
    <div className="graph-card-wrap ">
      <div className="student-graph-area-header">
        <h3>Student Performance</h3>
      </div>
      <div className="performance-graph">
        <div className="exam-filtering-top-wrap">
          <div className="exam-filtering-navbar-left">
            <ul>
              {examTitleData.map((navFilter, idx) => (
                <li
                  onClick={(e) => setSelectNav(e.target.innerText)}
                  className={selectNav === navFilter.name && `filter-active`}
                  key={idx}
                >
                  {navFilter.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="exam-filtering-date-right">
            <Form.Select
              aria-label="Default select example"
              onChange={handleChangeYear}
              value={year}
            >
              <option value="">Select Year</option>
              <option value="2026">2026</option>
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
            </Form.Select>
          </div>
        </div>

        <div className="student-performance-graph-data-wrap">
          <GraphBgPart />
          <GraphMainDataPart
            studentPerformance={studentPerformance}
            serviceName={selectNav}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
