import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";

import CouponMessage from "../../components/Common/CouponMessage";
import CreateOwnPackage from "../../components/Home/CreateOwnPackage/CreateOwnPackage";
import PeopleChoose from "../../components/Home/PeopleChoose/PeopleChoose";
import StudentCreatePackage from "../../components/Home/StudentCreatePackage/StudentCreatePackage";
import StudentHeroSlider from "../../components/Home/StudentHero/StudentHeroSlider";
import GmatPackage from "../../components/Home/StudentPackage/GmatPackage";
import GREPackage from "../../components/Home/StudentPackage/GREPackage";
import IeltsPackage from "../../components/Home/StudentPackage/IeltsPackage";
import { getBanner } from "../../store/Dashboard/actions";
import ClientChat from "../ClientChat/ClientChat";
const Home = () => {
  const dispatch = useDispatch();
  const { authToken, studentBannerList, studentLoading } = useSelector(
    (state) => ({
      loading: state.DashboardReducer.loading,
      studentBannerList: state.DashboardReducer.studentBannerList,
      studentLoading: state.DashboardReducer.studentLoading,
      authToken: state.Login.token,
    })
  );
  useEffect(() => {
    dispatch(getBanner(authToken));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Home page</title>
      </MetaTags>
      <CouponMessage />
      {studentBannerList?.length > 0 ? (
        <StudentHeroSlider
          studentBannerList={studentBannerList}
          studentLoading={studentLoading}
        />
      ) : (
        ""
      )}

      <StudentCreatePackage />
      <GmatPackage />
      <GREPackage />
      <IeltsPackage />
      <PeopleChoose />
      <CreateOwnPackage />
      <ClientChat />
    </React.Fragment>
  );
};

export default Home;
