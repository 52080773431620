import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Modal } from "reactstrap";
// import clock from "../../assets/images/Icon/clock.svg";
// import IetlsImage from "../../assets/images/ielts-logo.jpg";
import IetlsImage from "../../assets/images/defaultPackage2.svg";
import AddLoader from "../../components/Common/AddLoader";
import { imageBaseUrl } from "../../helpers/api_helper";
import { addCart } from "../../store/cart/actions";
import {
  getBuyPackageList,
  getDraftPackage,
} from "../../store/Package/actions";
import { addWishList, getWishList } from "../../store/Wishlist/actions";
import EmptyDraftData from "./emptyDraft.png";
import FacilitatorIcon from "./PackageIcon/FacilitatorIcon";
import ListeningIcon from "./PackageIcon/ListeningIcon";
import ReadingIcon from "./PackageIcon/ReadingIcon";
import SpeakingIcon from "./PackageIcon/SpeakingIcon";
import WritingIcon from "./PackageIcon/WritingIcon";

const DraftPackageList = ({ history }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });

  //   const [isDraft, setIsDraft] = useState(false);
  const {
    authToken,
    id,
    draftPackage,
    // loading,
    adding,
    // wishList,
    buyPackageList,
    cart,
    ownPackageId,
  } = useSelector((state) => ({
    authToken: state.Login.token,
    id: state.Login.id,
    loading: state.PackageReducer.loading,
    draftPackage: state.PackageReducer.draftPackage.getPackages,
    ownPackageId: state.PackageReducer.draftPackage.ownPackageId,
    selectedService: state.PackageReducer.selectedService,
    wishList: state.wishlist.wishList,
    adding: state.wishlist.adding,
    buyPackageList: state.PackageReducer.buyPackageList,
    cart: state.CardReducer.cart,
  }));

  const cartId = useMemo(() => cart.map((data, idx) => data._id), [cart]);

  useEffect(() => {
    if (id && authToken) {
      dispatch(getDraftPackage(authToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const wishListId = useMemo(
  //   () => wishList.map((data) => data?.package?._id),
  //   [wishList]
  // );

  useEffect(() => {
    if (id && authToken) {
      dispatch(getWishList(authToken));
      dispatch(getBuyPackageList(authToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleAddWishlist = (data) => {
  //   if (id) {
  //     if (wishListId.includes(data?._id)) {
  //       const findWishlistId = wishList.find(
  //         (list) => list?.package?._id === data?._id
  //       );
  //       dispatch(addWishList(authToken, findWishlistId?._id, true));
  //     } else {
  //       dispatch(addWishList(authToken, data?._id));
  //     }
  //   } else {
  //     toaster("warning", "Please login for add to wishlist");
  //   }
  // };

  const handleStore = (data) => {
    dispatch(addCart(data));
  };

  const handleRemove = () => {
    dispatch(addWishList(authToken, modal.id, true));
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return IetlsImage;
  };

  const filterDraft = draftPackage?.filter(
    (data) => !ownPackageId?.[data?._id]
  );

  return (
    <div>
      <MetaTags>
        <title>Draft List</title>
      </MetaTags>
      {/* <CouponMessage /> */}
      <Container>
        <Modal
          isOpen={modal.open}
          centered
          toggle={() => setModal({ open: false, id: "" })}
        >
          <div className="p-4 w-100">
            <div>
              <h6>Are you sure you want to remove from wishlist!!</h6>
              <div className="d-flex ">
                <button
                  disabled={adding}
                  onClick={handleRemove}
                  className="btn btn-sm btn-primary"
                >
                  Yes, Remove
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {adding ? <AddLoader /> : null}
        <div className="create_package pt-5">
          <div className="d-flex justify-content-between align-items-center create_package_title">
            <h4>Your Draft Package</h4>
          </div>
          <div className="ieltspackage-main-wrap-all-package pt-1">
            <Row>
              {filterDraft?.length > 0 ? (
                <>
                  {filterDraft?.map((data, idx) => (
                    <>
                      <Col lg={4} md={"6"} key={idx}>
                        <div className="student-courses-card">
                          <Card>
                            {/* <div
                        className="bookmark-button-wrap position-absolute"
                        style={{
                          background: "#fff",
                          padding: "5px",
                          lineHeight: "5px",
                          borderRadius: "5px",
                          top: "2%",
                          right: "2%",
                          fontSize: "18px",
                        }}
                        onClick={() => handleAddWishlist(data)}
                      >
                        {wishListId?.includes(data?._id) ? (
                          <i
                            className="bx bxs-bookmark"
                            style={{ color: "#004AB8" }}
                          ></i>
                        ) : (
                          <i
                            className="bx bx-bookmark"
                            style={{ color: "#004AB8" }}
                          ></i>
                        )}
                      </div> */}
                            <div
                              className="card-img-top package-card-image"
                              style={{
                                backgroundImage: `url(${getPhotoUrl(
                                  data?.photo
                                )})`,
                              }}
                            ></div>
                            <Card.Body>
                              {/* <div className="student-courses-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <span> 5.00(5)</span>
                        </div> */}
                              <Link to={`/package-details/${data._id}`}>
                                {/* `/package-details/${data._id}` */}
                                <Card.Title>
                                  {data?.packageName || "N/A"}
                                </Card.Title>
                                <div className="package-subject-name-list-item">
                                  {data?.packageService.map((service, idx) => (
                                    <>
                                      {service?.serviceName.toLowerCase() ===
                                        "listening" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <ListeningIcon />
                                          <span>
                                            {data?.listening}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "reading" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <ReadingIcon />
                                          <span>
                                            {data?.reading}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "writing" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <WritingIcon />
                                          <span>
                                            {data?.writing}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "speaking" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <SpeakingIcon />
                                          <span>
                                            {data?.speaking}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ))}{" "}
                                  {data?.totalFacilitator ? (
                                    <div className="package-subject-name-list-item-single-item facilitator-review">
                                      <FacilitatorIcon />

                                      <span>
                                        {data?.totalFacilitator} One To One
                                        Review
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Link>

                              {/* <Card.Text className="student-courses-timeline">
                          <img src={clock} alt="" />
                          Duration - {data?.validity || ""} Days
                        </Card.Text> */}
                              <div className="student-courses-card-button">
                                {cartId?.includes(data?._id) ? (
                                  <Button
                                    disabled={buyPackageList?.[data?._id] > 0}
                                    onClick={() => history.push(`/cart`)}
                                  >
                                    {buyPackageList?.[data?._id] > 0
                                      ? "Purchased"
                                      : "Go to cart"}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={buyPackageList?.[data?._id] > 0}
                                    onClick={() => handleStore(data)}
                                  >
                                    {buyPackageList?.[data?._id] > 0
                                      ? "Purchased"
                                      : "Add to cart"}
                                  </Button>
                                )}
                                <p>
                                  <b>TK. {data?.price || ""}</b>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="empty-draft-page-area-wrap">
                    <div className="empty-draft-inner-area">
                      <img src={EmptyDraftData} alt="" />
                      <p>Opps! You have not saved any package drafts yet</p>
                      <Link to="/create-package">Go back to create</Link>
                    </div>
                  </div>
                </>
              )}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(DraftPackageList);
