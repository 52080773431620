import AboutUs from "../Pages/AboutUs/AboutUs";
import CheckEmail from "../Pages/Authentication/ChackEmail/CheckEmail";
import ChangePassword from "../Pages/Authentication/ChangePassword/ChangePassword";
import ForgotPassword2 from "../Pages/Authentication/ForgotPassword/ForgotPassword2";
import LoginMain from "../Pages/Authentication/Login/LoginMain";
import Profile from "../Pages/Authentication/Profile/Profile";
import NewSignUp from "../Pages/Authentication/Register/NewSignUp";
import SignUp2 from "../Pages/Authentication/Register/SignUp2";
import ResetPassword2 from "../Pages/Authentication/ResetPassword/ResetPassword2";
import VerifyEmail from "../Pages/Authentication/VerifyEmail/VerifyEmail";
import Cart from "../Pages/Cart/Cart";
import FacilitatorCart from "../Pages/Cart/FacilitatorCart";
import DashboardBlogDetails from "../Pages/Dashboard/BlogDetails/DashboardBlogDetails";
import Dashboard2 from "../Pages/Dashboard/Dashboard2";
import EmailVerified from "../Pages/EmailVerified/EmailVerified";
import ListeningExam from "../Pages/Exam/Listening/ListeningExam";
import ReadingExam from "../Pages/Exam/Reading";
import PickTime from "../Pages/Exam/Speaking/PickTime";
import SpeakingExam from "../Pages/Exam/Speaking/SpeakingExam";
import WritingExam from "../Pages/Exam/Writing";
import FacilitatorParticularSlot from "../Pages/Facilatator/FacilitatorParticularSlot";
import FacilitatorService from "../Pages/Facilatator/FacilitatorService";
import FacilitatorTimeSet from "../Pages/Facilatator/FacilitatorTimeSet";
import faq from "../Pages/FAQ/Faq";
import Home from "../Pages/Home/Home";
import HomeNew from "../Pages/HomeNew/HomeNew";
import IELTSTip from "../Pages/IELTSTip/IELTSTip";
import Leaderboard from "../Pages/Leaderboard/Leaderboard";
import CreateYourPackage from "../Pages/Package/CreateYourPackage";
import DraftPackageList from "../Pages/Package/DraftPackageList";
import FavoriteCourse from "../Pages/Package/FavouriteCourse";
import GmatPackage from "../Pages/Package/GmatPackage";
import IeltsAcademicPackage from "../Pages/Package/IeltsAcademicPackage";
import IeltsGeneralPackage from "../Pages/Package/IeltsGeneralPackage";
import IeltsPackage from "../Pages/Package/IeltsPackage";
import EmptyPackage from "../Pages/Package/MyPackage/EmptyPackage";
import MyPackage from "../Pages/Package/MyPackage/MyPackage";
import NotRegister from "../Pages/Package/MyPackage/NotRegister";
import ViewSinglePackage from "../Pages/Package/MyPackage/ViewSinglePackage";
import PackageDetails from "../Pages/Package/PackageDetails/PackageDetails";
import PackageOne from "../Pages/Package/PackageOne";
import PackagePurchased from "../Pages/PackagePurchased/PackagePurchased";
import SuccessfullyPurchase from "../Pages/Purchase/SuccessfullyPurchase";
import RatingExam from "../Pages/RatingExam/RatingExam";
import Result from "../Pages/Result/Result";
import SpeakingResultPage from "../Pages/Result/SpeakingResultPage";
import ReturnPolicy from "../Pages/ReturnPolicy/ReturnPolicy";
import ServiceList from "../Pages/Services";
import ListeningService from "../Pages/Services/ListeningService";
import SpeakingService from "../Pages/Services/SpeakingService";
import WritingService from "../Pages/Services/WritingService";
import TermAndConditions from "../Pages/TermAndConditions/TermAndConditions";
import VideoTutorial from "../Pages/VideoTutorial/VideoTutorial";
import PrivecyPolicy from "./../Pages/PrivecyPolicy/PrivecyPolicy";

export const openRoute = [
  { path: "/home2", component: Home },
  { path: "/", component: HomeNew },

  { path: "/verify-account", component: VerifyEmail },
  { path: "/check-email", component: CheckEmail },
  // { path: "/packages/:courseId", component: PackageOne },
  { path: "/ielts-package", component: IeltsPackage },
  { path: "/ielts-academic-package", component: IeltsAcademicPackage },
  { path: "/ielts-general-package", component: IeltsGeneralPackage },
  { path: "/upcoming", component: GmatPackage },
  { path: "/ielts-tips", component: IELTSTip },
  { path: "/change-password", component: ChangePassword },
  { path: "/about-us", component: AboutUs },
  { path: "/facilitatorCart", component: FacilitatorCart },
  { path: "/no-register", component: NotRegister },
  { path: "/video-tutorial", component: VideoTutorial },
  { path: "/term-and-condition", component: TermAndConditions },
  { path: "/privacy-policy", component: PrivecyPolicy },
  { path: "/refound-policy", component: ReturnPolicy },
  { path: "/faq", component: faq },
  { path: "/blog-details/:id", component: DashboardBlogDetails },
];

export const openRoute2 = [
  { path: "/packages/Ielts", component: PackageOne },
  { path: "/create-package", component: CreateYourPackage },
  { path: "/package-details/:id", component: PackageDetails },
  { path: "/cart", component: Cart },
];
export const privateRouteHeadless = [
  { path: "/reading-exam/:id/:packageId", component: ReadingExam },
  { path: "/writing-exam/:id/:packageId", component: WritingExam },
  { path: "/listening-exam/:id/:packageId", component: ListeningExam },
];
export const openRouteHeadless = [
  { path: "/login", component: LoginMain },
  { path: "/signup", component: SignUp2 },
  { path: "/sampleSignup", component: NewSignUp },
  { path: "/reset-password", component: ResetPassword2 },
  { path: "/forgot-password", component: ForgotPassword2 },
];

export const privateRoute2 = [
  { path: "/successfully-purchase", component: SuccessfullyPurchase },
  { path: "/dashboard", component: Dashboard2 },
  { path: "/my-package", component: MyPackage },
  { path: "/leaderboard", component: Leaderboard },
  { path: "/empty-package", component: EmptyPackage },
  { path: "/my-favorites-courses", component: FavoriteCourse },
  { path: "/profile", component: Profile },
  { path: "/my-package/:id/:courseId", component: ViewSinglePackage },
  { path: "/my-package/:id/:courseId/:type", component: ViewSinglePackage },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/:courseId/reading",
    component: ServiceList,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/:courseId/reading/:type",
    component: ServiceList,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/listening",
    component: ListeningService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/listening/:type",
    component: ListeningService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/:courseId/writing",
    component: WritingService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/:time/:courseId/writing/:type",
    component: WritingService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/speaking",
    component: SpeakingService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/speaking/:type",
    component: SpeakingService,
  },
  {
    path: "/my-package/:packageId/service/:serviceId/set/speaking/pick-time",
    component: PickTime,
  },
  { path: "/speaking-exam/:id", component: SpeakingExam },
  { path: "/package/:packageId/result/:id/:type", component: Result },
  {
    path: "/package/:packageId/speaking-result/:slotId",
    component: SpeakingResultPage,
  },
  { path: "/email-verified", component: EmailVerified },
  { path: "/package-purchased", component: PackagePurchased },
  { path: "/rating-exam", component: RatingExam },
  // Facilitator

  {
    path: "/facilitator-service/:id/:isFacilatator",
    component: FacilitatorService,
  },
  {
    path: "/facilitator-time-set/:id",
    component: FacilitatorTimeSet,
  },
  {
    path: "/facilitator-particular-slot/:id",
    component: FacilitatorParticularSlot,
  },

  { path: "/draft-package", component: DraftPackageList },
];
