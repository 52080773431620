import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, ModalHeader, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "reactstrap";
import IetlsImage from "../../assets/images/ielts-logo.jpg";
import { imageBaseUrl } from "../../helpers/api_helper";
import FacilitatorIcon from "../../Pages/Package/PackageIcon/FacilitatorIcon";
import ListeningIcon from "../../Pages/Package/PackageIcon/ListeningIcon";
import ReadingIcon from "../../Pages/Package/PackageIcon/ReadingIcon";
import SpeakingIcon from "../../Pages/Package/PackageIcon/SpeakingIcon";
import WritingIcon from "../../Pages/Package/PackageIcon/WritingIcon";
import { addCart } from "../../store/cart/actions";
import { getTopThreePackageInfo } from "../../store/Dashboard/actions";

import { Link } from "react-router-dom";
import Loader from "../Atoms/Loader";
import "./style/new-popular-course.scss";
const NewPopularCourse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal2, setModal2] = useState(false);
  const [modalCardTitle, setModalCardTitle] = useState("");
  const [modalCardImage, setModalCardImage] = useState("");
  const [modalCardDuration, setModalCardDuration] = useState("");
  const {
    authtoken,
    topThreePackageData,
    topThreePackageDataLoading,
    cart,
    buyPackageList,
    countryName,
    dollarRate,
  } = useSelector((state) => ({
    authtoken: state.Login.token,

    topThreePackageData: state.DashboardReducer.topThreePackageData,
    topThreePackageDataLoading:
      state.DashboardReducer.topThreePackageDataLoading,
    cart: state.CardReducer.cart,
    buyPackageList: state.PackageReducer.buyPackageList,
    countryName: state.PackageReducer.countryName,
    dollarRate: state.PackageReducer.dollarRate,
  }));
  console.log("dollarRate", dollarRate);
  const cartId = useMemo(() => cart.map((data, idx) => data._id), [cart]);
  useEffect(() => {
    dispatch(getTopThreePackageInfo(authtoken));
  }, []);
  const handleStore = (data) => {
    setModalCardTitle(data?.packageName);
    setModalCardImage(data?.photo);
    setModalCardDuration(data?.validity);

    dispatch(addCart(data));
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return IetlsImage;
  };
  const convertToDollar = (data) => {
    console.log("data", data);
    return (data / dollarRate?.BDT).toFixed(2);
  };
  return (
    <div className="new-popular-course-full-wrap">
      <Container>
        <div className="new-popular-inner-area-wrap">
          <div className="popular-area-title">
            <h2>Some popular courses</h2>
          </div>
          {topThreePackageDataLoading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <div className="new-popular-course-list-content">
              <Row>
                {topThreePackageData?.map((data, idx) => (
                  <Col lg="4" className="mb-3" key={idx}>
                    <div className="single-new-popular-course">
                      <div className="single-new-popular-course-content">
                        <Link to={`/package-details/${data._id}`}>
                          <div className="single-new-popular-course-content-header">
                            <img
                              src={getPhotoUrl(data?.photo)}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </Link>
                        <div className="single-new-popular-course-info-content-bottom">
                          <div className="new-course-rating-top">
                            <div className="new-home-package-rating-area">
                              <span>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star-half"></i>
                              </span>
                              <span>5.00 ({(idx + 1) * 43})</span>
                            </div>
                            <span>
                              <i class="bx bx-time"></i>
                              {data?.validity} days
                            </span>
                          </div>
                          <Link to={`/package-details/${data._id}`}>
                            <div className="new-course-info-content-middle">
                              <h6>{data?.packageName}</h6>
                              <div className="popular-course-info-feature">
                                {data?.packageService.map((service, idx) => (
                                  <>
                                    {service?.serviceName?.toLowerCase() ===
                                      "listening" && (
                                      <div className="single-course-feature">
                                        <span>
                                          <ListeningIcon />
                                        </span>
                                        <span>
                                          {data?.readingQ}{" "}
                                          {service?.serviceName}
                                        </span>
                                      </div>
                                    )}
                                    {service?.serviceName?.toLowerCase() ===
                                      "reading" && (
                                      <div
                                        className="single-course-feature"
                                        key={idx}
                                      >
                                        <ReadingIcon />
                                        <span>
                                          {data?.readingQ}{" "}
                                          {service?.serviceName}
                                        </span>
                                      </div>
                                    )}
                                    {service?.serviceName?.toLowerCase() ===
                                      "writing" && (
                                      <div
                                        className="single-course-feature"
                                        key={idx}
                                      >
                                        <WritingIcon />
                                        <span>
                                          {data?.writingQ}{" "}
                                          {service?.serviceName}
                                        </span>
                                      </div>
                                    )}
                                    {service?.serviceName?.toLowerCase() ===
                                      "speaking" && (
                                      <div
                                        className="single-course-feature"
                                        key={idx}
                                      >
                                        <SpeakingIcon />
                                        <span>
                                          {data?.speakingQ}{" "}
                                          {service?.serviceName}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ))}
                                {data?.totalFacilitator ? (
                                  <div className="package-subject-name-list-item-single-item facilitator-review">
                                    <FacilitatorIcon />

                                    <span>
                                      {data?.totalFacilitator} One to One Review
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Link>
                          <div className="new-course-bottom-price-area">
                            {/* <Link to={`/cart-page/${data._id}`}> */}
                            {cartId?.includes(data?._id) ? (
                              <Button
                                disabled={buyPackageList?.[data?._id] > 0}
                                onClick={() =>
                                  // toaster(
                                  //   "warning",
                                  //   "Package is already to card added!"
                                  // )
                                  history.push(`/cart`)
                                }
                              >
                                {buyPackageList?.[data?._id] > 0
                                  ? "Purchased"
                                  : "Go to cart"}
                              </Button>
                            ) : (
                              <Button
                                disabled={buyPackageList?.[data?._id] > 0}
                                onClick={() => {
                                  handleStore(data);
                                  setModal2(true);
                                }}
                              >
                                {buyPackageList?.[data?._id] > 0
                                  ? "Purchased"
                                  : "Add to cart"}
                              </Button>
                            )}
                            {countryName === "Bangladesh" ? (
                              <p>
                                {data?.discount > 0 ? (
                                  <>
                                    <del style={{ color: "#0052CC" }}>
                                      TK. {data.price}
                                    </del>{" "}
                                    <b>TK. {data.price - data?.discount}</b>
                                  </>
                                ) : (
                                  <b>TK. {data.price}</b>
                                )}
                              </p>
                            ) : (
                              <p>
                                {data?.discount > 0 ? (
                                  <>
                                    <del style={{ color: "#0052CC" }}>
                                      &#36; {convertToDollar(data.price)}
                                    </del>{" "}
                                    <b>
                                      &#36;{" "}
                                      {convertToDollar(
                                        data.price - data?.discount
                                      )}
                                    </b>
                                  </>
                                ) : (
                                  <b> &#36; {convertToDollar(data.price)}</b>
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <div className="new-popular-all-course-button-bottom">
            <Link to={"/packages/Ielts"}>
              See All Pack
              <i class="bx bx-right-arrow-alt"></i>
            </Link>
          </div>
        </div>
      </Container>
      <Modal
        size={"lg"}
        isOpen={modal2}
        centered
        toggle={() => setModal2(false)}
      >
        <ModalHeader className="added-card-header" toggle={modal2}>
          <span>
            <i className="bx bx-check"></i>
            Added to the cart
          </span>
          <button
            onClick={() => {
              setModal2(false);
            }}
          >
            <span></span>
            <span></span>
          </button>
        </ModalHeader>
        <div className="added-card-go-to-card-popup">
          <div className="card-content-info-left">
            <img src={getPhotoUrl(modalCardImage)} alt="" />
            <div className="card-content-info-left-info">
              <h6>{modalCardTitle}</h6>
              <p>
                <i className="bx bx-time-five"></i> Duration -{" "}
                {modalCardDuration} Days{" "}
              </p>
            </div>
          </div>
          <div className="card-content-info-button">
            <button
              onClick={() => history.push(`/cart`)}
              className="btn btn-sm btn-primary"
            >
              Go to cart
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewPopularCourse;
