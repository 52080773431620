import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { STORE_OWN_PACKAGE_DATA } from "./actionTypes";
const initialState = {
  packageName: "",
  courseType: "",
  selectedOwnService: [],
};

const OwnPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_OWN_PACKAGE_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "exmPro-",
  key: "ownPackage",
  storage,
};

export default persistReducer(persistConfig, OwnPackageReducer);
