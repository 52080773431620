import {
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
} from './actionTypes';

export const updatePassword = (values, history, token) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { values, history, token },
  };
};

export const restPassword = (values, history, token) => {
  return {
    type: RESET_PASSWORD,
    payload: { values, history, token },
  };
};

export const updatePasswordSuccess = (message) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: {
      message,
    },
  };
};

export const updatePasswordFail = (message) => {
  return {
    type: UPDATE_PASSWORD_FAIL,
    payload: {
      message,
    },
  };
};
