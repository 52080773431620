import { all, fork } from "redux-saga/effects";
import AnswerSaga from "./Answer/saga";
import { forgetPasswordSaga } from "./auth/forgetpwd/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import UpdateProfilePassSaga from "./auth/profilepwdupdate/saga";
//public
import AccountSaga from "./auth/register/saga";
import updatePasswordSaga from "./auth/updatePassword/saga";
import verifyEmailSaga from "./auth/verifyEmail/saga";
import addCardSaga from "./cart/saga";
import ChatSaga from "./chat/saga";
import courseSaga from "./Course/saga";
import courseTypeSaga from "./CourseType/saga";
import DashboardSaga from "./Dashboard/saga";
import FacilitatorSaga from "./Facilitator/saga";
import FaqSaga from "./FAQ/saga";
import LayoutSaga from "./layout/saga";
import OrderSaga from "./Order/saga";
import PackageSaga from "./Package/saga";
import QuestionSaga from "./Question/saga";
import ResultSaga from "./Result/saga";
import ReviewAnswerSaga from "./ReviewAnswer/saga";
import ServiceSaga from "./Service/saga";
import speakingSaga from "./Speaking/saga";
import speakingQuesSet from "./SpeakingQuestion/saga";
import wishlistSaga from "./Wishlist/saga";
export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(updatePasswordSaga),
    fork(UpdateProfilePassSaga),
    fork(forgetPasswordSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(verifyEmailSaga),
    fork(ServiceSaga),
    fork(PackageSaga),
    fork(OrderSaga),
    fork(QuestionSaga),
    fork(AnswerSaga),
    fork(ResultSaga),
    fork(addCardSaga),
    fork(wishlistSaga),
    fork(courseSaga),
    fork(courseTypeSaga),
    fork(ReviewAnswerSaga),
    fork(speakingSaga),
    fork(speakingQuesSet),
    fork(FacilitatorSaga),
    fork(DashboardSaga),
    fork(ChatSaga),
    fork(FaqSaga),
  ]);
}
