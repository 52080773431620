import { call, put, takeEvery } from "redux-saga/effects";
import { getData, postData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addFacilitatorFail,
  addFacilitatorSuccess,
  getFacilitatorReviewFail,
  getFacilitatorReviewSuccess,
  getFacilitatorServiceFail,
  getFacilitatorServiceSuccess,
  getParticularSlotFail,
  getParticularSlotSuccess,
  getStudentFacilitatorSlotFail,
  getStudentFacilitatorSlotSuccess,
  getVerifyFacilitatorFail,
  getVerifyFacilitatorSuccess,
} from "./actions";
//from pacakge type
import {
  ADD_FACILITATOR_SLOT,
  GET_FACILITATOR_REVIEW,
  GET_FACILITATOR_SERVICE,
  GET_PARTICULAR_SLOT,
  GET_STUDENT_FACILITATOR_SLOT,
  GET_VERIFY_FACILITATOR,
} from "./actionTypes";

function* fetchVerifyFacilitator({ payload: { authtoken, id } }) {
  try {
    const url = `order/have-facilator/${id}`;
    const response = yield call(getData, url, authtoken);
    // console.log(response);
    yield put(getVerifyFacilitatorSuccess(response));
  } catch (error) {
    const message = error?.response?.message?.data || "failed";
    toaster("error", message);
    yield put(getVerifyFacilitatorFail(message));
  }
}
function* workerStudentFacilitatorSlot({
  payload: {
    token,
    options: { date },
  },
}) {
  try {
    const url = `/student/get-calendar-facilitator-fullfiled-or-not/${date}`;
    const response = yield call(getData, url, token);
    // console.log(`response2`, response);
    yield put(getStudentFacilitatorSlotSuccess(response));
  } catch (error) {
    const message = "Get single set fail";
    toaster("error", message);
    yield put(getStudentFacilitatorSlotFail(message));
  }
}
function* workerAddFacilitatorSlot({
  payload: {
    values,
    options: { history, token },
  },
}) {
  try {
    const url = `/student/pick-facilitator-meeting-time`;
    const response = yield call(postData, url, values, token);
    yield put(addFacilitatorSuccess(response?.getData));
    toaster("success", response.message || `Set time Successful`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(addFacilitatorFail(message));
  }
}
function* fetchFacilitatorServiceSet({ payload: { authtoken, packageId } }) {
  try {
    const url = `/student/get-facilitator-set-under/${packageId} `;
    const response = yield call(getData, url, authtoken);
    yield put(getFacilitatorServiceSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Speaking service set failed";
    yield put(getFacilitatorServiceFail(message));
    toaster("error", message);
  }
}

function* workerGetFacilitatorReview({
  payload: { token, setId, studentId, type },
}) {
  try {
    const url =
      type === "reading"
        ? `get-facilitator-reading-review/${studentId}/${setId}`
        : type === "listening"
        ? `get-facilitator-listening-review/${studentId}/${setId}`
        : `get-facilitator-writing-review/${studentId}/${setId}`;
    const response = yield call(getData, url, token);
    yield put(getFacilitatorReviewSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail";
    toaster("error", message);
    yield put(getFacilitatorReviewFail(message));
  }
}
function* fetchFacilitatorParticularSlot({ payload: { token, examId } }) {
  try {
    const url = `/facilitator/get-particular-exam-data/${examId} `;
    const response = yield call(getData, url, token);
    yield put(getParticularSlotSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Speaking service set failed";
    yield put(getParticularSlotFail(message));
    toaster("error", message);
  }
}
function* FacilitatorSaga() {
  yield takeEvery(GET_VERIFY_FACILITATOR, fetchVerifyFacilitator);
  yield takeEvery(GET_STUDENT_FACILITATOR_SLOT, workerStudentFacilitatorSlot);
  yield takeEvery(ADD_FACILITATOR_SLOT, workerAddFacilitatorSlot);
  yield takeEvery(GET_FACILITATOR_SERVICE, fetchFacilitatorServiceSet);
  yield takeEvery(GET_FACILITATOR_REVIEW, workerGetFacilitatorReview);
  yield takeEvery(GET_PARTICULAR_SLOT, fetchFacilitatorParticularSlot);
}

export default FacilitatorSaga;
