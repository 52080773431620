/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import dropfile from "../../assets/images/OBJECTS.svg";
import { formatBytes } from "../../helpers/custom/formatBytes";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from "../../helpers/custom/styleHelper.js";
import Loader from "../Atoms/Loader";

const UploadFile = ({
  setSelectedFiles,
  onDropCallBack,
  dropZoneClass,
  exceptFile = {
    "image/jpg": [],
    "image/png": [],
    "image/jpeg": [],
  },
  isDisabled,
  isMultiple,
  isLoading,
  dropText,
  reRender,
  isBrowse = true,
}) => {
  const onDropAccepted = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles && setSelectedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const singleFile = 1048576;
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setSelectedFiles && setSelectedFiles(acceptedFiles);
      onDropCallBack && onDropCallBack(acceptedFiles);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reRender]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: exceptFile || "image/png, image/jpg, image/jpeg",
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDrop,
    minSize: 0,
    multiple: isMultiple,
    disabled: isDisabled,
    // maxSize: singleFile,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <React.Fragment>
      <div
        className={dropZoneClass}
        style={{ cursor: isDisabled ? "not-allowed" : "" }}
      >
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isLoading ? (
            <Loader text="Uploading..." />
          ) : (
            <div className="d-flex align-items-center gap-3" onClick={open}>
              <span className="text-center">
                <img src={dropfile} alt="" />
                <div className="">
                  <div className="pointer_event" style={{ cursor: "pointer" }}>
                    {/* <img
                  style={{ maxWidth: "21px" }}
                  src={dropfile}
                  alt="dropImage"
                /> */}
                  </div>
                  <h6 className="text-dark">{dropText || "Drop files here"}</h6>
                  {isBrowse && (
                    <span>
                      <span className="text-muted">or </span> <a>Browse</a>
                    </span>
                  )}
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadFile;
