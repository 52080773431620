import icon1 from "../icon/icon-1.svg";
import icon2 from "../icon/icon-2.svg";
import icon3 from "../icon/icon-3.svg";
import icon4 from "../icon/icon-4.svg";
export const featureData = [
  {
    icon: icon1,
    title: "Complete exam packages    ",
    description: `Through Xampro you will be able to experience the environment that you may find in actual IELTS exam. The question formats are just like the ones in IELTS while all the modules are within limited time just like the examinees will face in IELTS exam. While features like "Take a note" in Reading and Listening test and "image upload" in writing test after writing your answer in paper will work as tools that you may use in actual exams as well. This overall will help you to strategize better.`,
  },
  {
    icon: icon2,
    title: `Get instant results    `,
    description: `You can find your results instantly and know what went wrong. While the result of writing test might take a bit of time as it is reviewed personally by a facilitator, for the other modules you will get your results instantly and that too what the right answers were.`,
  },
  {
    icon: icon3,
    title: "Check performance",
    description: `During this time you may be facing a lot of mock exams and you surely want to know how your performance has been over a period of time. In Xampro you will find a dashboard through which you can see your performance in each of the modules over time. Through this you will exactly find out on what you need to focus on and achieve a better overall score.
    `,
  },
  {
    icon: icon4,
    title: "Facilitator peview",
    description: `Giving mock exams only always do not cut it to achieve a better band score. One of the primary reasoning behind attending mock exams is to ensure that you do better in actual exams and its possible to do so only by finding your strengths and weaknesses. Well after performing a mock exam, you may not be able to find your strength and weakness all the time. Take a review from the experts and they will tell you exactly what the missing piece in your puzzle is, which is stopping you from achieving +1 band score from what you were suppose to achieve.`,
  },
];
