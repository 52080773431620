import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import { storeSlot } from "./actions";
//for main
import {
  GET_STUDENT_PROFILE,
  SHOW_RESULT,
  SUBMIT_MARKS,
  TEACHER_START_EXAM,
  UPDATE_ATTENDANCE,
} from "./actionTypes";

function* onGetProfile({ payload: { token, slotId } }) {
  try {
    const url = `/teacher/get-student-data/${slotId}`;
    const response = yield call(getData, url, token);

    yield put(storeSlot("profile", response?.getData));
    yield put(storeSlot("loading", false));
  } catch (error) {
    const message = error?.response?.data?.message || "Get profile fail";
    toaster("error", message);
    yield put(storeSlot("error", message));
    yield put(storeSlot("loading", false));
  }
}

function* onStartExam({ payload: { slotId, token, meetLink } }) {
  try {
    const url = `/teacher/start-exam/${slotId}`;
    const response = yield call(patchData, url, {}, token);
    yield put(storeSlot("adding", false));
    if (response) {
      window.location.replace(meetLink);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail";
    toaster("error", message);
    yield put(storeSlot("error", message));
    yield put(storeSlot("adding", false));
  }
}

function* onUpdateAttendance({
  payload: {
    options: { value, slotId, token, date, role },
  },
}) {
  try {
    const url = `/teacher/update-attandance-student/${slotId}`;
    yield call(patchData, url, value, token);
    yield put(storeSlot("adding", false));

    // yield put(getSingleSpeakingSetsSingleDay(token, { date, role }))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail";
    toaster("error", message);
    yield put(storeSlot("error", message));
    yield put(storeSlot("adding", false));
  }
}

function* onSubmitMarks({
  payload: {
    options: { value, slotId, token, history },
  },
}) {
  try {
    const url = `/teacher/submit-mark/${slotId}`;
    yield call(patchData, url, value, token);
    yield put(storeSlot("adding", false));

    history.goBack();
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail";
    toaster("error", message);
    yield put(storeSlot("error", message));
    yield put(storeSlot("adding", false));
  }
}

function* onGetMarks({ payload: { slotId, token } }) {
  try {
    const url = `/show-particular-exam-result/${slotId}`;
    const data = yield call(getData, url, token);

    yield put(storeSlot("loading", false));
    yield put(storeSlot("result", data?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get result fail";
    toaster("error", message);
    yield put(storeSlot("error", message));
    yield put(storeSlot("loading", false));
  }
}

function* speakingQuesSet() {
  yield takeEvery(GET_STUDENT_PROFILE, onGetProfile);
  yield takeEvery(TEACHER_START_EXAM, onStartExam);
  yield takeEvery(UPDATE_ATTENDANCE, onUpdateAttendance);
  yield takeEvery(SUBMIT_MARKS, onSubmitMarks);
  yield takeEvery(SHOW_RESULT, onGetMarks);
}
export default speakingQuesSet;
