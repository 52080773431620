import React, { useMemo, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useParams, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import arrowRight from "../../assets/images/Icon/arrow-ios-right.svg";
import time from "../../assets/images/Icon/clock.svg";
import keypad from "../../assets/images/Icon/keypad.svg";
import map from "../../assets/images/Icon/map.svg";
import papar from "../../assets/images/Icon/paper-plane.svg";
import { toaster } from "../../helpers/custom/Toast";
import { storeQuestion, submitExam } from "../../store/Question/actions";
import PromptMessage from "../UserLeaveConfirmation/PromptMessage";
import submitPopup from "./img/popupIamge.png";

const QuesFooter = ({ history, handleScroll }) => {
  const [timeValue, setTimeValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { packageId } = useParams();
  // const [isRender, setIsRender] = useState(false);
  const dispatch = useDispatch();
  // const [isSubmit, setIsSubmit] = useState(false);
  const {
    questions,
    currentIndex,
    ans,
    authToken,
    adding,
    answerId,
    quesLoading,
    isRouteChange,
  } = useSelector((state) => ({
    questions: state.QuesReducer.questions,
    currentIndex: state.QuesReducer.currentIndex,
    ansQuestion: state.QuesReducer.ansQuestion,
    readingQues: state.QuesReducer.readingQues?.data,
    quesLoading: state.QuesReducer.quesLoading,
    ans: state.QuesReducer.ans,
    adding: state.QuesReducer.adding,
    authToken: state.Login.token,
    answerId: state.QuesReducer.answerId,
    isRouteChange: state.QuesReducer.isRouteChange,
  }));
  // console.log(`isRouteChange`, isRouteChange, answerId);
  // console.log(`questions`, questions, ans);
  const ansData = useMemo(() => {
    let answer = [];
    for (const obj in ans) {
      answer.push({ questionNumber: obj, ...ans[obj] });
    }
    return answer;
  }, [ans]);

  const onOpenModal = () => setOpen(true);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal = () => setOpen(false);
  const onCloseModal2 = () => setOpen2(false);
  const [submitted, setSubmitted] = useState(false);

  const handleNext = () => {
    dispatch(storeQuestion("currentIndex", currentIndex + 1));
  };
  const handleBack = () => {
    dispatch(storeQuestion("currentIndex", currentIndex - 1));
  };

  const submitAnswer = () => {
    localStorage.removeItem("xampro-xam");
    const spendTime = Math.round((60000 * 60 - timeValue) / 60000);
    const options = {
      type: "reading",
      id: answerId,
      history,
      time: spendTime,
      submissionReason: 1,
      examStatus: 1,
      packageId: packageId,
    };
    dispatch(submitExam(ans, authToken, options));
    // dis;
  };

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  // window.addEventListener("beforeunload", function (e) {
  //   // Cancel the event and
  //   // show alert that the unsaved
  //   // changes would be lost
  //   e.preventDefault();
  //   e.returnValue = "";
  // });

  const checkpoints = [
    {
      time: 10000 * 60,
      callback: () => {
        toaster("warning", "You have 10min remaining");
      },
    },
    {
      time: 20000 * 60,
      callback: () => {
        toaster("warning", "You have 20min remaining");
      },
    },
    {
      time: 0,
      callback: () => {
        toaster("success", "Your time is up! \n Submitting The answer");
        submit();
        // history.goBack();
      },
    },
  ];
  const spendTime = Math.round((60000 * 60 - timeValue) / 60000);
  console.log("spendTime", spendTime);
  const options = {
    type: "reading",
    id: answerId,
    history,
    time: spendTime,
    submissionReason: 1,
    examStatus: 2,
    packageId: packageId,
  };
  const options2 = {
    type: "reading",
    id: answerId,
    history,
    time: spendTime,
    submissionReason: 1,
    examStatus: 2,
    packageId: packageId,
    authToken,
    ans,
  };
  const submit = (isBack) => {
    localStorage.removeItem("xampro-xam");
    dispatch(submitExam(ans, authToken, options));
  };

  window.addEventListener("unload", (event) => {
    //call function to save you state in API or save in localStore
    console.log("before reload");
    localStorage.setItem("xampro-xam", JSON.stringify(options2));
    // l.setItem('state', JSON.stringify(store.getState()))
  });

  return (
    <div className="exam-ques-footer-main-wrap">
      <PromptMessage
        dependent={isRouteChange}
        header={"Attention!! You are about to leave the exam."}
        content={
          "Are you sure you want to leave? You will not be able to give exam again."
        }
        submit={options2}
      />
      <div className="ques-footer-top">
        <div className="exam-f-left">
          <h5>Section {currentIndex + 1}</h5>
        </div>
        <div className="exam-f-right">
          <div className="exam-h-marks exam-h-t-right-button">
            {currentIndex > 0 ? (
              <button
                className="btn  me-2"
                style={{ backgroundColor: "#fff", color: "#000" }}
                onClick={handleBack}
              >
                <i className="fa fa-angle-left"></i> Previous
              </button>
            ) : null}
            {currentIndex < questions?.length - 1 ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleNext}
              >
                Next Section <img src={arrowRight} alt="" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="ques-footer-bottom">
        <div
          className="exam-f-left"
          style={{ cursor: "pointer" }}
          onClick={onOpenModal2}
        >
          <React.Fragment>
            <img src={keypad} alt="question" />
            <button className="btn questionPallet" type="submit">
              Question Palette
            </button>
          </React.Fragment>
        </div>
        <div className="exam-f-right">
          {quesLoading ? (
            "0:0"
          ) : (
            <div className="exam-h-marks">
              <h4>
                <img src={time} alt="" />
                <Timer
                  initialTime={60000 * 60}
                  checkpoints={checkpoints}
                  direction="backward"
                >
                  {({ getTime }) => (
                    <React.Fragment>
                      {setTimeValue(getTime())}
                      <Timer.Minutes />:
                      <Timer.Seconds />
                    </React.Fragment>
                  )}
                </Timer>
              </h4>
            </div>
          )}
        </div>{" "}
        <div className="exam-f-right">
          <div className="exam-h-right-button">
            <button onClick={onOpenModal}>
              {/* <button >Open modal</button> */}
              <img src={map} alt="" /> Review Answer
            </button>
            <Modal
              open={submitted}
              onClose={() => setSubmitted(false)}
              classNames={{
                modal: "submitExamModal",
              }}
              center
            >
              <div className="ques-answer-modal-container">
                <img src={submitPopup} alt="" />
                <h6>
                  <b>Want to submit?</b>
                </h6>
                <Row>
                  <Col lg={6}>
                    <div className="popup-answer-modal-single item-1 ">
                      <button onClick={() => setSubmitted(false)}>
                        Cancel
                      </button>
                    </div>
                  </Col>{" "}
                  <Col lg={6}>
                    <div className="popup-answer-modal-single item-2">
                      <button disabled={adding} onClick={submitAnswer}>
                        {/* <img src={papar} alt="" />{' '} */}
                        {adding ? "Submitting..." : "Submit and review "}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal>
            <button onClick={() => setSubmitted(true)}>
              <img src={papar} alt="" /> Submit
            </button>
          </div>
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <div className="ques-popup-answer-wrap text-center">
            <h4>Review your answers</h4>
            <p>
              You can not change any answers from here. Just review your
              answers.
            </p>
          </div>
          <div className="d-flex flex-wrap mt-3">
            {ansData.map((data, key) => (
              <div className="border p-2" style={{ width: "24%" }} key={key}>
                <p>
                  {data.questionNumber}:{" "}
                  {data?.answer?.length > 1
                    ? data?.answer?.map((data) => `${data},`)
                    : data?.answer?.[0]}
                </p>
              </div>
            ))}
          </div>
        </Modal>
        <Modal
          open={open2}
          onClose={onCloseModal2}
          center
          classNames={{
            overlay: "question-palette-overlay",
            modal: "question-palette-modal",
          }}
        >
          <div className="ques-answer-modal-container">
            <h3>Question Palette</h3>
            <p>
              You can not change any answers from here. Just review your
              answers.
            </p>
            <div className="answer-ques-list-color">
              <div className="answered-ques" style={{ fontSize: "14px" }}>
                <span
                  style={{
                    backgroundColor: "#34C38F",
                    width: "15px",
                    height: "15px",
                  }}
                ></span>
                Answered
              </div>
              <div className="unanswered-ques" style={{ fontSize: "14px" }}>
                <span
                  style={{
                    backgroundColor: "#F46A6A",
                    width: "15px",
                    height: "15px",
                  }}
                ></span>
                Unanswered
              </div>
            </div>
            <div className="ques-list-modal">
              <ul>
                {ansData.map((ques, i) => (
                  <li
                    key={i}
                    className="answer-single-question-palette"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleScroll(
                        `question${ques?.questionNumber}`,
                        ques?.questionNumber
                      );
                      onCloseModal2();
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: ques?.answer?.length
                          ? "#34C38F"
                          : "#F46A6A",
                      }}
                    >
                      {ques.questionNumber}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(QuesFooter);
