import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import TruncateMarkup from "react-truncate-markup";
import { featureData } from "./fackData/featureData";
import LongArrowRight from "./icon/LongArrowRight";
import "./style/new-feature.scss";
const NewSpacialFeature = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [popupData, setPopupData] = useState({});
  const handleModalData = (data) => {
    setPopupData(data);
  };
  console.log(popupData);
  return (
    <div className="new-spacial-feature-main-wrap">
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        style={{
          padding: "40px",
        }}
      >
        <Modal.Header closeButton className="speacial-feature-modal-title">
          <Modal.Title
            style={{
              padding: "0 12px",
            }}
          >
            {popupData?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            fontSize: "16px",
            lineHeight: "26px",
            padding: "20px 20px 30px 30px",
          }}
        >
          {popupData?.description}
        </Modal.Body>
      </Modal>
      <Container>
        <Row>
          <Col lg="8" className="mb-4 ">
            <div className="new-spacial-feature-single-item main-feature-box">
              <h4>
                Features uniquely made <br /> for you!
              </h4>
              <Link
                to="feature"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
              >
                See Features
              </Link>
            </div>
          </Col>
          {featureData?.map((feature, idx) => (
            <Col lg="4" className="mb-4">
              <div className="new-spacial-feature-single-item">
                <div className="icon-new-featue-single-item"></div>
                <div className="content-new-featue-single-item">
                  <img src={feature.icon} alt="" />
                  <h6>{feature.title}</h6>
                  <TruncateMarkup lines={2}>
                    <p>{feature.description}</p>
                  </TruncateMarkup>
                </div>
                <div
                  className="new-feature-bottom-button-area"
                  onClick={() => handleModalData(feature)}
                >
                  <button onClick={handleShow}>
                    More <LongArrowRight />
                  </button>
                </div>
              </div>
            </Col>
          ))}

          {/* <Col lg="4" className="mb-3">
            <div className="new-spacial-feature-single-item">
              <div className="icon-new-featue-single-item"></div>
              <div className="content-new-featue-single-item">
                <img src={icon2} alt="" />
                <h6>Instant Result</h6>
                <p>
                  IELTS general & academic Speaking, reading, writing full mock
                  module
                </p>
              </div>
              <div className="new-feature-bottom-button-area">
                <button>
                  More <LongArrowRight />
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" className="mb-3">
            <div className="new-spacial-feature-single-item">
              <div className="icon-new-featue-single-item"></div>
              <div className="content-new-featue-single-item">
                {" "}
                <img src={icon3} alt="" />
                <h6>Check Performance</h6>
                <p>
                  IELTS general & academic Speaking, reading, writing full mock
                  module
                </p>
              </div>
              <div className="new-feature-bottom-button-area">
                <button>
                  More <LongArrowRight />
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" className="mb-3">
            <div className="new-spacial-feature-single-item">
              <div className="icon-new-featue-single-item"></div>
              <div className="content-new-featue-single-item">
                {" "}
                <img src={icon4} alt="" />
                <h6>Facilitator Review</h6>
                <p>
                  IELTS general & academic Speaking, reading, writing full mock
                  module
                </p>
              </div>
              <div className="new-feature-bottom-button-area">
                <button>
                  More <LongArrowRight />
                </button>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default NewSpacialFeature;
