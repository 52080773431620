import {
  ADD_SPEAKING_SET,
  ADD_SPEAKING_SET_FAIL,
  ADD_SPEAKING_SET_SUCCESS,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS,
  GET_SPEAKING_SETS,
  GET_SPEAKING_SETS_FAIL,
  GET_SPEAKING_SETS_SUCCESS,
  GET_SPEAKING_SET_SINGLE,
  GET_SPEAKING_SET_SINGLE_FAIL,
  GET_SPEAKING_SET_SINGLE_SUCCESS,
} from './actionTypes';

export const addSpeakingSet = (values, options) => {
  return {
    type: ADD_SPEAKING_SET,
    payload: { values, options },
  };
};
export const addSpeakingSetSuccess = (message) => {
  return {
    type: ADD_SPEAKING_SET_SUCCESS,
    payload: { message },
  };
};

export const addSpeakingSetFail = (message) => {
  return {
    type: ADD_SPEAKING_SET_FAIL,
    payload: { message },
  };
};
export const getSpeakingSets = (token, options) => {
  return {
    type: GET_SPEAKING_SETS,
    payload: { token, options },
  };
};

export const getSpeakingSetsSuccess = (values) => {
  return {
    type: GET_SPEAKING_SETS_SUCCESS,
    payload: { values },
  };
};

export const getSpeakingSetsFail = (message) => {
  return {
    type: GET_SPEAKING_SETS_FAIL,
    payload: { message },
  };
};

export const getSingleSpeakingSets = (token, options) => {
  return {
    type: GET_SPEAKING_SET_SINGLE,
    payload: { token, options },
  };
};

export const getSingleSpeakingSetsSuccess = (values) => {
  return {
    type: GET_SPEAKING_SET_SINGLE_SUCCESS,
    payload: { values },
  };
};

export const getSingleSpeakingSetsFail = (message) => {
  return {
    type: GET_SPEAKING_SET_SINGLE_FAIL,
    payload: { message },
  };
};

export const getSingleSpeakingSetsSingleDay = (token, options) => {
  return {
    type: GET_SINGLE_SPEAKING_SET_SINGLEDAY,
    payload: { token, options },
  };
};

export const getSingleSpeakingSetsSingleDaySuccess = (values) => {
  return {
    type: GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS,
    payload: { values },
  };
};

export const getSingleSpeakingSetsSingleDayFail = (message) => {
  return {
    type: GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL,
    payload: { message },
  };
};
