import {
  ADD_GIFT,
  ADD_GIFT_FAIL,
  ADD_GIFT_SUCCESS,
  ADD_REVIEW_RATINGS,
  ADD_REVIEW_RATINGS_FAIL,
  ADD_REVIEW_RATINGS_SUCCESS,
  CREATE_CUSTOM_PACKAGE,
  CREATE_CUSTOM_PACKAGE_FAIL,
  CREATE_CUSTOM_PACKAGE_SUCCESS,
  GET_BUY_PACKAGE_LIST_FAIL,
  GET_BUY_PACKAGE_LIST_SUCCESS,
  GET_COUPON_CODE_FAIL,
  GET_COUPON_CODE_SUCCESS,
  GET_DOLLAR_DATA_FAIL,
  GET_DOLLAR_DATA_SUCCESS,
  GET_DRAFT_PACKAGE_FAIL,
  GET_DRAFT_PACKAGE_SUCCESS,
  GET_PACKAGES_FAIL,
  GET_PACKAGES_SUCCESS,
  GET_REVIEW_FAIL,
  GET_REVIEW_SUCCESS,
  GET_SINGLE_PACKAGE_FAIL,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_USER_PACKAGE,
  GET_USER_PACKAGE_FAIL,
  GET_USER_PACKAGE_SUCCESS,
  RECOMMENDED_PACKAGE_FAIL,
  RECOMMENDED_PACKAGE_SUCCESS,
  STORE_DATA,
  STORE_PACKAGE_DATA,
} from "./actionTypes";

const initialState = {
  allPackage: [],
  singlePackage: {},
  userPackage: [],
  value: {},
  message: "",
  error: "",
  countryName: "",
  dollarRate: "",
  packageLoading: true,
  singlePackageLoading: true,
  userPackageLoading: false,
  adding: false,
  loading: true,
  reviewData: {},
  buyPackageList: {},
  recommendedPackage: [],
  coupon: {},
  selectedService: [],
  packageService: [],
  draftPackage: {},
};

const PackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PACKAGE_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        allPackage: action.payload,
        packageLoading: false,
      };
    case GET_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        packageLoading: false,
      };
    case GET_BUY_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        buyPackageList: action.payload.data,
        loading: false,
      };
    case GET_BUY_PACKAGE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SINGLE_PACKAGE_SUCCESS:
      return {
        ...state,
        singlePackage: action.payload.data,
        singlePackageLoading: false,
        loading: false,
      };
    case GET_SINGLE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        singlePackageLoading: false,
        loading: false,
      };
    case RECOMMENDED_PACKAGE_SUCCESS:
      return {
        ...state,
        recommendedPackage: action.payload.data,
      };
    case RECOMMENDED_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USER_PACKAGE:
      return {
        ...state,
        userPackageLoading: true,
      };
    case GET_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        userPackage: action.payload.data,
        userPackageLoading: false,
      };
    case GET_USER_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        userPackageLoading: false,
      };
    case GET_DRAFT_PACKAGE_SUCCESS:
      return {
        ...state,
        draftPackage: action.payload.data,
        loading: false,
      };
    case GET_DRAFT_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_REVIEW_RATINGS:
      return {
        ...state,
        adding: true,
      };
    case ADD_REVIEW_RATINGS_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_REVIEW_RATINGS_FAIL:
      return {
        ...state,
        adding: false,
      };

    case CREATE_CUSTOM_PACKAGE:
      return {
        ...state,
        adding: true,
      };
    case CREATE_CUSTOM_PACKAGE_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case CREATE_CUSTOM_PACKAGE_FAIL:
      return {
        ...state,
        adding: false,
      };

    case ADD_GIFT:
      return {
        ...state,
        adding: true,
      };
    case ADD_GIFT_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_GIFT_FAIL:
      return {
        ...state,
        adding: false,
      };

    case GET_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewData: action.payload.data,
      };
    case GET_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload.data,
      };
    case GET_COUPON_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DOLLAR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dollarRate: action.payload.data,
      };
    case GET_DOLLAR_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case STORE_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};

export default PackageReducer;
