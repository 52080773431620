import { call, put, takeEvery } from "redux-saga/effects";
import { patchNonAuth } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { userForgetPasswordError, userForgetPasswordSuccess } from "./actions";
// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const url = "/forgot-password";
    const response = yield call(patchNonAuth, url, user);
    if (response.status === "success") {
      toaster("success", response.message);
      yield put(userForgetPasswordSuccess(response.message));
      // history.push("/")
    } else {
      toaster("warning", response.message);
      yield put(userForgetPasswordError(response.message));
    }
  } catch (error) {
    const message = error?.response?.data?.message;
    toaster("error", message);
    yield put(userForgetPasswordError(message));
  }
}

export function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}
