import moment from "moment";

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `${k + offset + Math.floor(Math.random() * (999 - 100 + 1) + 100)}`,
    content: `Test`,
    amount: Math.floor(Math.random() * (999 - 100 + 1) + 100),
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, table, news) => ({
  userSelect: "none",
  // padding: table ? "" : grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#d3e9e2" : "",
  borderRadius: "10px",
  width: news ? "initial" : "100%",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, items) => ({
  background: isDraggingOver ? "#f5f5f5" : "",
  padding: grid,
  width: "100%",
  position: "relative",
  height: "100%",
  // minHeight: "400px",
  display: items && "flex",
  flexWrap: items && "wrap",
  alignItems: items && "flex-start",
});

const convertSecondToHour = (second) => {
  const hour = Math.floor(second / 3600);
  const minute = Math.floor((second % 3600) / 60);
  const seconds = Math.floor(second % 60);
  return `${hour >= 10 ? hour : "0" + hour}:${
    minute >= 10 ? minute : "0" + minute
  }:${seconds >= 10 ? seconds : "0" + seconds}`;
};

const nameShorter = (val, count) => {
  if (val?.length > count) {
    return val?.substr(0, count) + "...";
  }
  return val;
};

const calculateEndTime = (duration, startTime, repeat) => {
  const totalTime = duration
    ?.split(":")
    .reduce((acc, time) => 60 * acc + +time);
  const totalDuration = totalTime * repeat;
  const date = moment().format("YYYY-MM-DD");
  const start = `${date}T${startTime}`;
  const endTime = moment(start).add(totalDuration, "seconds");

  return endTime;
};

export {
  getItems,
  reorder,
  move,
  getItemStyle,
  getListStyle,
  convertSecondToHour,
  nameShorter,
  calculateEndTime,
};
