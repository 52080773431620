/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import CardComponent from "../../../components/Common/CardComponent";
import {
  calculateTimeCount,
  findTime,
  timeList,
} from "../../../helpers/custom/TimeHelper";
import { getSpeakingSet } from "../../../store/Question/actions";
import googleMeetIcon from "./img/Google_Meet 1.png";

const SpeakingExam = ({ history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { authtoken, quesLoading, speakingQuesSet } = useSelector((state) => ({
    authtoken: state.Login.token,
    quesLoading: state.QuesReducer.quesLoading,
    speakingQuesSet: state.QuesReducer.speakingQuesSet,
    id: state.Login.id,
  }));

  useEffect(() => {
    dispatch(getSpeakingSet(authtoken, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container>
        <div className="speaking-main-container-wrap">
          <div className="speaking-exam-main-wrap">
            <div className="header-top-button-title">
              <h4>
                <span onClick={() => history.goBack()}>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>Set : {timeList?.[speakingQuesSet?.meetingTime]}</span>
              </h4>
            </div>
            {quesLoading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="speaking-exam-date-bottom-wrap">
                <CardComponent>
                  {quesLoading ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="speaking-package-listed-view-wrap">
                      <h3>Your Exam Start In</h3>
                      {/* <h4>
                        <span>03D</span>
                        <span>:</span>
                        <span>22H</span>
                        <span>:</span>
                        <span>22M</span>
                        <span>:</span>
                        <span>40S</span>
                      </h4> */}
                      <h4>
                        <Timer
                          initialTime={calculateTimeCount(speakingQuesSet) || 0}
                          // checkpoints={checkpoints}
                          direction="backward"
                        >
                          <span>
                            <Timer.Days />D :
                          </span>
                          <span>
                            <Timer.Hours />H :
                          </span>
                          <span>
                            <Timer.Minutes />M :
                          </span>
                          <span>
                            <Timer.Seconds />S
                          </span>
                        </Timer>
                      </h4>
                      <p></p>
                      <div className="exam-google-meet-link-button">
                        {findTime(speakingQuesSet) &&
                        speakingQuesSet?.complete !== 3 ? (
                          <a href={speakingQuesSet?.teacherId?.meetLink}>
                            <span>
                              <img src={googleMeetIcon} alt="" />
                            </span>
                            <span>Start exam</span>
                          </a>
                        ) : (
                          <a style={{ cursor: "not-allowed", color: "#fff" }}>
                            <span>
                              <img src={googleMeetIcon} alt="" />
                            </span>
                            <span>Start exam</span>
                          </a>
                        )}
                      </div>
                      <Form.Text>Google meet</Form.Text>
                    </div>
                  )}
                </CardComponent>
              </div>
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(SpeakingExam);
