import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";
const CircleRound = () => {
  const { login, profile, authToken, universityList } = useSelector(
    (state) => ({
      login: state.Login,
      profile: state.Profile,
      authToken: state.Login.token,
    })
  );
  const { loading, data } = profile;
  return (
    <div className="inner-circle-complate-profile">
      <div className="flex-wrapper">
        <div className="single-chart">
          <svg viewBox="0 0 36 36" class="circular-chart blue">
            <path
              className="circle-bg"
              d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle"
              strokeDasharray={`${data?.getData?.percentage}, 100`}
              d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <span className="percentage">{data?.getData?.percentage}%</span>
        </div>
      </div>
    </div>
  );
};

export default CircleRound;
