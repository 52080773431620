/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withRouter } from "react-router-dom";
import gre from "../../assets/images/graduation-cap 1.svg";
import gramt from "../../assets/images/Toga.svg";
import Loader from "../Atoms/Loader";
import translate from "./img/translate.svg";
import "./navbar.scss";
const NavBarDropdownSubMenu = ({ loading, course, history }) => {
  const handleView = (data) => {
    switch (data?.courseName) {
      case "IELTS":
        // return history.push(`/upcoming`);
        return history.push(`/packages/Ielts`);
      case "GRE":
        return history.push(`/upcoming`);
      default:
        return;
    }
  };

  const getImage = (data) => {
    switch (data?.courseName) {
      case "IELTS":
        return translate;
      case "GMAT":
        return gre;
      case "GRE":
        return gramt;
      default:
        return translate;
    }
  };
  return (
    <div
      className="nav-main-navbar-dropdown-menu-link-wrap"
      style={{ maxHeight: "400px", overflowY: "scroll" }}
    >
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : course?.length > 0 ? (
        course?.map((data, idx) => (
          <a className="nav-link" style={{ cursor: "pointer" }} key={idx}>
            <div
              className="d-flex justify-content-between w-100 align-items-center"
              onClick={() => handleView(data)}
            >
              <span className="ghdjk">
                <img src={getImage(data)} alt="" />
              </span>
              <span className="gdhdjhb">
                <h6>{data?.courseName}</h6>
                {/* <span>
                  the Lorem Ipsum generators on the Internet tend to repeat
                </span> */}
              </span>
            </div>
          </a>
        ))
      ) : (
        <div>No Data</div>
      )}
      {/* <Link to="/gmat-package" className="nav-link">
        <span className="ghdjk">
          <img src={translate} alt="" />
        </span>
        <span className="gdhdjhb">
          <h6>GMAT</h6>
          <span>the Lorem Ipsum generators on the Internet tend to repeat</span>
        </span>
      </Link>{' '}
      <Link to="/gre-package" className="nav-link">
        <span className="ghdjk">
          <img src={translate} alt="" />
        </span>
        <span className="gdhdjhb">
          <h6>GRE</h6>
          <span>the Lorem Ipsum generators on the Internet tend to repeat</span>
        </span>
      </Link> */}
    </div>
  );
};

export default withRouter(NavBarDropdownSubMenu);
