export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const GET_DASHBOARD_PROFILE = "GET_DASHBOARD_PROFILE";
export const GET_DASHBOARD_PROFILE_SUCCESS = "GET_DASHBOARD_PROFILE_SUCCESS";
export const GET_DASHBOARD_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const GET_UNIVERSITY = "GET_UNIVERSITY";
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS";
export const GET_UNIVERSITY_FAIL = "GET_UNIVERSITY_FAIL";
