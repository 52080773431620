import {
  ADD_GIFT,
  ADD_GIFT_FAIL,
  ADD_GIFT_SUCCESS,
  ADD_REVIEW_RATINGS,
  ADD_REVIEW_RATINGS_FAIL,
  ADD_REVIEW_RATINGS_SUCCESS,
  CREATE_CUSTOM_PACKAGE,
  CREATE_CUSTOM_PACKAGE_FAIL,
  CREATE_CUSTOM_PACKAGE_SUCCESS,
  GET_BUY_PACKAGE_LIST,
  GET_BUY_PACKAGE_LIST_FAIL,
  GET_BUY_PACKAGE_LIST_SUCCESS,
  GET_COUPON_CODE,
  GET_COUPON_CODE_FAIL,
  GET_COUPON_CODE_SUCCESS,
  GET_DOLLAR_DATA,
  GET_DOLLAR_DATA_FAIL,
  GET_DOLLAR_DATA_SUCCESS,
  GET_DRAFT_PACKAGE,
  GET_DRAFT_PACKAGE_FAIL,
  GET_DRAFT_PACKAGE_SUCCESS,
  GET_PACKAGES,
  GET_PACKAGES_FAIL,
  GET_PACKAGES_SUCCESS,
  GET_REVIEW,
  GET_REVIEW_FAIL,
  GET_REVIEW_SUCCESS,
  GET_SINGLE_PACKAGE,
  GET_SINGLE_PACKAGE_FAIL,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_USER_PACKAGE,
  GET_USER_PACKAGE_FAIL,
  GET_USER_PACKAGE_SUCCESS,
  RECOMMENDED_PACKAGE,
  RECOMMENDED_PACKAGE_FAIL,
  RECOMMENDED_PACKAGE_SUCCESS,
  STORE_DATA,
  STORE_PACKAGE_DATA,
} from "./actionTypes";

export const getPackages = (authtoken) => ({
  type: GET_PACKAGES,
  payload: { authtoken },
});

export const getPackagesSuccess = (data) => ({
  type: GET_PACKAGES_SUCCESS,
  payload: data,
});

export const getPackagesFail = (error) => ({
  type: GET_PACKAGES_FAIL,
  payload: error,
});

export const getSinglePackage = (authtoken, packageID) => ({
  type: GET_SINGLE_PACKAGE,
  payload: { authtoken, packageID },
});

export const getSinglePackageSuccess = (data) => ({
  type: GET_SINGLE_PACKAGE_SUCCESS,
  payload: { data },
});

export const getSinglePackagePassageFail = (error) => ({
  type: GET_SINGLE_PACKAGE_FAIL,
  payload: error,
});
export const getUserPackage = (authtoken, userID) => ({
  type: GET_USER_PACKAGE,
  payload: { authtoken, userID },
});

export const getUserPackageSuccess = (data) => ({
  type: GET_USER_PACKAGE_SUCCESS,
  payload: { data },
});

export const getUserPackageFail = (error) => ({
  type: GET_USER_PACKAGE_FAIL,
  payload: error,
});

export const addReview = (authtoken, data, id, setOpenModal) => ({
  type: ADD_REVIEW_RATINGS,
  payload: { authtoken, data, id, setOpenModal },
});

export const addReviewSuccess = (data) => ({
  type: ADD_REVIEW_RATINGS_SUCCESS,
  payload: { data },
});

export const addReviewFail = (error) => ({
  type: ADD_REVIEW_RATINGS_FAIL,
  payload: error,
});

export const getReview = (authtoken, id) => ({
  type: GET_REVIEW,
  payload: { authtoken, id },
});

export const getReviewSuccess = (data) => ({
  type: GET_REVIEW_SUCCESS,
  payload: { data },
});

export const getReviewFail = (error) => ({
  type: GET_REVIEW_FAIL,
  payload: error,
});

export const addGift = (data, options) => ({
  type: ADD_GIFT,
  payload: { data, options },
});

export const addGiftSuccess = (data) => ({
  type: ADD_GIFT_SUCCESS,
  payload: { data },
});

export const addGiftFail = (error) => ({
  type: ADD_GIFT_FAIL,
  payload: error,
});

export const getBuyPackageList = (authtoken) => ({
  type: GET_BUY_PACKAGE_LIST,
  payload: { authtoken },
});

export const getBuyPackageListSuccess = (data) => ({
  type: GET_BUY_PACKAGE_LIST_SUCCESS,
  payload: { data },
});

export const getBuyPackageListFail = (error) => ({
  type: GET_BUY_PACKAGE_LIST_FAIL,
  payload: error,
});

export const getRecommendedPackage = (authtoken, id) => ({
  type: RECOMMENDED_PACKAGE,
  payload: { authtoken, id },
});

export const getRecommendedPackageSuccess = (data) => ({
  type: RECOMMENDED_PACKAGE_SUCCESS,
  payload: { data },
});

export const getRecommendedPackageFail = (error) => ({
  type: RECOMMENDED_PACKAGE_FAIL,
  payload: error,
});

export const getCoupon = (authtoken) => ({
  type: GET_COUPON_CODE,
  payload: { authtoken },
});

export const getCouponSuccess = (data) => ({
  type: GET_COUPON_CODE_SUCCESS,
  payload: { data },
});

export const getCouponFail = (error) => ({
  type: GET_COUPON_CODE_FAIL,
  payload: error,
});

export const storePackageData = (name, data) => ({
  type: STORE_PACKAGE_DATA,
  payload: { name, data },
});

export const getDraftPackage = (authtoken) => ({
  type: GET_DRAFT_PACKAGE,
  payload: { authtoken },
});

export const getDraftPackageSuccess = (data) => ({
  type: GET_DRAFT_PACKAGE_SUCCESS,
  payload: { data },
});

export const getDraftPackageFail = (error) => ({
  type: GET_DRAFT_PACKAGE_FAIL,
  payload: error,
});

export const createCustomPackage = (data, options) => ({
  type: CREATE_CUSTOM_PACKAGE,
  payload: { data, options },
});

export const createCustomPackageSuccess = (data) => ({
  type: CREATE_CUSTOM_PACKAGE_SUCCESS,
  payload: { data },
});

export const createCustomPackageFail = (error) => ({
  type: CREATE_CUSTOM_PACKAGE_FAIL,
  payload: error,
});
export const getDollarData = (authtoken) => ({
  type: GET_DOLLAR_DATA,
  payload: { authtoken },
});

export const getDollarDataSuccess = (data) => ({
  type: GET_DOLLAR_DATA_SUCCESS,
  payload: { data },
});

export const getDollarDataFail = (error) => ({
  type: GET_DOLLAR_DATA_FAIL,
  payload: error,
});

export const storeData = (name, data) => ({
  type: STORE_DATA,
  payload: { name, data },
});
