import React from "react";
import { Container } from "react-bootstrap";
import DashboardBlogBody from "./DashboardBlogBody";
const DashboardBlogDetails = () => {
  return (
    <div>
      <div className="dashboard-new-main-area-wrap">
        <Container>
          <div className="dashboard-new-inner-area-wrap">
            <DashboardBlogBody />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DashboardBlogDetails;
