import image1 from "../images/st-1.jpg";
import image2 from "../images/st-2.jpg";
import image3 from "../images/st-3.jpg";
export const studentSliderData = [
  {
    id: 1,
    image: image1,
    title: "Pritam Rahman",
    description: `The IELTS Mock exam platform has been a game-changer for my test preparation. The one-to-one facilitator review has helped me identify my weaknesses and work on them effectively. The ability to view my performance over time has also been extremely helpful as it allows me to track my progress and make improvements.`,
  },
  {
    id: 2,
    image: image2,
    title: " Fuad Hassan",
    description: `I highly recommend the IELTS Mock exam platform for anyone preparing for the IELTS test. The platform offers a comprehensive and realistic simulation of the actual exam, allowing me to familiarize myself with the test format and build my confidence. The ability to view my performance over time has also been a great way to track my progress and keep me motivated.`,
  },
  {
    id: 3,
    image: image3,
    title: "Fatin Khan",
    description: `The IELTS Mock exam platform is a must-have tool for anyone looking to ace the IELTS test. The one-to-one facilitator review has been a valuable resource in improving my skills, and the instant result feature has been extremely convenient. The real simulation of the actual exam has helped me get a better understanding of what to expect on test day.`,
  },
];
