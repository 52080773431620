import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import packageDefaultImage from "../../../assets/images/autoQuesImage.png";
import Loader from "../../../components/Atoms/Loader";
import AddLoader from "../../../components/Common/AddLoader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { packageTimeCount } from "../../../helpers/custom/TimeHelper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addReview,
  getReview,
  getReviewSuccess,
  getUserPackage,
} from "../../../store/Package/actions";
import { addWishList, getWishList } from "../../../store/Wishlist/actions";
import FacilitatorIcon from "../PackageIcon/FacilitatorIcon";
import ListeningIcon from "../PackageIcon/ListeningIcon";
import ReadingIcon from "../PackageIcon/ReadingIcon";
import SpeakingIcon from "../PackageIcon/SpeakingIcon";
import WritingIcon from "../PackageIcon/WritingIcon";
import emptyBlogImg from "./img/emptyBlog.png";
import "./my-package.css";
const MyPackage = () => {
  const [ratingValue, setRatingValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    userPackage,
    authtoken,
    userPackageLoading,
    id,
    wishList,
    adding,
    addingRating,
    review,
    loading,
  } = useSelector((state) => ({
    userPackage: state.PackageReducer.userPackage,
    authtoken: state.Login.token,
    userPackageLoading: state.PackageReducer.userPackageLoading,
    loading: state.PackageReducer.loading,
    addingRating: state.PackageReducer.adding,
    review: state.PackageReducer.reviewData,
    id: state.Login.id,
    wishList: state.wishlist.wishList,
    adding: state.wishlist.adding,
  }));
  // console.log("userPackage", userPackage);

  const wishListId = wishList.map((data) => data?.package?._id);

  // console.log("user", userPackage, reviewData);

  useEffect(() => {
    dispatch(getUserPackage(authtoken, id));
    dispatch(getWishList(authtoken));
    return () => {
      dispatch(getReviewSuccess({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddWishlist = (data) => {
    if (id) {
      if (wishListId.includes(data?.course?._id)) {
        const findWishlistId = wishList.find(
          (list) => list?.package?._id === data?.course?._id
        );
        dispatch(addWishList(authtoken, findWishlistId?._id, true));
      } else {
        dispatch(addWishList(authtoken, data?.course?._id));
      }
    } else {
      toaster("warning", "Please login for add to wishlist");
    }
  };

  const handleAddRatings = (id) => {
    setOpenModal(true);
    dispatch(getReview(authtoken, id));
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageDefaultImage;
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={() => {
          setReviewData({});
          setOpenModal(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "Xam-Confirm-Modal-main-wrap",
        }}
        center
      >
        <div className="start-xam-popup-inner-wrap">
          <div className="start-xam-pro-content-wrap">
            {loading ? (
              <Loader />
            ) : !review?.ratings ? (
              <div className="">
                <h5>Are you sure you want to submit ratings?</h5>
                <div className="student-courses-review d-flex align-items-center mt-2">
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="simple-controlled"
                      value={ratingValue}
                      precision={0.5}
                      onChange={(event, newValue) => {
                        setRatingValue(newValue);
                      }}
                    />
                  </Box>
                  <span>{ratingValue}</span>
                </div>
              </div>
            ) : (
              <div>
                <h5>Your ratings!</h5>
                <div className="student-courses-review d-flex align-items-center mt-3">
                  <Box
                    component="fieldset"
                    className="d-flex"
                    borderColor="transparent"
                  >
                    <Rating
                      name="simple-controlled"
                      precision={0.1}
                      value={review?.ratings}
                      size="medium"
                      readOnly
                    />
                  </Box>
                  <span>{review?.ratings}</span>
                </div>
              </div>
            )}
          </div>
          {!review?.ratings && (
            <div className="popup-form-btn-container">
              <button
                onClick={() => setOpenModal(false)}
                className="btn button-popup-background-1"
              >
                Cancel
              </button>
              <button
                disabled={addingRating || loading}
                onClick={() => {
                  const data = {
                    packageId: reviewData?.package,
                    rating: ratingValue,
                  };
                  dispatch(addReview(authtoken, data, id, setOpenModal));
                }}
                className="btn button-popup-background-2"
              >
                {addingRating ? "Submitting..." : "Submit"}
              </button>
            </div>
          )}
        </div>
      </Modal>
      {adding ? <AddLoader /> : null}
      <div className="dashboard-main-wrap my-course-page">
        <Container>
          <div className="ieltspackage-main-wrap-all-package pt-3">
            <Container>
              <div className="package-area-title-wrap all-package">
                <div className="package-title-area-left-part">
                  <h2>My Package</h2>
                </div>
                <div
                  className="package-title-area-right-part"
                  style={{ display: "none" }}
                >
                  <Dropdown
                    className="btn-primary rounded-2"
                    isOpen={open}
                    toggle={() => setOpen(!open)}
                  >
                    <DropdownToggle color="primary" caret>
                      Active Course
                    </DropdownToggle>
                    <DropdownMenu className="mt-1">
                      <DropdownItem>Active Course</DropdownItem>
                      <DropdownItem>Deactive Course</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              {userPackageLoading ? (
                <div className="text-center  py-5">
                  <Loader />
                </div>
              ) : (
                <Row>
                  {userPackage?.length > 0 ? (
                    (userPackage || [])?.map((data, key) => (
                      <Col lg={4} key={key}>
                        <div className="student-courses-card">
                          <Card className=" position-relative">
                            <div
                              className="bookmark-button-wrap position-absolute"
                              style={{
                                background: "#fff",
                                padding: "5px",
                                lineHeight: "5px",
                                borderRadius: "5px",
                                top: "2%",
                                right: "2%",
                                fontSize: "18px",
                              }}
                              onClick={() => handleAddWishlist(data)}
                            >
                              {wishListId.includes(data?.course?._id) ? (
                                <i
                                  className="bx bxs-bookmark"
                                  style={{ color: "#004AB8" }}
                                ></i>
                              ) : (
                                <i
                                  className="bx bx-bookmark"
                                  style={{ color: "#004AB8" }}
                                ></i>
                              )}
                            </div>
                            <Link
                              to={`/package-details/${data?.course?._id}`}
                              className="btn"
                            >
                              <div
                                className="card-img-top package-image-preview"
                                style={{
                                  backgroundImage: `url(${getPhotoUrl(
                                    data?.course?.photo
                                  )})`,
                                }}
                              ></div>
                            </Link>

                            {/* <Card.Img
                              variant="top"
                              src={
                                data?.course?.photo
                                  ? imageBaseUrl + data?.course?.photo
                                  : image
                              }
                            /> */}
                            <Card.Body className="position-relative">
                              <span
                                className="position-absolute bg-white p-2 rounded-1"
                                style={{
                                  left: "50%",
                                  top: "-30%",
                                  transform: "translateX(-50%)",
                                  border: "1px solid #004AB8",
                                  width: "max-content",
                                }}
                              >
                                <Timer
                                  initialTime={
                                    packageTimeCount(data?.validity) || 0
                                  }
                                  // checkpoints={checkpoints}
                                  direction="backward"
                                >
                                  <span className="ms-2">
                                    <Timer.Days />D :
                                  </span>
                                  <span className="ms-2">
                                    <Timer.Hours />H :
                                  </span>
                                  <span className="ms-2">
                                    <Timer.Minutes />M :
                                  </span>
                                  <span className="ms-2">
                                    <Timer.Seconds />S
                                  </span>
                                </Timer>
                              </span>

                              <Link
                                to={`/package-details/${data?.course?._id}`}
                                className="btn"
                              >
                                <div className="course-review--card-area-top">
                                  <div className="student-courses-review">
                                    <div className="student-courses-review d-flex align-items-center">
                                      <Box
                                        component="fieldset"
                                        borderColor="transparent"
                                      >
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            handleAddRatings(data?._id);
                                            setReviewData({
                                              package: data?._id,
                                            });
                                          }}
                                        >
                                          <Rating
                                            name="simple-controlled"
                                            precision={0.1}
                                            defaultValue={
                                              data?.course?.aveRatings
                                            }
                                            readOnly={!id}
                                            // onClick={() => handleAddRatings(data?._id)}
                                            onChange={(event, newValue) => {
                                              setRatingValue(newValue);
                                            }}
                                          />
                                        </span>
                                      </Box>
                                      <span>
                                        {data?.course?.aveRatings}(
                                        {data?.course?.ratings?.length})
                                      </span>
                                    </div>
                                  </div>
                                  {data?.course?.validity > 0 && (
                                    <Card.Text className="student-courses-timeline header-right-timeline-header">
                                      <i className="bx bx-time"></i>
                                      {data?.course?.validity} Days
                                    </Card.Text>
                                  )}
                                </div>

                                <Card.Title className="my-course-card-slingle-title">
                                  {data?.course?.packageName}
                                </Card.Title>

                                <div className="package-subject-name-list-item">
                                  {data?.course?.packageService.map(
                                    (service, idx) => (
                                      <React.Fragment key={idx}>
                                        {service?.serviceName.toLowerCase() ===
                                          "listening" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <ListeningIcon />
                                            <span>
                                              {data?.course?.listeningQ
                                                ? data?.course?.listeningQ
                                                : data?.course?.listening}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "reading" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <ReadingIcon />
                                            <span>
                                              {data?.course?.readingQ
                                                ? data?.course?.readingQ
                                                : data?.course?.reading}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "writing" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <WritingIcon />
                                            <span>
                                              {data?.course?.writingQ
                                                ? data?.course?.writingQ
                                                : data?.course?.writing}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                        {service?.serviceName.toLowerCase() ===
                                          "speaking" && (
                                          <div
                                            className="package-subject-name-list-item-single-item"
                                            key={idx}
                                          >
                                            <SpeakingIcon />
                                            <span>
                                              {data?.course?.speakingQ
                                                ? data?.course?.speakingQ
                                                : data?.course?.speaking}{" "}
                                              {service?.serviceName}
                                            </span>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}{" "}
                                  {data?.course?.totalFacilitator ? (
                                    <div className="package-subject-name-list-item-single-item facilitator-review">
                                      <FacilitatorIcon />
                                      <span>
                                        {data?.course?.totalFacilitator} X One
                                        to One Review
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Link>

                              <div className="student-courses-card-button facilitator-button-area-wrap">
                                <Link
                                  to={
                                    data?.course?.ownPackage
                                      ? `/my-package/${data?.course?._id}/${data?.course?.courseType}/own-package`
                                      : `/my-package/${data?.course?._id}/${data?.course?.courseType}`
                                  }
                                  className="btn btn-primary"
                                >
                                  View Services
                                </Link>
                                <Link
                                  to={`/facilitator-service/${data?.course?._id}/${data?.course?.includedFacilitator}`}
                                  className="btn btn-outline-primary"
                                >
                                  Facilitator
                                </Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div>
                      <div className="empty-package-area-wrap">
                        <div>
                          <img src={emptyBlogImg} alt="" />
                          <h6>Ops! currently you have no packages</h6>
                          <Link to="/packages/Ielts">Buy Package</Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyPackage;
