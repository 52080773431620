import React from "react";
import Modal from "react-responsive-modal";

const RouteChangingWaring = ({
  open,
  header,
  onCancel,
  content,
  centered,
  onConfirm,
  submit,
}) => {
  return (
    <Modal
      open={open}
      classNames={{
        overlay: "customOverlay",
        modal: "Xam-Confirm-Modal-main-wrap",
      }}
      center
    >
      <div className="start-xam-popup-inner-wrap">
        <div className="start-xam-pro-content-wrap">
          <div className="start-xam-popup-content">
            <span className="d-flex align-items-center gap-2">
              <i className="bx bx-error-alt text-warning fs-3"></i>{" "}
              {/* Attention */}
              <span className="mb-0">{header}</span>
            </span>
            <p>{content}</p>
          </div>
        </div>
        <div className="popup-form-btn-container">
          <button onClick={onCancel} className="btn button-popup-background-1">
            Cancel
          </button>
          <button onClick={onConfirm} className="btn button-popup-background-2">
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RouteChangingWaring;
