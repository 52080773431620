import React, { useRef } from "react";
import UploadImageIcon from "./img/UploadImageIcon";

const ProfileCard = ({
  img,
  name,
  outerStyle,
  parentClass,
  onChange,
  inputFileClass,
}) => {
  const ref = useRef();
  return (
    <React.Fragment>
      <div
        onClick={() => {
          ref.current.click();
        }}
        className="profile-image-upload-image-wrap"
      >
        <div className="hover-image-upload-button-view">
          <UploadImageIcon />
        </div>
        <div
          className={`profileCard relative ${outerStyle} ${parentClass} d-flex cursor-pointer`}
        >
          {!img ? (
            <div className="nonProfile ">
              <span
                className={
                  "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                }
              >
                {name.charAt(0)}
              </span>
            </div>
          ) : (
            <div className="profile">
              <img
                className="rounded-circle avatar-sm"
                src={img}
                alt="Profile"
              />
            </div>
          )}
        </div>
      </div>

      <input
        className={`w-full h-full cursor-pointer ${inputFileClass}`}
        type="file"
        ref={ref}
        onChange={onChange}
        style={{ fontSize: "12px" }}
        accept="image/png, image/jpg, image/jpeg"
      />
    </React.Fragment>
  );
};

export default ProfileCard;
