import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Row } from "reactstrap";
import { userForgetPassword } from "../../../store/auth/forgetpwd/actions";
import "../Login/acoount.scss";
// import authPageBg from "./Frame.svg";
import shadowIcon from "./icon/leftshadho.svg";
import infoImage from "./icon/loginImage.png";
import logo from "./icon/logo.svg";
import mobileBg from "./icon/mobile-bg.svg";
const ForgotPassword2 = ({ history }) => {
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector(
    (store) => store.ForgetPassword
  );
  const handleSubmit = (event, errors, values) => {
    event.preventDefault();

    if (errors.length === 0 && values)
      dispatch(userForgetPassword(values, history));
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget password</title>
      </MetaTags>
      <div
        className="student-login-main-wrap signup-page account-form-submit-button "
        // style={{ backgroundImage: `url(${authPageBg})` }}
      >
        <div className="account-profile-main-area">
          <div className="account-main-left-part">
            <img src={shadowIcon} alt="" className="shadow-icon-account-page" />
            <img src={infoImage} alt="" className="account-page-info-image" />
          </div>
          <div className="account-main-right-submit-info">
            <div
              className="mobile-bg-show-account"
              style={{ backgroundImage: `url(${mobileBg})` }}
            />
            <div className="project-logo-name-right-top">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="student-account-form-wrap">
              <div className="login-form">
                <div className="acoount-email-password-area">
                  <div
                    className="account-form-title "
                    style={{ textAlign: "left" }}
                  >
                    <h3>Reset Password</h3>
                  </div>
                  <p style={{ fontSize: "14px", marginBottom: "10px" }}>
                    We will send you an email with further instructions on how
                    to reset your password.
                  </p>
                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {message ? <Alert color="success">{message}</Alert> : null}
                  </div>
                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            type="email"
                            errorMessage="Enter Email"
                            placeholder="Enter Your Email"
                            validate={{
                              required: { value: true },
                              email: {
                                value: true,
                                errorMessage: "Invalid Email ",
                              },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={loading}
                      className="account-btn-all-page"
                    >
                      {loading ? (
                        <section className="custom-spiner-main-wrap">
                          <span className="loader-11"> </span>
                        </section>
                      ) : (
                        "Send email"
                      )}
                    </Button>
                  </AvForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword2;
