export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAIL = "GET_PACKAGES_FAIL";

export const GET_SINGLE_PACKAGE = "GET_SINGLE_PACKAGE";
export const GET_SINGLE_PACKAGE_SUCCESS = "GET_SINGLE_PACKAGE_SUCCESS";
export const GET_SINGLE_PACKAGE_FAIL = "GET_SINGLE_PACKAGE_FAIL";

export const GET_USER_PACKAGE = "GET_USER_PACKAGE";
export const GET_USER_PACKAGE_SUCCESS = "GET_USER_PACKAGE_SUCCESS";
export const GET_USER_PACKAGE_FAIL = "GET_USER_PACKAGE_FAIL";

export const ADD_REVIEW_RATINGS = "ADD_REVIEW_RATINGS";
export const ADD_REVIEW_RATINGS_SUCCESS = "ADD_REVIEW_RATINGS_SUCCESS";
export const ADD_REVIEW_RATINGS_FAIL = "ADD_REVIEW_RATINGS_FAIL";

export const GET_REVIEW = "GET_REVIEW";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAIL = "GET_REVIEW_FAIL";

export const ADD_GIFT = "ADD_GIFT";
export const ADD_GIFT_SUCCESS = "ADD_GIFT_SUCCESS";
export const ADD_GIFT_FAIL = "ADD_GIFT_FAIL";

export const GET_BUY_PACKAGE_LIST = "GET_BUY_PACKAGE_LIST";
export const GET_BUY_PACKAGE_LIST_SUCCESS = "GET_BUY_PACKAGE_LIST_SUCCESS";
export const GET_BUY_PACKAGE_LIST_FAIL = "GET_BUY_PACKAGE_LIST_FAIL";

export const RECOMMENDED_PACKAGE = "RECOMMENDED_PACKAGE";
export const RECOMMENDED_PACKAGE_SUCCESS = "RECOMMENDED_PACKAGE_SUCCESS";
export const RECOMMENDED_PACKAGE_FAIL = "RECOMMENDED_PACKAGE_FAIL";

export const GET_COUPON_CODE = "GET_COUPON_CODE";
export const GET_COUPON_CODE_SUCCESS = "GET_COUPON_CODE_SUCCESS";
export const GET_COUPON_CODE_FAIL = "GET_COUPON_CODE_FAIL";

export const GET_DOLLAR_DATA = "GET_DOLLAR_DATA";
export const GET_DOLLAR_DATA_SUCCESS = "GET_DOLLAR_DATA_SUCCESS";
export const GET_DOLLAR_DATA_FAIL = "GET_DOLLAR_DATA_FAIL";

export const STORE_PACKAGE_DATA = "STORE_PACKAGE_DATA";

export const CREATE_CUSTOM_PACKAGE = "CREATE_CUSTOM_PACKAGE";
export const CREATE_CUSTOM_PACKAGE_SUCCESS = "CREATE_CUSTOM_PACKAGE_SUCCESS";
export const CREATE_CUSTOM_PACKAGE_FAIL = "CREATE_CUSTOM_PACKAGE_FAIL";

export const GET_DRAFT_PACKAGE = "GET_DRAFT_PACKAGE";
export const GET_DRAFT_PACKAGE_SUCCESS = "GET_DRAFT_PACKAGE_SUCCESS";
export const GET_DRAFT_PACKAGE_FAIL = "GET_DRAFT_PACKAGE_FAIL";
export const STORE_DATA = "STORE_DATA";
