import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "./assets/css/global.css";
import Layout from "./Layouts/Layout";
import Layout2 from "./Layouts/Layout2";
import Layout3 from "./Layouts/Layout3";
import OpenRouteLayout from "./Layouts/OpenRouteLayout";
import SpeakingPage from "./Pages/Exam/Speaking/SpeakingPage";
import NoMatchPage from "./Pages/NoMatchPage/NoMatchPage";
import ExamResult from "./Pages/StudentAfterLogin/ResultPage/ExamResult";
import AuthMiddleware from "./Routes/Middleware/AuthMiddleware";
import OpenRouteMiddleware from "./Routes/Middleware/OpenRouteMiddleware";
import {
  openRoute,
  openRoute2,
  openRouteHeadless,
  privateRoute2,
  privateRouteHeadless,
} from "./Routes/Routes";

import MoveToTop from "./components/ScrollToTop/MoveToTop";
import UserLeaveConfirmation from "./components/UserLeaveConfirmation/UserLeaveConfirmation";
import { getDollarData, storeData } from "./store/actions";
import { storeDashboardData } from "./store/Dashboard/actions";

const App = ({ location, history }) => {
  const [confirmOpen, setConfirmOpen] = useState(true);
  const [bangladeshTime, setBangladeshTime] = useState(new Date());
  const [otherCountryTime, setOtherCountryTime] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const dispatch = useDispatch();
  const { authtoken } = useSelector((state) => ({
    authtoken: state.Login.token,
  }));
  useEffect(() => {
    getCountryLocation();
  }, []);
  useEffect(() => {
    dispatch(getDollarData(authtoken));
  }, []);
  const getCountryLocation = async () => {
    try {
      const response = await axios.get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=8994ce639a01494595cc97734bba6d77"
      );
      console.log("response", response);
      const { country_name, city } = response.data;
      dispatch(storeData("countryName", country_name));
    } catch (error) {
      console.error(error);
    }
  };

  const [splashScreen, setSplashScreen] = useState(true);
  useEffect(() => {
    // console.log("splashScreen", splashScreen);
    // sessionStorage.setItem("splashScreen", JSON.stringify(splashScreen));
    dispatch(storeDashboardData("screen", true));
  }, []);
  const handleClose = () => setSplashScreen(false);

  // useEffect(() => {
  //   const selectedTimezone = "Europe/Amsterdam";
  //   if (selectedTimezone) {
  //     const convertedTime = bangladeshTime.toLocaleString("en-US", {
  //       timeZone: selectedTimezone,
  //     });
  //     setOtherCountryTime(convertedTime);
  //   } else {
  //     setOtherCountryTime("");
  //   }
  // }, []);

  // console.log("otherCountryTime", otherCountryTime);
  // useEffect(() => {
  //   const bangladeshOffset = new Date().getTimezoneOffset() / 60;
  //   console.log("bangladeshOffset", bangladeshOffset);
  //   const otherCountryOffset = new Date()
  //     .toLocaleString("en-US", { timeZone: "Europe/Amsterdam" })
  //     .match(/([\-+]\d{2}):(\d{2})/);
  //   console.log("otherCountryOffset", otherCountryOffset);
  // }, []);

  // const bangladeshTimeyu = new Date().toLocaleString("en-US", {
  //   timeZone: "Asia/Dhaka",
  //   hour12: false,
  //   hour: "numeric",
  // });

  // const ned = new Date().toLocaleString("en-US", {
  //   timeZone: "Asia/Kolkata",
  //   hour12: false,
  //   hour: "numeric",
  // });
  // console.log("bangladeshTimeyu", bangladeshTimeyu);
  // console.log("ned", ned);

  // const now = new Date();
  // const options = { timeZone: "Asia/Dhaka" };

  // const currentTime = now.toLocaleTimeString("en-US", options);

  // console.log("currentTime", currentTime);
  // var asiaDhaka = new Date().toLocaleString([], { timeZone: "Asia/Dhaka" });
  // console.log("today", new Date(asiaDhaka));

  // var ned22 = new Date().toLocaleString([], { timeZone: "Europe/Amsterdam" });
  // console.log("today2", new Date(ned22));

  // const options1 = { timeZone: "Europe/Amsterdam" };

  // const currentTime1 = now.toLocaleTimeString("en-US", options1);
  // const jd = moment
  //   .utc(
  //     moment(currentTime, "DD/MM/YYYY HH:mm:ss").diff(
  //       moment(options1, "DD/MM/YYYY HH:mm:ss")
  //     )
  //   )
  //   .format("HH:mm:ss");
  // console.log("jd", jd);
  // const [show, setShow] = useState(false);

  return (
    <div className="App">
      <MoveToTop />
      <Router
        getUserConfirmation={(message, callback) =>
          UserLeaveConfirmation(
            message,
            callback,
            confirmOpen,
            setConfirmOpen,
            history
          )
        }
      >
        <Switch>
          {openRoute.map((route, idx) => (
            <OpenRouteMiddleware
              exact
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
            />
          ))}
          {openRoute2.map((route, idx) => (
            <OpenRouteMiddleware
              exact
              path={route.path}
              layout={Layout3}
              component={route.component}
              key={idx}
            />
          ))}
          {/* {privateRoute.map((route, idx) => (
            <AuthMiddleware
              exact
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))} */}
          {privateRoute2.map((route, idx) => (
            <AuthMiddleware
              exact
              path={route.path}
              layout={Layout2}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          {privateRouteHeadless.map((route, idx) => (
            <AuthMiddleware
              exact
              path={route.path}
              layout={OpenRouteLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          {openRouteHeadless.map((route, idx) => (
            <AuthMiddleware
              exact
              path={route.path}
              layout={OpenRouteLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          <Route exact path="/result-page">
            <ExamResult />
          </Route>
          <Route exact path="/speaking-page">
            <SpeakingPage />
          </Route>
          <Route exact path="*">
            <NoMatchPage />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default withRouter(App);
