import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CreateOwnPackage from "../../components/Home/CreateOwnPackage/CreateOwnPackage";
import GetReadyStarted from "../../components/HomeNew/GetReadyStarted";
import NewFacilitorSchedule from "../../components/HomeNew/NewFacilitorSchedule";
import NewHeroInfo from "../../components/HomeNew/NewHeroInfo";
import NewHomeArticle from "../../components/HomeNew/NewHomeArticle";
import NewPopularCourse from "../../components/HomeNew/NewPopularCourse";
import NewSpacialFeature from "../../components/HomeNew/NewSpacialFeature";
import OurStudentSay from "../../components/HomeNew/OurStudentSay";
import ServiceProvide from "../../components/HomeNew/PeopleChoose/ServiceProvide";
import { imageBaseUrl } from "../../helpers/api_helper";
import { getScreen, storeDashboardData } from "../../store/Dashboard/actions";
import ClientChat from "../ClientChat/ClientChat";
import HeroNew from "./../../components/HomeNew/HeroNew";
const HomeNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClose = () => {
    console.log("cancel", "no");
    ReactGA.event({
      category: "cancle_splash",
      action: "cancle_splash",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    dispatch(storeDashboardData("screen", false));
  };
  const { screen, screenList, authtoken } = useSelector((state) => ({
    authtoken: state.Login.token,
    screen: state.DashboardReducer.screen,
    screenList: state.DashboardReducer.screenList,
  }));
  console.log("screenList", screenList);
  useEffect(() => {
    dispatch(getScreen(authtoken));
  }, []);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };
  console.log("screenList", screenList);
  const handleScreen = () => {
    console.log("hello");

    ReactGA.event({
      category: "Click splash",
      action: "Click splash",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    history.push(`${screenList?.[0]?.link}`);
  };
  return (
    <div className="new-home-page">
      {screen && screenList?.length > 0 ? (
        <Modal
          show={screen}
          onHide={handleClose}
          size="lg"
          className="splash-screen-main-wrap"
          centered
        >
          {" "}
          <Modal.Header
            closeButton
            className="splash-screen-header"
          ></Modal.Header>
          <div onClick={handleScreen} style={{ cursor: "pointer" }}>
            <>
              <Modal.Body>
                <div className="splash-screen-content-wrap">
                  <img
                    className="img-fluid desktop-screen"
                    src={getPhotoUrl(screenList?.[0]?.splash)}
                    alt=""
                  />
                  <img
                    className="img-fluid tab-screen"
                    src={getPhotoUrl(screenList?.[0]?.splashTab)}
                    alt=""
                  />
                  <img
                    className="img-fluid mobile-screen"
                    src={getPhotoUrl(screenList?.[0]?.splashMobile)}
                    alt=""
                  />
                </div>
              </Modal.Body>
            </>
          </div>
        </Modal>
      ) : (
        ""
      )}

      <HeroNew />
      <NewHeroInfo />
      <NewSpacialFeature />
      <NewPopularCourse />
      <NewFacilitorSchedule />
      <ServiceProvide />
      <OurStudentSay />
      <NewHomeArticle />
      <GetReadyStarted />
      <CreateOwnPackage />
      <ClientChat />
    </div>
  );
};

export default HomeNew;
