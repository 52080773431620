import {
  ADD_QUESTION_ANSWER_FAIL,
  ADD_QUESTION_ANSWER_SUCCESS,
  GET_QUESTION_ANSWER_FAIL,
  GET_QUESTION_ANSWER_SUCCESS,
} from './actionTypes';

const initialState = {
  readingAnswer: [],
  readingAnswerLoading: true,
};

const AnswerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        readingAnswer: [...state.readingAnswer, action.payload],
        readingAnswerLoading: false,
      };
    case ADD_QUESTION_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
        readingAnswerLoading: true,
      };

    case GET_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        readingAnswer: action.payload,
        readingAnswerLoading: false,
      };
    case GET_QUESTION_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
        readingAnswerLoading: true,
      };

    default:
      return state;
  }
};

export default AnswerReducer;
