import { css } from "@emotion/css";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ScrollToBottom from "react-scroll-to-bottom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import chatSound from "../../assets/images/chat.mp3";
import { image } from "../../helpers/api_helper";
import {
  getFAQtype,
  getSingleFaq,
  getSingleFaqSuccess,
  getSingleFaqType,
} from "../../store/actions";
import {
  getSingleChat,
  replyToUser,
  sendUserInfo,
} from "../../store/chat/actions";
import ChatIcon from "./ChatIcon";
import "./client-chat.scss";
import AttachmentIcon from "./icon/AttachmentIcon";
import ChatSendIcon from "./icon/ChatSendIcon";
import PopUpChat from "./PopUpChat";

import logo from "./XamproLogo.svg";

const PopUpchatRef = forwardRef((props, ref) => (
  <PopUpChat ref={ref} {...props} />
));
const ClientChat = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  // console.log("play", play);
  const [showMassages, setShowMassages] = useState(false);
  const [show, setShow] = useState(true);
  const [adminMessage, setAdminMessage] = useState("");
  const [typeName, setTypeName] = useState("");
  const [question, setQuestion] = useState("");
  const [alertVisible, setIsAlertVisible] = useState(false);
  const [imageUp, setImageUp] = useState({
    localUrl: "",
    serverUrl: "",
    uploading: false,
  });
  const [showChat, setShowChat] = useState(false);
  const {
    authtoken,
    userListChatLoading,
    userListChat,
    singleUserMessage,
    faqInfoList,
    email,
    id,
    singleFaq,
    singleFaqType,
    faqTypeList,
    singleFaqLoading,
    adding,
    nonUserId,
    nonUserEmail,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    email: state.Login.email,
    id: state.Login.id,
    userListChat: state.ChatReducer.userListChat,
    faqTypeList: state.FAQReducer.faqTypeList,
    faqInfoList: state.FAQReducer.faqInfoList,
    singleFaq: state.FAQReducer.singleFaq,
    singleFaqLoading: state.FAQReducer.singleFaqLoading,
    singleFaqType: state.FAQReducer.singleFaqType,
    userListChatLoading: state.ChatReducer.userListChatLoading,
    singleUserMessageLoading: state.ChatReducer.singleUserMessageLoading,
    singleUserMessage: state.ChatReducer.singleUserMessage,
    nonUserId: state.ChatReducer.nonUserId,
    nonUserEmail: state.ChatReducer.nonUserEmail,
    adding: state.ChatReducer.adding,
  }));

  const typeData = localStorage.getItem("typeName");
  const quesData = localStorage.getItem("quesData");
  const ROOT_CSS = css({
    height: "330px",
    overflowY: "scroll",
  });

  const sendMessage = () => {
    if (imageUp.uploading) return;
    if (id) {
      let body = {
        role: "Student",
        message: adminMessage ? adminMessage : null,
        photo: imageUp.serverUrl ? imageUp.serverUrl : null,
        senderMail: email,
        userMail: email,
      };
      dispatch(
        replyToUser(
          body,
          history,
          authtoken,
          singleUserMessage?._id,
          id,
          "user"
        )
      );
    }
    if (nonUserId) {
      let body = {
        role: "Student",
        message: adminMessage ? adminMessage : null,
        senderMail: nonUserEmail,
        userMail: nonUserEmail,
        photo: imageUp.serverUrl ? imageUp.serverUrl : null,
      };
      dispatch(
        replyToUser(
          body,
          history,
          authtoken,
          singleUserMessage?._id,
          nonUserId,
          "nonUser"
        )
      );
    }

    setAdminMessage("");
    setImageUp({
      localUrl: "",
      serverUrl: "",
      uploading: false,
    });
  };

  const handleChat = () => {
    setShowMassages(!showMassages);
    if (id) {
      dispatch(getSingleChat(authtoken, id, "user"));
    }
    if (nonUserId) {
      dispatch(getSingleChat(authtoken, nonUserId, "nonUser"));
    }
  };

  useEffect(() => {
    dispatch(getFAQtype(authtoken));
  }, []);
  const handleFaqAns = (data) => {
    setQuestion(data?.question);
    localStorage.setItem("quesData", data?.question);
    console.log("hello", data);
    if (data?._id) {
      dispatch(getSingleFaq(authtoken, data?._id));
    }
  };
  const handleFaqInfo = (data) => {
    setTypeName(data?.typeName);
    localStorage.setItem("typeName", data?.typeName);
    localStorage.setItem("quesData", "");
    setQuestion("");
    if (data?._id) {
      dispatch(getSingleFaqType(authtoken, data?._id));
      dispatch(getSingleFaqSuccess({}));
    }
  };
  const handlePhoto = async (e) => {
    if (!e.target.files) return;
    try {
      setImageUp((prev) => ({
        ...prev,
        localUrl: URL.createObjectURL(e.target.files[0]),
        uploading: true,
      }));
      const url = `/image-upload`;
      const formData = new FormData();
      formData.append("name", e.target.files[0]?.name);
      formData.append("photo", e.target.files[0]);
      const response = await image(url, formData, authtoken);
      console.log("responseImage", response);

      setImageUp((prev) => ({
        ...prev,
        serverUrl: response?.photo,
      }));
    } catch (err) {
      toast("error", "Upload Image failed");
    } finally {
      setImageUp((prev) => ({
        ...prev,

        uploading: false,
      }));
    }
  };
  const handleSubmit = (event, errors, values) => {
    event.preventDefault();
    if (errors?.length === 0) {
      setShowChat(true);
      if (values) dispatch(sendUserInfo(values, history, authtoken));
    }
  };
  const handleNonUserChat = () => {
    console.log("szc", nonUserId);
    // dispatch(getSingleChat(authtoken, nonUserId, "nonUser"));
  };
  useEffect(() => {
    if (nonUserId) {
      dispatch(getSingleChat(authtoken, nonUserId, "nonUser"));
    }
  }, [nonUserId]);
  const handleRemove = () => {
    setImageUp({
      localUrl: "",
      serverUrl: "",
      uploading: false,
    });
  };

  // const handleSound = () => {
  //   console.log("play");
  //   play();
  // };
  setTimeout(() => {
    setIsAlertVisible(true);
    // handleSound();
    // ref?.current?.play();
  }, 3000);
  return (
    <div className="website-chat-application">
      <button
        className={
          showMassages ? "website-massage-icon active" : "website-massage-icon"
        }
        onClick={() => handleChat()}
      >
        <ChatIcon />
        <span></span>
        <span></span>
      </button>
      <div className="client-chat-show-area">
        {showMassages ? (
          <>
            {id || nonUserId !== "" ? (
              <div className="alert-popup-always-show client-part-chat main-chat-part-show">
                <div className="client-chat-box-website">
                  <h6>Xampro support</h6>
                </div>

                <div className="exampro-chat-area">
                  <ScrollToBottom className={ROOT_CSS}>
                    <div className="xampro-chat-body">
                      <div className="xampro-single-chat">
                        <div className="xampro-chat-profile-client">
                          <img src={logo} alt="" />
                        </div>
                        <div className="xampro-chat-content-client">
                          <p>
                            We are available to assist you if you have any
                            problems. Choose your category from the list below.
                          </p>
                          <ul>
                            {faqTypeList?.map((data, idx) => (
                              <li onClick={() => handleFaqInfo(data)}>
                                {data?.typeName}
                              </li>
                            ))}
                          </ul>
                          {singleFaqType.length ? (
                            <ul
                              // className="mt-2"
                              style={{
                                border: "none",
                                justifyContent: "flex-end",
                                paddingRight: "0",
                                paddingBottom: "0",
                              }}
                            >
                              <div
                                className="client-side-chat"
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: "4px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "rgba(0, 82, 204, 1)",
                                      color: "#fff",
                                      display: "inline-block",
                                      padding: "8px 16px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {typeData}
                                  </p>
                                </div>
                              </div>
                              <ul>
                                {singleFaqType?.map((data, idx) => (
                                  <>
                                    <li onClick={() => handleFaqAns(data)}>
                                      {data?.question}
                                    </li>
                                  </>
                                ))}
                              </ul>
                              {quesData ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: "4px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "rgba(0, 82, 204, 1)",
                                      color: "#fff",
                                      display: "inline-block",
                                      padding: "8px 16px",
                                      borderRadius: "6px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {quesData}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                          {singleFaq.answer ? (
                            <ul
                              // className="mt-2"
                              style={{
                                border: "none",
                                justifyContent: "flex-end",
                                paddingRight: "0",
                              }}
                            >
                              <li>{singleFaq.answer}</li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {singleUserMessage?.chatArr?.map((data, idx) => (
                        <>
                          {data?.role === "Student" ? (
                            <div
                              className="client-side-chat"
                              style={{
                                textAlign: "right",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  gap: "4px",
                                }}
                              >
                                {data?.photo ? (
                                  <img src={data?.photo} alt="" />
                                ) : null}
                                {!!data?.message ? (
                                  <>
                                    {" "}
                                    <p
                                      style={{
                                        background: "rgba(0, 82, 204, 1)",
                                        color: "#fff",
                                        // display: "inline-block",
                                        padding: "8px 16px",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {data?.message}
                                    </p>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        display: "block",
                                      }}
                                    >
                                      {" "}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="admin-side-chat">
                              <div
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: "18px",
                                      width: "30px",
                                      height: " 30px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      background: "rgba(0, 82, 204, 1)",
                                      borderRadius: " 50%",
                                      marginBottom: "0",
                                      color: " #fff",
                                    }}
                                  >
                                    A
                                  </h4>
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      gap: "4px",
                                    }}
                                  >
                                    {" "}
                                    {data?.photo ? (
                                      <img src={data?.photo} alt="" />
                                    ) : null}
                                    {!!data?.message ? (
                                      <>
                                        <p
                                          style={{
                                            border: "1px solid",
                                            borderColor: "rgba(0, 82, 204, 1)",
                                            color: "rgba(116, 120, 141, 1)",
                                            display: "inline-block",
                                            padding: "8px 16px",
                                            borderRadius: "6px",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {data?.message}
                                        </p>
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            display: "block",
                                          }}
                                        ></span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </ScrollToBottom>

                  <div className="xampro-chat-write-bottom">
                    {imageUp.localUrl ? (
                      <div className="chat-image">
                        <div className="chat-single-image-upload-box">
                          {imageUp.uploading ? (
                            <i className="bx bx-loader-alt bx-spin image-spin"></i>
                          ) : (
                            <i className="bx bx-x"></i>
                          )}
                          {imageUp.uploading ? (
                            <i className="bx bx-loader-alt bx-spin image-spin"></i>
                          ) : (
                            <i
                              className="bx bx-x"
                              onClick={handleRemove}
                              style={{ cursor: "pointer" }}
                            ></i>
                          )}

                          <img src={imageUp.localUrl} alt="" />
                        </div>
                      </div>
                    ) : null}

                    <div className="xampro-massage-write-bottom-area">
                      <div className="text-input-field">
                        <input
                          type="text"
                          value={adminMessage}
                          placeholder="Still having issues?  write here."
                          onKeyPress={(event) => {
                            event.key === "Enter" && sendMessage();
                          }}
                          onChange={(e) => setAdminMessage(e.target.value)}
                        />
                      </div>
                      <div className="file-upload-button">
                        <label
                          htmlFor="imageUpolad"
                          style={{ cursor: "pointer" }}
                        >
                          <AttachmentIcon />
                        </label>
                        <input
                          type="file"
                          name=""
                          id="imageUpolad"
                          style={{ display: "none" }}
                          onChange={(e) => handlePhoto(e)}
                        />
                      </div>
                      <div className="massage-send-button-main">
                        <button
                          onClick={sendMessage}
                          disabled={imageUp.uploading}
                        >
                          <ChatSendIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="non-login-chat-user-form">
                  <div className="non-login-user-form-header">
                    <h6>
                      If you are new to the platform, please fill out the form
                    </h6>
                  </div>
                  <div className="non-login-user-form-body">
                    <div className="non-login-user-form-body-inner">
                      <div>
                        <AvForm onSubmit={handleSubmit}>
                          <Row>
                            <Col md={12}>
                              <div>
                                <AvField
                                  name="fullName"
                                  label="Name"
                                  type="text"
                                  errorMessage="Enter Your Name"
                                  placeholder="Enter Your Name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div>
                                <AvField
                                  name="email"
                                  label="Email"
                                  type="email"
                                  errorMessage="Enter Your email"
                                  placeholder="Enter Your Email"
                                  validate={{
                                    required: { value: true },
                                    email: {
                                      value: true,
                                      errorMessage: "Invalid Email format!",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div>
                                <AvField
                                  name="phoneNumber"
                                  label="Phone"
                                  type="phone"
                                  errorMessage="Enter Your phone"
                                  placeholder="Enter Your phone"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <React.Fragment>
                              <div className="flex-wrap gap-2 col-md-12">
                                <Button
                                  type="submit"
                                  color="primary"
                                  disabled={adding}
                                  className=""
                                  onClick={handleNonUserChat}
                                >
                                  {adding ? "Submitting" : "Next"}
                                </Button>
                              </div>
                            </React.Fragment>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : alertVisible && show ? (
          <PopUpchatRef setShow={setShow} chatSound={chatSound} ref={ref} />
        ) : (
          ""
        )}
      </div>

      {/* <audio
        ref={ref}
        src={chatSound}
        controls
        webkitPlaysInline="true"
        playsInline="true"
      ></audio> */}
    </div>
  );
};

export default ClientChat;
