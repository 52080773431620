export const ADD_PACKAGE_ORDER = "ADD_PACKAGE_ORDER";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const ADD_PACKAGE_ORDER_SUCCESS = "ADD_PACKAGE_ORDER_SUCCESS";
export const ADD_PACKAGE_ORDER_FAIL = "ADD_PACKAGE_ORDER_FAIL";

export const GET_PACKAGE_ORDER = "GET_PACKAGE_ORDER";
export const GET_PACKAGE_ORDER_SUCCESS = "GET_PACKAGE_ORDER_SUCCESS";
export const GET_PACKAGE_ORDER_FAIL = "GET_PACKAGE_ORDER_FAIL";

export const ADD_FACILITATOR_ORDER = "ADD_FACILITATOR_ORDER";
export const ADD_FACILITATOR_ORDER_SUCCESS = "ADD_FACILITATOR_ORDER_SUCCESS";
export const ADD_FACILITATOR_ORDER_FAIL = "ADD_FACILITATOR_ORDER_FAIL";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";
