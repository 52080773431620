import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./term-and-condition.scss";
const TermAndConditions = () => {
  const { pathname } = useLocation();
  // const { token } = useSelector((state) => ({
  //   token: state.Login.token,
  // }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>Terms & Conditions</title>
      </MetaTags>
      {/* {token ? <LoginHeader /> : <Header />} */}
      <Container>
        <div className="return-policy-main-wrap">
          <div className="return-policy-inner-content-wrap">
            <div className="return-policy-header">
              <h3>Terms & Conditions</h3>
              <p>Last Updated on 28th October 2022</p>
            </div>
            <div className="return-policy-content-bottom">
              <div className="return-policy-single-item">
                <h4>You're welcome for using XamPro!</h4>
                <p>
                  Services that XamPro offers (collectively, "XamPro," "us,"
                  "we," or "our"). Your use of our website, apps, and other
                  products and services is subject to the following terms and
                  conditions ("Terms") ("Services"). You acknowledge that some
                  of our services may require you to download software to your
                  computer, mobile device, tablet, or other devices, that we may
                  automatically update such software, and that such updates will
                  be subject to these conditions. Please take the time to
                  carefully read these conditions, and if you have any
                  questions, information requests, or grievances, visit the{" "}
                  <a
                    href="https://maacsolutions.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b>Contact Us</b>
                  </a>{" "}
                  page. You acknowledge and agree that these Terms, including
                  the policies referred to above, are binding upon you by
                  clicking "I accept" (or if you have not yet created an
                  account, by using our services). By using our services, you
                  accept these Terms and the policies that they mention as
                  binding on you.
                </p>
                <p>
                  THESE TERMS APPLY TO ALL CLAIMS BROUGHT AGAINST XamPro AND
                  INCLUDE AN ARBITRATION AGREEMENT AND A WAIVER OF CLASS ACTION.
                  THEY AFFECT YOUR LEGAL RIGHTS, SO PLEASE READ THEM CAREFULLY.
                  THE ARBITRATION AGREEMENT'S TERMS AND THE WAIVER OF CLASS
                  ACTIONS SHALL NOT APPLY IF THEY CAN NOT BE ENFORCED UNDER THE
                  LAWS OF THE COUNTRY IN WHICH YOU LIVE.
                </p>
              </div>
              <div className="return-policy-single-item">
                <h4>Using XamPro</h4>
                <h5>Who Could Utilize Our Services?</h5>
                <p>
                  Only those who can legally enter into a contract with XamPro
                  and who are in conformity with these Terms and all relevant
                  laws are permitted to use our services. You must give us true
                  and full information when you create your XamPro account and
                  later when you utilize particular features. You commit to
                  updating your data so that it remains precise and
                  comprehensive.{" "}
                  <b>
                    Anyone under the age of 13 must be at least 13 to use or
                    access this website, and specific geographic areas and
                    content offerings may have additional requirements.
                  </b>
                </p>
                <h5>Our License to You</h5>
                <p>
                  We provide you with a limited, personal, non-exclusive,
                  non-transferable, and revocable license to use our services,
                  subject to these Terms and our policies (such as the
                  Acceptable Use Policy, Honor Code, course-specific eligibility
                  requirements, and other terms). Unless you have our specific
                  consent to do so, you may only download anything from our
                  Services for your own noncommercial use. You also agree that
                  you will only create, access, and/or use one user account
                  unless specifically authorized by XamPro and that you won't
                  give anyone else access to your account or your account's
                  login details. You do not acquire any ownership or
                  intellectual property rights in our Services or the content
                  you access by using our Services.
                </p>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>Content Offerings</h4>
                <h5>Modifications to Content Offerings</h5>
                <p>
                  XamPro offers lessons and content from universities and other
                  suppliers (collectively, "Content Offerings") ("Content
                  Providers"). Even while we strive to offer top-notch content
                  offerings from our content providers, unforeseen situations do
                  occasionally happen. XamPro maintains the right to alter any
                  content offerings, including the point value or weight of any
                  assignment, quiz, or other assessment, either independently or
                  in compliance with the requirements of the content provider.
                  The sections below on disclaimers and limitations of liability
                  apply to content offerings.
                </p>
                <h5>No academic points </h5>
                <p>
                  Completion of content offerings does not result in academic
                  credit being awarded by XamPro. Participation in or completion
                  of Content Offerings does not award academic credit unless
                  specifically stated by a credit-granting institution. There is
                  no guarantee that other institutions will accept credit even
                  if it is granted by one institution. You consent to not
                  claiming credit for finishing a You cannot submit a content
                  offering until you have obtained a course certificate or
                  another form of proof that the content offering was completed.
                  No educational institution or accreditation body has to
                  acknowledge any content offerings made by XamPro, teachers, or
                  affiliated content providers.
                </p>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>Disclaimer of Student-Content Provider Relationship</h4>
                <p>
                  Nothing in these Terms or elsewhere concerning your
                  participation in any Content Offerings by Content Providers:
                  (a) creates a relationship between you and any Content
                  Provider; (b) enrolls or registers you in any Content Offering
                  offered by any Content Provider institution, except as
                  described in the Degree, MasterTrack, and University
                  Certificate Programs section below; or (c) grants you access
                  to any Content Provider institution's resources.
                </p>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>Your Content</h4>
                <h5>User Content</h5>
                <p>
                  You can share your content (also known as "User Content") with
                  XamPro, instructors, and/or other users using the services,
                  including your projects, homework, quizzes, examinations, and
                  other submitted assignments. You are in charge of and have all
                  ownership rights to the user content you produce and
                  distribute. Course content and other materials made available
                  on the XamPro platform by or on behalf of Content Providers or
                  their instructors via the Services or Content Offerings are
                  not considered User Content. Such content offerings are
                  subject to the applicable agreements in existence between
                  XamPro and the content providers.
                </p>
                <h5>How user content may be used by XamPro and others</h5>
                <p>
                  You grant XamPro a fully transferable, royalty-free,
                  perpetual, sublicensable, non-exclusive, worldwide license to
                  copy, distribute, modify, prepare derivative works based upon,
                  publicly perform, publicly display, and otherwise use the User
                  Content to the extent that you supply such User Content.
                  Following the terms of this license, XamPro is allowed to let
                  Content Providers utilize User Content with registered
                  students, students enrolled in on-campus programs, or other
                  learners not utilizing the Services. Nothing in these Terms
                  shall limit other legal rights that XamPro may have, such as
                  those arising from other licenses, concerning User Content.
                  For any reason, including if we believe that the user content
                  in question violates If we violate these Terms or other rules
                  like our Acceptable Use Policy, we have the right to remove or
                  alter the User Content.
                </p>
                <h5>Feedback</h5>
                <p>
                  Regarding the services, we appreciate your ideas, opinions,
                  and other feedback ("Feedback"). By providing any feedback,
                  you permit us to use it without restriction or payment to you.
                  XamPro does not give up the right to use similar or related
                  Feedback that was already known to XamPro, developed by our
                  staff or contractors, or acquired from other sources, even
                  after accepting your feedback.
                </p>
                <h5>Security</h5>
                <p>
                  We are concerned about our users' safety. While XamPro makes
                  every effort to safeguard the security of your account and any
                  associated data, we cannot guarantee that unauthorized parties
                  won't be able to circumvent our security precautions. Please
                  inform us at info@maacsolutions.com as soon as you become
                  aware of any compromise or unauthorized use of your account.
                </p>
                <h5>Third-Party Content</h5>
                <p>
                  You will have access to and/or use content given by
                  instructors, other users, and/or other third parties through
                  the services, as well as links to websites and services run by
                  third parties. XamPro makes no promises that any third-party
                  information found on the Services or elsewhere will be free of
                  offensive, inappropriate, or harmful content. This includes
                  malware and other contaminants that could damage your
                  computer, mobile device, or any files stored on it. Regarding
                  your access to, use of, or inability to access or use such
                  third-party content, XamPro expressly disclaims any duty or
                  obligation.
                </p>
              </div>{" "}
              <div className="return-policy-single-item">
                <h4>Copyright and Trademark Policy</h4>

                <p>
                  When using the services, users are expected to respect the
                  intellectual property rights of XamPro's content providers,
                  instructors, users, and other third parties. Users who
                  frequently violate or are repeatedly accused of violating the
                  copyrights, trademarks or other intellectual property rights
                  of others risk having their accounts suspended, disabled, or
                  terminated.
                </p>

                <p>
                  Copyright holders who believe that information on the Internet
                  violates their rights under U.S. copyright law have recourse
                  thanks to the Digital Millennium Copyright Act of 1998 (the
                  "DMCA"). Please go to
                  http://www.copyright.gov/legislation/dmca.pdf on the U.S.
                  Copyright Office website to read the DMCA.
                </p>

                <p>
                  The DMCA allows you (or your agent) to submit us a notice
                  asking for the item to be withdrawn or access to it to be
                  restricted if you genuinely believe that materials on the
                  XamPro platform violate your copyright.
                </p>
                <p>The following details must be included in the notice:</p>
                <ul>
                  <li>
                    The physical or digital signature of a representative with
                    the authority to act on behalf of the holder of the
                    purportedly infringing exclusive right;
                  </li>
                  <li>
                    Identity of the allegedly infringed copyrighted work (or, if
                    more than one copyrighted work on the platform is covered by
                    a single notification, a representative list of those
                    works);
                  </li>
                  <li>
                    A description of the allegedly unlawful or the subject of
                    unlawful conduct infringing material, as well as information
                    that is reasonably necessary for XamPro to find the
                    allegedly unlawful or unlawful material on the platform;
                  </li>
                  <li>
                    The complainant's name, address, phone number, and email
                    address (if any are provided);
                  </li>
                  <li>
                    A declaration that the complaining party, in good faith,
                    believes that the copyright owner, the owner's agent, or the
                    law does not permit the use of the content in the way
                    complained of;
                  </li>
                  <li>
                    A declaration that the details in the notification are true
                    and, under pain of perjury, that the party filing the
                    complaint has the right to speak on behalf of the owner of
                    the allegedly violated exclusive right
                  </li>
                </ul>
                <p>
                  Notices must adhere to the DMCA's then-current legislative
                  provisions. Regarding the services, notices and
                  counter-notices may be delivered either by:
                </p>
                <ul>
                  <li>via email: info@maacsolutions.com</li>
                </ul>
                <p>
                  We advise you to speak with a lawyer before submitting a
                  notification. Additionally, be aware that the DMCA has
                  consequences for making fraudulent claims.
                </p>
                <p>
                  XamPro also respects other people's trademark ownership
                  rights. XamPro reserves the right to update, suspend, disable,
                  or terminate accounts with any other content that deceives
                  users or infringes on another party's trademark. Please send
                  us an email at info@maacsolutions.com if you have any concerns
                  about someone using your trademark on our services without
                  your consent. We will then review your application. If we
                  think it's appropriate, we may delete the offensive content,
                  notify the person who uploaded it, and/or suspend or disable
                  their account indefinitely.
                </p>
              </div>
              <div className="return-policy-single-item">
                <h4>General Terms</h4>
                <h5>A revision to the Terms.</h5>
                <p>
                  We reserve the right to change the Terms at any moment, based
                  solely on our discretion. Any changes to the Terms will take
                  effect right away once we post them. If there are significant
                  changes, we'll make a fair effort to let you know by posting a
                  banner on the website, sending you an email, using another
                  technique, or a combination of approaches. Your continuing use
                  of the services following the publication of such
                  modifications, whether or not you are notified, represents
                  binding acceptance of the updated Terms in all circumstances.
                </p>
                <h5>Suspension; Waiver</h5>
                <p>
                  No other terms of these Terms will be affected if it turns out
                  that a particular provision is not enforceable. If you violate
                  these Terms and we don't take immediate action, it doesn't
                  mean that we are waiving any of our rights (such as taking
                  action in the future).
                </p>
                <h5>Content Creators</h5>
                <p>
                  The terms that apply to our content providers and integrated
                  service providers may be enforced by them as third-party
                  beneficiaries of the terms.
                </p>
              </div>
              <div className="return-policy-single-item">
                <h4>Acceptable Use Policy</h4>

                <p>
                  Our goal is to make the best education available to everyone.
                  We are adamant about protecting both academic freedoms for our
                  content creators and educators, as well as our users' right to
                  free speech and expression. Additionally, we want to make sure
                  that all of our teachers and users are secure and at ease when
                  utilizing our services. This policy was created by us to make
                  sure that everyone who uses our services and interacts with
                  our online community is aware of and abides by the rules.
                </p>

                <p>
                  If inappropriate content or activity is found or reported to
                  us, we may remove or amend it. Access to all or a portion of
                  the services may be separately suspended, disabled, or
                  terminated for a user.
                </p>
                <ul>
                  <li>
                    You are not allowed to transmit materials using our services
                    that: Contain unlawful materials or encourage the commission
                    of illegal acts. Please remember that XamPro has users as
                    young as 13 and that we do not permit content that is
                    improper for them.
                  </li>
                  <li>
                    Contains plausible threats or plans of actual acts of
                    violence. Content that poses a real risk of physical harm or
                    property damage credibly endangers people or the public, or
                    plans or incites violence is not permitted.
                  </li>
                  <li>
                    Harasses other people. We welcome opinions on people and
                    topics of general interest, but we do not permit abusive or
                    otherwise objectionable material addressed to specific
                    individuals.
                  </li>
                  <li>
                    Violates a person's privacy, intellectual property, or other
                    rights. Do not share content that you are not authorized to
                    share, claim as your own content that was not created by
                    you, or otherwise violate or misappropriate the rights of
                    others, including their intellectual property. Always give
                    credit to the original author of any content you use or
                    quote.
                  </li>
                  <li>
                    Others with spam. Share no inappropriate or irrelevant
                    advertising, marketing, or solicitation material.
                  </li>
                  <li>Violates the XamPro Terms of Use in any other way.</li>
                </ul>
                <p>
                  Please be aware that certain content offers might have extra
                  guidelines and specifications.
                </p>
                <p>Additionally, you are not permitted to:</p>
                <ul>
                  <li>
                    Take any action that contravenes any fiduciary or
                    contractual obligations or local, state, national, or
                    international legislation.
                  </li>
                  <li>
                    Share your password, permit unauthorized users to log into
                    your account, or take any other actions that could
                    jeopardize the security of your account.
                  </li>
                  <li>Make an effort to log into any other user's account.</li>
                  <li>
                    Unless expressly permitted, duplicate, transfer, sell,
                    resell, or otherwise abuse any content from our Services.
                  </li>
                  <li>
                    Unless expressly permitted, you may not enter, tamper with,
                    or utilize any private or restricted parts of our systems.
                  </li>
                  <li>
                    Without express permission, do not attempt to compromise our
                    authentication or security procedures or in any other way
                    test the susceptibility of our networks or systems.
                  </li>
                  <li>
                    Reverse engineering any element of our services is
                    prohibited.
                  </li>
                  <li>
                    Try to obstruct any user, host, or network, for instance by
                    spamming, overloading, or mail-bombing, or by transmitting a
                    virus.
                  </li>
                  <li>Make use of our services to spread malware.</li>
                  <li>
                    Use the XamPro platform's features or our services for
                    anything other than finishing online courses or for
                    educational reasons.
                  </li>
                  <li>
                    Impersonate someone else or falsely claim to be associated
                    with them.
                  </li>
                  <li>
                    Encourage or assist someone in performing any action on this
                    list.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* <MainFooter /> */}
    </div>
  );
};

export default TermAndConditions;
