import moment from "moment";
import { call, put, takeEvery } from "redux-saga/effects";
import { getData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  GET_BLOG_POST,
  GET_LAST_THREE_LISTENING,
  GET_LAST_THREE_READING,
  GET_LAST_THREE_SPEAKING,
  GET_LAST_THREE_WRITING,
  GET_ONGOING_PACKAGE,
  GET_RESULT_DASHBOARD,
  GET_SINGLE_BLOG,
  GET_STUDENT_BANNER,
  GET_STUDENT_PERFORMANCE,
  GET_STUDENT_SCREEN,
  GET_TOP_THREE_PACKAGE,
} from "./actionTypes";
import {
  getBannerFail,
  getBannerSuccess,
  getBlogPostFail,
  getBlogPostSuccess,
  getLastThreeListeningFail,
  getLastThreeListeningSuccess,
  getLastThreeReadingFail,
  getLastThreeReadingSuccess,
  getLastThreeSpeakingFail,
  getLastThreeSpeakingSuccess,
  getLastThreeWritingFail,
  getLastThreeWritingSuccess,
  getLeaderBoardResultFail,
  getLeaderBoardResultSuccess,
  getOngoingPackageFail,
  getOngoingPackageSuccess,
  getScreenFail,
  getScreenSuccess,
  getSingleBlogFail,
  getSingleBlogSuccess,
  getStudentPerformanceFail,
  getStudentPerformanceSuccess,
  getTopThreePackageInfoFail,
  getTopThreePackageInfoSuccess,
} from "./actions";

function* onGetBlogList({ payload: { authtoken } }) {
  try {
    const url = "/author/user/get-top-three-post";
    const response = yield call(getData, url, authtoken);
    yield put(getBlogPostSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get BlogList failed";
    yield put(getBlogPostFail(message));
    toaster("error", message);
  }
}
function* onGetOngoingPackage({ payload: { token, number } }) {
  try {
    const url = `/student-progress/${number}`;
    const response = yield call(getData, url, token);
    yield put(getOngoingPackageSuccess(response));
  } catch (error) {
    const message = error.response?.data?.message || "get package failed";
    yield put(getOngoingPackageFail(message));
    toaster("error", message);
  }
}
function* onGetSingleBlog({ payload: { token, id } }) {
  try {
    const url = `author/get-post/${id}`;
    const response = yield call(getData, url, token);
    yield put(getSingleBlogSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get BlogList failed";
    yield put(getSingleBlogFail(message));
    toaster("error", message);
  }
}
function* onGetLastThreeReading({ payload: { token } }) {
  try {
    const url = `/student-last-three-reading-data`;
    const response = yield call(getData, url, token);
    yield put(getLastThreeReadingSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get marks failed";
    yield put(getLastThreeReadingFail(message));
    toaster("error", message);
  }
}
function* onGetLastThreeSpeaking({ payload: { token } }) {
  try {
    const url = `/student-last-three-speaking-data`;
    const response = yield call(getData, url, token);
    yield put(getLastThreeSpeakingSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get marks failed";
    yield put(getLastThreeSpeakingFail(message));
    toaster("error", message);
  }
}
function* onGetLastThreeListening({ payload: { token } }) {
  try {
    const url = `/student-last-three-listening-data`;
    const response = yield call(getData, url, token);
    yield put(getLastThreeListeningSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get marks failed";
    yield put(getLastThreeListeningFail(message));
    toaster("error", message);
  }
}
function* onGetLastThreeWriting({ payload: { token } }) {
  try {
    const url = `/student-last-three-writing-data`;
    const response = yield call(getData, url, token);
    yield put(getLastThreeWritingSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get marks failed";
    yield put(getLastThreeWritingFail(message));
    toaster("error", message);
  }
}
function* onGetResultDashboard({
  payload: {
    token,
    options: { serviceName, startDate, endDate },
  },
}) {
  try {
    const url = `student-leadership-board/${serviceName}?startDate=${startDate}&endDate=${endDate}`;
    const response = yield call(getData, url, token);
    yield put(getLeaderBoardResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get result failed";
    yield put(getLeaderBoardResultFail(message));
    toaster("error", message);
  }
}
function* onGetStudentPerformance({
  payload: {
    token,
    options: { serviceName, startDate, endDate },
  },
}) {
  try {
    console.log("serviceName", serviceName);
    const url = `student-performance/${serviceName}?startDate=${startDate}&endDate=${endDate}`;
    const response = yield call(getData, url, token);

    response?.getData?.forEach((user, index) => {
      // product.receivedQty = product?.quantity;
      user.mark = user?.mark || 0;
      user.date =
        serviceName === "Speaking"
          ? moment(user?.date?.split("T")?.[0])?.format("DD MMM")
          : moment(user?.createdAt?.split("T")?.[0])?.format("DD MMM");
    });

    yield put(getStudentPerformanceSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get result failed";
    yield put(getStudentPerformanceFail(message));
    toaster("error", message);
  }
}
function* onGetStudentBanner({ payload: { token } }) {
  try {
    const url = `/getBanners`;
    const response = yield call(getData, url, token);
    yield put(getBannerSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getBannerFail(message));
    toaster("error", message);
  }
}
function* onGetStudentScreen({ payload: { token } }) {
  try {
    const url = `/getSplashs`;
    const response = yield call(getData, url, token);
    yield put(getScreenSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getScreenFail(message));
    toaster("error", message);
  }
}
function* onGetTopThreePackage({ payload: { token } }) {
  try {
    const url = `/package/get-top-three-popular-package`;
    const response = yield call(getData, url, token);
    yield put(getTopThreePackageInfoSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getTopThreePackageInfoFail(message));
    toaster("error", message);
  }
}
function* DashboardSaga() {
  yield takeEvery(GET_BLOG_POST, onGetBlogList);
  yield takeEvery(GET_ONGOING_PACKAGE, onGetOngoingPackage);
  yield takeEvery(GET_SINGLE_BLOG, onGetSingleBlog);
  yield takeEvery(GET_LAST_THREE_READING, onGetLastThreeReading);
  yield takeEvery(GET_LAST_THREE_SPEAKING, onGetLastThreeSpeaking);
  yield takeEvery(GET_LAST_THREE_LISTENING, onGetLastThreeListening);
  yield takeEvery(GET_LAST_THREE_WRITING, onGetLastThreeWriting);
  yield takeEvery(GET_RESULT_DASHBOARD, onGetResultDashboard);
  yield takeEvery(GET_STUDENT_PERFORMANCE, onGetStudentPerformance);
  yield takeEvery(GET_STUDENT_BANNER, onGetStudentBanner);
  yield takeEvery(GET_TOP_THREE_PACKAGE, onGetTopThreePackage);
  yield takeEvery(GET_STUDENT_SCREEN, onGetStudentScreen);
}

export default DashboardSaga;
