import {
  ADD_REVIEW,
  ADD_REVIEW_FAIL,
  ADD_REVIEW_SUCCESS,
  GET_ALL_ANSWER_FAIL,
  GET_ALL_ANSWER_SUCCESS,
  GET_PARTICULAR_SET_FAIL,
  GET_PARTICULAR_SET_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: true,
  adding: false,
  message: '',
  error: '',
  allAnswer: [],
  particularSet: {},
};

const ReviewAnswer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEW:
      state = {
        ...state,
        adding: true,
      };
      break;
    case ADD_REVIEW_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      };
      break;
    case ADD_REVIEW_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;

    case GET_ALL_ANSWER_FAIL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_ALL_ANSWER_SUCCESS:
      state = {
        ...state,
        loading: false,
        allAnswer: action.payload.values,
      };
      break;

    case GET_PARTICULAR_SET_FAIL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_PARTICULAR_SET_SUCCESS:
      state = {
        ...state,
        loading: false,
        particularSet: action.payload.values,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default ReviewAnswer;
