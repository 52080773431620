import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { printToLetter } from '../../../helpers/custom/printNumberToLetter';

const ComparisonType = ({
  comprehensionType,
  handleOnChange,
  idx,
  options,
  questionNumber,
  ans,
  currentIndex,
  currentPassage,
  view,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const findQuestionsNumberRange = `${
    comprehensionType?.[0]?.questionNumber + questionNumber
  }-
  ${
    comprehensionType?.[comprehensionType?.length - 1]?.questionNumber +
    questionNumber
  }`;
  const findPassageLength = `${printToLetter(1)} - ${printToLetter(
    currentPassage?.readingPessageDescription?.length
  )}`;
  const [viewExplain, setViewExplain] = useState('');
  const [toggle2, setToggle2] = useState();
  return (
    <React.Fragment>
      <div className="ques-serial">
        <h5>Question {findQuestionsNumberRange}</h5>
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle2(!toggle2)}
            >
              {toggle2 ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle2
                  ? 'navbar_mobile_menu show my-2'
                  : 'navbar_mobile_menu hide my-2'
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        <div className="sub-title-header">
          <p>
            Reading Passage {currentIndex + 1} has{' '}
            {currentPassage?.readingPessageDescription?.length} paragraphs,{' '}
            {findPassageLength}.
          </p>
          <p>Which paragraph contains the following information?</p>
          <p>
            Write the appropriate letter, {findPassageLength}, in boxes{' '}
            {findQuestionsNumberRange} on your answer sheet.
          </p>
        </div>
        {(comprehensionType || []).map((data, key) => {
          const number = data.questionNumber + questionNumber;
          return (
            <div
              className="answer-checkbox-mainwrap"
              key={key}
              id={`question${number}`}
            >
              <div className="checkbox-ques-serial-no option-select-ans mb-2">
                <div className="ques-sl-no-main">
                  <h5>{data.questionNumber}</h5>
                </div>
                {/* edit form area wrap  */}
                {/* edit form area wrap  */}
                {/* <div
                  className="student-note-btn"
                  onClick={() => setToggle(!toggle)}
                >
                  {toggle ? (
                    <p>
                       <img src={notePad} alt="" /> 
                      hide Notepad
                    </p>
                  ) : (
                    <p>
                      {/* <img src={notePad} alt="" /> 
                      Show Notepad
                    </p>
                  )}
                </div> */}
                <AvForm>
                  <AvField
                    aria-label="Default select example"
                    type="select"
                    className="form-select"
                    disabled={view}
                    value={ans?.[number]?.answer?.[0]}
                    name={`question${data?.questionNumber}`}
                    onChange={(e) => handleOnChange(e, data, number)}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {options?.map((list, idx) => (
                      <option value={list} key={idx}>
                        {list}
                      </option>
                    ))}
                  </AvField>
                </AvForm>
                <p>{data.q}</p>
              </div>
              {view ? (
                <React.Fragment>
                  <div className="d-flex align-items-center py-2">
                    <span>
                      Answer :{' '}
                      <strong>
                        {data?.answer?.map(
                          (data, idx) => `${data}  ${idx === 1 ? '' : ','}`
                        )}
                      </strong>
                    </span>
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          if (viewExplain !== `view${number}`) {
                            setViewExplain(`view${number}`);
                          } else {
                            setViewExplain('');
                          }
                        }}
                        style={{
                          background: 'transparent',
                          color: '#485ec4',
                          border: '1px solid #485ec4',
                        }}
                        className="btn btn-sm m-1 gapsInput"
                      >
                        {viewExplain === `view${number}`
                          ? 'Hide'
                          : 'See Explanation'}
                      </Button>
                    </div>
                    {ans?.[number]?.mark === 1 ? (
                      <span className="correct-ans">
                        <i className="bx bx-check"></i>
                      </span>
                    ) : (
                      <span className="false-ans">
                        <i className="bx bx-x"></i>
                      </span>
                    )}
                  </div>
                  {viewExplain === `view${number}` ? (
                    <div className="border rounded-2 p-3 my-2">
                      {data?.explanation}
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ComparisonType;
