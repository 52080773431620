import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { patchData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import RouteChangingWaring from "./RouteChangingWaring";

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen,
  history
) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    handleSubmit();
    setConfirmOpen(false);
  };

  const handleCancel = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);
  const { header, content, submit } = JSON.parse(message);
  const handleSubmit = async (e) => {
    const {
      authToken,
      ans,
      type,
      id,
      time,
      submissionReason,
      examStatus,
      packageId,
    } = submit;
    try {
      const url = `/package/submit-answer/${id}/${type}/${packageId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
      const response = patchData(url, ans, authToken);
      if (response) {
        toaster("success", "Exam submitted successfully");
        localStorage.removeItem("xampro-xam");
      }
    } catch {
      toaster("error", "Submit exam failed");
    }
  };
  ReactDOM.render(
    <BrowserRouter>
      <RouteChangingWaring
        open={confirmOpen}
        header={header}
        onCancel={handleCancel}
        content={content}
        centered={false}
        onConfirm={handleConfirm}
        submit={submit}
        history
      />
    </BrowserRouter>,
    container
  );
};

export default UserLeaveConfirmation;
