import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import facilitorImage from "./images/facilitor.png";
import "./style/facilitor.scss";
const NewFacilitorSchedule = () => {
  return (
    <div className="new-home-facilitor-full-area">
      <Container>
        <Row>
          <Col lg="7" className="my-auto">
            <div className="new-home-facilitor-left-content">
              <h2>Take review from experts on your preferred schedule.</h2>
              <p>
                Getting detailed reviews on your mock exams and finding your
                shortcomings is the perfect way to achieve your desired score
                and exceed your expectations!
              </p>
            </div>
          </Col>
          <Col lg="5">
            <div className="new-home-facilitor-right-image">
              <img src={facilitorImage} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewFacilitorSchedule;
