import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StudentFooter from '../../components/StudentFooter/StudentFooter';
import StudentNavbar from '../../components/StudentNavbar/StudentNavbar';
import './not-found.scss';
import emptyCart from './oppsImage.svg';
const NoMatchPage = () => {
  return (
    <React.Fragment>
      <StudentNavbar />
      <div className="link-not-found-404-page">
        <Container>
          <div className="empty-cart-main-area-wrap-empty mt-5 p-5">
            <div className="empty-cart-inner-wrap">
              <img src={emptyCart} alt="" />
              <h4>Oops!</h4>
              <p style={{ color: '#74788D' }}>
                We can't seem to find the page you are looking for.
              </p>
              <Link to="/" className="btn btn-primary mt-3">
                Back to home
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <StudentFooter />
    </React.Fragment>
  );
};

export default NoMatchPage;
