import {
  ADD_REVIEW,
  ADD_REVIEW_FAIL,
  ADD_REVIEW_SUCCESS,
  GET_ALL_ANSWER,
  GET_ALL_ANSWER_FAIL,
  GET_ALL_ANSWER_SUCCESS,
  GET_PARTICULAR_SET,
  GET_PARTICULAR_SET_FAIL,
  GET_PARTICULAR_SET_SUCCESS,
} from "./actionTypes"

export const addReview = (values, options) => {
  return {
    type: ADD_REVIEW,
    payload: { values, options },
  }
}
export const addReviewSuccess = message => {
  return {
    type: ADD_REVIEW_SUCCESS,
    payload: { message },
  }
}

export const addReviewFail = message => {
  return {
    type: ADD_REVIEW_FAIL,
    payload: { message },
  }
}
export const getAllAnswer = token => {
  return {
    type: GET_ALL_ANSWER,
    payload: { token },
  }
}

export const getAllAnswerSuccess = values => {
  return {
    type: GET_ALL_ANSWER_SUCCESS,
    payload: { values },
  }
}

export const getAllAnswerFail = message => {
  return {
    type: GET_ALL_ANSWER_FAIL,
    payload: { message },
  }
}

export const getParticularSet = (token, options) => {
  return {
    type: GET_PARTICULAR_SET,
    payload: { token, options },
  }
}

export const getParticularSetSuccess = values => {
  return {
    type: GET_PARTICULAR_SET_SUCCESS,
    payload: { values },
  }
}

export const getParticularSetFail = message => {
  return {
    type: GET_PARTICULAR_SET_FAIL,
    payload: { message },
  }
}
