import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, Container, Label, Row } from "reactstrap";
import logo from "../../../assets/images/Frame.svg";
import Loader from "../../../components/Atoms/Loader";
import ProfileCard from "../../../components/Common/ProfileCard";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  editProfile,
  getUniversityList,
} from "../../../store/auth/profile/actions";
import CircleRound from "./CircleRound";
import "./profile.scss";

const Profile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [university, setUniversity] = useState("");
  // const [selectedDate, setSelectedDate] = useState("");
  // const [gender, setGender] = useState("");
  // const [educationLevel, setEducationLevel] = useState("");
  const dispatch = useDispatch();
  const { login, profile, authToken, universityList } = useSelector(
    (state) => ({
      login: state.Login,
      profile: state.Profile,
      authToken: state.Login.token,
      universityList: state.Profile.universityList,
    })
  );
  // console.log("profile", profile);
  // console.log("university", university);
  // console.log("selectedDate", selectedDate);
  const { loading, data } = profile;

  // useEffect(() => {
  //   dispatch(getProfile(login?.token));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  function handleSubmit(event, errors, values) {
    event.preventDefault();
    const fd = new FormData();
    for (let obj in values) {
      fd.append(obj, values[obj]);
    }
    if (profileImage) {
      fd.append("photo", profileImage);
    } else {
      !!data?.photo && fd.append("photo", data.photo);
    }
    if (university) {
      fd.append("university", university?._id);
    }

    if (errors.length === 0) dispatch(editProfile(fd, login?.token));
  }
  const onChangeImage = (e) => {
    console.log("to", e.target?.files);
    const file = e.target?.files?.[0];
    console.log("e.target?.files?.[0]?.size", e.target?.files?.[0]?.size);
    if (e.target?.files?.[0]?.size > 100000) {
      return toaster(
        "warning",
        "Your profile photo must be smaller than 1 MB in size."
      );
    }

    setProfileImage(file);
  };

  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return logo;
  };
  useEffect(() => {
    dispatch(getUniversityList(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleUniversity = (data) => {
    if (data) {
      setUniversity(data);
    } else {
      setUniversity("");
    }
  };
  // const handleGender = (e) => {
  //   setGender(e.target.value);
  // };
  // const handleEducationLabel = (e) => {
  //   setEducationLevel(e.target.value);
  // };
  useEffect(() => {
    if (profile?.data) {
      setUniversity(profile?.data?.getData?.getProfile?.university);
    }
  }, [profile]);

  return (
    <React.Fragment>
      <div>
        <Container>
          <div
            className="profile-view-inner-wrap"
            style={{ paddingTop: "30px" }}
          >
            <div className="profile-view-area">
              <h2>Profile</h2>
              <div className="profile-picture-area">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                ) : (
                  <Fragment>
                    <h6 className="">Your Profile</h6>
                    <div className="profile-picture-wrap">
                      <CircleRound />
                      <ProfileCard
                        img={
                          !!profileImage
                            ? URL.createObjectURL(profileImage)
                            : `${getPhotoUrl(data?.getData?.getProfile?.photo)}`
                        }
                        name={data?.getData?.getProfile?.fullName || "N/A"}
                        outerStyle="border-blue"
                        onChange={onChangeImage}
                        parentClass="settingsProfile"
                        inputFileClass={"profile-page-image-upload"}
                      />
                    </div>
                    <AvForm onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12}>
                          <AvField
                            name="fullName"
                            label="Full Name"
                            type="text"
                            value={data?.getData?.getProfile?.fullName}
                            errorMessage="Enter Full Name"
                            placeholder="Enter Full Name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <AvField
                            name="email"
                            label="Email"
                            type="email"
                            placeholder="Enter Email"
                            errorMessage="Enter Email"
                            value={data?.getData?.getProfile?.email}
                            disabled
                            validate={{
                              required: { value: true },
                              email: {
                                value: true,
                                errorMessage: "Invalid Email format!",
                              },
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <AvField
                            name="phoneNumber"
                            label="Phone Number"
                            value={data?.getData?.getProfile?.phoneNumber}
                            placeholder="Enter Phone Number"
                            type="text"
                            errorMessage="Phone Number"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <AvField
                            name="dob"
                            label="Date of birth"
                            // value={data?.phoneNumber}
                            placeholder="Select your Birth date"
                            type="date"
                            errorMessage="Select your Birth date"
                            validate={{ required: { value: true } }}
                            // onChange={(e) => setSelectedDate(e.target.value)}
                            value={
                              data?.getData?.getProfile?.dob
                                ? moment(data?.getData?.getProfile?.dob).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                          />
                        </Col>
                      </Row>{" "}
                      <Row>
                        <div className="mb-3 profileRadio">
                          <AvRadioGroup
                            inline
                            name="gender"
                            label="Select Gender"
                            required
                            value={data?.getData?.getProfile?.gender}
                            className="jxhbsdgdvbch"
                            // onChange={handleGender}
                          >
                            <AvRadio label="Male" value="male" />
                            <AvRadio label="Female" value="female" />
                          </AvRadioGroup>
                        </div>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <AvField
                            type="select"
                            name="education"
                            label="Education"
                            className="form-control"
                            value={data?.getData?.getProfile?.education}
                            // onChange={handleEducationLabel}
                            // helpMessage="Idk, this is an example. Deal with it!"
                          >
                            <option value="">Select</option>
                            <option value="SSC">SSC</option>
                            <option value="HSC">HSC</option>
                            <option className="A level">A level</option>
                            <option className="O level">O level</option>
                            <option className="BSC">BSC</option>
                            <option className="MBA">MBA</option>
                          </AvField>
                        </Col>
                      </Row>{" "}
                      {/* <Row>
                        <Col md={12}>
                          <AvField
                            type="select"
                            name="working-as"
                            label="Working as"
                            className="form-control"
                            // helpMessage="Idk, this is an example. Deal with it!"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </AvField>
                        </Col>
                      </Row>{" "} */}
                      <Row>
                        <div className="mb-3">
                          <Label className="control-label">University</Label>
                          <Select
                            cacheOptions
                            getOptionLabel={(e) => e?.universityName}
                            getOptionValue={(e) => e?._id}
                            options={universityList}
                            isClearable
                            onChange={handleUniversity}
                            value={university}

                            // isDisabled={hiddenValue===false}
                            // isMulti
                          />
                        </div>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <div className="flex-wrap gap-2 col-md-12 mt-2">
                            <Button
                              type="submit"
                              color="primary"
                              disabled={profile?.editing}
                              className="profile-submit-page-btn"
                            >
                              {profile?.editing ? "Updating..." : "Update"}
                            </Button>
                          </div>
                        </React.Fragment>
                        {/* // )} */}
                      </Row>
                    </AvForm>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
