import React from "react";
import Timer from "react-compound-timer/build";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import arrowLeft from "../../assets/images/Icon/arrow-right.svg";
const QuesHeader = ({ history }) => {
  const { saving, quesLoading } = useSelector((state) => ({
    questions: state.QuesReducer.questions,
    currentIndex: state.QuesReducer.currentIndex,
    ansQuestion: state.QuesReducer.ansQuestion,
    readingQues: state.QuesReducer.readingQues?.data,
    quesLoading: state.QuesReducer.quesLoading,
    ans: state.QuesReducer.ans,
    adding: state.QuesReducer.adding,
    saving: state.QuesReducer.saving,
  }));

  return (
    <div className="exam-ques-header-main-wrap">
      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        classNames={{
          overlay: "backConfirmOverlay",
          modal: "backConfirmModal",
        }}
      >
        <div>
          <div
            className="ques-answer-modal-container"
            style={{ padding: "50px 25px" }}
          >
            <Row>
              <Col lg={6}>
                <div className="popup-answer-modal-single item-1 ">
                  <img src={submitPopup} alt="" />
                </div>
              </Col>{" "}
              <Col lg={6} className="my-auto">
                <div className="popup-answer-modal-single item-2">
                  <h3>Want to quit exam?</h3>
                  <p>
                    You cannot leave during the test, This will have an effect
                    on your test results.
                  </p>
                  <button onClick={() => history.goBack()}>I am sure</button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal> */}
      <div className="exam-h-left" onClick={() => history.goBack()}>
        <div style={{ cursor: "pointer" }}>
          <img src={arrowLeft} alt="" />
        </div>
        <h4>Reading Exam</h4>{" "}
        <small style={{ fontSize: "10px" }} className="ms-2">
          {saving ? "Saving..." : "Saved"}
        </small>
      </div>
      <div className="exam-h-right">
        <div className="exam-h-marks me-2">
          Mark :<span>40</span>
        </div>
        <div className="exam-h-time">
          Time :
          {quesLoading ? (
            "0:0"
          ) : (
            <span>
              <Timer
                initialTime={60000 * 60}
                // checkpoints={checkpoints}
                direction="backward"
              >
                <Timer.Minutes />:
                <Timer.Seconds />
              </Timer>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(QuesHeader);
