import React, { useEffect } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Truncate from "react-truncate";
import defaultPackageImg from "../../../assets/images/defaultPackage2.svg";
import Loader from "../../../components/Atoms/Loader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { getOngoingPackage } from "../../../store/Dashboard/actions";
import emptyBlogImg from "../img/blog/emptyBlog.png";
const OngoingPackageSlider = () => {
  const dispatch = useDispatch();

  const { onGoingProjectLoading, authToken, onGoingProject } = useSelector(
    (state) => ({
      onGoingProject: state.DashboardReducer.onGoingProject,
      onGoingProjectLoading: state.DashboardReducer.onGoingProjectLoading,
      authToken: state.Login.token,
    })
  );

  useEffect(() => {
    dispatch(getOngoingPackage(authToken, 3));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const progressNumber = (data) => {
    const totalSetGiven = onGoingProject?.totalFinished[data?._id];
    const totalSet =
      (data?.listeningQ || 0) +
      (data?.listening || 0) +
      (data?.readingQ || 0) +
      (data?.reading || 0) +
      (data?.writingQ || 0) +
      (data?.writing || 0) +
      (data?.speakingQ || 0) +
      (data?.speaking || 0);
    const totalProgress = parseInt((totalSetGiven * 100) / totalSet);
    return totalProgress || 0;
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return defaultPackageImg;
  };

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-arrow-icon" onClick={onClick}>
        <i className="bx bx-left-arrow-alt"></i>
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="next-arrow-icon" onClick={onClick}>
        <i className="bx bx-right-arrow-alt"></i>
      </div>
    );
  }

  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <>
      {onGoingProjectLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <div className="ongoing-project-title">
            <h3>Ongoing package</h3>
          </div>
          {onGoingProject?.getData?.length > 3 ? (
            <Slider {...settings}>
              {onGoingProject?.getData?.length
                ? onGoingProject?.getData?.map((data, key) => (
                    <Link
                      key={key}
                      to={
                        data?.package?.ownPackage
                          ? `/my-package/${data?.course}/${data?.package?.courseType}/own-package`
                          : `/my-package/${data?.course}/${data?.package?.courseType}`
                      }
                      className="slider-text-info-ongoing-project"
                    >
                      <div className="single-card-for-ongoing-package ">
                        <div className="card-banner-image">
                          <img
                            src={`${getPhotoUrl(data?.package?.photo)}`}
                            alt=""
                            style={{ height: "150px", objectFit: "cover" }}
                          />
                        </div>
                        <div
                          className="card-bottom-content"
                          title={data?.package?.packageName}
                        >
                          <div className="card-bottom-content-top-title">
                            <h6>
                              <Truncate lines={1} ellipsis={<span>...</span>}>
                                {data?.package?.packageName}
                              </Truncate>
                            </h6>
                            <span>
                              <i className="bx bx-time-five"></i>Duration -{" "}
                              {data?.package?.validity} Days
                            </span>
                            <hr />
                          </div>

                          <div className="card-bottom-content-bottom-progress">
                            <ProgressBar now={progressNumber(data?.package)} />{" "}
                            <b>
                              {progressNumber(data?.package) > 100
                                ? 100
                                : progressNumber(data?.package)}{" "}
                              %
                            </b>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                : ""}
            </Slider>
          ) : (
            <Row>
              {onGoingProject?.getData?.length ? (
                onGoingProject?.getData?.map((data, key) => (
                  <Col lg="4 mb-3" sm="6 mb-3" key={key}>
                    <Link
                      to={
                        data?.package?.ownPackage
                          ? `/my-package/${data?.course}/${data?.package?.courseType}/own-package`
                          : `/my-package/${data?.course}/${data?.package?.courseType}`
                      }
                      className="slider-text-info-ongoing-project"
                      key={key}
                    >
                      <div className="single-card-for-ongoing-package ">
                        <div className="card-banner-image">
                          <img
                            src={`${getPhotoUrl(data?.package?.photo)}`}
                            alt=""
                            style={{ height: "150px", objectFit: "cover" }}
                          />
                        </div>
                        <div
                          className="card-bottom-content"
                          title={data?.package?.packageName}
                        >
                          <div className="card-bottom-content-top-title">
                            <h6>
                              {" "}
                              <Truncate lines={1} ellipsis={<span>...</span>}>
                                {data?.package?.packageName}
                              </Truncate>
                            </h6>
                            <span>
                              <i className="bx bx-time-five"></i>Duration -{" "}
                              {data?.package?.validity} Days
                            </span>
                            <hr />
                          </div>

                          <div className="card-bottom-content-bottom-progress">
                            <ProgressBar now={progressNumber(data?.package)} />{" "}
                            <b>
                              {progressNumber(data?.package) > 100
                                ? 100
                                : progressNumber(data?.package)}{" "}
                              %
                            </b>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))
              ) : (
                <div className="empty-blog-area-wrap">
                  <div>
                    <img src={emptyBlogImg} alt="" />
                    <h6>Ops! currently you have no packages</h6>
                    <Link to="/packages/Ielts">Purchase Package</Link>
                  </div>
                </div>
              )}
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default OngoingPackageSlider;
