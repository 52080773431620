import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import PlayNewIcon from "./icon/PlayNewIcon";
import rightMainImage from "./icon/right-image.png";
import "./style/new-hero.scss";
const HeroNew = () => {
  const audioRef = React.createRef();
  const [isPlaying, setIsPlaying] = useState(false);
  window.onload = function () {
    var context = new AudioContext();
  };
  setTimeout(() => {
    setIsPlaying(true);
    // handleSound();
    // ref?.current?.play();
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, 3000);
  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.play();
  //   }
  // }, []);
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="new-hero-area">
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="jhSg4UJf7J4"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
      <div className="new-inner-hero-inner-wrap">
        <Container>
          <Row>
            <Col lg="6" className="my-auto">
              <div className="new-hero-left-content">
                <iframe
                  src="../../assets/images/silence.mp3"
                  allow="autoplay"
                  id="audio"
                  title="myFrame"
                  style={{ display: "none" }}
                ></iframe>

                <audio autoplay id="playAudio">
                  <source src="../../assets/images/chat.mp3" type="audio/mp3" />
                </audio>

                <h2>
                  A perfect tool for a perfect score | Make your{" "}
                  <span>IELTS</span> preparation perfect!
                </h2>
                <p>
                  Prepare yourself to achieve a 7+ score in your IELTS exam!
                  Anytime, anywhere!
                </p>
                <div className="new-hero-footer-button">
                  <Link to="/packages/Ielts">Take a free test </Link>
                  <button onClick={() => setOpen(true)}>
                    <span>
                      <PlayNewIcon />
                    </span>
                    <span>Watch it in action </span>
                  </button>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="new-hero-right-image-area">
                <img src={rightMainImage} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HeroNew;
