import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { printToLetter } from "../../../helpers/custom/printNumberToLetter";
import { storeQuestion } from "../../../store/Question/actions";

const HeadMatchingType = ({
  headingMatchingType,
  handleOnChange,
  idx,
  options,
  questionNumber,
  ans,
  currentIndex,
  currentPassage,
  view,
  clue,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const findQuestionsNumberRange = `${
    headingMatchingType?.[0]?.questionNumber + questionNumber
  }-
  ${
    headingMatchingType?.[headingMatchingType?.length - 1]?.questionNumber +
    questionNumber
  }`;
  const findPassageLength = `${printToLetter(1)} - ${printToLetter(
    currentPassage?.listeningAudioModel?.length
  )}`;
  const [viewExplain, setViewExplain] = useState("");
  const [toggle, setToggle] = useState();

  const dispatch = useDispatch();

  const onDrop = (ev, list, serial, questionIndex) => {
    const id = ev.dataTransfer.getData("text");
    // console.log(`onDrop ~ id`, id);
    // console.log(`onDrop ~ id`, id, list, serial);
    const [value, idx] = id.split("/");
    const data = {
      target: {
        value: value,
      },
    };
    handleOnChange(data, list, serial, idx, serial);
  };
  const onDragStart = (e, data, idx) => {
    e.dataTransfer.setData("text/plain", data + "/" + idx);
  };

  const onDropItem = (e) => {
    const id = e.dataTransfer.getData("text");
    const [, idx] = id.split("/");
    const newClues = { ...clue };
    Object.keys(clue).forEach((data, i) => {
      if (clue[data] === idx * 1) {
        newClues[data] = 0;
      }
    });
    dispatch(storeQuestion("clues", newClues));
    handleOnChange({ target: { value: "" } }, { answer: "" }, idx);
  };
  return (
    <React.Fragment>
      <div className="ques-serial">
        <h5>Question {findQuestionsNumberRange}</h5>
        {/* edit form area wrap  */}
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle
                  ? "navbar_mobile_menu show my-2"
                  : "navbar_mobile_menu hide my-2"
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        {/* edit form area wrap  */}
        <div className="sub-title-header">
          <p>
            Listening Passage {currentIndex + 1} has{" "}
            {currentPassage?.readingPessageDescription?.length} paragraphs,{" "}
            {findPassageLength}.
          </p>
          <p>
            Choose the correct heading for each paragraphs from the list of
            heading below.
          </p>
          <p className="mb-3">
            Write the appropriate letter, {findPassageLength}, in boxes{" "}
            {findQuestionsNumberRange} on your answer sheet.
          </p>
        </div>

        <div
          className="border rounded-3 d-flex flex-wrap p-3"
          style={{ backgroundColor: "#F1F4FE" }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => onDropItem(e)}
        >
          {options?.map((list, idx) => {
            // if (clue?.[idx] > 0) {
            //   return null;
            // }
            return (
              <div
                draggable={!view}
                onDragStart={(e) => onDragStart(e, list, idx)}
                disabled={clue?.[idx]}
                onDragEnd={(e) => {
                  e.target.style.background = "";
                }}
                className="py-2"
                style={{ width: "33%", cursor: view ? "default" : "grab" }}
                key={idx}
              >
                {idx + 1}. {list}
              </div>
            );
          })}
        </div>
        <div className="ques-p-gap-main-wrap">
          {(headingMatchingType || []).map((data, key) => {
            const number = data.questionNumber + questionNumber;
            return (
              <div
                className="answer-checkbox-mainwrap"
                key={key}
                id={`question${number}`}
              >
                <div className="checkbox-ques-serial-no option-select-ans mb-2">
                  <div className="ques-sl-no-main">
                    <h5>{number}</h5>
                  </div>
                  <div
                    // draggable={!view}
                    // onDragStart={(e) => {
                    //   if (ans?.[number]?.answer?.[0]) {
                    //     onDragStart(
                    //       e,
                    //       ans?.[number]?.answer?.[0] || '',
                    //       number
                    //     );
                    //   }
                    // }}
                    className="text-center"
                    style={{
                      border: "1px solid",
                      padding: "2px",
                      // cursor: view ? 'default' : 'grab',
                      borderRadius: "3px",
                      maxWidth: "max-content",
                      minWidth:
                        ans?.[number]?.answer?.[0]?.length > 0
                          ? "max-content"
                          : "70px",
                      height: "30px",
                      marginLeft: "10px",
                      textAlign: "center",
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      // if (!ans?.[number]?.answer?.[0]) {
                      onDrop(e, data, number, idx);
                      // }
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {ans?.[number]?.answer?.[0] || ""}
                    </span>
                    {/* <AvForm>
                      <AvField
                        aria-label="Default select example"
                        type="select"
                        disabled={view}
                        className="form-select"
                        value={ans?.[number]?.answer?.[0]}
                        name={`question${data?.questionNumber}`}
                        onChange={(e) => handleOnChange(e, data, number)}
                      >
                        <option selected value="">
                          Select
                        </option>
                        {options?.map((list, idx) => (
                          <option value={list} key={idx}>
                            {list}
                          </option>
                        ))}
                      </AvField>
                    </AvForm> */}
                  </div>
                  {/* <div
                    style={{ cursor: 'grab' }}
                    draggable
                    onDragStart={(e) => {
                      if (ans?.[number]?.answer?.[0]) {
                        onDragStart(
                          e,
                          ans?.[number]?.answer?.[0] || '',
                          number
                        );
                      }
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      if (!ans?.[number]?.answer?.[0]) {
                        onDrop(e, data, number, idx);
                      }
                    }}
                  >
                    <select
                      aria-label="Default select example"
                      type="select"
                      disabled={view}
                      className="form-select"
                      value={ans?.[number]?.answer?.[0]}
                      name={`question${data?.questionNumber}`}
                      onChange={(e) => handleOnChange(e, data, number)}
                    >
                      <option selected value="">
                        Select
                      </option>
                      {options?.map((list, idx) => (
                        <option value={list} key={idx}>
                          {list}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <p>{data.q}</p>
                </div>
                {view ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center">
                      <span>
                        Answer :{" "}
                        <strong>
                          {ans?.[number]?.answer?.map(
                            (data, idx) => `${data}  ${idx === 1 ? "" : ","}`
                          )}
                        </strong>
                      </span>
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            if (viewExplain !== `view${number}`) {
                              setViewExplain(`view${number}`);
                            } else {
                              setViewExplain("");
                            }
                          }}
                          style={{
                            background: "transparent",
                            color: "#485ec4",
                            border: "1px solid #485ec4",
                          }}
                          className="btn btn-sm m-1 gapsInput"
                        >
                          {viewExplain === `view${number}`
                            ? "Hide"
                            : "See Explanation"}
                        </Button>
                      </div>
                      {ans?.[number]?.mark === 1 ? (
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      ) : (
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      )}
                    </div>
                    {viewExplain === `view${number}` ? (
                      <div className="border rounded-2 p-3 my-2">
                        {data?.explanation}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeadMatchingType;
