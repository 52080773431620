import persistReducer from "redux-persist/es/persistReducer";
import storageSession from "redux-persist/lib/storage/session";
import {
  ADD_FAQ_INFO,
  ADD_FAQ_INFO_FAIL,
  ADD_FAQ_INFO_SUCCESS,
  ADD_FAQ_TYPE,
  ADD_FAQ_TYPE_FAIL,
  ADD_FAQ_TYPE_SUCCESS,
  GET_FAQ_INFO,
  GET_FAQ_INFO_FAIL,
  GET_FAQ_INFO_SUCCESS,
  GET_FAQ_TYPE,
  GET_FAQ_TYPE_FAIL,
  GET_FAQ_TYPE_SUCCESS,
  GET_SINGLE_FAQ,
  GET_SINGLE_FAQ_FAIL,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_TYPE,
  GET_SINGLE_FAQ_TYPE_FAIL,
  GET_SINGLE_FAQ_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  adding: false,
  message: "",
  error: "",
  faqTypeList: [],
  faqInfoList: [],
  singleFaq: [],
  singleFaqType: [],
  faqTypeListLoading: false,
  faqInfoListLoading: false,
  singleFaqLoading: false,
  singleFaqTypeLoading: false,
};

const FAQReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAQ_TYPE:
      state = {
        ...state,
        adding: true,
      };
      break;
    case ADD_FAQ_TYPE_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      };
      break;
    case ADD_FAQ_TYPE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case ADD_FAQ_INFO:
      state = {
        ...state,
        adding: true,
      };
      break;
    case ADD_FAQ_INFO_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      };
      break;
    case ADD_FAQ_INFO_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case GET_FAQ_TYPE:
      state = {
        ...state,
        faqTypeListLoading: true,
      };
      break;
    case GET_FAQ_TYPE_SUCCESS:
      state = {
        ...state,
        faqTypeListLoading: false,
        faqTypeList: action.payload.values,
      };
      break;
    case GET_FAQ_TYPE_FAIL:
      state = {
        ...state,
        faqTypeListLoading: false,
        message: action.payload.message,
      };
      break;

    case GET_FAQ_INFO:
      state = {
        ...state,
        faqInfoListLoading: true,
      };
      break;
    case GET_FAQ_INFO_SUCCESS:
      state = {
        ...state,
        faqInfoListLoading: false,
        faqInfoList: action.payload.values,
      };
      break;
    case GET_FAQ_INFO_FAIL:
      state = {
        ...state,
        faqInfoListLoading: false,
        message: action.payload.message,
      };
      break;
    case GET_SINGLE_FAQ:
      state = {
        ...state,
        singleFaqLoading: true,
      };
      break;
    case GET_SINGLE_FAQ_SUCCESS:
      state = {
        ...state,
        singleFaqLoading: false,
        singleFaq: action.payload.values,
      };
      break;
    case GET_SINGLE_FAQ_FAIL:
      state = {
        ...state,
        singleFaqLoading: false,
        message: action.payload.message,
      };
      break;
    case GET_SINGLE_FAQ_TYPE:
      state = {
        ...state,
        singleFaqTypeLoading: true,
      };
      break;
    case GET_SINGLE_FAQ_TYPE_SUCCESS:
      state = {
        ...state,
        singleFaqTypeLoading: false,
        singleFaqType: action.payload.values,
      };
      break;
    case GET_SINGLE_FAQ_TYPE_FAIL:
      state = {
        ...state,
        singleFaqTypeLoading: false,
        message: action.payload.message,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

const persistConfig = {
  keyPrefix: "exmPro-",
  key: "faq",
  storage: storageSession,
};

export default persistReducer(persistConfig, FAQReducer);
