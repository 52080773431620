import React from "react";
import Modal from "react-responsive-modal";
import "./rating-exam.scss";
const RatingExam = () => {
  const [openFirst, setOpenFirst] = React.useState(false);
  return (
    <div style={{ margin: "30px auto" }}>
      <button
        className="btn text-center d-block w-100"
        onClick={() => setOpenFirst(true)}
      >
        Open Rating popup
      </button>

      <Modal
        open={openFirst}
        onClose={() => setOpenFirst(false)}
        classNames={{
          overlay: "ratingOverlay",
          modal: "ratingModal",
        }}
        center
      >
        <div className="rating-exam-main-area-wrap">
          <div className="rating-header">
            <h3>Rating Exam</h3>
          </div>
          <div className="rating-exam-main-body">
            <div className="rating-stars">
              <span>
                <i className="bx bx-star"></i>
              </span>{" "}
              <span>
                <i className="bx bx-star"></i>
              </span>{" "}
              <span>
                <i className="bx bx-star"></i>
              </span>{" "}
              <span>
                <i className="bx bx-star"></i>
              </span>{" "}
              <span>
                <i className="bx bx-star"></i>
              </span>
            </div>
            <div className="rating-comment-box">
              <label htmlFor="rating-comment" className="form-label">
                Comments (optional)
              </label>
              <textarea
                className="form-control"
                name=""
                id="rating-comment"
                rows="3"
              />
            </div>
            <div className="rating-bottom-wrap">
              <button className="btn">Skip for now</button>
              <button className="btn">Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RatingExam;
