import {
  ADD_WISHLIST,
  ADD_WISHLIST_FAIL,
  ADD_WISHLIST_SUCCESS,
  GET_WISHLIST,
  GET_WISHLIST_FAIL,
  GET_WISHLIST_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
  error: '',
  adding: false,
  wishList: [],
};

const wishListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WISHLIST:
      return {
        ...state,
        adding: true,
      };
    case ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_WISHLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      };
    case GET_WISHLIST:
      return {
        ...state,
        loading: true,
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishList: action.payload.data,
      };
    case GET_WISHLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default wishListReducer;
