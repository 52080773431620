/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Container } from "reactstrap";

import Loader from "../../components/Atoms/Loader";
import CardComponent from "../../components/Common/CardComponent";
import {
  calculateTimeCount,
  findTime,
  timeList,
} from "../../helpers/custom/TimeHelper";

import { getParticularSlot } from "../../store/Facilitator/actions";
import googleMeetIcon from "./img/Google_Meet.png";

const FacilitatorParticularSlot = ({ history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { authtoken, quesLoading, particularSlot, particularSlotLoading } =
    useSelector((state) => ({
      authtoken: state.Login.token,
      particularSlotLoading: state.facilitator.particularSlotLoading,
      particularSlot: state.facilitator.particularSlot,
      id: state.Login.id,
    }));

  useEffect(() => {
    dispatch(getParticularSlot(authtoken, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container>
        <div className="speaking-main-container-wrap">
          <div className="speaking-exam-main-wrap">
            <div className="header-top-button-title">
              <h4>
                <span onClick={() => history.goBack()}>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>
                  Set : {timeList?.[particularSlot?.values?.meetingTime]}
                </span>
              </h4>
            </div>
            {particularSlotLoading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="speaking-exam-date-bottom-wrap">
                <CardComponent>
                  {particularSlotLoading ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="speaking-package-listed-view-wrap">
                      <h3>Your Time Slot Start In</h3>
                      {/* <h4>
                        <span>03D</span>
                        <span>:</span>
                        <span>22H</span>
                        <span>:</span>
                        <span>22M</span>
                        <span>:</span>
                        <span>40S</span>
                      </h4> */}
                      <h4>
                        <Timer
                          initialTime={
                            calculateTimeCount(particularSlot?.values) || 0
                          }
                          // checkpoints={checkpoints}
                          direction="backward"
                        >
                          <span>
                            <Timer.Days />D :
                          </span>
                          <span>
                            <Timer.Hours />H :
                          </span>
                          <span>
                            <Timer.Minutes />M :
                          </span>
                          <span>
                            <Timer.Seconds />S
                          </span>
                        </Timer>
                      </h4>
                      <p></p>
                      <div className="exam-google-meet-link-button">
                        {findTime(particularSlot?.values) &&
                        particularSlot?.values?.complete !== 3 ? (
                          <a href={particularSlot?.values?.teacherId?.meetLink}>
                            <span>
                              <img src={googleMeetIcon} alt="" />
                            </span>
                            <span>Start exam</span>
                          </a>
                        ) : (
                          <a style={{ cursor: "not-allowed", color: "#fff" }}>
                            <span>
                              <img src={googleMeetIcon} alt="" />
                            </span>
                            <span>Start Meeting</span>
                          </a>
                        )}
                      </div>
                      <Form.Text>Google meet</Form.Text>
                    </div>
                  )}
                </CardComponent>
              </div>
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FacilitatorParticularSlot);
