import {
  GET_BLOG_POST,
  GET_BLOG_POST_FAIL,
  GET_BLOG_POST_SUCCESS,
  GET_LAST_THREE_LISTENING,
  GET_LAST_THREE_LISTENING_FAIL,
  GET_LAST_THREE_LISTENING_SUCCESS,
  GET_LAST_THREE_READING,
  GET_LAST_THREE_READING_FAIL,
  GET_LAST_THREE_READING_SUCCESS,
  GET_LAST_THREE_SPEAKING,
  GET_LAST_THREE_SPEAKING_FAIL,
  GET_LAST_THREE_SPEAKING_SUCCESS,
  GET_LAST_THREE_WRITING,
  GET_LAST_THREE_WRITING_FAIL,
  GET_LAST_THREE_WRITING_SUCCESS,
  GET_ONGOING_PACKAGE,
  GET_ONGOING_PACKAGE_FAIL,
  GET_ONGOING_PACKAGE_SUCCESS,
  GET_RESULT_DASHBOARD,
  GET_RESULT_DASHBOARD_FAIL,
  GET_RESULT_DASHBOARD_SUCCESS,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_FAIL,
  GET_SINGLE_BLOG_SUCCESS,
  GET_STUDENT_BANNER,
  GET_STUDENT_BANNER_FAIL,
  GET_STUDENT_BANNER_SUCCESS,
  GET_STUDENT_PERFORMANCE,
  GET_STUDENT_PERFORMANCE_FAIL,
  GET_STUDENT_PERFORMANCE_SUCCESS,
  GET_STUDENT_SCREEN,
  GET_STUDENT_SCREEN_FAIL,
  GET_STUDENT_SCREEN_SUCCESS,
  GET_TOP_THREE_PACKAGE,
  GET_TOP_THREE_PACKAGE_FAIL,
  GET_TOP_THREE_PACKAGE_SUCCESS,
  STORE_DASHBOARD_DATA,
} from "./actionTypes";

export const getBlogPost = (token, id) => ({
  type: GET_BLOG_POST,
  payload: { token, id },
});

export const getBlogPostSuccess = (data) => ({
  type: GET_BLOG_POST_SUCCESS,
  payload: { data },
});

// charts data
export const getBlogPostFail = (data) => ({
  type: GET_BLOG_POST_FAIL,
  payload: { data },
});
export const getOngoingPackage = (token, number) => ({
  type: GET_ONGOING_PACKAGE,
  payload: { token, number },
});

export const getOngoingPackageSuccess = (data) => ({
  type: GET_ONGOING_PACKAGE_SUCCESS,
  payload: { data },
});

// charts data
export const getOngoingPackageFail = (data) => ({
  type: GET_ONGOING_PACKAGE_FAIL,
  payload: { data },
});

export const getSingleBlog = (token, id) => ({
  type: GET_SINGLE_BLOG,
  payload: { token, id },
});

export const getSingleBlogSuccess = (data) => ({
  type: GET_SINGLE_BLOG_SUCCESS,
  payload: { data },
});

// charts data
export const getSingleBlogFail = (data) => ({
  type: GET_SINGLE_BLOG_FAIL,
  payload: { data },
});

export const getLastThreeSpeaking = (token) => ({
  type: GET_LAST_THREE_SPEAKING,
  payload: { token },
});

export const getLastThreeSpeakingSuccess = (data) => ({
  type: GET_LAST_THREE_SPEAKING_SUCCESS,
  payload: { data },
});

// charts data
export const getLastThreeSpeakingFail = (data) => ({
  type: GET_LAST_THREE_SPEAKING_FAIL,
  payload: { data },
});
export const getLastThreeReading = (token) => ({
  type: GET_LAST_THREE_READING,
  payload: { token },
});

export const getLastThreeReadingSuccess = (data) => ({
  type: GET_LAST_THREE_READING_SUCCESS,
  payload: { data },
});

// charts data
export const getLastThreeReadingFail = (data) => ({
  type: GET_LAST_THREE_READING_FAIL,
  payload: { data },
});
export const getLastThreeListening = (token) => ({
  type: GET_LAST_THREE_LISTENING,
  payload: { token },
});

export const getLastThreeListeningSuccess = (data) => ({
  type: GET_LAST_THREE_LISTENING_SUCCESS,
  payload: { data },
});

// charts data
export const getLastThreeListeningFail = (data) => ({
  type: GET_LAST_THREE_LISTENING_FAIL,
  payload: { data },
});

export const getLastThreeWriting = (token) => ({
  type: GET_LAST_THREE_WRITING,
  payload: { token },
});

export const getLastThreeWritingSuccess = (data) => ({
  type: GET_LAST_THREE_WRITING_SUCCESS,
  payload: { data },
});

// charts data
export const getLastThreeWritingFail = (data) => ({
  type: GET_LAST_THREE_WRITING_FAIL,
  payload: { data },
});
export const storeDashboardData = (name, data) => ({
  type: STORE_DASHBOARD_DATA,
  payload: { name, data },
});
export const getLeaderBoardResult = (token, options) => ({
  type: GET_RESULT_DASHBOARD,
  payload: { token, options },
});

export const getLeaderBoardResultSuccess = (data) => ({
  type: GET_RESULT_DASHBOARD_SUCCESS,
  payload: { data },
});

// charts data
export const getLeaderBoardResultFail = (data) => ({
  type: GET_RESULT_DASHBOARD_FAIL,
  payload: { data },
});
export const getStudentPerformance = (token, options) => ({
  type: GET_STUDENT_PERFORMANCE,
  payload: { token, options },
});

export const getStudentPerformanceSuccess = (data) => ({
  type: GET_STUDENT_PERFORMANCE_SUCCESS,
  payload: { data },
});

// charts data
export const getStudentPerformanceFail = (data) => ({
  type: GET_STUDENT_PERFORMANCE_FAIL,
  payload: { data },
});

export const getBanner = (token) => ({
  type: GET_STUDENT_BANNER,
  payload: { token },
});

export const getBannerSuccess = (data) => ({
  type: GET_STUDENT_BANNER_SUCCESS,
  payload: { data },
});

// charts data
export const getBannerFail = (data) => ({
  type: GET_STUDENT_BANNER_FAIL,
  payload: { data },
});

export const getScreen = (token) => ({
  type: GET_STUDENT_SCREEN,
  payload: { token },
});

export const getScreenSuccess = (data) => ({
  type: GET_STUDENT_SCREEN_SUCCESS,
  payload: { data },
});

// charts data
export const getScreenFail = (data) => ({
  type: GET_STUDENT_SCREEN_FAIL,
  payload: { data },
});
export const getTopThreePackageInfo = (token) => ({
  type: GET_TOP_THREE_PACKAGE,
  payload: { token },
});

export const getTopThreePackageInfoSuccess = (data) => ({
  type: GET_TOP_THREE_PACKAGE_SUCCESS,
  payload: { data },
});

// charts data
export const getTopThreePackageInfoFail = (data) => ({
  type: GET_TOP_THREE_PACKAGE_FAIL,
  payload: { data },
});
