import {
  VERIFY_EMAILS,
  VERIFY_EMAILS_FAIL,
  VERIFY_EMAILS_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
  error: '',
  message: '',
};

const verifyEmail = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_EMAILS:
      state = { ...state, loading: true };
      break;
    case VERIFY_EMAILS_SUCCESS:
      state = { ...state, message: action.payload.message, loading: false };
      break;
    case VERIFY_EMAILS_FAIL:
      state = { ...state, error: action.payload.message, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default verifyEmail;
