import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  storeQuestion,
  storeQuestionWithClues,
  submitAnswerOneByOne,
} from "../../store/Question/actions";
import ComplateQuesForm from "./Question2/ComplateQuesForm";
import ComparisonType from "./QuestionComponent/ComparisonType";
import FillGaps from "./QuestionComponent/FillGaps";
import FillGapsOne from "./QuestionComponent/FillGapsOne";
import FillGapsWithClue from "./QuestionComponent/FillGapsWithClue";
import HeadMatchingType from "./QuestionComponent/HeadMatchingType";
import McqType from "./QuestionComponent/McqType";
import MultiSelectType from "./QuestionComponent/MultiSelectType";
import ShortAnswer from "./QuestionComponent/ShortAnswer";
import TrueFalseType from "./QuestionComponent/TrueFalseType";
import YesNoType from "./QuestionComponent/YesNoType";

const mcqType = "mcq";
const trueFalseQues = "trueFalse";
const comprehensionQues = "comprehension";
const YesNoQues = "YesNoQues";
const HeadingMatching = "headingMatching";
const multipleChoice = "multipleSelection";
const fillGapsWords = "fillGapsWords";
const fillGapsWithoutWords = "fillGaps";
export const fillGapsOne = "fillGapsOne";
export const fillGapsTwo = "fillGapsTwo";
export const fillGapsThree = "fillGapsThree";
const sortAnswerOne = "sortAnswerOne";
const sortAnswerTwo = "sortAnswerTwo";
const sortAnswerThree = "sortAnswerThree";
const tableOne = "tableOne";
const tableTwo = "tableTwo";
const tableThree = "tableThree";
const formOne = "formOne";
const formTwo = "formTwo";
const formThree = "formThree";
const noteOne = "noteOne";
const noteTwo = "noteTwo";
const noteThree = "noteThree";

const RightSide = ({ question, history }) => {
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const { questions, currentIndex, ans, clues, authToken, answerId } =
    useSelector((state) => ({
      questions: state.QuesReducer.questions,
      currentIndex: state.QuesReducer.currentIndex,
      ansQuestion: state.QuesReducer.ansQuestion,
      ans: state.QuesReducer.ans,
      clues: state.QuesReducer.clues,
      authToken: state.Login.token,
      answerId: state.QuesReducer.answerId,
    }));

  const [notePad, setNotePad] = useState([]);

  useEffect(() => {
    if (questions?.length > 0) {
      questions?.forEach((data, i) => {
        setNotePad((prev) => [...prev, ""]);
      });
    }
  }, [questions]);

  const findPreviousPassageLastQuestion = useMemo(() => {
    let questionPosition = 0;
    if (currentIndex > 0) {
      for (let i = 0; i < currentIndex; i++) {
        const previous = questions?.[i];
        const lastQuestion =
          previous?.questions?.[previous?.questions?.length - 1];
        questionPosition +=
          lastQuestion?.Que?.question?.[lastQuestion?.Que?.question?.length - 1]
            .questionNumber;
      }
    }
    return questionPosition;
  }, [questions, currentIndex]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (object?.answer) {
        submitData(object);
        setObject({});
      }
    }, 300);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object?.answer]);

  const handleStoreAnswer = (
    e,
    data,
    number,
    idx,
    questionIndex,
    fill,
    questionIdx
  ) => {
    const { value } = e.target;
    const body = {
      serial: number,
      answer: value,
      questionId: data?._id,
      questionSetId: questions?.[currentIndex]?._id,
      setSerial: questionIdx + 1,
      underlength: null,
    };

    const findAns = { ...ans };
    findAns[number].answer = [value];
    if (data?.answer?.includes(value)) {
      findAns[number].mark = 1;
    } else {
      findAns[number].mark = 0;
    }

    if (idx > -1) {
      dispatch(
        storeQuestionWithClues(findAns, { ...clues, [idx]: questionIndex })
      );
    } else {
      dispatch(storeQuestion("ans", findAns));
    }
    if (fill === "fill") {
      setObject(body);
    } else {
      submitData(body);
    }
  };

  const submitData = (data) => {
    const options = {
      type: "reading",
      id: answerId,
      history,
    };
    dispatch(submitAnswerOneByOne(data, authToken, options));
  };

  const handleStoreAnswerMulti = (e, data, number, idx) => {
    const { value, checked } = e.target;
    const findAns = { ...ans };
    if (checked && !findAns[number].answer.includes(value)) {
      findAns[number]?.answer.push(value);
    } else {
      const data = findAns[number].answer.filter((data) => data !== value);
      findAns[number].answer = data;
    }
    const givenAnswer = findAns[number]?.answer;
    if (_.isEqual(givenAnswer, data.answer)) {
      findAns[number].mark = 1;
    } else {
      findAns[number].mark = 0;
    }
    const body = {
      serial: number,
      answer: value,
      questionId: data?._id,
      questionSetId: questions?.[currentIndex]?._id,
      setSerial: idx + 1,
      underlength: null,
    };

    submitData(body);
    dispatch(storeQuestion("ans", findAns));
  };
  const findQuestionsNumberRange = `${
    question?.[0]?.Que?.question?.[0]?.questionNumber +
    findPreviousPassageLastQuestion
  } - 
  ${
    question?.[question?.length - 1]?.Que?.question[
      question?.[question?.length - 1]?.Que?.question.length - 1
    ]?.questionNumber + findPreviousPassageLastQuestion
  }`;

  const handleChangeNotePad = (e, idx) => {
    const { value } = e.target;
    setNotePad((prev) => {
      const newNotePad = [...prev];
      newNotePad[idx] = value;
      return newNotePad;
    });
  };

  return (
    <React.Fragment>
      <div className="ques-right-part-container">
        <div className="ques-section-main-wrapper mb-5">
          <div className="ques-title">
            <p>Section 1 : Question {findQuestionsNumberRange}</p>
          </div>
          {question?.map((data, idx) => {
            if (data?.Que?.questype === multipleChoice) {
              return (
                <MultiSelectType
                  multipleQuesType={data?.Que?.question}
                  handleOnChange={(e, data, number) =>
                    handleStoreAnswerMulti(e, data, number, idx)
                  }
                  key={idx}
                  idx={idx}
                  ans={ans}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === trueFalseQues) {
              return (
                <TrueFalseType
                  TrueFalseQuesType={data?.Que?.question}
                  handleOnChange={(e, data, number) =>
                    handleStoreAnswer(e, data, number, null, null, null, idx)
                  }
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === YesNoQues) {
              return (
                <YesNoType
                  yesNoQues={data?.Que?.question}
                  handleOnChange={(e, data, number) =>
                    handleStoreAnswer(e, data, number, null, null, null, idx)
                  }
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === comprehensionQues) {
              return (
                <ComparisonType
                  comprehensionType={data?.Que?.question}
                  handleOnChange={(e, data, number) =>
                    handleStoreAnswer(e, data, number, null, null, null, idx)
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  key={idx}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === mcqType) {
              return (
                <McqType
                  questions={data?.Que?.question}
                  handleChangeMcq={(e, data, number) =>
                    handleStoreAnswer(e, data, number, null, null, null, idx)
                  }
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === HeadingMatching) {
              return (
                <HeadMatchingType
                  headingMatchingType={data?.Que?.question}
                  handleOnChange={(e, data, number) =>
                    handleStoreAnswer(e, data, number, null, null, null, idx)
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  clue={clues}
                  currentIndex={currentIndex}
                  currentPassage={questions?.[currentIndex]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (
              data?.Que?.questype === fillGapsWithoutWords ||
              // data?.Que?.questype === fillGapsOne ||
              data?.Que?.questype === fillGapsTwo ||
              data?.Que?.questype === fillGapsThree
            ) {
              return (
                <FillGaps
                  questions={data?.Que?.question}
                  handleOnChange={(
                    e,
                    data,
                    number,
                    qIdx,
                    questionIndex,
                    fill
                  ) =>
                    handleStoreAnswer(
                      e,
                      data,
                      number,
                      qIdx,
                      questionIndex,
                      fill,
                      idx
                    )
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentPassage={questions?.[currentIndex]}
                  currentIndex={currentIndex}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === fillGapsOne) {
              return (
                <FillGapsOne
                  questions={data?.Que?.question}
                  handleOnChange={(
                    e,
                    data,
                    number,
                    qIdx,
                    questionIndex,
                    fill
                  ) =>
                    handleStoreAnswer(
                      e,
                      data,
                      number,
                      qIdx,
                      questionIndex,
                      fill,
                      idx
                    )
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentPassage={questions?.[currentIndex]}
                  currentIndex={currentIndex}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === fillGapsWords) {
              return (
                <FillGapsWithClue
                  questions={data?.Que?.question}
                  handleOnChange={(
                    e,
                    data,
                    number,
                    qIdx,
                    questionIndex,
                    fill
                  ) =>
                    handleStoreAnswer(
                      e,
                      data,
                      number,
                      qIdx,
                      questionIndex,
                      fill,
                      idx
                    )
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentPassage={questions?.[currentIndex]}
                  currentIndex={currentIndex}
                  questionNumber={findPreviousPassageLastQuestion}
                  clue={clues}
                />
              );
            } else if (
              data?.Que?.questype === sortAnswerOne ||
              data?.Que?.questype === sortAnswerTwo ||
              data?.Que?.questype === sortAnswerThree
            ) {
              return (
                <ShortAnswer
                  questions={data?.Que?.question}
                  question={data?.Que}
                  handleOnChange={(
                    e,
                    data,
                    number,
                    qIdx,
                    questionIndex,
                    fill
                  ) =>
                    handleStoreAnswer(
                      e,
                      data,
                      number,
                      qIdx,
                      questionIndex,
                      fill,
                      idx
                    )
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  currentPassage={questions?.[currentIndex]}
                  currentIndex={currentIndex}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (
              data?.Que?.questype === tableOne ||
              data?.Que?.questype === tableTwo ||
              data?.Que?.questype === tableThree ||
              data?.Que?.questype === formOne ||
              data?.Que?.questype === formTwo ||
              data?.Que?.questype === formThree ||
              data?.Que?.questype === noteOne ||
              data?.Que?.questype === noteTwo ||
              data?.Que?.questype === noteThree
            ) {
              return (
                <ComplateQuesForm
                  questions={data?.Que}
                  handleOnChange={(
                    e,
                    data,
                    number,
                    qIdx,
                    questionIndex,
                    fill
                  ) =>
                    handleStoreAnswer(
                      e,
                      data,
                      number,
                      qIdx,
                      questionIndex,
                      fill,
                      idx
                    )
                  }
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  notePad={notePad}
                  handleChangeNotePad={handleChangeNotePad}
                  questionNumber={findPreviousPassageLastQuestion}
                  clue={clues}
                  newIdx={
                    idx +
                    (questions?.[currentIndex - 1]?.questions?.length || 0)
                  }
                  currentPassage={questions?.[currentIndex]}
                  currentIndex={currentIndex}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RightSide);
