import React from "react";

const ReadingIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1422 0.652344C2.14182 0.652344 2.14139 0.652344 2.14102 0.652344C2.00861 0.652344 1.88407 0.70396 1.79003 0.797765C1.69495 0.892607 1.64258 1.01889 1.64258 1.15333V8.66551C1.64258 8.94099 1.86757 9.16565 2.14418 9.16635C3.31028 9.16914 5.26397 9.41218 6.61175 10.8226V2.96089C6.61175 2.86751 6.58789 2.77979 6.54288 2.70719C5.43669 0.925745 3.31094 0.655078 2.1422 0.652344Z"
        fill="#0052CC"
      />
      <path
        d="M12.3578 8.66556V1.15333C12.3578 1.01889 12.3054 0.892607 12.2103 0.797765C12.1163 0.70396 11.9917 0.652344 11.8594 0.652344C11.859 0.652344 11.8586 0.652344 11.8582 0.652344C10.6895 0.655125 8.56373 0.925792 7.45749 2.70724C7.41248 2.77983 7.38867 2.86756 7.38867 2.96094V10.8226C8.73645 9.41218 10.6901 9.16913 11.8562 9.16635C12.1328 9.16564 12.3578 8.94098 12.3578 8.66556Z"
        fill="#0052CC"
      />
      <path
        d="M13.4997 2.38477H13.1365V8.66551C13.1365 9.36886 12.5634 9.94239 11.859 9.94413C10.8699 9.94649 9.23901 10.1399 8.08398 11.2331C10.0816 10.744 12.1875 11.0619 13.3876 11.3354C13.5375 11.3695 13.6923 11.3343 13.8124 11.2386C13.932 11.1431 14.0006 11.0005 14.0006 10.8473V2.88566C14.0007 2.60947 13.7759 2.38477 13.4997 2.38477Z"
        fill="#0052CC"
      />
      <path
        d="M0.864138 8.66551V2.38477H0.500891C0.224755 2.38477 0 2.60947 0 2.88566V10.8472C0 11.0004 0.0686332 11.143 0.18827 11.2385C0.308237 11.3341 0.462944 11.3695 0.613032 11.3353C1.81317 11.0617 3.91907 10.7438 5.9166 11.2329C4.76162 10.1398 3.13073 9.94644 2.14163 9.94409C1.43724 9.94239 0.864138 9.36886 0.864138 8.66551Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default ReadingIcon;
