import {
  ADD_COUPON,
  ADD_COUPON_FAIL,
  ADD_COUPON_SUCCESS,
  ADD_FACILITATOR_ORDER,
  ADD_FACILITATOR_ORDER_FAIL,
  ADD_FACILITATOR_ORDER_SUCCESS,
  ADD_PACKAGE_ORDER,
  ADD_PACKAGE_ORDER_FAIL,
  ADD_PACKAGE_ORDER_SUCCESS,
  ADD_PRODUCT,
  GET_PACKAGE_ORDER,
  GET_PACKAGE_ORDER_FAIL,
  GET_PACKAGE_ORDER_SUCCESS,
  REMOVE_PRODUCT,
} from "./actionTypes.js";

export const addPackageOrder = (data, history, authtoken, cuponName, type) => ({
  type: ADD_PACKAGE_ORDER,
  payload: { data, history, authtoken, cuponName, type },
});

export const addProduct = (data) => ({
  type: ADD_PRODUCT,
  payload: { data },
});

export const removeProduct = (data) => ({
  type: REMOVE_PRODUCT,
  payload: { data },
});

export const addPackageOrderSuccess = (data) => ({
  type: ADD_PACKAGE_ORDER_SUCCESS,
  payload: data,
});

export const addPackageOrderFail = (error) => ({
  type: ADD_PACKAGE_ORDER_FAIL,
  payload: error,
});
export const getPackageOrder = (authtoken) => ({
  type: GET_PACKAGE_ORDER,
  payload: { authtoken },
});

export const getPackageOrderSuccess = (data, authtoken) => ({
  type: GET_PACKAGE_ORDER_SUCCESS,
  payload: { data, authtoken },
});

export const getPackageOrderFail = (error) => ({
  type: GET_PACKAGE_ORDER_FAIL,
  payload: error,
});

export const addFacilitatorOrder = (data, history, authtoken) => ({
  type: ADD_FACILITATOR_ORDER,
  payload: { data, history, authtoken },
});
export const addFacilitatorOrderSuccess = (data) => ({
  type: ADD_FACILITATOR_ORDER_SUCCESS,
  payload: data,
});

export const addFacilitatorOrderFail = (error) => ({
  type: ADD_FACILITATOR_ORDER_FAIL,
  payload: error,
});
export const addOrderCoupon = (data, history, authtoken, setDiscountCard) => ({
  type: ADD_COUPON,
  payload: { data, history, authtoken, setDiscountCard },
});
export const addOrderCouponSuccess = (data) => ({
  type: ADD_COUPON_SUCCESS,
  payload: data,
});
export const addOrderCouponFail = (error) => ({
  type: ADD_COUPON_FAIL,
  payload: error,
});
