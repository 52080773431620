import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import longLeft from "../../../assets/images/Icon/arrow-left.svg";
import longRight from "../../../assets/images/Icon/arrow-long-right.svg";
import Loader from "../../../components/Atoms/Loader";
import UploadFile from "../../../components/Common/Upload";
import QuestionFooter from "../../../components/WrittingExam/QuestionFooter";
import QuestionHeader from "../../../components/WrittingExam/QuestionHeader";
import { image, imageBaseUrl } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  getWritingQues,
  resetAll,
  storeQuestion,
  submitAnswerOneByOne,
  submitExam,
} from "../../../store/Question/actions";
import quesImage from "./img/Group 97758.png";
import "./writing.scss";

const WritingExam = ({ history }) => {
  const { id, packageId } = useParams();
  const [object, setObject] = useState({});
  // const [writtenArea, setWrittenArea] = useState(false);
  const [imageArea, setImageArea] = useState(false);
  // const [imageUp, setImageUp] = useState("");
  const [loadingImage] = useState(false);
  const dispatch = useDispatch();
  const {
    authToken,
    quesLoading,
    // writingQuestion,
    currentIndex,
    question,
    writingAns,
    answerId,
  } = useSelector((state) => ({
    currentPassage: state.QuesReducer.currentPassage,
    authToken: state.Login.token,
    quesLoading: state.QuesReducer.quesLoading,
    writingQuestion: state.QuesReducer.writingQues,
    question: state.QuesReducer.writingQues?.writtingQuestion?.questions,
    id: state.Login.id,
    currentIndex: state.QuesReducer.currentIndex,
    writingAns: state.QuesReducer.writingAns,
    answerId: state.QuesReducer.answerId,
  }));
  // console.log("writingAns", writingAns);

  useEffect(() => {
    // dispatch(storeQuestion("isRouteChange", false));
    const prevQuestionData = JSON.parse(localStorage.getItem("xampro-xam"));
    if (
      prevQuestionData?.id &&
      prevQuestionData?.ans &&
      prevQuestionData?.time > -1
    ) {
      const { authToken, ans, ...rest } = prevQuestionData;
      dispatch(submitExam(ans, authToken, { ...rest, history }));
    } else {
      dispatch(resetAll());
      dispatch(getWritingQues(authToken, id, false, packageId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const currentData = question?.[currentIndex];

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (object?.answer || object?.photo) {
        submitData(object);
        setObject({});
      }
    }, 1000);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object?.answer, object?.photo]);

  const submitData = (data) => {
    const options = {
      type: "writing",
      id: answerId,
    };
    dispatch(submitAnswerOneByOne(data, authToken, options));
  };

  const [iconFixed, setIconFixed] = useState(true);

  const handleAnswer = (e) => {
    const { value } = e.target;
    const values = { ...writingAns };
    const body = {
      serial: currentIndex + 1,
      answer: value,
      questionId: null,
      questionSetId: null,
      setSerial: null,
      underlength: parseInt(countWords(value)) >= 150 ? 1 : 0,
    };
    values[currentIndex + 1].answer = value;
    dispatch(storeQuestion("writingAns", values));

    setObject(body);
  };

  function countWords(str) {
    if (str?.length === 0) {
      return 0;
    }
    return str?.trim()?.split(/\s+/).length;
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return quesImage;
  };
  const handlePhoto = async (file) => {
    const values = { ...writingAns };
    try {
      const url = `package/writing/image-upload`;
      const formData = new FormData();
      formData.append("photo", file);
      const response = await image(url, formData, authToken);
      const body = {
        serial: currentIndex + 1,
        photo: response?.photo,
        havePhoto: 1,
        questionId: null,
        questionSetId: null,
        setSerial: null,
        underlength: 0,
      };

      values[currentIndex + 1].photo = response?.photo;
      values[currentIndex + 1].havePhoto = 1;

      dispatch(storeQuestion("writingAns", values));
      setObject(body);
    } catch (err) {
      toaster("error", "Upload Image failed");
    }
  };
  const handleImageShow = () => {
    setImageArea(true);
    const values = { ...writingAns };
    values[currentIndex + 1].answer = "";
    values[currentIndex + 1].isImage = true;

    dispatch(storeQuestion("writingAns", values));
    setObject({});
  };
  const handleTextShow = () => {
    setImageArea(false);
    const values = { ...writingAns };
    values[currentIndex + 1].photo = "";
    values[currentIndex + 1].havePhoto = 0;
    values[currentIndex + 1].isImage = false;

    dispatch(storeQuestion("writingAns", values));
    setObject({});
  };

  const cancelImage = () => {
    const values = { ...writingAns };
    values[currentIndex + 1].photo = "";
    values[currentIndex + 1].havePhoto = 0;
    dispatch(storeQuestion("writingAns", values));
  };
  return (
    <React.Fragment>
      <QuestionHeader />
      <div className={"question-right-area-hidden-show-class"}>
        <div
          className={
            iconFixed
              ? "exam-body-main"
              : "exam-body-main click-icon-fixed-side"
          }
        >
          <div className="container-fluid p-0">
            <Row className="g-0">
              <div
                className={
                  toggle2 ? "question-right-area-hidden-class" : "col-lg-6"
                }
              >
                <div className="passage-left-area-main-wrap">
                  <div className="reading-passage-wrap left">
                    <img
                      src={longLeft}
                      alt=""
                      onClick={() => {
                        if (toggle) {
                          setToggle(false);
                          setIconFixed(true);
                        } else {
                          setToggle2(true);
                          setToggle(false);
                          setIconFixed(false);
                        }
                      }}
                    />
                    <div className="writing-passage-wrap-content">
                      {quesLoading ? (
                        <Loader />
                      ) : (
                        <div className="writing-passage-wrap-inner-content">
                          <h2>Writing Task {currentIndex + 1}</h2>
                          <p>
                            You should spend about{" "}
                            <strong>
                              {" "}
                              {currentIndex === 0 ? "20" : "40"} minutes
                            </strong>{" "}
                            on this task.
                          </p>
                          <div className="ms-3">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: currentData?.q,
                              }}
                            ></p>
                          </div>
                          <p>
                            You do <strong>NOT</strong> need to write any
                            address.
                          </p>
                          <p>
                            You should write at least{" "}
                            <strong className="text-danger fw-bold">
                              {currentIndex === 0 ? "150" : "250"} words.
                            </strong>
                          </p>
                          {currentData?.image ? (
                            <img
                              className="img-fluid"
                              src={`${getPhotoUrl(currentData?.image)}`}
                              alt="imge"
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  toggle ? "question-right-area-hidden-class" : "col-lg-6"
                }
              >
                <div>
                  <div className="passage-right-area-main-wrap">
                    <div className="reading-passage-wrap right mb-5">
                      <img
                        onClick={() => {
                          if (toggle2) {
                            setToggle2(false);
                            setIconFixed(true);
                          } else {
                            setToggle(true);
                            setToggle2(false);
                            setIconFixed(false);
                          }
                        }}
                        src={longRight}
                        alt=""
                      />
                      <div className="writing-passage-wrap-content right">
                        <div className="writing-ques-answer-content">
                          <h4>
                            Type your essay below and click Submit for
                            evaluation
                          </h4>
                          <p>
                            You have {currentIndex === 0 ? "20" : "40"} minutes
                            to complete Writing task {currentIndex + 1}. The
                            timer will start when you start typing.
                          </p>
                          {/* <Button
                            className="me-2 mb-4"
                            onClick={() => handleImageShow()}
                          >
                            Upload Image
                          </Button>
                          <Button
                            onClick={() => handleTextShow()}
                            className="mb-4"
                          >
                            Text
                          </Button> */}
                          {writingAns?.[currentIndex + 1]?.isImage === false ? (
                            <div>
                              <b>
                                Word count :{" "}
                                {countWords(
                                  writingAns?.[currentIndex + 1]?.answer
                                )}
                              </b>
                              <textarea
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                spellcheck="false"
                                value={writingAns?.[currentIndex + 1]?.answer}
                                onChange={handleAnswer}
                                rows="8"
                                className="form-control p-2"
                              ></textarea>
                              <p>
                                If you want, you can upload image,
                                <span
                                  onClick={() => handleImageShow()}
                                  className="writing-click-answer-info"
                                >
                                  {" "}
                                  click here
                                </span>
                              </p>
                            </div>
                          ) : writingAns?.[currentIndex + 1]?.havePhoto ===
                            1 ? (
                            <>
                              <div className="writing-exam-ques-image-single-wrap">
                                <div className="writing-exam-ques-image-upload-area">
                                  <img
                                    className="upload-ques-image-item"
                                    src={`${getPhotoUrl(
                                      writingAns?.[currentIndex + 1]?.photo
                                    )}`}
                                    alt="imge"
                                  />
                                </div>
                                <Button
                                  className="close-image-button"
                                  onClick={() => {
                                    cancelImage();
                                  }}
                                >
                                  <i className="fa-solid fa-xmark"></i>
                                </Button>
                              </div>
                              <p className="mt-3">
                                If you want to write,
                                <span
                                  className="writing-click-answer-info"
                                  onClick={() => handleTextShow()}
                                >
                                  {" "}
                                  click here
                                </span>
                              </p>
                            </>
                          ) : (
                            <Row>
                              <Col md="12 mb-3 mt-5">
                                <div className="writing-exam-file-upload-drag-drop-area">
                                  <UploadFile
                                    reRender={currentIndex}
                                    isLoading={loadingImage}
                                    onDropCallBack={(files, values) => {
                                      if (files.length > 0) {
                                        handlePhoto(files?.[0]);
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <p>
                                If you want to write,
                                <span
                                  onClick={() => handleTextShow()}
                                  className="writing-click-answer-info"
                                >
                                  {" "}
                                  click here
                                </span>
                              </p>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <QuestionFooter />
    </React.Fragment>
  );
};

export default WritingExam;
