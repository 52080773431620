import React from "react";

const LongArrowRight = () => {
  return (
    <svg
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 4L27.075 8.075L23 12.15"
        stroke="#253642"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.05261H25.29"
        stroke="#253642"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LongArrowRight;
