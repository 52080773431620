import React from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import StudentFooter from '../../../components/StudentFooter/StudentFooter';
import StudentNavbar from '../../../components/StudentNavbar/StudentNavbar';
import packageImage from './img/01-3.png';
import packageProfile from './img/profile.svg';
import './ResultPage.css';
const ExamResult = () => {
  return (
    <div>
      <StudentNavbar />
      <div className="result-main-area">
        <Container>
          <div className="result-inner-area">
            <h3>Your Result</h3>
            <div className="result-main-area-inner-box">
              <h4>Package</h4>
              <div className="course-result--profile-area first-result-box">
                <Row>
                  <Col lg={3}>
                    <div className="exem-course-result-package">
                      <Card className="course-result--profile-box">
                        <Card.Img variant="top" src={packageImage} />
                        <Card.Body>
                          <div className="course-review-area-exam-page">
                            <span>
                              <i className="bx bxs-star"></i>
                            </span>
                            <span>
                              <i className="bx bxs-star"></i>
                            </span>
                            <span>
                              <i className="bx bxs-star"></i>
                            </span>
                            <span>
                              <i className="bx bxs-star"></i>
                            </span>
                            <span>
                              <i className="bx bxs-star"></i>
                            </span>
                            <span className="count-review-exam-page">
                              5.00 (3)
                            </span>
                          </div>
                          <Card.Title className="course-review-area-exam-course-title">
                            Full Package Vol.1
                          </Card.Title>

                          {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="exam-result-profile-feedback-view">
                      <div className="exam-result-profile---content">
                        <img src={packageProfile} alt="" />
                        <span>Esther Gomes</span>
                      </div>{' '}
                      <div className="exam-result-feedback---content">
                        <div className="exam-result-feedback---item">
                          <p>Correct Answers</p>
                          <h6>37/40</h6>
                        </div>
                        <div className="exam-result-feedback---item">
                          {' '}
                          <p>Your Score</p>
                          <h6>8.5</h6>
                        </div>
                        <div className="exam-result-feedback---item">
                          {' '}
                          <p>Time Spent</p>
                          <h6>39 min</h6>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="exam-ques-your-position">
                      <p>Review Brand Score</p>
                      <ul>
                        <li>9</li>
                        <li className="your-exam-answer">8.5</li>
                        <li>8</li>
                        <li>7.5</li>
                        <li>7</li>
                        <li>6.5</li>
                        <li>6</li>
                        <li>5.5</li>
                        <li>5</li>
                        <li>4.5</li>
                        <li>4</li>
                        <li>3.5</li>
                        <li>3</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>{' '}
              <h4>Your answers keys</h4>
              <div className="course-result--profile-area">
                <Table borderless hover>
                  <thead>
                    <tr>
                      <th>Question no</th>
                      <th>Your answer</th>
                      <th>Right answer</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="ques-id-serial">1</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">2</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">3</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">4</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">5</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">6</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">7</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      </td>
                    </tr>{' '}
                    <tr>
                      <td>
                        <span className="ques-id-serial">8</span>
                      </td>
                      <td>B</td>
                      <td>B</td>
                      <td>
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <StudentFooter />
    </div>
  );
};

export default ExamResult;
