import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, patchData, postData } from '../../helpers/backend_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addCourseFail,
  addCourseSuccess,
  getCourseFail,
  getCourseSuccess,
  getSingleCourseFail,
  getSingleCourseSuccess,
} from './actions';
// Crypto Redux States
import { ADD_COURSE, GET_COURSE, GET_SINGLE_COURSE } from './actionTypes';

function* onAddCourse({ payload: { data, token, history, id } }) {
  try {
    const url1 = `/update-course/${id}`;
    const url = '/create-course';
    const response = yield call(
      id ? patchData : postData,
      id ? url1 : url,
      data,
      token
    );
    if (response) {
      toaster('success', `${id ? 'Edit' : 'Create'} added successful`);
      history.push('/courses');
    }
    yield put(addCourseSuccess({}));
  } catch (err) {
    const message =
      err?.response?.data?.message || `${id ? 'Edit' : 'Create'} added failed`;
    toaster('error', message);
    yield put(addCourseFail(message));
  }
}

function* onGetCourse({ payload: { token } }) {
  try {
    const url = '/get-courses';
    const response = yield call(getData, url, token);
    yield put(getCourseSuccess(response?.getData));
  } catch (err) {
    const message = err?.response?.data?.message || 'Get Courses failed';
    toaster('error', message);
    yield put(getCourseFail(message));
  }
}

function* onGetSingleCourse({ payload: { token, id } }) {
  try {
    const url = `/get-single-course/${id}`;
    const response = yield call(getData, url, token);
    const packageUrl = `/package/get-course-And-Type-wise-packages/${id}`;
    const body = {
      courseTypeData: response.getData.courseType,
    };
    const packagesList = yield call(postData, packageUrl, body, token);
    yield put(
      getSingleCourseSuccess({
        courseType: response?.getData,
        packagesList: packagesList?.packageObj,
      })
    );
  } catch (err) {
    const message = err?.response?.data?.message || 'Get Course failed';
    toaster('error', message);
    yield put(getSingleCourseFail(message));
  }
}

function* courseSaga() {
  yield takeEvery(ADD_COURSE, onAddCourse);
  yield takeEvery(GET_COURSE, onGetCourse);
  yield takeEvery(GET_SINGLE_COURSE, onGetSingleCourse);
}

export default courseSaga;
