import {
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
  error: '',
  message: '',
};

const updatePass = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD:
      state = { ...state, loading: true };
      break;
    case RESET_PASSWORD:
      state = { ...state, loading: true };
      break;
    case UPDATE_PASSWORD_SUCCESS:
      state = { ...state, message: action.payload.message, loading: false };
      break;
    case UPDATE_PASSWORD_FAIL:
      state = { ...state, error: action.payload.message, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default updatePass;
