import { call, put, takeEvery } from "redux-saga/effects";
import { getData, postData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addSpeakingSetFail,
  addSpeakingSetSuccess,
  getSingleSpeakingSetsFail,
  getSingleSpeakingSetsSingleDayFail,
  getSingleSpeakingSetsSingleDaySuccess,
  getSingleSpeakingSetsSuccess,
  getSpeakingSetsFail,
  getSpeakingSetsSuccess,
} from "./actions";
//for main
import {
  ADD_SPEAKING_SET,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY,
  GET_SPEAKING_SETS,
  GET_SPEAKING_SET_SINGLE,
} from "./actionTypes";

function* workerGetSpeakingSets({
  payload: {
    token,
    options: { teacherId, startDate, endDate, role },
  },
}) {
  try {
    const url =
      role === "admin"
        ? `/admin/get-time-slot/${teacherId}?startDate=${startDate}&endDate=${endDate}`
        : `/teacher/get-time-slot/?startDate=${startDate}&endDate=${endDate}`;
    const response = yield call(getData, url, token);
    yield put(getSpeakingSetsSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get all slice fail";
    toaster("error", message);
    yield put(getSpeakingSetsFail(message));
  }
}

function* workerGetSingleSpeakingSets({
  payload: {
    token,
    options: { date },
  },
}) {
  try {
    const url = `/student/get-calendar-fullfiled-or-not/${date}`;
    const response = yield call(getData, url, token);
    yield put(getSingleSpeakingSetsSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail";
    toaster("error", message);
    yield put(getSingleSpeakingSetsFail(message));
  }
}

function* workerAddSpeakingSet({
  payload: {
    values,
    options: { history, token },
  },
}) {
  try {
    const url = `/student/pick-meeting-time`;
    const response = yield call(postData, url, values, token);
    yield put(addSpeakingSetSuccess(response?.getData));
    toaster("success", response.message || `Set time Successful`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(addSpeakingSetFail(message));
  }
}

function* onGetSingleSpeakingSetSingleDay({
  payload: {
    token,
    options: { teacherId, date: searchDate, role },
  },
}) {
  try {
    const url =
      role === "admin"
        ? `/admin/get-single-day/${teacherId}?searchDate=${searchDate}`
        : `/teacher/get-single-day/?searchDate=${searchDate}`;
    const response = yield call(getData, url, token);
    yield put(getSingleSpeakingSetsSingleDaySuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get all slice fail";
    toaster("error", message);
    yield put(getSingleSpeakingSetsSingleDayFail(message));
  }
}

function* speakingSaga() {
  yield takeEvery(ADD_SPEAKING_SET, workerAddSpeakingSet);
  yield takeEvery(GET_SPEAKING_SETS, workerGetSpeakingSets);
  yield takeEvery(GET_SPEAKING_SET_SINGLE, workerGetSingleSpeakingSets);
  yield takeEvery(
    GET_SINGLE_SPEAKING_SET_SINGLEDAY,
    onGetSingleSpeakingSetSingleDay
  );
}
export default speakingSaga;
