import React from "react";

const ListeningIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99729 0.693359C3.13903 0.711273 0 3.86473 0 7.72283C0 8.89451 0.293759 10.0496 0.84926 11.0731V9.21459C0.84926 8.87206 0.971174 8.55435 1.18488 8.28179C1.16719 8.09644 1.15808 7.90989 1.15808 7.72283C1.15808 4.50029 3.77991 1.86643 6.99729 1.85146C10.22 1.86638 12.8418 4.50029 12.8418 7.72283C12.8418 7.93 12.8303 8.13644 12.8087 8.34148C12.9944 8.6003 13.0993 8.89686 13.0993 9.21459V11.1673C13.6884 10.1208 14 8.93049 14 7.72283C14 3.86473 10.861 0.711273 6.99729 0.693359Z"
        fill="#0052CC"
      />
      <path
        d="M1.27969 8.91481C1.25012 9.01169 1.23438 9.11206 1.23438 9.21495V11.0062V11.8399C1.23438 12.65 2.19922 13.3068 3.38949 13.3068H4.29688V7.74805H3.38949C2.35037 7.74805 1.48327 8.24857 1.27969 8.91481Z"
        fill="#0052CC"
      />
      <path
        d="M12.7149 11.8399V10.977V9.21495C12.7149 9.15172 12.7083 9.08948 12.6969 9.02835C12.5621 8.30632 11.657 7.74805 10.5598 7.74805H9.65234V13.3068H10.5598C11.75 13.3068 12.7149 12.65 12.7149 11.8399Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default ListeningIcon;
