import React from "react";

const GraphMainDataPart = ({ studentPerformance, serviceName }) => {
  console.log("studentPerformance", studentPerformance, serviceName);
  const handleBarHeight = (data) => {
    switch (data) {
      case 0:
        return "190px";
      case 1:
        return "190px";
      case 1.5:
        return "190px";
      case 2:
        return "190px";
      case 2.5:
        return "190px";

      case 3:
        return "190px";
      case 3.5:
        return "190px";
      case 4:
        return "190px";
      case 4.5:
        return "190px";
      case 5:
        return "190px";
      case 5.5:
        return "190px";
      case 6:
        return "190px";
      case 6.5:
        return "190px";
      case 7:
        return "190px";
      case 7.5:
        return "228px";
      case 8:
        return "266px";
      case 8.5:
        return "304px";
      default:
        return "0px";
    }
  };
  const handleBarColor = (data) => {
    switch (data) {
      case 0:
        return "#D61818";
      case 1:
        return "#D61818";
      case 1.5:
        return "#D61818";
      case 2:
        return "#D61818";
      case 2.5:
        return "#D61818";
      case 3:
        return "#D61818";
      case 3.5:
        return "#D61818";
      case 4:
        return "#D61818";
      case 4.5:
        return "#D61818";
      case 5:
        return "#D61818";
      case 5.5:
        return "#D61818";
      case 6:
        return "#D61818";
      case 6.5:
        return "#D61818";
      case 7:
        return "#0052CC";
      case 7.5:
        return "#2FBB2C";
      case 8:
        return "#2FBB2C";
      case 8.5:
        return "#2FBB2C";
      default:
        return "red";
    }
  };
  const handleBarAddClass = (data) => {
    switch (data) {
      case 0:
        return "score-zero";
      case 1:
        return "score-one";
      case 1.5:
        return "score-one-half";
      case 2:
        return "score-two";
      case 2.5:
        return "score-two-half";
      case 3:
        return "score-three";
      case 3.5:
        return "score-three-half";
      case 4:
        return "score-four";
      case 4.5:
        return "score-four-half";
      case 5:
        return "score-five";
      case 5.5:
        return "five-point-five";
      case 6:
        return "score-six";
      case 6.5:
        return "six-point-five";
      case 7:
        return "score-saven";
      case 7.5:
        return "saven-point-five";
      case 8:
        return "score-eight";
      case 8.5:
        return "eight-point-five";
      default:
        return "0px";
    }
  };
  const handlePercentage = (data) => {
    if (data < 0) {
      return "";
    }
    if (data > 7) {
      let increase = data - 7;
      return Math.ceil((increase / 7) * 100) + " % more";
    }
    if (data < 7) {
      let decrease = 7 - data;
      return Math.ceil((decrease / 7) * 100) + " % less";
    }
    if (data === 7) {
      return "equal";
    }
  };
  return (
    <>
      <div className="student-exam-graph">
        {studentPerformance?.map((exam, idx) => (
          <>
            {serviceName === "Speaking" ? (
              <div
                className={`single-exam-data-bar `}
                style={{
                  height: handleBarHeight(exam?.score),
                  background: handleBarColor(exam?.score),
                }}
                key={idx}
              >
                <div
                  className={`score-position-average  ${handleBarAddClass(
                    exam?.score
                  )}`}
                >
                  <div className="hover-graph-mark-view">
                    <span>Score: {exam?.score}</span>
                  </div>

                  <span>{handlePercentage(exam?.score)}</span>
                </div>
                <h4>{exam?.date}</h4>
              </div>
            ) : (
              <div
                className={`single-exam-data-bar `}
                style={{
                  height: handleBarHeight(exam?.mark),
                  background: handleBarColor(exam?.mark),
                }}
                key={idx}
              >
                <div
                  className={`score-position-average  ${handleBarAddClass(
                    exam?.mark
                  )}`}
                >
                  <div className="hover-graph-mark-view">
                    <span>Score: {exam?.mark}</span>
                  </div>

                  <span>{handlePercentage(exam?.mark)}</span>
                </div>
                <h4>{exam?.date}</h4>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default GraphMainDataPart;
