import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import Loader from "../../../components/Atoms/Loader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { getBlogPost } from "../../../store/Dashboard/actions";
const BlogDetailsSinglePost = () => {
  const dispatch = useDispatch();
  const { blogList, loading, authToken } = useSelector((state) => ({
    loading: state.DashboardReducer.loading,
    blogList: state.DashboardReducer.blogList,
    authToken: state.Login.token,
  }));
  useEffect(() => {
    dispatch(getBlogPost(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };

  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="blog-post-single-post-right-sidebar">
          <div className="blog-details-sidebar-header-title">
            <h3>Popular Blog</h3>
          </div>
          {blogList?.length ? (
            blogList?.slice(0, 2).map((data, key) => (
              <Link to={`/blog-details/${data._id}`}>
                <div className="blog-details-single-card-item">
                  <div className="blog-image">
                    <img src={`${getPhotoUrl(data?.photo)}`} alt="" />
                  </div>
                  <div className="blog-card-bottom-content">
                    <h5>{data?.postTitle}</h5>
                    <TruncateMarkup>
                      <p>{data?.postDescription}</p>
                    </TruncateMarkup>

                    <hr />
                    <div className="post-author-single-post-info">
                      <div className="post-author-part-left">
                        <img
                          src={`${getPhotoUrl(data?.postBy?.photo)}`}
                          alt=""
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "25px",
                          }}
                        />
                        <span>{data?.postBy?.authorName}</span>
                      </div>
                      <div className="post-author-part-right">
                        <span>
                          {" "}
                          {moment(
                            data?.postBy?.updatedAt?.split("T")?.[0]
                          )?.format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <hr />
              </Link>
            ))
          ) : (
            <div>
              <p>Empty Blog List</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogDetailsSinglePost;
