export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAIL = "ADD_CART_FAIL";

export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";

export const STORE_CART = "STORE_CART";
