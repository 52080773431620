import React from "react";
import StudentFooterCommon from "../components/StudentFooter/StudentFooterCommon";
import StudentNavbar from "../components/StudentNavbar/StudentNavbar";

const Layout3 = ({ children }) => {
  return (
    <React.Fragment>
      <StudentNavbar />
      <div>{children}</div>
      <StudentFooterCommon />
    </React.Fragment>
  );
};

export default Layout3;
