import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { studentSliderData } from "./fackData/studentSlider";
import DoubleQuotesDown from "./icon/DoubleQuotesDown";
import DoubleQuotesUp from "./icon/DoubleQuotesUp";
import { SampleNextArrow } from "./icon/SliderImageArrowNext";
import { SamplePrevArrow } from "./icon/SliderImageArrowPrev";
import "./style/our-student-say.scss";
const OurStudentSay = () => {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    dots: true,
    speed: 500,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="our-student-say-full-area">
      <Container>
        <div className="our-student-say-area-title">
          <h3>What do our students say?</h3>
        </div>
        <div className="our-student-say-slider-area">
          <Slider {...settings}>
            {studentSliderData.map((slide, idx) => (
              <div className="our-student-single-slider" key={idx}>
                <DoubleQuotesDown />
                <DoubleQuotesUp />
                <div className="our-student-single-slide-inner-content">
                  <img src={slide?.image} alt="" />
                  <h2>{slide.title}</h2>
                  <p>{slide.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default OurStudentSay;
