import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Calendar } from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import {
  addSpeakingSet,
  addSpeakingSetSuccess,
  getSingleSpeakingSets,
} from "../../../store/Speaking/actions";
import confirmIcon from "./img/illustration.svg";
const timeSlice = [
  { time: 10, value: `10:00 - 10:30` },
  { time: 1030, value: `10:30 - 11:00` },
  { time: 11, value: `11:00 - 11:30` },
  { time: 1130, value: `11:30 - 12:00` },
  { time: 12, value: `12:00 - 12:30` },
  { time: 1230, value: `12:30 - 01:00` },
  { time: 1, value: `01:00 - 01:30` },
  { time: 130, value: `01:30 - 02:00` },
  { time: 2, value: `02:00 - 02:30` },
  { time: 230, value: `02:30 - 03:00` },
  { time: 3, value: `03:00 - 03:30` },
  { time: 330, value: `03:30 - 04:00` },
  { time: 4, value: `04:00 - 04:30` },
  { time: 430, value: `04:30 - 05:00` },
  { time: 5, value: `05:00 - 05:30` },
  { time: 530, value: `05:30 - 06:00` },
  { time: 6, value: `06:00 - 06:30` },
  { time: 630, value: `06:30 - 07:00` },
  { time: 7, value: `07:00 - 07:30` },
  { time: 730, value: `07:30 - 08:00` },
];

const PickTime = ({ history }) => {
  const { packageId } = useParams();
  const dispatch = useDispatch();
  const { authToken, loading, speakingSet, adding, speakingSuccess } =
    useSelector((state) => ({
      authToken: state.Login.token,
      loading: state.speakingSet.loading,
      adding: state.speakingSet.adding,
      speakingSet: state.speakingSet.speakingSet,
      set: state.speakingSet,
      speakingSuccess: state.speakingSet.speakingSuccess,
    }));

  const [value, onChange] = useState(new Date());
  const [changed, setOnChanged] = useState("");
  const [selectedTime, setSelectedTime] = useState({});

  useEffect(() => {
    dispatch(
      getSingleSpeakingSets(authToken, {
        date: moment(value).format("YYYY-MM-DD"),
      })
    );

    return () => {
      dispatch(addSpeakingSetSuccess({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed]);

  const handleChange = (value) => {
    setOnChanged(value);
    onChange(value);
  };
  const handleData = (data) => {
    setSelectedTime(data);
  };

  const handleAdd = () => {
    const data = {
      date: moment(value).format("YYYY-MM-DD"),
      meetingTime: selectedTime.time,
      packageId,
    };

    dispatch(addSpeakingSet(data, { history, token: authToken }));
  };
  const activeClass = (data) => {
    if (speakingSet?.[data.time] === 0) {
      return " disable";
    }
    // else
    // if (speakingSet?.[data.time] > 0) {
    //   return ' active';
    // }
    if (data.time === selectedTime.time) {
      return " selected";
    }
    return "";
  };
  return (
    <React.Fragment>
      <Container>
        <div className="speaking-main-container-wrap">
          <div className="header-top-button-title">
            <h4>
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back"></i>
              </span>
              <span>Select your Preferable Date</span>
            </h4>
          </div>
          {speakingSuccess?.teacherId ? (
            <div className="speaking-exam-date-bottom-wrap">
              <div className="speaking-package-listed-confirmation">
                <div>
                  <img src={confirmIcon} alt="icon" />
                  <h3>Confirm</h3>
                  <p>
                    Your exam date & time confirmed. Please attend your exam on{" "}
                    <br />
                    time following link. you find it on your exam page.
                    <br /> Your exam link :{" "}
                    <a href={speakingSuccess?.teacherId?.meetLink}>
                      {speakingSuccess?.teacherId?.meetLink}
                    </a>
                  </p>
                  <Link to="/my-package">My Package</Link>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {!changed ? (
                <div className="speaking-exam-main-wrap">
                  <div className="speaking-exam-date-bottom-wrap">
                    <Row className="justify-content-center">
                      <Col lg={6}>
                        <div className="speaking-exam-date-content-wrap">
                          <Calendar onChange={handleChange} value={value} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <div className="speaking-exam-main-wrap">
                  <div className="speaking-exam-date-bottom-wrap">
                    <Row className="justify-content-center">
                      <Col lg={6}>
                        <div className="speaking-exam-date-content-wrap">
                          <Calendar onChange={handleChange} value={value} />
                        </div>
                      </Col>{" "}
                      <Col lg={6} className="p-0">
                        {loading ? (
                          <div className="position-absolute w-100 text-center">
                            <Loader />
                          </div>
                        ) : null}
                        <div className="form-submit-main-time-wrap">
                          <div className="speaking-exam-selected-time mx-auto">
                            <Row className="mx-auto">
                              {timeSlice.map((item, index) => {
                                return (
                                  <Col
                                    lg={4}
                                    key={index}
                                    onClick={() => {
                                      if (speakingSet?.[item?.time] > 0) {
                                        handleData(item);
                                      }
                                    }}
                                    className={
                                      "speaking-selected-time-button m-1" +
                                      activeClass(item)
                                    }
                                  >
                                    {item.value}
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                          {selectedTime?.value ? (
                            <div className="time-selected-submit-btn">
                              <button
                                className="button"
                                disabled={adding}
                                onClick={handleAdd}
                              >
                                {adding ? "Submitting..." : "Confirm Time"}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(PickTime);
