import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { addAnswerData } from "../../helpers/backend_helper";
import { addAnswerFail, addAnswerSuccess } from "./actions";
//from pacakge type
import { ADD_QUESTION_ANSWER } from "./actionTypes";

function* AddQuestionAnswer({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addAnswerData, data, authtoken);

    yield put(addAnswerSuccess(response));

    toast("🦄 Answer Submitted successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //  history.push("/admindashboard")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Answer Submitted  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //   history.push("/IeltsSetList")
    } else {
      let message = error.response.data.message;
      yield put(addAnswerFail(message));

      toast.error(message);
    }
  }
}

// function* fetchAllPackages({payload: { authtoken }}) {
//   try {
//
//     const response = yield call(getPackageData, authtoken)
//
//     yield put(getPackagesSuccess(response.data))
//   } catch (error) {
//
//     yield put(getPackagesFail(error))
//   }
// }

// function* fetchSinglePackage({  payload: { authtoken}}) {
//   try {
//
//     const response = yield call(getPackageData, authtoken)
//
//     yield put(getIELTSReadingSinglePassageSuccess(response))
//   } catch (error) {
//
//     yield put(getIELTSReadingSinglePassageFail(error))
//   }
// }

function* AnswerSaga() {
  yield takeEvery(ADD_QUESTION_ANSWER, AddQuestionAnswer);
}

export default AnswerSaga;
