export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";

export const GET_SINGLE_SERVICE = "GET_SINGLE_SERVICE";
export const GET_SINGLE_SERVICE_SUCCESS = "GET_SINGLE_SERVICE_SUCCESS";
export const GET_SINGLE_SERVICE_FAIL = "GET_SINGLE_SERVICE_FAIL";

export const GET_USER_SERVICE = "GET_USER_SERVICE";
export const GET_USER_SERVICE_SUCCESS = "GET_USER_SERVICE_SUCCESS";
export const GET_USER_SERVICE_FAIL = "GET_USER_SERVICE_FAIL";

export const GET_USER_SERVICE_SET = "GET_USER_SERVICE_SET";
export const GET_USER_SERVICE_SET_SUCCESS = "GET_USER_SERVICE_SET_SUCCESS";
export const GET_USER_SERVICE_SET_FAIL = "GET_USER_SERVICE_SET_FAIL";

export const GET_LISTENING_SERVICE_SET = "GET_LISTENING_SERVICE_SET";
export const GET_LISTENING_SERVICE_SET_SUCCESS =
  "GET_LISTENING_SERVICE_SET_SUCCESS";
export const GET_LISTENING_SERVICE_SET_FAIL = "GET_LISTENING_SERVICE_SET_FAIL";

export const GET_WRITING_SERVICE_SET = "GET_WRITING_SERVICE_SET";
export const GET_WRITING_SERVICE_SET_SUCCESS =
  "GET_WRITING_SERVICE_SET_SUCCESS";
export const GET_WRITING_SERVICE_SET_FAIL = "GET_WRITING_SERVICE_SET_FAIL";

export const GET_SPEAKING_SERVICE_SET = "GET_SPEAKING_SERVICE_SET";
export const GET_SPEAKING_SERVICE_SET_SUCCESS =
  "GET_SPEAKING_SERVICE_SET_SUCCESS";
export const GET_SPEAKING_SERVICE_SET_FAIL = "GET_SPEAKING_SERVICE_SET_FAIL";

export const STORE_SERVICE_DATA = "STORE_SERVICE_DATA";
