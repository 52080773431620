import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5574 37.9642L42.4349 45.0742C42.6599 46.9417 40.6574 48.2467 39.0599 47.2792L31.2749 42.6442C30.7349 42.3292 30.5999 41.6542 30.8924 41.1142C32.0174 39.0442 32.6249 36.7042 32.6249 34.3642C32.6249 26.1292 25.5599 19.4242 16.8749 19.4242C15.0974 19.4242 13.3649 19.6942 11.7449 20.2342C10.9124 20.5042 10.1024 19.7392 10.3049 18.8842C12.3524 10.6942 20.2274 4.59668 29.6324 4.59668C40.6124 4.59668 49.4999 12.8992 49.4999 23.1367C49.4999 29.2117 46.3724 34.5892 41.5574 37.9642Z"
        fill="white"
      />
      <path
        d="M29.25 34.3638C29.25 37.0413 28.26 39.5163 26.595 41.4738C24.3675 44.1738 20.835 45.9063 16.875 45.9063L11.0025 49.3938C10.0125 50.0013 8.7525 49.1688 8.8875 48.0213L9.45 43.5888C6.435 41.4963 4.5 38.1438 4.5 34.3638C4.5 30.4038 6.615 26.9163 9.855 24.8463C11.8575 23.5413 14.265 22.7988 16.875 22.7988C23.715 22.7988 29.25 27.9738 29.25 34.3638Z"
        fill="white"
      />
    </svg>
  );
};

export default ChatIcon;
