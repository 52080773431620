import React from "react";
const graphBgLavel = [
  {
    position: "8.5",
  },
  {
    position: "8.0",
  },
  {
    position: "7.5",
  },
  {
    position: "7.0",
    achivementScrore: "achivement-seven-plus",
  },
  {
    position: "6.5",
  },
  {
    position: "6.0",
  },
  {
    position: "5.5",
  },
  {
    position: "5.0",
  },
  {
    position: "0",
  },
];
const GraphBgPart = () => {
  return (
    <div className="graph-bg-part-main-wrap">
      {graphBgLavel.map((label, idx) => (
        <div className="graph-bg-part" key={idx}>
          <div
            className={
              label.achivementScrore
                ? `graph-bg-single-lavel achivement-seven-plus`
                : `graph-bg-single-lavel `
            }
          >
            <span>{label.position}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GraphBgPart;
