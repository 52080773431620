import { studentSliderData } from "../fackData/studentSlider";

export const SampleNextArrow = (props) => {
  const { onClick } = props;

  const prevarrowImage = props.currentSlide;
  const totalSlides = studentSliderData.length - props.currentSlide;
  return (
    <div className="next-arrow-image-circle-icon" onClick={onClick}>
      {totalSlides === 1 ? (
        <img src={studentSliderData[0]?.image} alt="" />
      ) : (
        <img src={studentSliderData[prevarrowImage + 1]?.image} alt="" />
      )}
    </div>
  );
};
