import {
  GET_LISTENING_SERVICE_SET,
  GET_LISTENING_SERVICE_SET_FAIL,
  GET_LISTENING_SERVICE_SET_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  GET_SPEAKING_SERVICE_SET,
  GET_SPEAKING_SERVICE_SET_FAIL,
  GET_SPEAKING_SERVICE_SET_SUCCESS,
  GET_USER_SERVICE_FAIL,
  GET_USER_SERVICE_SET,
  GET_USER_SERVICE_SET_FAIL,
  GET_USER_SERVICE_SET_SUCCESS,
  GET_USER_SERVICE_SUCCESS,
  GET_WRITING_SERVICE_SET,
  GET_WRITING_SERVICE_SET_FAIL,
  GET_WRITING_SERVICE_SET_SUCCESS,
  STORE_SERVICE_DATA,
} from "./actionTypes";

const INIT_STATE = {
  services: [],
  userService: [],
  error: {},
  loading: false,
  isLoading: false,
  userServiceLoading: true,
  listeningSet: {},
  writingSet: {},
  speakingSet: {},
};

const serviceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_SERVICE_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case GET_SERVICES:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICES_SUCCESS:
      const { data = [], selectedOwnService } = action.payload;

      const newData = [
        ...data,
        {
          serviceName: "One to one review",
          newPrice: 1000,
          price: 1000,
          quantity: 1,
          _id: "63527236236",
        },
      ];
      newData?.forEach((el) => {
        el.quantity = 1;
        el.newPrice =
          el?.serviceName === "One to one review" ? 1000 : el.price * 1;
        el.price =
          el?.serviceName === "One to one review" ? 1000 : el.price * 1;
      });
      const selectedFormatId = selectedOwnService
        ? selectedOwnService?.map((data) => data?._id)
        : [];
      const filterFormat = newData?.filter(
        (data) => !selectedFormatId?.includes(data?._id)
      );

      return {
        ...state,
        services: filterFormat,
        loading: false,
      };
    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case GET_USER_SERVICE_SUCCESS:
      return {
        ...state,
        userService: action.payload,
        userServiceLoading: false,
      };
    case GET_USER_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        userServiceLoading: false,
      };
    case GET_USER_SERVICE_SET:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SERVICE_SET_SUCCESS:
      return {
        ...state,
        error: "",
        services: action.payload.data,
        loading: false,
      };
    case GET_USER_SERVICE_SET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case GET_LISTENING_SERVICE_SET:
      return {
        ...state,
        loading: true,
      };
    case GET_LISTENING_SERVICE_SET_SUCCESS:
      return {
        ...state,
        error: "",
        listeningSet: action.payload.data,
        loading: false,
      };
    case GET_LISTENING_SERVICE_SET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    case GET_WRITING_SERVICE_SET:
      return {
        ...state,
        loading: true,
      };
    case GET_WRITING_SERVICE_SET_SUCCESS:
      return {
        ...state,
        error: "",
        writingSet: action.payload.data,
        loading: false,
      };
    case GET_WRITING_SERVICE_SET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    case GET_SPEAKING_SERVICE_SET:
      return {
        ...state,
        loading: true,
      };
    case GET_SPEAKING_SERVICE_SET_SUCCESS:
      return {
        ...state,
        error: "",
        speakingSet: action.payload.data,
        loading: false,
      };
    case GET_SPEAKING_SERVICE_SET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default serviceReducer;
