export const ADD_REVIEW = "ADD_REVIEW"
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS"
export const ADD_REVIEW_FAIL = "ADD_REVIEW_FAIL"

export const GET_ALL_ANSWER = "GET_ALL_ANSWER"
export const GET_ALL_ANSWER_SUCCESS = "GET_ALL_ANSWER_SUCCESS"
export const GET_ALL_ANSWER_FAIL = "GET_ALL_ANSWER_FAIL"

export const GET_PARTICULAR_SET = "GET_PARTICULAR_SET"
export const GET_PARTICULAR_SET_SUCCESS = "GET_PARTICULAR_SET_SUCCESS"
export const GET_PARTICULAR_SET_FAIL = "GET_PARTICULAR_SET_FAIL"
