import React from "react";
import noImage from "../../assets/images/noData.png";

const NoData = ({ children }) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-center my-3">
          <img src={noImage} alt="noData" />
          <p className="pt-1">{children}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoData;
