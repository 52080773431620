import moment from "moment";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Atoms/Loader";
import NoData from "../../../components/Common/NoData";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { getSingleBlog } from "../../../store/Dashboard/actions";
import DashboardFeedback from "../dashboardPart/DashboardFeedback";
import BlogDetailsSinglePost from "./BlogDetailsSinglePost";

const DashboardBlogBody = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { singleBlog, singleBlogLoading, authToken } = useSelector((state) => ({
    singleBlogLoading: state.DashboardReducer.singleBlogLoading,
    singleBlog: state.DashboardReducer.singleBlog,
    authToken: state.Login.token,
  }));

  useEffect(() => {
    if (id) {
      dispatch(getSingleBlog(authToken, id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };
  return (
    <>
      {singleBlogLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="content-new-body-main-content-items">
          <Row>
            <Col lg={"8"}>
              <div className="dashboard--new-main-content-area-wrap  blog-details-page">
                <img src={`${getPhotoUrl(singleBlog[0]?.photo)}`} alt="" />
                <div className="blog-details-content-body-area-wrap">
                  <h6>
                    Published date -{" "}
                    <b>
                      {moment(
                        singleBlog[0]?.updatedAt?.split("T")?.[0]
                      )?.format("DD MMM YYYY")}
                    </b>
                  </h6>
                  <h3>{singleBlog[0]?.postTitle}</h3>
                  {singleBlog[0]?.postDescription?.length ? (
                    singleBlog[0]?.postDescription?.map((data, key) => (
                      <p>{data}</p>
                    ))
                  ) : (
                    <div>
                      <NoData>
                        <p>Empty Blog List</p>
                      </NoData>
                    </div>
                  )}

                  <div className="post-author-profile-info-area">
                    <div className="profile-info-left">
                      <img
                        src={`${getPhotoUrl(singleBlog[0]?.postBy?.photo)}`}
                        alt=""
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "25px",
                        }}
                      />
                    </div>
                    <div className="profile-info-right">
                      <h4>{singleBlog[0]?.postBy?.authorName}</h4>
                      <p>{singleBlog[0]?.postBy?.authorPosition}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={"4"}>
              <aside>
                <div className="dashboard-right-sidebar-wrap single-post-details-page">
                  <BlogDetailsSinglePost />
                  <DashboardFeedback />
                </div>
              </aside>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default DashboardBlogBody;
