import React, { useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import addPackage from "../../assets/images/add-circle.svg";
import arrow from "../../assets/images/arrow-right.svg";
import packages from "../../assets/images/book.svg";
import image from "../../assets/images/Frame.svg";
import cart from "../../assets/images/Icon/cart.svg";
import logo from "../../assets/images/Icon/logo.svg";
import userAdd from "../../assets/images/user-add.svg";
import { imageBaseUrl } from "../../helpers/api_helper";
import { logoutUser } from "../../store/auth/login/actions";
import { getProfile } from "../../store/auth/profile/actions";
import { getCourse } from "../../store/Course/actions";
import { getCoupon } from "../../store/Package/actions";
import Clipboard from "./img/Clipboard";
import DraftPackIcon from "./img/DraftPackIcon";
import LogOutIcon from "./LogOutIcon";
import "./navbar.scss";
import NavBarDropdownSubMenu from "./NavBarDropdownSubMenu";

const StudentNavbar = ({ location }) => {
  ReactGA.initialize("AW-10942159544");
  const dispatch = useDispatch();
  const history = useHistory();
  const { card, token, profile } = useSelector((state) => ({
    username: state.Login.username,
    data: state.Login.data?.data,
    card: state.CardReducer.cart,
    token: state.Login.token,
    profile: state.Profile,
  }));
  const { data } = profile;

  const handleLogout = () => {
    dispatch(logoutUser(history, token));
  };
  const { course, loading } = useSelector((state) => ({
    loading: state.course.loading,
    course: state.course.data,
    // error: state.course.error,
  }));

  useEffect(() => {
    dispatch(getCourse(token));
    dispatch(getCoupon(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getProfile(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window?.scroll(0, 0);
  }, [location?.pathname]);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return image;
  };

  const [showMobile, setShowMobileMenu] = useState(false);
  const goToSignUP = () => {
    ReactGA.event({
      category: "Sign Up",
      action: "Sign Up Redirect",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  return (
    <React.Fragment>
      <div className="student-main-navbar-wrap">
        <Navbar>
          <Container>
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <NavLink to="/packages" className="nav-link">
                  Exam Packages
                </NavLink> */}
                <NavDropdown
                  title="Exam Packages"
                  id="basic-nav-dropdown"
                  className="dropdown-desktop-show-mobile-hide"
                >
                  <NavBarDropdownSubMenu loading={loading} course={course} />
                </NavDropdown>

                <div className="navbar-link-border mobile-hide-border" />
                <NavLink to="/cart" className="nav-link cart-navbar-button">
                  <img src={cart} alt="" />
                  <span>{card?.length || 0}</span>
                </NavLink>
                <div className="navbar-link-border" />
                {token === "" ? (
                  <React.Fragment>
                    <div className="mobile-menu-dropdown-area-show">
                      <div
                        className={
                          showMobile
                            ? "dropdown-mobile-menu-button-area show-mobile"
                            : "dropdown-mobile-menu-button-area"
                        }
                        onClick={() => setShowMobileMenu(!showMobile)}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <div
                        className={
                          showMobile
                            ? "drop-down-show-body-area body-content-show mobile_menu"
                            : "drop-down-show-body-area "
                        }
                      >
                        <NavLink
                          className="navbar-login-btn nav-link"
                          to="/login"
                        >
                          <div className="d-flex align-items-center gap-1 mobile-menu-single-item">
                            {showMobile ? <img src={arrow} alt="" /> : ""}

                            <span>Sign In</span>
                          </div>
                        </NavLink>
                        <NavLink
                          className="navbar-register-btn nav-link"
                          to="/signup"
                          onClick={goToSignUP}
                        >
                          <div className="d-flex align-items-center gap-1 mobile-menu-single-item">
                            {showMobile ? <img src={userAdd} alt="" /> : ""}

                            <span>Registration</span>
                          </div>
                        </NavLink>
                        <NavLink
                          className="nav-link mobile-area-show-package-link "
                          to="/packages/Ielts"
                        >
                          <div className="d-flex align-items-center gap-1 mobile-menu-single-item">
                            <img src={packages} alt="" />
                            <span>Exam Packages</span>
                          </div>
                        </NavLink>
                        <NavLink
                          className="nav-link mobile-area-show-package-link "
                          to="/create-package"
                        >
                          <div className="d-flex align-items-center gap-1 mobile-menu-single-item">
                            <img src={addPackage} alt="" />
                            <span>Create Package</span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="show-student-profile">
                    <span>
                      <img
                        src={`${getPhotoUrl(data?.getData?.getProfile?.photo)}`}
                        alt="profile"
                        style={{
                          borderRadius: "50%",
                          height: "30px",
                          objectFit: "cover",
                        }}
                      />
                      <span title={data?.getData?.getProfile?.fullName}>
                        {data?.getData?.getProfile?.fullName?.split(" ")?.length
                          ? data?.getData?.getProfile?.fullName
                              ?.split(" ")
                              .shift()
                          : data?.getData?.getProfile?.fullName}
                      </span>
                    </span>
                    <NavDropdown
                      title=""
                      className="profile-view-btn"
                      id="basic-nav-dropdown"
                    >
                      <ul className="mb-0">
                        <li>
                          <Link to="/dashboard">
                            <i className="bx bx-home"></i>
                            <span>Dashboard</span>
                          </Link>
                        </li>
                        <li
                          className="dropdown-mobile-show-desktop-hide"
                          style={{ position: "relative" }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="package-list-dropdown-for-mobile"
                            >
                              <i>
                                <Clipboard />
                              </i>
                              <span>Exam Packages</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <NavBarDropdownSubMenu
                                loading={loading}
                                course={course}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li>
                          <Link to="/my-package">
                            <i className="bx bx-book-alt"></i>
                            <span>My Packages</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/my-favorites-courses">
                            <i className="bx bx-bookmark"></i>
                            <span>Favorite Courses</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-package">
                            <i className="bx bx-plus-circle"></i>
                            <span>Create Package</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/draft-package"
                            className="svg-nav-icon-dropdown"
                          >
                            <DraftPackIcon />
                            <span>Draft Packages</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/leaderboard">
                            <i className="bx bx-lock"></i>
                            <span>Leaderboard</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/profile">
                            <i className="bx bx-user-circle"></i>
                            <span>Profile</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-password">
                            <i className="bx bx-lock"></i>
                            <span>Password Change</span>
                          </Link>
                        </li>
                        <NavDropdown.Divider />
                        <li onClick={() => handleLogout()}>
                          <Link
                            to={"#"}
                            className="svg-nav-icon-dropdown log-out-btn"
                          >
                            <LogOutIcon />
                            <span>Logout</span>
                          </Link>
                        </li>
                      </ul>
                    </NavDropdown>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StudentNavbar);
