import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Modal } from "reactstrap";
import clock from "../../assets/images/Icon/clock.svg";
import star from "../../assets/images/Icon/star.svg";
import IetlsImage from "../../assets/images/ielts-logo.jpg";
import Loader from "../../components/Atoms/Loader";
import AddLoader from "../../components/Common/AddLoader";
import { imageBaseUrl } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import { addCart } from "../../store/cart/actions";
import { getPackages } from "../../store/Package/actions";
import { addWishList, getWishList } from "../../store/Wishlist/actions";
import "./student-package.scss";

const IeltsAcademicPackage = () => {
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });
  const dispatch = useDispatch();
  const { allPackage, authtoken, loading, wishList, adding, id } = useSelector(
    (state) => ({
      allPackage: state.PackageReducer.allPackage,
      authtoken: state.Login.token,
      loading: state.PackageReducer.packageLoading,
      id: state.Login.id,
      wishList: state.wishlist.wishList,
      adding: state.wishlist.adding,
    })
  );

  useEffect(() => {
    dispatch(getPackages(authtoken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wishListId = wishList.map((data) => data?.package?._id);

  useEffect(() => {
    if (id) {
      dispatch(getWishList(authtoken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddWishlist = (data) => {
    if (id) {
      if (wishListId.includes(data?._id)) {
        const findWishlistId = wishList.find(
          (list) => list?.package?._id === data?._id
        );
        dispatch(addWishList(authtoken, findWishlistId?._id, true));
      } else {
        dispatch(addWishList(authtoken, data?._id));
      }
    } else {
      toaster("warning", "Please login for add to wishlist");
    }
  };

  const handleStore = (data) => {
    dispatch(addCart(data));
  };

  const handleRemove = () => {
    dispatch(addWishList(authtoken, modal.id, true));
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return IetlsImage;
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Ielts academic package</title>
      </MetaTags>
      <Modal
        isOpen={modal.open}
        centered
        toggle={() => setModal({ open: false, id: "" })}
      >
        <div className="p-4 w-100">
          <div>
            <h6>Are you sure you want to remove from wishlist!!</h6>
            <div className="d-flex ">
              <button
                disabled={adding}
                onClick={handleRemove}
                className="btn btn-sm btn-primary"
              >
                Yes, Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {adding ? <AddLoader /> : null}
      <Container>
        <div className="ieltspackage-main-wrap-all-package pb-5">
          <div className="package-area-title-wrap all-package">
            <div className="package-title-area-left-part">
              <h4>IELTS Academic Package</h4>
              <p>Get the best one from the lot</p>
            </div>
            {/* <div className="package-title-area-right-part">
              <Link to="/ielts-academic-package">See all package</Link>
            </div> */}
          </div>
          <Row>
            {loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              (allPackage.AllPackages || []).map((data, key) => {
                return (
                  <Col lg={4} key={key}>
                    <div className="student-courses-card">
                      <Card>
                        <div
                          className="bookmark-button-wrap position-absolute"
                          style={{
                            background: "#fff",
                            padding: "5px",
                            lineHeight: "5px",
                            borderRadius: "5px",
                            top: "2%",
                            right: "2%",
                            fontSize: "18px",
                          }}
                          onClick={() => handleAddWishlist(data)}
                        >
                          {wishListId.includes(data?._id) ? (
                            <i
                              className="bx bxs-bookmark"
                              style={{ color: "#004AB8" }}
                            ></i>
                          ) : (
                            <i
                              className="bx bx-bookmark"
                              style={{ color: "#004AB8" }}
                            ></i>
                          )}
                        </div>
                        <Card.Img
                          variant="top"
                          src={`${getPhotoUrl(data?.photo)}`}
                        />
                        <Card.Body>
                          <div className="student-courses-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <span> 5.00(5)</span>
                          </div>
                          <Link
                            to={`/package-details/${data._id}`}
                            className="student-courses-review-package-details-btn"
                          >
                            <Card.Title>{data.packageName}</Card.Title>
                          </Link>

                          <Card.Text className="student-courses-timeline ">
                            <img src={clock} alt="" />
                            Duration - {data.packageLength} Days
                          </Card.Text>
                          <div className="student-courses-card-button">
                            {/* <Link to={`/cart-page/${data._id}`}> */}
                            <Button onClick={() => handleStore(data)}>
                              Add to cart
                            </Button>
                            {/* </Link> */}
                            <p>
                              {data?.discount > 0 ? (
                                <div>
                                  TK. <del>{data.price}</del>{" "}
                                  <b>{data?.discount}</b>
                                </div>
                              ) : (
                                <b>TK. {data.price}</b>
                              )}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })
            )}
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default IeltsAcademicPackage;
