import { useEffect, useRef, useState } from "react";

const useAudioPlayer = (endCallBack) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [pauseTime, setPauseTime] = useState(null);
  const [mute, setMute] = useState(false);

  // references
  const audioPlayer = useRef(null); // reference our audio component
  const progressBar = useRef(null); // reference our progress bar
  const animationRef = useRef(); // reference the animation
  // console.log(`audioPlayer`, audioPlayer);

  // handle time jumps
  const timeJump = (time) => {
    timeTravel(time);
    setIsPlaying(true);
    play();
  };

  const handlePlay = (data) => {
    timeJump(data?.startTime);
    setPauseTime(data?.endTime);
  };
  // controlled pause time
  useEffect(() => {
    if (!!pauseTime) {
      if (currentTime === pauseTime) {
        togglePlayPause();
        console.log("playback2");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  // setPause time
  const handlePauseTime = (value) => {
    setPauseTime(value);
  };

  // grabs the loaded metadata
  useEffect(() => {
    if (audioPlayer.current?.duration) {
      const seconds = Math.floor(audioPlayer.current?.duration);
      setDuration(seconds);
      console.log("playback3");
      progressBar.current.max = seconds?.toString();
    }
  }, [
    audioPlayer?.current?.onloadedmetadata,
    audioPlayer?.current?.readyState,
  ]);

  // when you get to the end
  useEffect(() => {
    if (Number(duration) > 1 && Number(currentTime) === Number(duration)) {
      console.log("playback");
      togglePlayPause();
      timeTravel(0);
      endCallBack && endCallBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, duration]);

  const play = () => {
    audioPlayer.current?.play();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const toggleMute = () => {
    setMute(!mute);
    audioPlayer.current.muted = !audioPlayer.current.muted;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      play();
    } else {
      audioPlayer.current?.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const reinitialize = () => {
    timeTravel(0);
    setIsPlaying(false);
    audioPlayer.current?.pause();
    cancelAnimationFrame(animationRef.current);
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current
      ? String(audioPlayer.current?.currentTime || 0)
      : "0";
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = Number(progressBar.current?.value);
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(progressBar.current?.value) / duration) * 100}%`
    );
    setCurrentTime(Number(progressBar.current?.value));
  };

  const backward = (time) => {
    timeTravel(Number(progressBar.current?.value) - time);
  };

  const forward = (time) => {
    timeTravel(Number(progressBar.current?.value) + time);
  };

  const timeTravel = (newTime) => {
    progressBar.current.value = String(newTime);
    changeRange();
  };

  return {
    isPlaying,
    duration,
    currentTime,
    audioPlayer,
    progressBar,
    mute,
    togglePlayPause,
    changeRange,
    backward,
    forward,
    timeTravel,
    setDuration,
    setIsPlaying,
    toggleMute,
    play,
    reinitialize,
    handlePlay,
    handlePauseTime,
    timeJump,
  };
};

export { useAudioPlayer };
