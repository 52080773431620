import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "reactstrap";

const FillGaps = ({
  questions,
  handleOnChange,
  ans,
  questionNumber,
  view,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const fillTab = (value) => {
    let displayText = _clone(value);
    const tags = value?.match(/\t/gi) || [];
    if (tags?.length) {
      tags.forEach((myTag, idx) => {
        displayText = displayText?.replace(
          new RegExp(_escapeRegExp(myTag), "gi"),
          `<i style="padding-left : 5px"></i>`
        );
      });
    }
    return displayText;
  };
  const replaceGap = (word, serial, list) => {
    const word2 = fillTab(word);
    const newWord = word2.split("$gap$");

    const [fist, ...rest] = newWord?.[0].split("<p>");
    return (
      <div className="question-fill-inThe-gap-wrap">
        <span dangerouslySetInnerHTML={{ __html: fist }}></span>
        <p>
          {rest
            ? rest?.map((data, idx) => (
                <span
                  dangerouslySetInnerHTML={{ __html: data }}
                  key={idx}
                ></span>
              ))
            : null}
          {/* {newWord?.[0]} */}
          <span className="checkbox-ques-serial-no d-inline">
            <span className="d-inline">({serial})</span>
          </span>
          <input
            type="text"
            className="gapsInput"
            disabled={view}
            style={{
              border: "0",
              borderBottom: "2px dashed",
              maxWidth: "70px",
              display: "inline-block",
            }}
            onChange={(e) =>
              handleOnChange(e, list, serial, null, null, "fill")
            }
            value={ans?.[serial]?.answer?.[0] || ""}
          />
          {/* {newWord?.[newWord?.length - 1]} */}
          <span
            dangerouslySetInnerHTML={{ __html: newWord?.[newWord?.length - 1] }}
          ></span>
        </p>
      </div>
    );
  };

  const findQuestionsNumberRange = `${
    questions?.[0]?.questionNumber + questionNumber
  }-
  ${questions?.[questions?.length - 1]?.questionNumber + questionNumber}`;

  const [viewExplain, setViewExplain] = useState("");
  const [toggle2, setToggle2] = useState();
  return (
    <React.Fragment>
      <div className="ques-serial">
        <div className="ques-sl-no-main">
          <h5>Question {findQuestionsNumberRange}</h5>
          {!view ? (
            <div className="notepade-area-wrap-write-text">
              <div
                className="student-note-btn mt-3"
                onClick={() => setToggle2(!toggle2)}
              >
                {toggle2 ? (
                  <p>
                    <i className="fa-solid fa-pen"></i>
                    hide Notepad
                  </p>
                ) : (
                  <p>
                    <i className="fa-solid fa-pen"></i>
                    Show Notepad
                  </p>
                )}
              </div>
              <Form
                className={
                  toggle2
                    ? "navbar_mobile_menu show my-2"
                    : "navbar_mobile_menu hide my-2"
                }
              >
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    value={notePad?.[newIdx]}
                    onChange={(e) => handleChangeNotePad(e, newIdx)}
                    rows={3}
                  />
                </Form.Group>
              </Form>
            </div>
          ) : null}
        </div>
        <div className="sub-title-header">
          <p>
            Complete the summary below. Choose one word only from the passage
            for each answer. Write your answers in boxes{" "}
            {findQuestionsNumberRange} on your answer sheet.
          </p>
        </div>
        <div className="">
          {questions?.map((list, idx) => (
            <React.Fragment key={list?.questionNumber + questionNumber}>
              <span
                key={list.questionNumber + questionNumber}
                id={`question${list.questionNumber + questionNumber}`}
              >
                {replaceGap(list.q, list.questionNumber + questionNumber, list)}
              </span>
              {view ? (
                <React.Fragment>
                  <div className="d-flex align-items-center py-2">
                    <span>
                      Answer :{" "}
                      <strong>
                        {ans?.[
                          list.questionNumber + questionNumber
                        ]?.answer?.map(
                          (data, idx) => `${data}  ${idx === 1 ? "" : ","}`
                        )}
                      </strong>
                    </span>
                    <div>
                      <Button
                        type="button"
                        onClick={() => {
                          if (
                            viewExplain !==
                            `view${list.questionNumber + questionNumber}`
                          ) {
                            setViewExplain(
                              `view${list.questionNumber + questionNumber}`
                            );
                          } else {
                            setViewExplain("");
                          }
                        }}
                        style={{
                          background: "#cce1ff",
                          color: "#485ec4",
                          border: "none",
                        }}
                        className="btn btn-sm m-1 gapsInput"
                      >
                        {viewExplain ===
                        `view${list.questionNumber + questionNumber}`
                          ? "Hide"
                          : "See Explanation"}
                      </Button>
                    </div>
                    {ans?.[list.questionNumber + questionNumber]?.mark === 1 ? (
                      <span className="correct-ans">
                        <i className="bx bx-check"></i>
                      </span>
                    ) : (
                      <span className="false-ans">
                        <i className="bx bx-x"></i>
                      </span>
                    )}
                  </div>
                  {viewExplain ===
                  `view${list.questionNumber + questionNumber}` ? (
                    <div className="border rounded-2 p-3 my-2">
                      {list?.explanation}
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FillGaps;
