import { call, put, takeEvery } from "redux-saga/effects";
import { getData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  getListeningServiceSetFail,
  getListeningServiceSetSuccess,
  getServiceFail,
  getServiceSuccess,
  getSpeakingServiceSetFail,
  getSpeakingServiceSetSuccess,
  getUserServiceFail,
  getUserServiceSetFail,
  getUserServiceSetSuccess,
  getUserServiceSuccess,
  getWritingServiceSetFail,
  getWritingServiceSetSuccess,
} from "./actions";
import {
  GET_LISTENING_SERVICE_SET,
  GET_SERVICES,
  GET_SPEAKING_SERVICE_SET,
  GET_USER_SERVICE,
  GET_USER_SERVICE_SET,
  GET_WRITING_SERVICE_SET,
} from "./actionTypes";

function* fetchServices({ payload: { authtoken, selectedOwnService } }) {
  try {
    const url = "/service/getallservices";
    const response = yield call(getData, url, authtoken);
    yield put(getServiceSuccess(response?.getDatas || [], selectedOwnService));
  } catch (error) {
    const message = error?.response?.data?.message || "get service failed";
    yield put(getServiceFail(message));
    toaster("error", message);
  }
}
function* fetchUserServices({ payload: { authtoken, packageID } }) {
  try {
    const url = `/package/get-service-of-package/${packageID}`;
    const response = yield call(getData, url, authtoken);

    yield put(getUserServiceSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "get service failed";
    yield put(getUserServiceFail(message));
    toaster("error", message);
  }
}

function* fetchServicesSet({
  payload: { authtoken, serviceId, number, packageId, type, courseId },
}) {
  try {
    const url =
      packageId === "63c390c26209820007f8f1f8" ||
      packageId === "63c39485c9adcc0008d22c66"
        ? `/package/get-reading-passages-trailer-under-reading-service/${serviceId}/${packageId}/${number}/${courseId}`
        : `/package/get-reading-passages-under-reading-service/${serviceId}/${packageId}/${number}/${courseId}${
            type ? "?ownPackage=true" : ""
          }`;
    const response = yield call(getData, url, authtoken);
    yield put(getUserServiceSetSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get service set failed";
    yield put(getUserServiceSetFail(message));
    toaster("error", message);
  }
}

function* fetchListeningServicesSet({
  payload: { authtoken, serviceId, number, packageId, type },
}) {
  try {
    const url =
      packageId === "63c390c26209820007f8f1f8" ||
      packageId === "63c39485c9adcc0008d22c66"
        ? `/package/get-listening-trailer-set-under-listening-service/${serviceId}/${packageId}/${number}`
        : `/package/get-listening-set-under-listening-service/${serviceId}/${packageId}/${number}${
            type ? "?ownPackage=true" : ""
          }`;
    const response = yield call(getData, url, authtoken);
    yield put(getListeningServiceSetSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get listening service set failed";
    yield put(getListeningServiceSetFail(message));
    toaster("error", message);
  }
}

function* fetchWritingServicesSet({
  payload: { authtoken, serviceId, number, packageId, type, courseId },
}) {
  try {
    const url = `/package/get-writting-set-under-writting-service/${serviceId}/${packageId}/${number}/${courseId}${
      type ? "?ownPackage=true" : ""
    }`;
    const response = yield call(getData, url, authtoken);
    yield put(getWritingServiceSetSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Writing service set failed";
    yield put(getWritingServiceSetFail(message));
    toaster("error", message);
  }
}

function* fetchSpeakingServicesSet({
  payload: { authtoken, serviceId, number, type },
}) {
  try {
    const url = `/student/get-speaking-set-under/${serviceId}${
      type ? "?ownPackage=true" : ""
    }`;
    const response = yield call(getData, url, authtoken);
    yield put(getSpeakingServiceSetSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Speaking service set failed";
    yield put(getSpeakingServiceSetFail(message));
    toaster("error", message);
  }
}

function* ServiceSaga() {
  yield takeEvery(GET_SERVICES, fetchServices);
  yield takeEvery(GET_USER_SERVICE_SET, fetchServicesSet);
  yield takeEvery(GET_LISTENING_SERVICE_SET, fetchListeningServicesSet);
  yield takeEvery(GET_WRITING_SERVICE_SET, fetchWritingServicesSet);
  yield takeEvery(GET_SPEAKING_SERVICE_SET, fetchSpeakingServicesSet);
  yield takeEvery(GET_USER_SERVICE, fetchUserServices);
}

export default ServiceSaga;
