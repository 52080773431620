import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { postData } from "../../../helpers/backend_helper";
//Include Both Helper File with needed methods
import {
  newRegisterUserFailed,
  newRegisterUserSuccessful,
  registerUserFailed,
  registerUserSuccessful,
} from "./actions";
//Account Redux states
import { NEW_REGISTER_USER, REGISTER_USER } from "./actionTypes";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const url = `/signup`;
    const registerResponse = yield call(postData, url, user);
    if (registerResponse.status === "success") {
      yield put(
        registerUserSuccessful(
          "Account registered successful. Check your email for verify account"
        )
      );
      setTimeout(() => {
        history.push("/check-email");
      }, 2000);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Registration Failed!!";
    yield put(registerUserFailed(message));
  }
}
function* newRegisterUser({ payload: { user, history } }) {
  try {
    const url = `student-crm/signup`;
    const registerResponse = yield call(postData, url, user);
    if (registerResponse.status === "success") {
      yield put(
        newRegisterUserSuccessful(
          "Account registered successful. Check your email for verify account"
        )
      );
      setTimeout(() => {
        history.push("/check-email");
      }, 2000);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Registration Failed!!";
    yield put(newRegisterUserFailed(message));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}
export function* watchNewUserRegister() {
  yield takeEvery(NEW_REGISTER_USER, newRegisterUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
  yield all([fork(watchNewUserRegister)]);
}

export default accountSaga;
