import moment from "moment";
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import Loader from "../../components/Atoms/Loader";
import CardComponent from "../../components/Common/CardComponent";
import EmptyCommonComp from "../../components/Common/EmptyCommonComp";
import {
  getFacilitatorService,
  getVerifyFacilitator,
} from "../../store/Facilitator/actions";
import googleMeetIcon from "./img/Google_Meet.png";
const time = {
  10: `10:00 - 10:59`,
  11: `11:00 - 11:59`,
  12: `12:00 - 12:59`,
  1: `01:00 - 01:59`,
  2: `02:00 - 02:59`,
  3: `03:00 - 03:59`,
  4: `04:00 - 04:59`,
  5: `05:00 - 05:59`,
  6: `06:00 - 06:59`,
  7: `07:00 - 07:59`,
};

const FacilitatorService = ({ history }) => {
  const { id, isFacilatator } = useParams();

  const dispatch = useDispatch();
  const {
    authtoken,
    speakingSet,
    facilitatorServiceLoading,
    verifyFacilitator,
    facilitatorService,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    facilitatorServiceLoading: state.facilitator.facilitatorServiceLoading,
    speakingSet: state.facilitator.speakingSet,
    profile: state.Profile?.data,
    verifyFacilitator: state.facilitator.verifyFacilitator,
    facilitatorService: state.facilitator.facilitatorService,
  }));

  useEffect(() => {
    dispatch(getFacilitatorService(authtoken, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id && isFacilatator === "false") {
      dispatch(getVerifyFacilitator(authtoken, id));
    }
  }, [id, isFacilatator]);

  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Container>
          {(verifyFacilitator?.data?.getData === true ||
            isFacilatator === "true") &&
          facilitatorServiceLoading === false ? (
            <div>
              <div className="page-title-wrap">
                <div className="">
                  <h4>Facilitator Slot</h4>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    {/* <span>Time Expire</span> */}
                    {/* <span>30D : 24H : 58M : 56S</span> */}
                  </div>

                  <div>
                    <button
                      onClick={() =>
                        history.push(`/facilitator-time-set/${id}`)
                      }
                      className="btn btn-primary"
                      disabled={
                        !facilitatorService?.expire
                          ? facilitatorService?.getData?.length === 1
                          : facilitatorService?.getData?.length ===
                            facilitatorService?.expire
                      }
                    >
                      Set Time
                    </button>
                  </div>
                </div>
              </div>
              {facilitatorServiceLoading ? (
                <div className="text-center  pt-5">
                  <Loader />
                </div>
              ) : (
                <div className="single-package-inner-main-wrap">
                  <Row>
                    {facilitatorService?.getData?.length ? (
                      <>
                        {(facilitatorService?.getData || []).map(
                          (data, key) => (
                            <Col lg={4} md={6} key={key}>
                              <div className="card-active-deactive-content">
                                <div className="active-pending-top-right-massage">
                                  <div className="course-active-btn">
                                    <span className="color-success-text">
                                      Active
                                    </span>
                                  </div>
                                </div>
                                <CardComponent className={"text-center py-4"}>
                                  <div className="mx-auto">
                                    <img
                                      src={
                                        require(`../../components/Package/img/writing.svg`)
                                          .default
                                      }
                                      alt="examImage"
                                      className="mb-3"
                                    />
                                  </div>
                                  <div>
                                    <h4 className="mb-1">
                                      {time?.[data?.meetingTime]}
                                    </h4>
                                    <p className="mb-2">
                                      {moment(
                                        data?.date?.split("T")?.[0]
                                      )?.format("DD MMM YYYY")}
                                    </p>

                                    {data?.attandance !== 1 ? (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          history.push(
                                            `/facilitator-particular-slot/${data?._id}`
                                          )
                                        }
                                      >
                                        <span>
                                          <img src={googleMeetIcon} alt="" />
                                        </span>
                                        Google Meet
                                      </button>
                                    ) : (
                                      <button className="btn btn-primary">
                                        Already Attended
                                      </button>
                                    )}

                                    {/* </Link> */}
                                  </div>
                                </CardComponent>
                              </div>
                            </Col>
                          )
                        )}
                      </>
                    ) : (
                      <EmptyCommonComp
                        linkText={" Set Time"}
                        link={`/facilitator-time-set/${id}`}
                      >
                        Opps! There are no selected slot for you. To set <br />{" "}
                        the slot click{" "}
                      </EmptyCommonComp>
                    )}
                  </Row>
                </div>
              )}
            </div>
          ) : (
            <div>
              <EmptyCommonComp
                link={`/package-details/${id}`}
                linkText={" consult facilitator"}
              >
                You have no facilitator available{" "}
              </EmptyCommonComp>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(FacilitatorService);
