import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "reactstrap";
import "./questions.scss";
const ComplateQuesForm = ({
  questions,
  handleOnChange,
  ans,
  questionNumber,
  view,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const [viewExplain, setViewExplain] = useState("");
  const [toggle2, setToggle2] = useState();
  return (
    <React.Fragment>
      <div className="ques-right-part-container p-2 w-100">
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn"
              onClick={() => setToggle2(!toggle2)}
            >
              {toggle2 ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle2
                  ? "navbar_mobile_menu show my-2"
                  : "navbar_mobile_menu hide my-2"
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        <div className="ques-section-main-wrapper mb-5">
          <h5 className="mb-3" style={{ fontSize: "20px" }}>
            Complete the table below
          </h5>
          <h5 className="mb-3" style={{ fontSize: "20px" }}>
            Write <span className="text-danger">{questions?.answerType}</span>{" "}
            for each answer.
          </h5>
          <img
            style={{ width: "100%" }}
            src={questions?.questionDetails || ""}
            alt="img"
          />
          <div className="mt-5">
            {questions?.question?.map((list, idx) => (
              <React.Fragment key={list?._id}>
                <div className="complate-ques-table-single-data mb-3">
                  <span>{list.questionNumber + questionNumber}</span>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      // value={}
                      onChange={(e) =>
                        handleOnChange(
                          e,
                          list,
                          list.questionNumber + questionNumber,
                          null,
                          null,
                          "fill"
                        )
                      }
                      value={
                        ans?.[list.questionNumber + questionNumber]
                          ?.answer?.[0] || ""
                      }
                      style={{
                        borderColor: "#9E9E9E",
                        minWidth: "350px",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
                {view ? (
                  <div>
                    <div className="d-flex align-items-center py-2">
                      <span>
                        Answer :{" "}
                        <strong>
                          {ans?.[
                            list.questionNumber + questionNumber
                          ]?.answer?.map(
                            (data, idx) => `${data}  ${idx === 1 ? "" : ","}`
                          )}
                        </strong>
                      </span>
                      <div>
                        <Button
                          type="button"
                          onClick={() => {
                            if (
                              viewExplain !==
                              `view${list.questionNumber + questionNumber}`
                            ) {
                              setViewExplain(
                                `view${list.questionNumber + questionNumber}`
                              );
                            } else {
                              setViewExplain("");
                            }
                          }}
                          style={{
                            background: "#cce1ff",
                            color: "#485ec4",
                            border: "none",
                          }}
                          className="btn btn-sm m-1 gapsInput"
                        >
                          {viewExplain ===
                          `view${list.questionNumber + questionNumber}`
                            ? "Hide"
                            : "See Explanation"}
                        </Button>
                      </div>
                      {ans?.[list.questionNumber + questionNumber]?.mark ===
                      1 ? (
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      ) : (
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      )}
                    </div>
                    {viewExplain ===
                    `view${list.questionNumber + questionNumber}` ? (
                      <div className="border rounded-2 p-3 my-2">
                        {list?.explanation}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComplateQuesForm;
