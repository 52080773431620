import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Table } from "reactstrap";
// import { printToLetter } from '../../../helpers/custom/printNumberToLetter';

const list = ["True", "False", "NotGiven"];

const TrueFalseType = ({
  TrueFalseQuesType,
  handleOnChange,
  idx,
  questionNumber,
  ans,
  currentPassage,
  currentIndex,
  view,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const findQuestionsNumberRange = `${
    TrueFalseQuesType?.[0]?.questionNumber + questionNumber
  }-
  ${
    TrueFalseQuesType?.[TrueFalseQuesType?.length - 1]?.questionNumber +
    questionNumber
  }`;
  // const findPassageLength = `${printToLetter(1)} - ${printToLetter(
  //   currentPassage?.readingPessageDescription?.length
  // )}`;
  const [viewExplain, setViewExplain] = useState("");
  const [toggle, setToggle] = useState();
  return (
    <React.Fragment>
      <div className="ques-serial">
        <h5>Question {findQuestionsNumberRange}</h5>
        {/* edit form area wrap  */}
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle
                  ? "navbar_mobile_menu show my-2"
                  : "navbar_mobile_menu hide my-2"
              }
            >
              <Form.Group>
                <Form.Control
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        {/* edit form area wrap  */}
        <div className="sub-title-header">
          <p>
            Do the following statements agree with the writer's claims in
            Reading Passage {currentIndex + 1}?
            {/* Reading Passage {currentIndex + 1} has{' '}
            {currentPassage?.readingPessageDescription?.length} paragraphs,{' '}
            {findPassageLength}. */}
          </p>
          {/* <p>Which paragraph contains the following information?</p> */}
          <p>
            In boxes {findQuestionsNumberRange} on your answer sheet, write
            {/* Write the appropriate letter, {findPassageLength}, in boxes{' '}
            {findQuestionsNumberRange} on your answer sheet. */}
          </p>
        </div>
        <div className="my-4">
          <Table striped borderless>
            <tbody>
              <tr>
                <td>
                  <b>True</b>
                </td>
                <td>if the statement agrees with the information</td>
              </tr>
              <tr>
                <td>
                  <b>False</b>
                </td>
                <td>if the statement contradicts the information</td>
              </tr>
              <tr>
                <td>
                  <b>Not Given</b>
                </td>
                <td> If there is no information on this</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="ques-p-gap-main-wrap">
          {(TrueFalseQuesType || []).map((data, key) => {
            const number = data?.questionNumber + questionNumber;
            return (
              <div
                className="answer-checkbox-mainwrap"
                key={key}
                id={`question${number}`}
              >
                <div className="checkbox-ques-serial-no option-select-ans mb-2">
                  <div className="ques-sl-no-main">
                    <h5>{number}</h5>
                  </div>
                  <AvForm>
                    <AvField
                      aria-label="Default select example"
                      type="select"
                      className="form-select"
                      // value={data?.givenAnswer?.[0]}
                      disabled={view}
                      value={ans?.[number]?.answer?.[0]}
                      name={`question${data?.questionNumber}`}
                      onChange={(e) => handleOnChange(e, data, number)}
                    >
                      <option selected value="">
                        Select
                      </option>
                      {list?.map((ques, idx) => (
                        <option key={idx} value={ques}>
                          {ques}
                        </option>
                      ))}
                      {/* <option value="false">False</option> */}
                      {/* <option value="Not Given">Not Given</option> */}
                    </AvField>
                  </AvForm>
                  <p>{data.q}</p>
                </div>
                {view ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center py-2">
                      <span>
                        Answer :{" "}
                        <strong>
                          {ans?.[number]?.answer?.length
                            ? ans?.[number]?.answer?.map(
                                (data, idx) =>
                                  `${data}  ${idx === 1 ? "" : ","}`
                              )
                            : ""}
                        </strong>
                      </span>
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            if (viewExplain !== `view${number}`) {
                              setViewExplain(`view${number}`);
                            } else {
                              setViewExplain("");
                            }
                          }}
                          style={{
                            background: "transparent",
                            color: "#485ec4",
                            border: "1px solid #485ec4",
                          }}
                          className="btn btn-sm m-1 gapsInput"
                        >
                          {viewExplain === `view${number}`
                            ? "Hide"
                            : "See Explanation"}
                        </Button>
                      </div>
                      {ans?.[number]?.mark === 1 ? (
                        <span className="correct-ans">
                          <i className="bx bx-check"></i>
                        </span>
                      ) : (
                        <span className="false-ans">
                          <i className="bx bx-x"></i>
                        </span>
                      )}
                    </div>
                    {viewExplain === `view${number}` ? (
                      <div className="border rounded-2 p-3 my-2">
                        {data?.explanation}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrueFalseType;
