import {
  VERIFY_EMAILS,
  VERIFY_EMAILS_FAIL,
  VERIFY_EMAILS_SUCCESS,
} from './actionTypes';

export const verifyEmail = (values, history) => {
  return {
    type: VERIFY_EMAILS,
    payload: { values, history },
  };
};

export const verifyEmailSuccess = (message) => {
  return {
    type: VERIFY_EMAILS_SUCCESS,
    payload: {
      message,
    },
  };
};

export const verifyEmailFail = (message) => {
  return {
    type: VERIFY_EMAILS_FAIL,
    payload: {
      message,
    },
  };
};
