import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";

const IELTSTip = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>IELTS tip</title>
      </MetaTags>
      <Container>
        <div className="faq-main-area-wrap">
          <div className="faq-items-inner-area">
            <div className="accordion-area-title">
              <h4>IELTS Tips</h4>
            </div>
            <div className="faq-accordion-items-wrap ielts-body"></div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default IELTSTip;
