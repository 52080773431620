import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import sliderImage1 from "../img/create-package-slider.png";
import "./create-own-package-slider.scss";
const CreateOwnPackage = () => {
  return (
    <div>
      <div className="create-own-package-slider-area-wrap">
        <Container>
          <div className="create-package-slider-inner-area">
            <div className="create-package-single-slide">
              <Row>
                <Col lg="6">
                  <div className="create-package-slider-left-part">
                    <div>
                      {" "}
                      <h4>
                        Create your own <br /> package
                      </h4>
                      <p>
                        If you wish to purchase a combo package, <br /> you can
                        choose your own selections from this <br /> page and
                        purchase it.
                      </p>
                      <Link to="/create-package">Create Package</Link>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="create-package-slider-right-part">
                    <img src={sliderImage1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CreateOwnPackage;
