import {
  ADD_SPEAKING_SET,
  ADD_SPEAKING_SET_FAIL,
  ADD_SPEAKING_SET_SUCCESS,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL,
  GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS,
  GET_SPEAKING_SETS,
  GET_SPEAKING_SETS_FAIL,
  GET_SPEAKING_SETS_SUCCESS,
  GET_SPEAKING_SET_SINGLE,
  GET_SPEAKING_SET_SINGLE_FAIL,
  GET_SPEAKING_SET_SINGLE_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
  adding: false,
  message: '',
  speakingSuccess: {},
  error: '',
  speakingSet: {},
  speakingSets: [],
  singleDay: {},
};

const speakingSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPEAKING_SET:
      state = {
        ...state,
        adding: true,
      };
      break;
    case ADD_SPEAKING_SET_SUCCESS:
      state = {
        ...state,
        speakingSuccess: action.payload.message,
        adding: false,
      };
      break;
    case ADD_SPEAKING_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      };
      break;
    case GET_SPEAKING_SETS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SPEAKING_SETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        speakingSets: action.payload.values,
      };
      break;
    case GET_SPEAKING_SETS_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;
    case GET_SPEAKING_SET_SINGLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SPEAKING_SET_SINGLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        speakingSet: action.payload.values,
      };
      break;
    case GET_SPEAKING_SET_SINGLE_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    case GET_SINGLE_SPEAKING_SET_SINGLEDAY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleDay: action.payload.values,
      };
      break;
    case GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default speakingSetReducer;
