import {
  GET_LISTENING_SERVICE_SET,
  GET_LISTENING_SERVICE_SET_FAIL,
  GET_LISTENING_SERVICE_SET_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  GET_SPEAKING_SERVICE_SET,
  GET_SPEAKING_SERVICE_SET_FAIL,
  GET_SPEAKING_SERVICE_SET_SUCCESS,
  GET_USER_SERVICE,
  GET_USER_SERVICE_FAIL,
  GET_USER_SERVICE_SET,
  GET_USER_SERVICE_SET_FAIL,
  GET_USER_SERVICE_SET_SUCCESS,
  GET_USER_SERVICE_SUCCESS,
  GET_WRITING_SERVICE_SET,
  GET_WRITING_SERVICE_SET_FAIL,
  GET_WRITING_SERVICE_SET_SUCCESS,
  STORE_SERVICE_DATA,
} from "./actionTypes";

export const getService = (authtoken, selectedOwnService) => ({
  type: GET_SERVICES,
  payload: { authtoken, selectedOwnService },
});

export const getServiceSuccess = (data, selectedOwnService) => ({
  type: GET_SERVICES_SUCCESS,
  payload: { data, selectedOwnService },
});

export const getServiceFail = (error) => ({
  type: GET_SERVICES_FAIL,
  payload: error,
});
export const getUserService = (authtoken, packageID, type) => ({
  type: GET_USER_SERVICE,
  payload: { authtoken, packageID, type },
});

export const getUserServiceSuccess = (data) => ({
  type: GET_USER_SERVICE_SUCCESS,
  payload: data,
});

export const getUserServiceFail = (error) => ({
  type: GET_USER_SERVICE_FAIL,
  payload: error,
});

export const getUserServiceSet = (
  authtoken,
  serviceId,
  number,
  packageId,
  type,
  courseId
) => ({
  type: GET_USER_SERVICE_SET,
  payload: { authtoken, serviceId, number, packageId, type, courseId },
});

export const getUserServiceSetSuccess = (data) => ({
  type: GET_USER_SERVICE_SET_SUCCESS,
  payload: { data },
});

export const getUserServiceSetFail = (error) => ({
  type: GET_USER_SERVICE_SET_FAIL,
  payload: error,
});

export const getListeningServiceSet = (
  authtoken,
  serviceId,
  number,
  packageId,
  type
) => ({
  type: GET_LISTENING_SERVICE_SET,
  payload: { authtoken, serviceId, number, packageId, type },
});

export const getListeningServiceSetSuccess = (data) => ({
  type: GET_LISTENING_SERVICE_SET_SUCCESS,
  payload: { data },
});

export const getListeningServiceSetFail = (error) => ({
  type: GET_LISTENING_SERVICE_SET_FAIL,
  payload: error,
});

export const getWritingServiceSet = (
  authtoken,
  serviceId,
  number,
  packageId,
  type,
  courseId
) => ({
  type: GET_WRITING_SERVICE_SET,
  payload: { authtoken, serviceId, number, packageId, type, courseId },
});

export const getWritingServiceSetSuccess = (data) => ({
  type: GET_WRITING_SERVICE_SET_SUCCESS,
  payload: { data },
});

export const getWritingServiceSetFail = (error) => ({
  type: GET_WRITING_SERVICE_SET_FAIL,
  payload: error,
});

export const getSpeakingServiceSet = (authtoken, serviceId, type) => ({
  type: GET_SPEAKING_SERVICE_SET,
  payload: { authtoken, serviceId, type },
});

export const getSpeakingServiceSetSuccess = (data) => ({
  type: GET_SPEAKING_SERVICE_SET_SUCCESS,
  payload: { data },
});

export const getSpeakingServiceSetFail = (error) => ({
  type: GET_SPEAKING_SERVICE_SET_FAIL,
  payload: error,
});

export const storeServiceData = (name, data) => ({
  type: STORE_SERVICE_DATA,
  payload: { name, data },
});
