import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import getStartedImage from "./images/get-started.png";
import "./style/get-started.scss";
const GetReadyStarted = () => {
  const { id } = useSelector((state) => ({
    id: state.Login.id,
  }));
  return (
    <div className="get-ready-started-full-area">
      <Container>
        <div className="get-ready-started-inner-area">
          <Row>
            <Col lg="6" className="my-auto">
              <div className="get-started-image-left-area">
                <img
                  src={getStartedImage}
                  alt=""
                  className="desktop-get-stated-image"
                />
                <img
                  src={getStartedImage}
                  alt=""
                  className="mobile-get-stated-image"
                />
              </div>
            </Col>
            <Col lg="6" className="my-auto">
              <div className="get-started-content-right-area">
                <h2>
                  Are you ready <br /> to start?
                </h2>
                <p>
                  Take our packages to kickstart your perfect <br /> preparation
                  for IELTS & find strategies to <br /> pave the way to it.
                </p>
                {id ? (
                  <Link to={"/my-package"} className="get-stated-link-button">
                    Get Started <i className="bx bx-arrow-back"></i>
                  </Link>
                ) : (
                  <Link to={"/sampleSignup"} className="get-stated-link-button">
                    Get Started <i className="bx bx-arrow-back"></i>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default GetReadyStarted;
