import {
  UPDATE_PROFILE_PASS,
  UPDATE_PROFILE_PASS_ERROR,
  UPDATE_PROFILE_PASS_SUCCESS,
} from './actionTypes';

const initialState = {
  error: '',
  success: '',
  loading: false,
};

const updateProfilePassword = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_PASS:
      state = { ...state, loading: true };
      break;
    case UPDATE_PROFILE_PASS_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        error: '',
        loading: false,
      };
      break;
    case UPDATE_PROFILE_PASS_ERROR:
      state = {
        ...state,
        message: '',
        error: action.payload.message,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default updateProfilePassword;
