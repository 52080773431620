import moment from "moment";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import TruncateMarkup from "react-truncate-markup";
import { imageBaseUrl } from "../../helpers/api_helper";

import { getBlogPost } from "../../store/Dashboard/actions";
import "./style/new-home-article.scss";
const NewHomeArticle = () => {
  const dispatch = useDispatch();
  const { blogList, loading, authToken } = useSelector((state) => ({
    loading: state.DashboardReducer.loading,
    blogList: state.DashboardReducer.blogList,
    authToken: state.Login.token,
  }));
  useEffect(() => {
    dispatch(getBlogPost(authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    centerMode: true,
    speed: 3000,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className="new-home-article-full-area">
      <Container>
        <div className="new-home-article-content-wrap">
          <Row>
            <Col lg="6" className=" ">
              <div className="new-home-article-content-left">
                <h2>Get more tips and guidelines from the experts</h2>
                <p>
                  Our experienced instructors are also writing blogs to let you
                  know more IELTS tips & tricks to guide you towards success!
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div className="new-home-article-slider-area">
                <Slider {...settings}>
                  {blogList?.length ? (
                    blogList?.map((data, key) => (
                      <Link to={`/blog-details/${data._id}`} key={key}>
                        <div className="new-home-single-article-item">
                          <div className="new-home-single-article-item-top-image">
                            <img
                              src={`${getPhotoUrl(data?.photo)}`}
                              style={{ width: "100%" }}
                              alt=""
                            />
                          </div>
                          <div className="new-home-single-article-item-bottom-content">
                            <TruncateMarkup lines={1}>
                              <h5>{data?.postTitle}</h5>
                            </TruncateMarkup>

                            <TruncateMarkup lines={1}>
                              <div>
                                <p>{data?.postDescription}</p>
                              </div>
                            </TruncateMarkup>

                            <div className="new-home-single-article-item-bottom-profile">
                              <div className="profile-article-info">
                                <div className="profile-info-left">
                                  <img
                                    src={`${getPhotoUrl(data?.postBy?.photo)}`}
                                    alt=""
                                  />
                                  <p>{data?.postBy?.authorName}</p>
                                </div>
                                <div className="article-publish-date">
                                  <p>
                                    {" "}
                                    {moment(
                                      data?.postBy?.createdAt?.split("T")?.[0]
                                    )?.format("DD MMM YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div>
                      <p>Empty Blog List</p>
                    </div>
                  )}
                </Slider>
                {/* <div className="new-article-all-post-button-bottom">
                  <button>
                    Read All
                    <i class="bx bx-right-arrow-alt"></i>
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default NewHomeArticle;
