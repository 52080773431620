/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useParams, withRouter } from "react-router-dom";
import Loader from "../../../components/Atoms/Loader";
import LeftSide from "../../../components/LiteningExam/LeftSide";
import QuesFooter from "../../../components/LiteningExam/QuesFooter";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { useAudioPlayer } from "../../../Hooks/AudioPlayerHooks";
import {
  getListeningQues,
  resetAll,
  storeQuestion,
  submitExam,
} from "../../../store/Question/actions";
import AudioComponent from "./AudioComponent";
import "./listening.scss";
const track =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/1649152157808-audio.ogg";

const ListeningExam = ({ history }) => {
  const {
    authtoken,
    quesLoading,
    listeningQuestion,
    currentIndex,
    listeningAudioControl,
    saving,
    answerId,
  } = useSelector((state) => ({
    currentPassage: state.QuesReducer.currentPassage,
    authtoken: state.Login.token,
    QuesReducer: state.QuesReducer,
    quesLoading: state.QuesReducer.quesLoading,
    listeningQues: state.QuesReducer,
    listeningAudio: state.QuesReducer.listeningQues?.listeningOnlyAudioModel,
    listeningQuestion: state.QuesReducer.listeningQues?.listeningAudioModel,
    id: state.Login.id,
    currentIndex: state.QuesReducer.currentIndex,
    listeningAudioControl: state.QuesReducer.listeningAudio,
    saving: state.QuesReducer.saving,
    answerId: state.QuesReducer.answerId,
  }));

  const {
    audioPlayer,
    backward,
    changeRange,
    currentTime,
    duration,
    forward,
    isPlaying,
    progressBar,
    mute,
    togglePlayPause,
    toggleMute,
    reinitialize,
    timeJump,
  } = useAudioPlayer(() => {
    const data = { ...listeningAudioControl };
    data[currentIndex] = true;
    dispatch(storeQuestion("listeningAudio", data));
  });
  const { id, packageId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAll());
    const prevQuestionData = JSON.parse(localStorage.getItem("xampro-xam"));
    console.log(`prevQuestionData`, prevQuestionData);
    if (
      prevQuestionData?.id &&
      prevQuestionData?.ans &&
      prevQuestionData?.time > -1
    ) {
      dispatch(storeQuestion("isRouteChange", false));
      const { authToken, ans, ...rest } = prevQuestionData;
      dispatch(submitExam(ans, authToken, { ...rest, history }));
    } else {
      dispatch(getListeningQues(authtoken, id, false, packageId));
    }
    return () => {
      dispatch(storeQuestion("ans", {}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentData = listeningQuestion?.[currentIndex];
  // console.log(`currentData`, currentData);

  // const openModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open, setOpen] = useState(false);

  const getAudioUrl = (audio) => {
    if (audio) {
      return audio?.startsWith("h") ? audio : imageBaseUrl + audio;
    }
    return track;
  };
  const data = {
    audioPlayer,
    backward: () => backward(5),
    changeRange,
    currentTime,
    duration,
    forward: () => forward(5),
    isPlaying,
    progressBar,
    togglePlayPause,
    track: getAudioUrl(currentData?.audioUrl),
    mute,
    toggleMute,
    disable: listeningAudioControl[currentIndex],
    isExam: true,
  };

  const handleScroll = (element, number) => {
    const el = document.getElementById(element);
    let passage = 0;
    listeningQuestion?.forEach((data, index) => {
      let questionPosition = 0;
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          const previous = listeningQuestion?.[i];
          const lastQuestion =
            previous?.questions?.[previous?.questions?.length - 1];
          questionPosition +=
            lastQuestion?.Que?.question?.[
              lastQuestion?.Que?.question?.length - 1
            ]?.questionNumber;
        }
      }
      data?.questions?.forEach((ques) => {
        ques?.Que?.question?.forEach((list) => {
          const questonNum = list?.questionNumber + questionPosition;
          if (questonNum == number) {
            passage = index;
          }
        });
      });
    });

    dispatch(storeQuestion("currentIndex", passage));
    var offset = 100;
    window.scroll({ top: el?.offsetTop - offset, left: 0, behavior: "smooth" });
  };

  // document?.getElementById('listening_exam')?.on('contextmenu', function (e) {
  //   return false;
  // });

  const input = document.querySelectorAll("input");
  // document?.addEventListener('ready', () => {
  //   listening_exam?.on('contextmenu', function (e) {
  //     return false;
  //   });
  // });

  useEffect(() => {
    input.onpaste = (e) => e.preventDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div className="exam-ques-main-page-wrap">
        <Modal open={open} onClose={onCloseModal} center>
          <div className="ques-popup-answer-wrap text-center">
            <h4>Review your answers</h4>
            <p>
              You can not change any answers from here. Just review your
              answers.
            </p>
          </div>
          <div className="d-flex flex-wrap mt-3">
            <div className="border p-2" style={{ width: "24%" }}>
              <p>data</p>
            </div>
          </div>
        </Modal>
        <div className="question-main-header-listening-part">
          <Container>
            <div className="listening-ques-header">
              <div className="listening-header-left">
                <span onClick={() => history.goBack()}>
                  <i className="fa-solid fa-arrow-left-long"></i>
                </span>
                <b>Listening Exam</b>{" "}
                <small style={{ fontSize: "10px" }} className="ms-2">
                  {saving ? "Saving..." : "Saved"}
                </small>
              </div>
              <div className="listening-header-right">
                <div className="listening-header-right-time">
                  <span>Mark:</span>
                  <span>40</span>
                </div>{" "}
                <div className="listening-header-right-time">
                  <span>Time:</span>
                  {quesLoading ? (
                    "0:0"
                  ) : (
                    <span>
                      <Timer
                        initialTime={40000 * 60}
                        // checkpoints={checkpoints}
                        direction="backward"
                      >
                        <Timer.Minutes />:
                        <Timer.Seconds />
                      </Timer>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="listening-exam-inner-wrap">
            <Row className="g-0 justify-content-center">
              <Col lg={9}>
                {quesLoading ? (
                  <Loader />
                ) : (
                  <div className="listening-ques-main-area-left-part pb-5">
                    <AudioComponent
                      data={data}
                      timeJump={timeJump}
                      currentData={currentData}
                      currentIndex={currentIndex}
                      listeningAudioControl={listeningAudioControl}
                      audioPlayer={audioPlayer}
                    />
                    {/* list */}
                    {currentData?.questions?.length > 0 ? (
                      <div className="px-5">
                        <LeftSide
                          question={currentData?.questions}
                          data={currentData}
                          index={currentIndex}
                        />
                      </div>
                    ) : (
                      <div className="text-center">No Question available</div>
                    )}
                    {/* <div className="question-part-left-area-listening">
                      {listeningQuestion?.map((ques, idx) => {
                        return (
                          <div key={idx}>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {answerId && (
        <QuesFooter
          handlePause={reinitialize}
          handleScroll={handleScroll}
          togglePlayPause={togglePlayPause}
        />
      )}
    </div>
  );
};

export default withRouter(ListeningExam);
