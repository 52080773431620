import { call, put, takeEvery } from 'redux-saga/effects';
import { patchData } from '../../../helpers/backend_helper';
import { toaster } from '../../../helpers/custom/Toast';
import { updatePasswordFail, updatePasswordSuccess } from './actions';
import { RESET_PASSWORD, UPDATE_PASSWORD } from './actionTypes';

function* workerUpdatePass({ payload: { values, history, token } }) {
  try {
    const url = '/user/profile/update-pass';
    const response = yield call(patchData, url, values, token);
    if (response.status === 'success') {
      yield put(updatePasswordSuccess(response.message));
      toaster('success', response.message);
      history.push('/dashboard');
    } else {
      yield put(updatePasswordFail(response.message));
      toaster('error', response.message);
    }
  } catch (error) {
    const message = error?.response?.data?.message;
    toaster('error', message);
    yield put(updatePasswordFail(message));
  }
}

function* workerResetPass({ payload: { values, history, token } }) {
  try {
    const url = '/update-password';
    const response = yield call(patchData, url, values, token);
    if (response.status === 'success') {
      yield put(updatePasswordSuccess(response.message + 'Login again.'));
      toaster('success', response.message + 'Login again.');
      history.push('/login');
    }
  } catch (error) {
    const message = error?.response?.data?.message;
    toaster('error', message);
    yield put(updatePasswordFail(message));
  }
}

function* updatePasswordSaga() {
  yield takeEvery(UPDATE_PASSWORD, workerUpdatePass);
  yield takeEvery(RESET_PASSWORD, workerResetPass);
}
// function* accountSaga() {
//   yield all([fork(watchUserRegister)])
// }
export default updatePasswordSaga;
