import { call, put, takeEvery } from "redux-saga/effects";
import { patchData, postData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import { storeOwnPackageData } from "../OwnPackage/actions";
import {
  addFacilitatorOrderFail,
  addFacilitatorOrderSuccess,
  addOrderCouponFail,
  addOrderCouponSuccess,
  addPackageOrderFail,
  addPackageOrderSuccess,
} from "./actions";
//from pacakge type
import {
  ADD_COUPON,
  ADD_FACILITATOR_ORDER,
  ADD_PACKAGE_ORDER,
} from "./actionTypes";

function* AddPackageOrder({
  payload: { data, history, authtoken, cuponName, type },
}) {
  try {
    // const url = `/order/createOrder`;
    const url = `/order/createOrder?couponCode=${cuponName}&currencyType=${type}`;
    const response = yield call(postData, url, data, authtoken);

    window.location.href = response?.link;
    yield put(addPackageOrderSuccess(response));
    yield put(storeOwnPackageData("selectedOwnService", []));
    yield put(storeOwnPackageData("packageName", ""));
    yield put(storeOwnPackageData("courseType", ""));
    toaster("success", "Order added successfully!");
  } catch (error) {
    const message = error.response?.data?.message;
    yield put(addPackageOrderFail(message));
    toaster("error", message);
  }
}
function* AddFacilitatorOrder({ payload: { data, history, authtoken } }) {
  try {
    const url = "order/buy-facilitator";
    const response = yield call(postData, url, data, authtoken);

    window.location.href = response?.link;
    yield put(addFacilitatorOrderSuccess(response));
    toaster("success", "Facilitator added successfully!");
  } catch (error) {
    const message = error.response?.data?.message;
    yield put(addFacilitatorOrderFail(message));
    toaster("error", message);
  }
}
function* AddCouponData({
  payload: { data, history, authtoken, setDiscountCard },
}) {
  try {
    const url = "/apply-coupon";
    const response = yield call(patchData, url, data, authtoken);
    yield put(addOrderCouponSuccess(response?.getData));
    toaster("success", "Coupon added successfully!");
  } catch (error) {
    setDiscountCard([]);
    const message = error.response?.data?.message;
    yield put(addOrderCouponFail(message));
    toaster("error", message);
  }
}

function* OrderSaga() {
  yield takeEvery(ADD_PACKAGE_ORDER, AddPackageOrder);
  yield takeEvery(ADD_FACILITATOR_ORDER, AddFacilitatorOrder);
  yield takeEvery(ADD_COUPON, AddCouponData);
}

export default OrderSaga;
