import React from "react";
import Slider from "react-slick";
import { imageBaseUrl } from "../../../helpers/api_helper";
import banner1 from "./img/banner-1.png";
import "./student-home.css";
import "./student-home.scss";
const heroBannerSlider = [
  {
    image: banner1,
  },
  {
    image: banner1,
  },
  {
    image: banner1,
  },
];
const StudentHeroSlider = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    cssEase: "linear",
    autoplay: true,
    pauseOnHover: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return "";
  };
  return (
    <div className="student-hero-main-area-wrap">
      {props?.studentBannerList?.length > 1 ? (
        <Slider {...settings}>
          {props?.studentBannerList?.map((data, idx) => (
            <div key={idx}>
              <div className="slider-show-desktop">
                <img src={getPhotoUrl(data?.banner)} alt="" />
              </div>
              <div className="slider-show-tab">
                {" "}
                <img src={getPhotoUrl(data?.bannerTab)} alt="" />
              </div>
              <div className="slider-show-mobile">
                {" "}
                <img src={getPhotoUrl(data?.bannerMobile)} alt="" />
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <>
          <div className="slider-show-desktop">
            <img
              src={getPhotoUrl(props?.studentBannerList[0]?.banner)}
              alt=""
            />
          </div>
          <div className="slider-show-tab">
            {" "}
            <img
              src={getPhotoUrl(props?.studentBannerList[0]?.bannerTab)}
              alt=""
            />
          </div>
          <div className="slider-show-mobile">
            {" "}
            <img
              src={getPhotoUrl(props?.studentBannerList[0]?.bannerMobile)}
              alt=""
            />
          </div>
        </>
        // <img src={getPhotoUrl(studentBannerList[0]?.banner)} alt="" />
      )}

      {/* <Slider {...settings}>
        {studentBannerList?.map((data, idx) => (
          <img src={getPhotoUrl(data?.banner)} alt="" />
        ))}
      </Slider> */}
    </div>
  );
};

export default StudentHeroSlider;
