import {
  ADD_COURSE,
  ADD_COURSE_FAIL,
  ADD_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_FAIL,
  GET_COURSE_SUCCESS,
  GET_SINGLE_COURSE,
  GET_SINGLE_COURSE_FAIL,
  GET_SINGLE_COURSE_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  loading: false,
  singleCourseLoading: false,
  error: '',
  data: [],
  adding: false,
  singleCourse: {},
  packageList: {},
};

const Course = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COURSE:
      return {
        ...state,
        adding: true,
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_COURSE_FAIL:
      return {
        ...state,
        adding: false,
        error: action.payload.data,
      };
    case GET_COURSE:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: '',
      };
    case GET_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        data: {},
      };
    case GET_SINGLE_COURSE:
      return {
        ...state,
        singleCourseLoading: true,
      };
    case GET_SINGLE_COURSE_SUCCESS:
      return {
        ...state,
        singleCourseLoading: false,
        singleCourse: action.payload.data.courseType,
        packageList: action.payload.data.packagesList,
        error: '',
      };
    case GET_SINGLE_COURSE_FAIL:
      return {
        ...state,
        singleCourseLoading: false,
        error: action.payload.data,
        singleCourse: {},
      };

    default:
      return state;
  }
};

export default Course;
