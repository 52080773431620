import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import expertiseImage from "./img/Image 1.png";
const OurExpertise = () => {
  return (
    <div className="our-expertise-main-area-wrap">
      <Container>
        <div className="our-expertise-inner-container">
          <Row>
            <Col lg={7} className="my-auto">
              <div className="our-expertise-left-content">
                <h5>Our expertise</h5>
                <h3>Stick to Basics</h3>
                <p>Key concepts in simulation</p>
                <p>Corporate concepts (Tailor made)</p>
                <p>Mini games on courses</p>
                <p>Engaging and relevant contents</p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="our-expertise-right-content">
                <img src={expertiseImage} alt="" />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="trade-license-area-wrap">
              <h3>Trade License & Address</h3>
              {/* <p>
                The trade license of Courseware is registered under the
                ownership of Sharif Uddin MD <br /> Hassan(MD) under the Dhaka
                North City Corporation.
              </p> */}
              <p>Trade Licence No: TRAD/DNCC/029467. Date issued: 4/10/2022</p>
              <p>
                Address: Ta-143/A, Moddho Badda (3rd Floor), Gulshan, Badda,
                Dhaka - 1212
              </p>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default OurExpertise;
