import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Modal,
  ProgressBar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label } from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import replyIcon from "../../../assets/images/Icon/corner-down-right.svg";
import profile from "../../../assets/images/profile.png";
import productImage from "../../../assets/images/Rectangle 2117.png";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { addFacilitatorOrder } from "../../../store/actions";
import { addCart } from "../../../store/cart/actions";
import { getVerifyFacilitator } from "../../../store/Facilitator/actions";
import { addGift } from "../../../store/Package/actions";
import clock from "./img/clock.svg";
const PackageDetailsBody = ({
  singlePackageData,
  history,
  singlePackage,
  buyPackageList,
}) => {
  const dispatch = useDispatch();
  const [facilitatorData, setFacilitatorData] = useState();
  const [show, setShow] = useState(false);
  const [agree, setAgree] = useState(false);
  const [modal2, setModal2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalCardTitle, setModalCardTitle] = useState("");
  const [modalCardImage, setModalCardImage] = useState("");
  const [modalCardDuration, setModalCardDuration] = useState("");
  const {
    packageName,
    packageDescription,
    price,
    discount,
    tags,
    eligible,
    description,
    courseType,
    aveRatings,
    _id,
    updatedAt,
    aboutPackage,
    features,
    includedFacilitator,
    // advantage,
  } = singlePackageData;

  const {
    email,
    cart,
    authToken,
    adding,
    verifyFacilitator,
    facilitatorOrdering,
    dollarRate,
    countryName,
  } = useSelector((state) => ({
    error: state.OrderReducer.error,
    ordering: state.OrderReducer.ordering,
    email: state.Login.id,
    username: state.Login.username,
    authToken: state.Login.token,
    cart: state.CardReducer.cart,
    adding: state.PackageReducer.adding,
    verifyFacilitator: state.facilitator.verifyFacilitator,
    facilitatorOrdering: state.OrderReducer.facilitatorOrdering,
    dollarRate: state.PackageReducer.dollarRate,
    countryName: state.PackageReducer.countryName,
  }));

  const cartId = useMemo(() => cart.map((data, idx) => data._id), [cart]);

  const totalPrice = useMemo(
    () => cart.reduce((total, item) => total + Number(item.price), 0),
    [cart]
  );

  const totalDiscount = useMemo(
    () => cart.reduce((total, item) => total + Number(item.discount), 0),
    [cart]
  );
  const [openFirst, setOpenFirst] = useState(false);

  const handleSubmit = (e, error, values) => {
    e.preventDefault();
    if (error.length === 0) {
      const { giftReceiverEmail } = values;
      const body = {
        packageData: [singlePackageData],
        price: totalPrice,
        mainPrice: totalPrice,
        discount: totalDiscount,
      };
      dispatch(
        addGift(body, {
          history,
          authtoken: authToken,
          setOpenFirst,
          email,
          giftReceiverEmail,
        })
      );
    }
  };
  const calculatePercentage = (data, list) => {
    const findNumber = list?.filter((dt) => dt?.ratings === data?._id);
    const totalRatings = list?.length;
    const percentage = ((findNumber?.length / totalRatings) * 100)?.toFixed(2);
    return percentage;
  };
  const handleFacilitatorInfo = (data) => {
    let newArray = [];
    setShow(true);
    let facilitatorInfo = {
      _id: data?._id,
      expire: 1,
      currentRemaining: 1,
      price: 1000,
      mainPrice: 1000,
      discount: 0,
    };
    newArray.push(facilitatorInfo);
    // setFacilitatorData(newArray);
    let body = {
      packageData: newArray,
      price: 100,
      mainPrice: 100,
      discount: 0,
    };
    setFacilitatorData(body);
  };
  const facilitatorSubmit = () => {
    dispatch(addFacilitatorOrder(facilitatorData, history, authToken));
  };
  useEffect(() => {
    if (singlePackageData && email !== "") {
      dispatch(getVerifyFacilitator(authToken, singlePackageData?._id));
    }
  }, [singlePackageData]);
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return productImage;
  };
  const handleChange = (e) => {
    const { checked } = e.target;
    setAgree(checked);
  };
  const handleStore = (data) => {
    setModalCardTitle(data?.packageName);
    setModalCardImage(data?.photo);
    setModalCardDuration(data?.validity);

    dispatch(addCart(data));
  };
  const convertToDollar = (data) => {
    console.log("data", data);
    return (data / dollarRate?.BDT).toFixed(2);
  };
  return (
    <div className="PackageDetails-main-body">
      <Container>
        <div className="package-all-content-details mb-3">
          <div className="package-all-content-details-title">
            <h3>{packageName}</h3>
            <div className="package-all-content-details-sub-title-time">
              <p>
                <span>
                  <i className="bx bx-time"></i>
                </span>
                Validity - {singlePackageData?.validity} Days
              </p>
              <p>
                <span>
                  <i className="bx bx-time"></i>
                </span>
                Last Update: {moment(updatedAt).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        open={openFirst}
        onClose={() => setOpenFirst(false)}
        classNames={{
          overlay: "gift-page-custom-Overlay",
          modal: "gift-package-popup-modal",
        }}
        center
      >
        <div className="modal-inner-main-wrap">
          <div className="modal-header-top-wrap">Gift a course</div>
          <div className="gift-package-content-details">
            <Row>
              <Col lg={6}>
                <div className="gift-package-data">
                  <div className="student-courses-card">
                    <Card>
                      <div
                        className="card-img-top gift-popup-package"
                        style={{
                          backgroundImage: `url(${getPhotoUrl(
                            singlePackageData?.photo
                          )})`,
                        }}
                      ></div>
                      {/* <Card.Img
                        variant="top"
                        src={
                          singlePackageData?.photo
                            ? imageBaseUrl + singlePackageData?.photo
                            : productImage
                        }
                      /> */}
                      <Card.Body>
                        <div className="student-courses-review">
                          <Box component="fieldset" borderColor="transparent">
                            <Rating
                              name="simple-controlled"
                              value={aveRatings}
                              precision={0.5}
                              readOnly
                            />
                          </Box>
                          <span>{aveRatings}</span>
                        </div>
                        <Link to="#" className="btn">
                          <Card.Title>{packageName}</Card.Title>
                        </Link>
                        <Card.Text className="student-courses-timeline">
                          <img src={clock} alt="" />
                          Duration - {singlePackageData?.validity} Days
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="package-received-persion-list">
                  <AvForm onSubmit={handleSubmit}>
                    <AvField
                      type="email"
                      name="giftReceiverEmail"
                      placeholder="name@example.com"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Email is required",
                        },
                        email: {
                          value: true,
                          errorMessage: "Invalid Email format!",
                        },
                      }}
                      label={"Recipient's Email"}
                    />
                    <AvField
                      name="message"
                      type="textarea"
                      placeholder="Your Message"
                      rows={3}
                      label={"Your Message (optional)"}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={adding}
                      style={{ display: "block", width: "100%" }}
                    >
                      {adding ? "Submitting..." : "Proceed to checkout"}
                    </Button>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Container>
        <div className="product-details-main-area-wrap-content">
          <Row>
            <Col lg={8}>
              <div className="package-all-content-details">
                <div className="package-details-table">
                  <Table borderless responsive="md">
                    <thead>
                      <tr>
                        <th>
                          <span>Category</span>
                        </th>

                        <th>
                          <span>Total Enrolled</span>
                        </th>
                        <th>
                          <span>Package level</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>{courseType?.courseTypeName}</span>
                        </td>

                        <td>
                          <span>2K</span>
                        </td>
                        <td>
                          <span>Beginner</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="package-details-content-title">
                  <h4>About package</h4>
                  <p>{packageDescription}</p>
                </div>
                <div className="package-details-content">
                  <Accordion flush>
                    {aboutPackage?.length
                      ? aboutPackage?.map((el, key) => (
                          <Accordion.Item eventKey={key} key={key}>
                            <Accordion.Header>{el?.question}</Accordion.Header>
                            <Accordion.Body>{el?.answer}</Accordion.Body>
                          </Accordion.Item>
                        ))
                      : null}
                  </Accordion>
                </div>
                <div className="sidebar-single-content-product tag-desktop-show">
                  <div className="sidebar-single-content-product-single-item">
                    <h4>Who will eligible for this course</h4>
                    <p>{eligible}</p>
                  </div>
                  <div className="sidebar-single-content-product-single-item">
                    <h4>What I will learn?</h4>
                    <p>{description}</p>
                  </div>
                  <div className="sidebar-single-content-product-single-item course-tag-area">
                    <h4>Popular Tags</h4>
                    {tags?.map((data, idx) => (
                      <button className="btn" kye={idx}>
                        {data}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="package-all-content-details-sidebar">
                <div className="details-sidebar-content-wrap">
                  <div className="sidebar-single-product">
                    <div
                      className="product-image"
                      style={{
                        backgroundImage: `url(${getPhotoUrl(
                          singlePackageData?.photo
                        )})`,
                      }}
                    ></div>
                    <div className="product-content">
                      <div className="package-price-information-top-wrap">
                        {countryName === "Bangladesh" ? (
                          <p>
                            {discount > 0 ? (
                              <>
                                <del style={{ color: "#0052CC" }}>
                                  TK. {price}
                                </del>{" "}
                                <b>TK. {price - discount}</b>
                              </>
                            ) : (
                              <b>TK. {price}</b>
                            )}
                          </p>
                        ) : (
                          <p>
                            {discount > 0 ? (
                              <>
                                <del style={{ color: "#0052CC" }}>
                                  &#36; {convertToDollar(price)}
                                </del>{" "}
                                <b>&#36; {convertToDollar(price - discount)}</b>
                              </>
                            ) : (
                              <b> &#36; {convertToDollar(price)}</b>
                            )}
                          </p>
                        )}
                        <h4>Package Content</h4>
                      </div>

                      <div className="product-content-list-items-wrap">
                        <ul>
                          {features?.map((data, idx) => (
                            <li key={idx}>
                              <span>
                                <i className="bx bx-check"></i>
                              </span>
                              {data}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="product-content-sidebar-btn-wrap ">
                        {/* /cart-page/${_id} */}
                        {cartId?.includes(_id) ? (
                          <button
                            className="btn btn-primary"
                            disabled={
                              buyPackageList?.[singlePackageData?._id] > 0
                            }
                            onClick={() =>
                              toaster("warning", "Product is already added!")
                            }
                          >
                            {buyPackageList?.[singlePackageData?._id] > 0
                              ? "Purchased"
                              : "Added to cart"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            disabled={
                              buyPackageList?.[singlePackageData?._id] > 0
                            }
                            onClick={() => {
                              handleStore(singlePackageData);
                              setModal2(true);
                            }}
                          >
                            {buyPackageList?.[singlePackageData?._id] > 0
                              ? "Purchased"
                              : "Add to cart"}
                          </button>
                        )}
                        {buyPackageList?.[singlePackageData?._id] > 0 &&
                        includedFacilitator === false ? (
                          <button
                            className="btn btn-primary"
                            disabled={verifyFacilitator?.data?.getData === true}
                            onClick={() => {
                              handleFacilitatorInfo(singlePackageData);
                            }}
                          >
                            Consult Facilitator
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          className="btn add-to-cart-btn button"
                          // onClick={() => setOpenFirst(true)}
                          onClick={() => history.push("/upcoming")}
                        >
                          {"Gift & Reward"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar-single-content-product tag-mobile-show">
                    <div className="sidebar-single-content-product-single-item">
                      <h4>Who will eligible for this course</h4>
                      <p>{eligible}</p>
                    </div>
                    <div className="sidebar-single-content-product-single-item">
                      <h4>What I will learn?</h4>
                      <p>{description}</p>
                    </div>
                    <div className="sidebar-single-content-product-single-item course-tag-area">
                      <h4>Popular Tags</h4>
                      {tags?.map((data, idx) => (
                        <button className="btn" kye={idx}>
                          {data}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="d-none">
          <Col lg={12}>
            <div className="package-details-feedback-area">
              <div className="mt-3 mb3">
                <h3>Students feedback</h3>
              </div>
              <div className="feedback-main-wrap">
                <div className="feedback-single-item">
                  <div className="feedback-single-item-left">
                    <div className="feedback-review-count">
                      <h2>{singlePackageData?.aveRatings}</h2>
                      <Box component="fieldset" borderColor="transparent">
                        <span>
                          <Rating
                            name="simple-controlled"
                            precision={0.1}
                            defaultValue={singlePackageData?.aveRatings || 0}
                            readOnly
                          />
                        </span>
                      </Box>
                    </div>
                  </div>
                  <div className="feedback-single-item-right">
                    {singlePackage?.ratingsAggregate
                      ?.sort((a, b) => b?._id - a?._id)
                      ?.map((data, idx) => (
                        <div className="feedback-review-bar-wrap" key={idx}>
                          <span>
                            <i className="bx bxs-star"></i> {data?._id}
                          </span>
                          <ProgressBar
                            now={calculatePercentage(
                              data,
                              singlePackage?.ratingsData
                            )}
                          />
                          <span>
                            {calculatePercentage(
                              data,
                              singlePackage?.ratingsData
                            )}
                            %
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                        </p>
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>
                          Last Update: Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                        </p>
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>
                          Last Update: Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                        </p>
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>
                          Last Update: Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                          <span>
                            <i className="bx bxs-star"></i>
                          </span>
                        </p>
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>
                          Last Update: Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-see-more-feedback">
                  <button className="btn btn-primary" type="submit">
                    See all review
                  </button>
                </div>
              </div>
            </div>

            {/* =============================================== */}
            <div className="package-details-feedback-area">
              <div className="mt-5 mb3">
                <h3>All comments</h3>
              </div>
              <div className="feedback-main-wrap all-comments">
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>
                          Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                      <h6>
                        Replys <img src={replyIcon} alt="" />
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>{" "}
                          Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                      <h6>
                        Replys <img src={replyIcon} alt="" />
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>{" "}
                          Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                      <h6>
                        Replys <img src={replyIcon} alt="" />
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="feedback-single-item feedback-review-content-wrap">
                  <div className="feedback-single-item-left feedback-content-left">
                    <img src={profile} alt="" />
                  </div>
                  <div className="feedback-single-item-right feedback-content-right">
                    <div className="feedback-review-count">
                      <h3>Baker H.</h3>
                      <div className="package-all-content-details-sub-title-time">
                        <p>
                          <span>
                            <i className="bx bx-time"></i>
                          </span>{" "}
                          Jan 01, 2022
                        </p>
                      </div>
                      <p>
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for looks reasonable. The generated is therefore always.
                      </p>
                      <h6>
                        Replys <img src={replyIcon} alt="" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="comment-account-area">
                <Link to="/">Have a comments? Please login</Link>
              </div>
            </div>
            {/* =============================================== */}
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <h5 style={{ fontWeight: 700 }} className="mb-1">
              Do you want to buy this feature?
            </h5>
            <Col lg={12}>
              <div className="cart-page-total-amount facilitator-confirm-card-wrap">
                <div className="basic-price-top">
                  <div className="single-price">
                    <div className="p-neme">
                      <p>Price</p>
                    </div>
                    <div className="p-price">
                      <p>Tk. 1000</p>
                    </div>
                  </div>
                  <div className="single-price">
                    <div className="p-neme">
                      <p>Discount </p>
                    </div>
                    <div className="p-price">
                      <p> Tk. 0</p>
                    </div>
                  </div>
                  <div className="single-price">
                    <div className="p-neme">
                      <p>VAT</p>
                    </div>
                    <div className="p-price">
                      <p>Tk. 0</p>
                    </div>
                  </div>
                </div>
                <div className="basic-price-top middle-cart">
                  <div className="single-price">
                    <div className="p-neme">
                      <p>Price</p>
                    </div>
                    <div className="p-price">
                      <p>Tk. 1000</p>
                    </div>
                  </div>
                </div>
                <div className="basic-price-top last-bottom-cart ">
                  <div className="basic-price-top last-bottom-cart ">
                    <AvForm onSubmit={handleSubmit}>
                      <div className="single-price d-flex  align-items-center">
                        <div className="p-neme">
                          <FormGroup className="facilitator-confirm-area ">
                            <AvField
                              type="checkbox"
                              name="agree"
                              id={"agree"}
                              checked
                              onChange={handleChange}
                              label="I agree all terms and conditions"
                              required
                            />
                            <Label className="ms-1" htmlFor="agree">
                              I have read and agreed to the website's{" "}
                              <Link to="/privacy-policy">Privacy Policy</Link>,{" "}
                              <Link to="/term-and-condition">
                                Terms & Conditions
                              </Link>
                              ,{" "}
                              <Link to="/refound-policy">
                                Refund and Return Policy
                              </Link>
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer
            className="facilitator-confirm-modal-bottom"
            style={{ borderTop: "none", justifyContent: "center" }}
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            {false ? (
              <div className="d-flex justify-content-end ">
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : (
              <Button
                className={`btn ${
                  agree ? "btn-primary" : "payment-final-submit"
                }`}
                onClick={facilitatorSubmit}
                disabled={!agree || facilitatorOrdering}
              >
                {facilitatorOrdering === true ? "Submitting..." : "Payment"}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          size={"lg"}
          centered
          show={modal2}
          onHide={() => setModal2(false)}
        >
          <Modal.Header className="added-card-header" toggle={modal2}>
            <span>
              <i className="bx bx-check"></i>
              Added to the cart
            </span>
            <button
              onClick={() => {
                setModal2(false);
              }}
            >
              <span></span>
              <span></span>
            </button>
          </Modal.Header>
          <div className="added-card-go-to-card-popup">
            <div className="card-content-info-left">
              <img src={getPhotoUrl(modalCardImage)} alt="" />
              <div className="card-content-info-left-info">
                <h6>{modalCardTitle}</h6>
                <p>
                  <i className="bx bx-time-five"></i> Duration -{" "}
                  {modalCardDuration} Days{" "}
                </p>
              </div>
            </div>
            <div className="card-content-info-button">
              <button
                onClick={() => history.push(`/cart`)}
                className="btn btn-sm btn-primary"
              >
                Go to cart
              </button>
            </div>
          </div>
        </Modal>
        {/* )} */}
      </Container>
    </div>
  );
};

export default withRouter(PackageDetailsBody);
