import React from "react";
import { useParams, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { toCapitalize } from "../../helpers/custom/capitalize";
import CardComponent from "../Common/CardComponent";
const imageHelper = (data) => {
  switch (data.serviceName?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg";
    case "speaking":
      return "speaking.svg";
    case "writing":
      return "writing.svg";
    case "listening":
      return "listening.svg";
    default:
      return "reading.svg";
  }
};

const PackageCard = ({ userService, history }) => {
  const { id, type, courseId } = useParams();
  // console.log("courseType", courseId);
  const handler = (data) => {
    switch (data?.serviceName?.toLowerCase()?.trim()) {
      case "reading":
        history.push(
          `/my-package/${id}/service/${data?._id}/${2}/${courseId}/reading${
            type ? `/${type}` : ""
          }`
        );
        break;
      case "listening":
        history.push(
          `/my-package/${id}/service/${data?._id}/${2}/listening${
            type ? `/${type}` : ""
          }`
        );
        break;
      case "writing":
        history.push(
          `/my-package/${id}/service/${data?._id}/${2}/${courseId}/writing${
            type ? `/${type}` : ""
          }`
        );
        break;
      case "speaking":
        history.push(
          `/my-package/${id}/service/${data?._id}/speaking${
            type ? `/${type}` : ""
          }`
        );
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div
        className="single-package-inner-main-wrap mx-auto"
        style={{ maxWidth: "80%" }}
      >
        <Row>
          {(userService || [])?.map((data, key) => (
            <Col lg={6} md={6} key={key}>
              <div className="card-active-deactive-content">
                {/* <div className="active-pending-top-right-massage">
                  <div className="course-active-btn">
                    <span className="color-success-text">Active</span>
                  </div>
                </div> */}
                <CardComponent className={"text-center py-4"}>
                  <div className="mx-auto mb-3">
                    <img
                      src={require(`./img/${imageHelper(data)}`).default}
                      alt="examImage"
                    />
                  </div>
                  <div>
                    <h5 className="mb-3">{toCapitalize(data.serviceName)}</h5>
                    {/* <p className="mb-3">1st out of 7th attempt</p> */}
                    {/* <Link to={`/reading-exam/${data._id}`}> */}
                    <button
                      className="btn btn-primary"
                      onClick={() => handler(data)}
                    >
                      View Set
                    </button>
                    {/* </Link> */}
                  </div>
                </CardComponent>
              </div>
            </Col>
          ))}
          {/* {(packagedesc.packageService || []).map((data, key) => {
            return (
              <Col lg={6} key={key}>
                <div className="single-package-data-column">
                  <Row>
                    <Col lg={7} className="my-auto">
                      <div className="single-package-data-column-left">
                        <h4>{toCapitalize(data.serviceName)}</h4>
                        <p>1st out of 7th attempt</p>
                        <Link to={`/reading-exam/${data._id}`}>
                          <button className="btn btn-primary">
                            Start Your Exam
                          </button>
                        </Link>
                      </div>
                    </Col>
                    <Col lg={5} className="my-auto">
                      <div className="single-package-data-column-right">
                        <img src={icon1} alt="" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })} */}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PackageCard);
