import { toast } from "react-toastify";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { axiosUpdateProfilePassword } from "./../../../helpers/api_helper";
import { updateProfilePassError, updateProfilePassSuccess } from "./actions";
import { UPDATE_PROFILE_PASS } from "./actionTypes";

const asyncUpdateProfilePass = async (
  authtoken,
  currentPassword,
  confirmPassword,
  password
) => {
  try {
    const response = await axiosUpdateProfilePassword(
      authtoken,
      currentPassword,
      password,
      confirmPassword
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerUpdateProfilePass({ payload: { values } }) {
  const { authtoken, confirmPassword, password, currentPassword } = values;

  const response = yield call(
    asyncUpdateProfilePass,
    authtoken,
    currentPassword,
    password,
    confirmPassword
  );

  if (response.status === "success") {
    toast.success(response.message);
    yield put(updateProfilePassSuccess(response.message));
  } else {
    // toast.error(response.message)
    yield put(updateProfilePassError(response.message));
  }
}

export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_PROFILE_PASS, workerUpdateProfilePass);
}

function* UpdateProfilePass() {
  yield all([fork(watchUpdateProfile)]);
}

export default UpdateProfilePass;
