import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";
import img1 from "../../assets/images/product.png";
import { imageBaseUrl } from "../../helpers/api_helper";
import { removeCart } from "../../store/cart/actions";
import {
  addOrderCoupon,
  addOrderCouponSuccess,
  addPackageOrder,
} from "../../store/Order/actions";
import createpBanner from "./img/create-banner.png";
import emptyCart from "./img/emptyCart.svg";
const freeCupon = [
  "COUPON 100",
  "COUPON FRE",
  "BACK2SCHOOL",
  "LITTLELEARNER",
  "FESTIVE100",
  "DRINKMAS",
];
const CartPageBody = ({ history, location }) => {
  const [agree, setAgree] = useState(false);
  const [cuponName, setCuponName] = useState("");
  const [free, setFree] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [couponInfo, setCouponInfo] = useState([]);
  const [discountCard, setDiscountCard] = useState([]);
  const dispatch = useDispatch();
  ReactGA.initialize("AW-10942159544");
  const {
    ordering,
    id,
    cart,
    authToken,
    couponData,
    couponOrdering,
    reRender,
    countryName,
    dollarRate,
  } = useSelector((state) => ({
    error: state.OrderReducer.error,
    ordering: state.OrderReducer.ordering,
    id: state.Login.id,
    username: state.Login.username,
    authToken: state.Login.token,
    cart: state.CardReducer.cart,
    coupon: state.PackageReducer.coupon,
    couponData: state.OrderReducer.couponData,
    couponOrdering: state.OrderReducer.couponOrdering,
    reRender: state.OrderReducer.reRender,
    countryName: state.PackageReducer.countryName,
    dollarRate: state.PackageReducer.dollarRate,
  }));
  console.log("countryName", countryName);
  const totalPrice = useMemo(() => {
    const data = discountCard.length ? discountCard : cart;
    return data.reduce(
      (total, item) =>
        total + Number(item?.price - (item?.isDiscount ? 0 : item?.discount)),
      0
    );
  }, [cart, discountCard]);

  const totalDiscount = useMemo(() => {
    return discountCard.reduce(
      (total, item) =>
        total + (item?.isDiscount ? Number(item?.discount || 0) : 0),
      0
    );
  }, [discountCard]);

  const totalPackage = cart.map((data) => data?.id);

  const finalPrice = totalPrice - totalDiscount;
  const vatPrice = Math.round(finalPrice * 0.045);
  const calcDiscountPrice = (mainPrice, discount) => {
    return mainPrice - (mainPrice * discount) / 100;
  };
  useEffect(() => {
    if (Object.keys(couponData).length > 0) {
      const couponPackage = cart.map((data) => {
        const couponId = couponData?.obj[data?._id];
        if (couponId) {
          const discountPrice =
            ((data?.price - data?.discount) * couponId) / 100;
          return {
            ...data,
            price: data?.price * 1 - data?.discount,
            discount: discountPrice,
            isDiscount: true,
          };
        }
        return data;
      });
      setDiscountCard(couponPackage);

      // const couponPackage = setCouponInfo(couponPackage);
    }
  }, [reRender]);

  //
  useEffect(() => {
    return () => {
      dispatch(addOrderCouponSuccess({}));
    };
  }, []);

  function handleSubmit(event, errors, values) {
    ReactGA.event({
      category: "payment",
      action: "proceed to payment",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

    event.preventDefault();
    setDisableBtn(true);
    setTimeout(() => {
      console.log("hello123");
      setDisableBtn(false);
    }, 10000);

    if (errors.length === 0) {
      discountCard.forEach((el) => {
        el.price = el?.price - el?.discount;
      });
      if (id !== "") {
        const price = finalPrice + vatPrice;
        const body = {
          packageData: discountCard?.length ? discountCard : cart,
          price,
          mainPrice: totalPrice,
          discount: price,
        };
        console.log("body", body);
        if (countryName === "Bangladesh") {
          dispatch(addPackageOrder(body, history, authToken, cuponName, "BDT"));
        } else {
          dispatch(addPackageOrder(body, history, authToken, cuponName, "USD"));
        }
      } else {
        localStorage.setItem("isOwnPackageAvailable", "false");
        localStorage.setItem("isServicePackageAvailable", "true");
        history.push("/no-register", { from: location });
      }
    }
  }

  const handleSubmitCoupon = async (event, errors, values) => {
    event.preventDefault();
    if (errors.length) return;
    dispatch(addOrderCouponSuccess({}));
    const { code } = values;
    setCuponName(code);
    // const availableFreeCupon = freeCupon.find((data) => data === code);
    let body = {
      packageData: totalPackage,
      couponCode: code,
    };
    dispatch(addOrderCoupon(body, history, authToken, setDiscountCard));
    // try {
    //   const response = await patchData("/apply-coupon", body, authToken);

    // const couponPackage = cart.map((data) => {
    //   const couponId = response?.getData?.obj[data?._id];
    //   if (couponId) {
    //     const discountPrice = (data.price * couponId) / 100;
    //     return {
    //       ...data,
    //       price: data.price - discountPrice,
    //     };
    //   }
    //   return data;
    // });

    //   dispatch(storeCartData("cart", couponPackage));
    // } catch {
    //   toaster("error", "Coupon is not valid");
    // }
    // dispatch(addOrderCoupon(body, history, authToken));
    // if (availableFreeCupon) {
    //   setFree(true);
    // } else {
    //   setFree(false);
    // }

    // if (code?.trim() === coupon?.couponCode?.trim()) {
    //   const discountPrice = (totalPrice * coupon?.couponPercentage) / 100;
    //   setCouponDiscount(discountPrice);
    // } else {
    //   setCouponDiscount(0);
    //   // toaster("warning", "Coupon code doesn't matched.");
    // }
  };

  const handleRemove = (data) => {
    const filterCart = discountCard?.filter((el) => el?._id !== data?._id);
    setDiscountCard(filterCart);
    dispatch(removeCart(data));
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setAgree(checked);
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return img1;
  };

  const convertToDollar = (data) => {
    console.log("data", data);
    return (data / dollarRate?.BDT).toFixed(2);
  };
  console.log("disableBtn", disableBtn);
  return (
    <div className="cart-page-main-wrap-area">
      <Container>
        <div className="cart-page-inner-wrap">
          <h3>Cart</h3>
          <div className="full-cart-area">
            {cart?.length ? (
              <Row>
                <Col lg={6}>
                  <div className="cart-page-left-wrap">
                    {cart?.map((data, idx) => (
                      <div className="cart-item-wrap" key={idx}>
                        <div className="cart-single-item">
                          <div
                            className="cart-remove-icon cursor-pointer"
                            onClick={() => handleRemove(data)}
                          >
                            <i className="bx bx-x fs-3"></i>
                          </div>
                          <div
                            className="single-product-image"
                            // style={{
                            //   maxWidth: "30%",
                            // }}
                          >
                            <img
                              src={`${getPhotoUrl(data?.photo)}`}
                              alt=""
                              className="img-fluid rounded-2"
                            />
                          </div>
                          <div className="single-product-content">
                            <h4>{data?.packageName}</h4>
                            <div className="package-all-content-details-sub-title-time cart-page-time">
                              <p>
                                <span>
                                  <i className="bx bx-time"></i>
                                </span>
                                Validity: {data?.packageLength} Days
                              </p>
                            </div>
                            <h6>
                              {couponData?.obj?.[data?._id] ? (
                                <div
                                  style={{
                                    marginTop: "8px",
                                  }}
                                >
                                  {countryName === "Bangladesh" ? (
                                    <span
                                      className="text-decoration-line-through"
                                      style={{
                                        letterSpacing: "0.05rem",
                                        color: "#606060",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data?.discount
                                        ? data?.price - data?.discount
                                        : data?.price}{" "}
                                      Tk
                                    </span>
                                  ) : (
                                    <span
                                      className="text-decoration-line-through"
                                      style={{
                                        letterSpacing: "0.05rem",
                                        color: "#606060",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {convertToDollar(
                                        data?.discount
                                          ? data?.price - data?.discount
                                          : data?.price
                                      )}{" "}
                                      &#36;
                                    </span>
                                  )}
                                  <span className="ms-2 d-none d-md-inline-block">
                                    - {couponData?.obj?.[data?._id] + " %"}
                                  </span>
                                  <span className="text-danger ms-2">
                                    {countryName === "Bangladesh" ? (
                                      <>
                                        {calcDiscountPrice(
                                          data?.price - data?.discount,
                                          couponData?.obj[data?._id]
                                        )}{" "}
                                        Tk.
                                      </>
                                    ) : (
                                      <>
                                        {convertToDollar(
                                          calcDiscountPrice(
                                            data?.price - data?.discount,
                                            couponData?.obj[data?._id]
                                          )
                                        )}
                                      </>
                                    )}{" "}
                                    &#36;
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {countryName === "Bangladesh"
                                    ? data?.price - data?.discount + " Tk"
                                    : convertToDollar(
                                        data?.price - data?.discount
                                      ) + " $"}
                                </>
                              )}{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Link to="/">Continue shopping</Link>

                    <div className="ssl-commerce-require-pages-link">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                      <Link to="/term-and-condition">Terms & Conditions</Link>
                      <Link to="/refound-policy">Refund and Return Policy</Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="cart-page-total-amount m-0">
                    <div className="basic-price-top">
                      <div className="single-price">
                        <div className="p-neme">
                          <p>Price</p>
                        </div>
                        <div className="p-price">
                          {countryName === "Bangladesh" ? (
                            <p>Tk. {totalPrice}</p>
                          ) : (
                            <p>&#36; {convertToDollar(totalPrice)}</p>
                          )}
                        </div>
                      </div>
                      <div className="single-price">
                        <div className="p-neme">
                          <p>Discount</p>
                        </div>
                        <div className="p-price">
                          {countryName === "Bangladesh" ? (
                            <p>- Tk. {totalDiscount}</p>
                          ) : (
                            <p>- &#36; {convertToDollar(totalDiscount)}</p>
                          )}
                        </div>
                      </div>
                      <div className="single-price">
                        <div className="p-neme">
                          <p>VAT</p>
                        </div>
                        <div className="p-price">
                          {countryName === "Bangladesh" ? (
                            <p>Tk. {vatPrice}</p>
                          ) : (
                            <p>&#36; {convertToDollar(vatPrice)}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="basic-price-top middle-cart">
                      <div className="single-price">
                        <div className="p-neme">
                          <p>Price</p>
                        </div>
                        <div className="p-price">
                          {countryName === "Bangladesh" ? (
                            <p>
                              Tk. {free === true ? 0 : finalPrice + vatPrice}
                            </p>
                          ) : (
                            <p>
                              &#36;{" "}
                              {free === true
                                ? 0
                                : convertToDollar(finalPrice + vatPrice)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="basic-price-top bottom-cart">
                      <div className="single-price">
                        <div className="p-neme">
                          <p>Apply Coupon</p>
                        </div>
                        <div className="p-price">
                          <AvForm
                            onSubmit={handleSubmitCoupon}
                            className="form-apply-coupon-area"
                          >
                            <AvField
                              type="text"
                              name="code"
                              validate={{
                                required: { value: true },
                              }}
                              id="exampleInputEmail1"
                              placeholder="Type coupon"
                            />
                            <button
                              type="submit"
                              className="btn btn-primary align-self-baseline"
                              disabled={couponOrdering}
                            >
                              {couponOrdering ? "Applying..." : "Apply"}
                            </button>
                          </AvForm>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="basic-price-top last-bottom-cart ">
                      <AvForm onSubmit={handleSubmit}>
                        <div className="single-price d-flex  align-items-center">
                          <div className="p-neme">
                            <FormGroup className="d-flex hjkhfb">
                              <AvField
                                type="checkbox"
                                name="agree"
                                id={"agree"}
                                checked
                                onChange={handleChange}
                                label="I agree all terms and conditions"
                                required
                              />
                              <Label className="ms-1" htmlFor="agree">
                                I have read and agreed to the website's{" "}
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                ,{" "}
                                <Link to="/term-and-condition">
                                  Terms & Conditions
                                </Link>
                                ,{" "}
                                <Link to="/refound-policy">
                                  Refund and Return Policy
                                </Link>
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="p-price">
                            <button
                              type="submit"
                              disabled={!agree || ordering || disableBtn}
                              className={`btn ${
                                agree ? "btn-primary" : "payment-final-submit"
                              }`}
                            >
                              {ordering ? "Submitting..." : "Payment"}
                            </button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="empty-cart-main-area-wrap-empty">
                <div className="empty-cart-inner-wrap">
                  <img src={emptyCart} alt="" />
                  <h6>Your cart is empty.</h6>
                  <p style={{ color: "#74788D" }}>
                    Please browse from the available resources , and shop for
                    your brains
                  </p>
                  <Link to="/packages/Ielts" className="btn btn-primary mt-3">
                    Continue Shopping
                  </Link>
                </div>
              </div>
            )}
            <div className="create-new-package-request-area-wrap">
              <div className="create-new-package-request-title">
                <h3>Create package</h3>
              </div>
              <div className="package-page-create-package">
                <img
                  src={createpBanner}
                  alt=""
                  className="banner-image-right-area"
                />
                <div className="">
                  <h2>
                    Would you like to create <br /> your own package?{" "}
                  </h2>
                  <Link to="/create-package">Create Package</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(CartPageBody);
