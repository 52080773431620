import React, { Fragment, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useParams, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import arrowRight from "../../assets/images/Icon/arrow-ios-right.svg";
import time from "../../assets/images/Icon/clock.svg";
import { toaster } from "../../helpers/custom/Toast";
import { storeQuestion, submitWritingExam } from "../../store/Question/actions";
import submitPopup from "../ReadingExam/img/popupIamge.png";
import PromptMessage from "../UserLeaveConfirmation/PromptMessage";

const QuestionFooter = ({ history }) => {
  const dispatch = useDispatch();
  const [timeValue, setTimeValue] = useState(0);
  const { packageId } = useParams();
  const [submitted, setSubmitted] = useState(false);

  const {
    currentIndex,
    question,
    writingAns,
    authToken,
    adding,
    answerId,
    isRouteChange,
    quesLoading,
  } = useSelector((state) => ({
    writingQuestions: state.QuesReducer.writingQues,
    currentIndex: state.QuesReducer.currentIndex,
    question: state.QuesReducer.writingQues?.writtingQuestion?.questions,
    quesLoading: state.QuesReducer.quesLoading,
    writingAns: state.QuesReducer.writingAns,
    authToken: state.Login.token,
    adding: state.QuesReducer.adding,
    answerId: state.QuesReducer.answerId,
    isRouteChange: state.QuesReducer.isRouteChange,
  }));
  console.log(`isRouteChange`, isRouteChange);

  const handleNext = () => {
    dispatch(storeQuestion("currentIndex", currentIndex + 1));
  };
  const handleBack = () => {
    dispatch(storeQuestion("currentIndex", currentIndex - 1));
  };

  const spendTime = Math.round((60000 * 60 - timeValue) / 60000);

  const options = {
    type: "writing",
    id: answerId,
    history,
    time: spendTime,
    submissionReason: 1,
    examStatus: 2,
    packageId: packageId,
  };

  const submitAnswer = () => {
    dispatch(submitWritingExam(writingAns, authToken, options));
  };

  const checkpoints = [
    {
      time: 10000 * 60,
      callback: () => {
        toaster("warning", "You have 10min remaining");
      },
    },
    {
      time: 20000 * 60,
      callback: () => {
        toaster("warning", "You have 20min remaining");
      },
    },
    {
      time: 0,
      callback: () => {
        dispatch(submitWritingExam(writingAns, authToken, options));
      },
    },
  ];

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  // window.addEventListener("beforeunload", function (e) {
  //   // Cancel the event and
  //   // show alert that the unsaved
  //   // changes would be lost
  //   e.preventDefault();
  //   e.returnValue = "";
  // });

  const options2 = {
    type: "writing",
    id: answerId,
    history,
    time: spendTime,
    submissionReason: 1,
    examStatus: 2,
    packageId: packageId,
    isBack: true,
    authToken,
    ans: writingAns,
  };
  window.addEventListener("unload", (event) => {
    //call function to save you state in API or save in localStore
    console.log("before reload");
    const { isBack, ...rest } = options2;
    localStorage.setItem("xampro-xam", JSON.stringify(rest));
  });

  return (
    <Fragment>
      <PromptMessage
        dependent={isRouteChange}
        header={"Attention!! You are about to leave the exam."}
        content={
          "Are you sure you want to leave? You will not be able to give the exam again."
        }
        // data={{ writingAns, authToken, options }}
        // type="writing"
        submit={options2}
      />
      <Modal
        open={submitted}
        onClose={() => setSubmitted(false)}
        classNames={{
          modal: "submitExamModal",
        }}
        center
      >
        <div className="ques-answer-modal-container">
          <img src={submitPopup} alt="" />
          <h6>
            <b>Want to submit?</b>
          </h6>
          <Row>
            <Col lg={6}>
              <div className="popup-answer-modal-single item-1 ">
                <button onClick={() => setSubmitted(false)}>Cancel</button>
              </div>
            </Col>{" "}
            <Col lg={6}>
              <div className="popup-answer-modal-single item-2">
                <button disabled={adding} onClick={submitAnswer}>
                  {adding ? "Submitting..." : "Submit and review "}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="exam-ques-footer-main-wrap">
        <div className="ques-footer-bottom" style={{ height: "initial" }}>
          <div
            className="exam-f-left"
            style={{ cursor: "pointer" }}
            //   onClick={onOpenModal2}
          >
            {/* <React.Fragment>
            <img src={keypad} alt="question" />
            <button className="btn questionPallet" type="submit">
              Question Palette
            </button>
          </React.Fragment> */}
          </div>
          <div className="exam-f-right">
            <div className="exam-h-marks">
              <h4>
                <img src={time} alt="" />
                {quesLoading ? (
                  "0:0"
                ) : (
                  <Timer
                    initialTime={60000 * 60}
                    checkpoints={checkpoints}
                    direction="backward"
                  >
                    {({ getTime }) => (
                      <React.Fragment>
                        {setTimeValue(getTime())}
                        <Timer.Minutes />:
                        <Timer.Seconds />
                      </React.Fragment>
                    )}
                  </Timer>
                )}
              </h4>
            </div>
          </div>{" "}
          <div className="exam-f-right">
            <div className="exam-f-right">
              <div className="exam-h-marks exam-h-t-right-button">
                {currentIndex > 0 ? (
                  <button
                    className="btn  me-2 btn-primary"
                    // style={{ backgroundColor: '#fff', color: '#000' }}
                    onClick={handleBack}
                  >
                    <i className="fa fa-angle-left"></i> Previous
                  </button>
                ) : null}
                {currentIndex < question?.length - 1 ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleNext}
                  >
                    Next Section <img src={arrowRight} alt="" />
                  </button>
                ) : null}
                {currentIndex === question?.length - 1 ? (
                  <button
                    className="btn"
                    style={{ backgroundColor: "#FFFFFF", color: "#000" }}
                    type="button"
                    onClick={() => setSubmitted(true)}
                  >
                    <i className="bx bx-paper-plane"></i> Submit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(QuestionFooter);
