import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Alert, Button, Col, Row } from "reactstrap";
import {
  loginUser,
  loginUserError,
  socialLogin,
} from "../../../store/auth/login/actions";
import SocialLoginNew from "../SocialLogin/SocialLoginNew";
import "./acoount.scss";
import longArrow from "./icon/ForwardArrow.svg";
import shadowIcon from "./icon/leftshadho.svg";
import infoImage from "./icon/loginImage.png";
import logo from "./icon/logo.svg";
import mobileBg from "./icon/mobile-bg.svg";
const LoginMain = ({ history, location }) => {
  const dispatch = useDispatch();

  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  useEffect(() => {
    dispatch(loginUserError(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event, errors, values) => {
    event.preventDefault();
    if (errors?.length === 0) {
      if (values) dispatch(loginUser(values, history));
    }
  };

  const signIn = (res, type) => {
    if (res?.error) return;
    if (type === "google" && res) {
      const values = {
        fullName: res.profileObj.name,
        email: res.profileObj.email,
      };
      const url = "/google-login";
      dispatch(socialLogin(values, history, url));
    } else if (type === "facebook" && res) {
    }
  };

  const googleResponse = (response) => {
    signIn(response, "google");
  };

  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <div className="account-profile-main-area">
      <div className="account-main-left-part">
        <img src={shadowIcon} alt="" className="shadow-icon-account-page" />
        <img src={infoImage} alt="" className="account-page-info-image" />
      </div>
      <div className="account-main-right-submit-info">
        <div
          className="mobile-bg-show-account"
          style={{ backgroundImage: `url(${mobileBg})` }}
        />
        <div className="project-logo-name-right-top">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="student-login-main-wrap">
          <div className="student-account-form-wrap">
            <div className="login-form">
              <div className="acoount-email-password-area-wrap">
                <div className="account-form-title">
                  <h3>Sign In</h3>
                </div>
                <div className="mb-3">
                  {error ? <Alert color="danger">{error}</Alert> : null}
                </div>

                <AvForm onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <div className="account-input-field-single">
                        <AvField
                          name="email"
                          label="Email"
                          type="email"
                          errorMessage="Enter Your email"
                          placeholder="Enter Your Email"
                          validate={{
                            required: { value: true },
                            email: {
                              value: true,
                              errorMessage: "Invalid Email format!",
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="account-input-field-single">
                        <AvField
                          name="password"
                          type={viewPassword ? "text" : "password"}
                          label="Password"
                          placeholder="Enter Your Password"
                          errorMessage="Enter Your Password"
                          validate={{
                            required: { value: true },
                            minLength: {
                              value: 8,
                              errorMessage: "Password must be 8 characters",
                            },
                          }}
                        />
                        <span
                          className="password-show-btn"
                          onClick={() => setViewPassword(!viewPassword)}
                        >
                          {viewPassword ? (
                            <i className="fa-solid fa-eye-slash"></i>
                          ) : (
                            <i className="fa-solid fa-eye"></i>
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <div className="forget-password-link-area-button">
                    <Link to="/forgot-password">Forget Password?</Link>
                  </div>
                  <Row>
                    <React.Fragment>
                      <div className="account-form-submit-button">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={loading}
                          className=""
                        >
                          {loading ? (
                            <section className="custom-spiner-main-wrap">
                              <span className="loader-11"> </span>
                            </section>
                          ) : (
                            <div className="account-access-submit-button">
                              <span> Login</span>

                              <img src={longArrow} alt="" />
                            </div>
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  </Row>
                </AvForm>
              </div>
              <div className="new-account-link-area-wrap">
                <p>
                  Need to create a account?{" "}
                  <Link
                    to={{ pathname: "/signup", state: location?.state || "" }}
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
              <SocialLoginNew
                facebookResponse={facebookResponse}
                googleResponse={googleResponse}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginMain);
