import { studentSliderData } from "../fackData/studentSlider";

export const SamplePrevArrow = (props) => {
  const { onClick } = props;
  const prevarrowImage = props.currentSlide;

  return (
    <div className="prev-arrow-image-circle-icon" onClick={onClick}>
      {props.currentSlide === 0 ? (
        <img
          src={studentSliderData[studentSliderData.length - 1]?.image}
          alt=""
        />
      ) : (
        <img src={studentSliderData[prevarrowImage - 1]?.image} alt="" />
      )}
    </div>
  );
};
