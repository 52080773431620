import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData, postData } from "../../helpers/backend_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  getSingleChat,
  getSingleChatFail,
  getSingleChatSuccess,
  getUserChatFail,
  getUserChatSuccess,
  replyToUserFail,
  replyToUserSuccess,
  seenMessageFail,
  seenMessageSuccess,
  sendUserInfoFail,
  sendUserInfoSuccess,
} from "./actions";
import {
  CHAT_INFO_USER,
  GET_CHAT_USER,
  GET_SINGLE_USER_MESSAGE,
  REPLY_USER,
  SEEN_MESSAGE,
} from "./actionTypes";

//for main

function* onGetUserListChat({ payload: { token, skipNumber } }) {
  try {
    const url = `chat/admin/get-last-messages?skipNumber=${skipNumber}`;
    const response = yield call(getData, url, token);
    yield put(getUserChatSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getUserChatFail(message));
  }
}
function* onGetSingleChat({ payload: { token, chatId, type } }) {
  console.log("type", type);
  try {
    const url = `chat/admin/get-single-message?chatUnknown=${
      type === "user" ? false : true
    }&user=${chatId}`;
    const response = yield call(getData, url, token);
    yield put(getSingleChatSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getSingleChatFail(message));
  }
}
function* workerReplyChat({
  payload: { values, history, token, chatId, senderId, type },
}) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values);
  try {
    const url =
      type === "user"
        ? `/chat/user/create-message`
        : `chat/user/create-message-unknown?chatUnknown=true&user=${senderId}`;
    const response = yield call(patchData, url, values, token);
    // toaster("success", `Slot Added Successfully`)
    yield put(replyToUserSuccess(response?.message));
    yield put(getSingleChat(token, senderId, type));
    // toaster("success", `FAQ type added Successfully`)
    // history.push("/faqType")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(replyToUserFail(message));
  }
}
function* workerSeenMessage({
  payload: { values, history, token, chatId, messageId },
}) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values);
  try {
    const url = `/chat/check-by/${chatId}/${messageId}`;
    const response = yield call(patchData, url, values, token);
    // toaster("success", `Slot Added Successfully`)
    yield put(seenMessageSuccess(response?.message));
    // toaster("success", `FAQ type added Successfully`)
    // history.push("/faqType")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(seenMessageFail(message));
  }
}

function* workerSentInfo({ payload: { values, history, token } }) {
  try {
    const url = `chat/user/create-without-login`;
    const response = yield call(postData, url, values, token);
    console.log("response", response);
    yield put(
      sendUserInfoSuccess(
        response?.message,
        response?.getData?._id,
        response?.getData?.email
      )
    );
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(sendUserInfoFail(message));
  }
}
function* ChatSaga() {
  yield takeEvery(REPLY_USER, workerReplyChat);
  yield takeEvery(GET_CHAT_USER, onGetUserListChat);
  yield takeEvery(GET_SINGLE_USER_MESSAGE, onGetSingleChat);
  yield takeEvery(SEEN_MESSAGE, workerSeenMessage);
  yield takeEvery(CHAT_INFO_USER, workerSentInfo);
}
export default ChatSaga;
