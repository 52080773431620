import React from "react";

const LogOutIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.66634 1.16683C3.66634 1.62516 3.29134 2.00016 2.83301 2.00016H1.99967V12.0002H2.83301C3.29134 12.0002 3.66634 12.3752 3.66634 12.8335C3.66634 13.2918 3.29134 13.6668 2.83301 13.6668H1.16634C0.708008 13.6668 0.333008 13.2918 0.333008 12.8335V1.16683C0.333008 0.708496 0.708008 0.333496 1.16634 0.333496H2.83301C3.29134 0.333496 3.66634 0.708496 3.66634 1.16683ZM12.0029 3.18748L14.3479 6.52081C14.5562 6.81581 14.5496 7.21164 14.3329 7.49998L11.8329 10.8333C11.6696 11.0516 11.4187 11.1666 11.1654 11.1666C10.9921 11.1666 10.8162 11.1125 10.6662 11C10.2979 10.7241 10.2237 10.2016 10.4996 9.83414L12.0004 7.83331H11.9996H5.33291C4.87291 7.83331 4.49957 7.46081 4.49957 6.99998C4.49957 6.53914 4.87291 6.16664 5.33291 6.16664H11.9996C12.0133 6.16664 12.0261 6.17044 12.039 6.17427C12.0495 6.17738 12.06 6.18052 12.0712 6.18164L10.6396 4.14581C10.3746 3.76998 10.4654 3.24998 10.8421 2.98498C11.2179 2.71914 11.7379 2.81081 12.0029 3.18748Z"
        fill="#74788D"
      />
    </svg>
  );
};

export default LogOutIcon;
