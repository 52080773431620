import React from "react";

const WritingIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5053_39982)">
        <path
          d="M13.5898 4.80176H12.7148C12.4883 4.80176 12.3047 4.9854 12.3047 5.21191V11.2275C12.3047 11.454 12.4883 11.6377 12.7148 11.6377H13.5898C13.8164 11.6377 14 11.454 14 11.2275V5.21191C14 4.9854 13.8164 4.80176 13.5898 4.80176Z"
          fill="#0052CC"
        />
        <path
          d="M7.93253 2.21341L8.61612 1.0294C8.76714 0.76783 8.67751 0.433361 8.41594 0.282342L7.94234 0.00890478C7.68077 -0.142115 7.3463 -0.0524819 7.19531 0.209088L6.51172 1.3931L7.93253 2.21341Z"
          fill="#0052CC"
        />
        <path
          d="M0.48719 9.21387C0.721553 9.34747 1.02061 9.26022 1.1555 9.02659L4.50732 3.22107C4.19465 3.14678 3.86094 3.21287 3.59836 3.4098L2.43715 4.2807C2.09924 4.53415 1.81847 4.85593 1.61318 5.22507L0.121768 7.90684C-0.134251 8.3672 0.0263386 8.94781 0.482514 9.21119C0.484073 9.21209 0.485631 9.21297 0.48719 9.21387Z"
          fill="#0052CC"
        />
        <path
          d="M2.91563 7.81373H4.69385L7.51704 2.92383L6.0962 2.10352L2.79688 7.81816C2.83617 7.81554 2.87568 7.81373 2.91563 7.81373Z"
          fill="#0052CC"
        />
        <path
          d="M1.3262 10.375L0.90185 11.11C0.881889 11.1446 0.867097 11.1819 0.857909 11.2207L0.466593 12.8759C0.391699 13.1927 0.774046 13.4134 1.01095 13.1902L2.24875 12.0237C2.27779 11.9963 2.3027 11.9649 2.32266 11.9303L2.65817 11.3492C2.07244 11.262 1.57926 10.888 1.3262 10.375Z"
          fill="#0052CC"
        />
        <path
          d="M6.18694 11.3691H3.59604L3.05469 12.3068C3.22876 12.4872 3.47285 12.5996 3.74334 12.5996C4.27189 12.5996 4.70037 12.1711 4.70037 11.6426C4.70037 12.1711 5.12884 12.5996 5.6574 12.5996C6.18595 12.5996 6.61443 12.1711 6.61443 11.6426V11.5801C6.51514 11.5347 6.28778 11.4192 6.18694 11.3691Z"
          fill="#0052CC"
        />
        <path
          d="M7.814 4.05469L5.64337 7.81432H6.28781C6.51432 7.81432 6.69796 7.99796 6.69796 8.22447C6.69796 8.45096 6.51438 8.6346 6.28786 8.63463H2.91797C2.38941 8.63463 1.96094 9.06311 1.96094 9.59166C1.96094 10.1202 2.38941 10.5487 2.91797 10.5487H6.37553L6.82916 10.7753C7.60545 11.1635 8.47501 11.369 9.3438 11.369C10.0636 11.369 10.8076 11.2275 11.4831 10.9584V5.62674H10.8367L7.814 4.05469Z"
          fill="#0052CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_5053_39982">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WritingIcon;
