import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./not-found.scss";
import emptyCart from "./oppsImage.svg";
const PackagePurchased = () => {
  return (
    <>
      <div className="link-not-found-404-page">
        <Container>
          <div className="empty-cart-main-area-wrap-empty mt-5 p-5">
            <div className="empty-cart-inner-wrap">
              <img src={emptyCart} alt="" />
              <h4>Package Successfully Purchased!</h4>
              <p style={{ color: "#74788D" }}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem{" "}
                <br />
                accusantium doloremque laudantium, totam rem aperiam, eaque.
              </p>
              <Link to="/" className="btn btn-primary mt-3">
                My Package
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PackagePurchased;
