import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import ieltsImage from "../img/ielts.png";
import checkIcon from "./img/Base.svg";
import "./student-package2.scss";

const IeltsPackage = ({ history }) => {
  return (
    <div className="gmat-package-main-wrap ielts">
      <Container>
        <Row>
          <Col lg={5}>
            <div className="course-single-part-left">
              <img src={ieltsImage} alt="" />
            </div>
          </Col>
          <Col lg={7} className="my-auto">
            <div className="course-single-part-right ps-4">
              <h3 className="mb-5">
                Score to reach top <br /> notch university
              </h3>
              <ul>
                <li>
                  <img src={checkIcon} alt="" />
                  <p>‘Score’ to reach at the IVY league institution</p>
                </li>{" "}
                <li>
                  <img src={checkIcon} alt="" />
                  <p>
                    Jump start your prep though mentoring both online and
                    offline
                  </p>
                </li>{" "}
                <li>
                  <img src={checkIcon} alt="" />
                  <p>Use the tricks-of-the trade easily</p>
                </li>{" "}
                <li>
                  <img src={checkIcon} alt="" />
                  <p>70% higher chances to set the desired score.</p>
                </li>
              </ul>
              <Button
                onClick={() => history.push("/packages/Ielts")}
                className="btn package-connectbutton"
              >
                Connect
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(IeltsPackage);
