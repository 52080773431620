import {
  CHAT_INFO_USER,
  CHAT_INFO_USER_FAIL,
  CHAT_INFO_USER_SUCCESS,
  GET_CHAT_USER,
  GET_CHAT_USER_FAIL,
  GET_CHAT_USER_SUCCESS,
  GET_SINGLE_USER_MESSAGE,
  GET_SINGLE_USER_MESSAGE_FAIL,
  GET_SINGLE_USER_MESSAGE_SUCCESS,
  REPLY_USER,
  REPLY_USER_FAIL,
  REPLY_USER_SUCCESS,
  SEEN_MESSAGE,
  SEEN_MESSAGE_FAIL,
  SEEN_MESSAGE_SUCCESS,
} from "./actionTypes";

export const replyToUser = (values, history, token, chatId, senderId, type) => {
  return {
    type: REPLY_USER,
    payload: { values, history, token, chatId, senderId, type },
  };
};
export const replyToUserSuccess = (message) => {
  return {
    type: REPLY_USER_SUCCESS,
    payload: { message },
  };
};

export const replyToUserFail = (message) => {
  return {
    type: REPLY_USER_FAIL,
    payload: { message },
  };
};

export const seenMessage = (values, history, token, chatId, messageId) => {
  return {
    type: SEEN_MESSAGE,
    payload: { values, history, token, chatId, messageId },
  };
};
export const seenMessageSuccess = (message) => {
  return {
    type: SEEN_MESSAGE_SUCCESS,
    payload: { message },
  };
};

export const seenMessageFail = (message) => {
  return {
    type: SEEN_MESSAGE_FAIL,
    payload: { message },
  };
};

export const getUserChat = (token, skipNumber) => {
  return {
    type: GET_CHAT_USER,
    payload: { token, skipNumber },
  };
};

export const getUserChatSuccess = (values) => {
  return {
    type: GET_CHAT_USER_SUCCESS,
    payload: { values },
  };
};

export const getUserChatFail = (message) => {
  return {
    type: GET_CHAT_USER_FAIL,
    payload: { message },
  };
};

export const getSingleChat = (token, chatId, type) => {
  return {
    type: GET_SINGLE_USER_MESSAGE,
    payload: { token, chatId, type },
  };
};

export const getSingleChatSuccess = (values) => {
  return {
    type: GET_SINGLE_USER_MESSAGE_SUCCESS,
    payload: { values },
  };
};

export const getSingleChatFail = (message) => {
  return {
    type: GET_SINGLE_USER_MESSAGE_FAIL,
    payload: { message },
  };
};
export const sendUserInfo = (values, history, token) => {
  return {
    type: CHAT_INFO_USER,
    payload: { values, history, token },
  };
};
export const sendUserInfoSuccess = (message, nonUserId, nonUserEmail) => {
  return {
    type: CHAT_INFO_USER_SUCCESS,
    payload: { message, nonUserId, nonUserEmail },
  };
};

export const sendUserInfoFail = (message) => {
  return {
    type: CHAT_INFO_USER_FAIL,
    payload: { message },
  };
};
