import React from "react";
import { useDispatch, useSelector } from "react-redux";

const CouponMessage = () => {
  const dispatch = useDispatch();
  const { coupon } = useSelector((state) => ({
    coupon: state.PackageReducer.coupon,
  }));

  return coupon?.couponCode ? (
    <div className="">
      {/* <Container>
        <div className="position-relative coupon_text d-flex justify-content-center text-white cupon-text-show-area">
          <p>
            Apply coupon "{coupon?.couponCode?.trim()}" to get{" "}
            {coupon?.couponPercentage}% Off!
          </p>
          <div
            onClick={() => dispatch(getCouponSuccess({}))}
            className="position-absolute"
            style={{ top: "0px", right: "0", cursor: "pointer" }}
          >
            <i className="bx bx-x-circle fs-5"></i>
          </div>
        </div>
      </Container> */}
    </div>
  ) : (
    <></>
  );
};

export default CouponMessage;
