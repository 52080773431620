import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import Modal from "react-responsive-modal";
import videoBg from "./img/Image.png";
import "./video-totorial.scss";
const VideoTutorial = () => {
  const [videoOpen, setVideoOpen] = useState(false);

  return (
    <div className="video-tutorial-main-area-wrap">
      <MetaTags>
        <title>Video tutorial</title>
      </MetaTags>
      <Modal
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
        classNames={{
          overlay: "videoTutorialOverlay",
          modal: "videoTutorialModal",
        }}
        center
      >
        <div className="videoTutorialModal-inner-wrap">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/fQDhSGCmyMc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
      <Container>
        <div className="video-tutrial-inner-wrap">
          <div className="video-tutorial-header">
            <h4>Video Tutorials</h4>
          </div>
          <Row>
            <Col lg={4}>
              <div className="video-tutrial-single-item">
                <div
                  className="video-tutorial-top"
                  style={{ backgroundImage: `url(${videoBg})` }}
                >
                  <button
                    className="btn"
                    style={{ color: "white", fontSize: "40px" }}
                    onClick={() => setVideoOpen(true)}
                  >
                    <i className="bx bxl-youtube"></i>
                  </button>
                </div>
                <div className="video-tutrial-bottom">
                  <h4>How to start your exam.</h4>
                </div>
              </div>
            </Col>{" "}
            <Col lg={4}>
              <div className="video-tutrial-single-item">
                <div
                  className="video-tutorial-top"
                  style={{ backgroundImage: `url(${videoBg})` }}
                >
                  <button
                    className="btn"
                    style={{ color: "white", fontSize: "40px" }}
                    onClick={() => setVideoOpen(true)}
                  >
                    <i className="bx bxl-youtube"></i>
                  </button>
                </div>
                <div className="video-tutrial-bottom">
                  <h4>How to start your exam.</h4>
                </div>
              </div>
            </Col>{" "}
            <Col lg={4}>
              <div className="video-tutrial-single-item">
                <div
                  className="video-tutorial-top"
                  style={{ backgroundImage: `url(${videoBg})` }}
                >
                  <button
                    className="btn"
                    style={{ color: "white", fontSize: "40px" }}
                    onClick={() => setVideoOpen(true)}
                  >
                    <i className="bx bxl-youtube"></i>
                  </button>
                </div>
                <div className="video-tutrial-bottom">
                  <h4>How to start your exam.</h4>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default VideoTutorial;
