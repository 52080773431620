export const GET_READING_QUES = "GET_READING_QUES";
export const GET_READING_QUES_SUCCESS = "GET_READING_QUES_SUCCESS";
export const GET_READING_QUES_QUES_FAIL = "GET_READING_QUES_QUES_FAIL";

export const GET_LISTENING_QUES = "GET_LISTENING_QUES";
export const GET_LISTENING_QUES_SUCCESS = "GET_LISTENING_QUES_SUCCESS";
export const GET_LISTENING_QUES_QUES_FAIL = "GET_LISTENING_QUES_QUES_FAIL";

export const GET_WRITING_QUES = "GET_WRITING_QUES";
export const GET_WRITING_QUES_SUCCESS = "GET_WRITING_QUES_SUCCESS";
export const GET_WRITING_QUES_QUES_FAIL = "GET_WRITING_QUES_QUES_FAIL";

export const GET_SPEAKING_SET_LINK = "GET_SPEAKING_SET_LINK";
export const GET_SPEAKING_SET_LINK_SUCCESS = "GET_SPEAKING_SET_LINK_SUCCESS";
export const GET_SPEAKING_SET_LINK_QUES_FAIL = "GET_SPEAKING_SET_LINK_FAIL";

export const SUBMIT_EXAM = "SUBMIT_EXAM";
export const SUBMIT_EXAM_SUCCESS = "SUBMIT_EXAM_SUCCESS";
export const SUBMIT_EXAM_QUES_FAIL = "SUBMIT_EXAM_QUES_FAIL";

export const SUBMIT_LISTENING_EXAM = "SUBMIT_LISTENING_EXAM";
export const SUBMIT_WRITING_EXAM = "SUBMIT_WRITING_EXAM";

export const STORE_QUESTION = "STORE_QUESTION";
export const RESET_ALL = "RESET_ALL";
export const STORE_QUESTION_WITH_CLUES = "STORE_QUESTION_WITH_CLUES";

export const SUBMIT_ANSWER_ONE_BY_ONE = "SUBMIT_ANSWER_ONE_BY_ONE";
export const SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS =
  "SUBMIT_ANSWER_ONE_BY_ONE_SUCCESS";
export const SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL =
  "SUBMIT_ANSWER_ONE_BY_ONE_QUES_FAIL";

export const GET_REMAINING_QUES = "GET_REMAINING_QUES";
export const GET_REMAINING_QUES_SUCCESS = "GET_REMAINING_QUES_SUCCESS";
export const GET_REMAINING_QUES_FAIL = "GET_REMAINING_QUES_FAIL";
