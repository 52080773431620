import {
  ADD_SPEAKING_SET,
  GET_SINGLE_SPEAKING_SLOT,
  GET_STUDENT_PROFILE,
  SHOW_RESULT,
  STORE_QUESTION,
  SUBMIT_MARKS,
  TEACHER_START_EXAM,
  UPDATE_ATTENDANCE,
} from "./actionTypes"

export const addSpeakingSet = (values, options) => {
  return {
    type: ADD_SPEAKING_SET,
    payload: { values, options },
  }
}

export const getSingleSpeakingTimeSlot = (token, options) => {
  return {
    type: GET_SINGLE_SPEAKING_SLOT,
    payload: { token, options },
  }
}

export const teacherStartExam = (token, slotId, meetLink) => {
  return {
    type: TEACHER_START_EXAM,
    payload: { token, slotId, meetLink },
  }
}

export const updateAttendaceData = options => {
  return {
    type: UPDATE_ATTENDANCE,
    payload: { options },
  }
}

export const submitSpeakingMarks = options => {
  return {
    type: SUBMIT_MARKS,
    payload: { options },
  }
}

export const getUserProfile = (token, slotId) => {
  return {
    type: GET_STUDENT_PROFILE,
    payload: { slotId, token },
  }
}

export const getSpeakingResult = (token, slotId) => {
  return {
    type: SHOW_RESULT,
    payload: { slotId, token },
  }
}

export const storeSlot = (name, data) => ({
  type: STORE_QUESTION,
  payload: { name, data },
})
