import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import bgImage from "./emptyImage.png";
const EmptyPackage = () => {
  return (
    <div>
      <div className="package-empty-main-wrap">
        <Container>
          <div className="package-inner-items-wrap">
            <div className="">
              <img src={bgImage} alt="" />
              <p>
                Currently you have no package.{" "}
                <Link to="/ielts-package">Buy Package</Link>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EmptyPackage;
