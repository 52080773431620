import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getFAQInfo } from "../../store/actions";
import "./faq.scss";
const Faq = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { authtoken, message, values, loading, packageData, faqInfoList } =
    useSelector((state) => ({
      authtoken: state.Login.token,

      faqInfoList: state.FAQReducer.faqInfoList,
    }));
  useEffect(() => {
    dispatch(getFAQInfo(authtoken));
  }, []);
  console.log(search);

  return (
    <div>
      <div className="search-faq-info">
        <div className="">
          <h1>Frequently Asked Question</h1>
          <div className="search-faq-details-header mt-5">
            {/* <Form>
              <InputGroup>
                <Form.Control onChange={(e) => setSearch(e.target.value)} />
              </InputGroup>
            </Form> */}
            <input
              type="text"
              // className="form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value?.toLocaleLowerCase())}
              placeholder="Search"
            ></input>
          </div>
        </div>
      </div>
      <div className="faq-info-details-content">
        <Container>
          <Accordion flush>
            {faqInfoList
              ?.filter((item) => {
                return search?.toLocaleLowerCase() === ""
                  ? item
                  : item?.question?.toLocaleLowerCase().includes(search);
              })
              .map((item, key) => (
                <Accordion.Item eventKey={key} key={key}>
                  <Accordion.Header>
                    <h4> {item?.question}</h4>
                  </Accordion.Header>
                  <Accordion.Body>{item?.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  );
};

export default Faq;
