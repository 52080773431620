import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Alert, Button, Col, Row } from "reactstrap";
import { toaster } from "../../../helpers/custom/Toast";
import { restPassword } from "../../../store/auth/updatePassword/actions";
import shadowIcon from "./icon/leftshadho.svg";
import infoImage from "./icon/loginImage.png";
import logo from "./icon/logo.svg";
import mobileBg from "./icon/mobile-bg.svg";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword2 = ({ history }) => {
  let query = useQuery();
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((store) => store.updatePass);
  const { token } = useSelector((state) => state.Login);
  useEffect(() => {
    if (!query.get("email") || !query.get("token")) {
      toaster("warning", "Queries are missing in URl!!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (event, errors, values) => {
    const data = {
      ...values,
      email: query.get("email"),
      accountActivateToken: query.get("token"),
    };
    if (errors.length === 0) dispatch(restPassword(data, history, token));
  };

  const [viewPassword, setViewPassword] = useState(false);
  const [viewPassword2, setViewPassword2] = useState(false);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset Password</title>
      </MetaTags>
      <div className="student-login-main-wrap signup-page account-form-submit-button ">
        <div className="account-profile-main-area">
          <div className="account-main-left-part">
            <img src={shadowIcon} alt="" className="shadow-icon-account-page" />
            <img src={infoImage} alt="" className="account-page-info-image" />
          </div>
          <div className="account-main-right-submit-info">
            <div
              className="mobile-bg-show-account"
              style={{ backgroundImage: `url(${mobileBg})` }}
            />
            <div className="project-logo-name-right-top">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="student-account-form-wrap">
              <div className="login-form">
                <div className="account-form-title-wrap mb-3">
                  <h3>Reset Password</h3>
                </div>
                <div className="acoount-email-password-area">
                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {message ? <Alert color="success">{message}</Alert> : null}
                  </div>
                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="password"
                            type={viewPassword ? "text" : "password"}
                            label="Password"
                            errorMessage="Enter New Password"
                            placeholder="Enter New Password"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 8,
                                errorMessage: "Password must be 8 characters",
                              },
                            }}
                          />
                          <span
                            className="password-show-btn"
                            onClick={() => setViewPassword(!viewPassword)}
                          >
                            {viewPassword ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="account-input-field-single">
                          <AvField
                            name="confirmPassword"
                            label="Confirm Password"
                            type={viewPassword2 ? "text" : "password"}
                            placeholder="Enter Confirm Password"
                            errorMessage="Enter confirm password"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 8,
                                errorMessage: "Password must be 8 characters",
                              },
                            }}
                          />
                          <span
                            className="password-show-btn"
                            onClick={() => setViewPassword2(!viewPassword2)}
                          >
                            {viewPassword2 ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      disabled={loading}
                      type="submit"
                      className="account-btn-all-page"
                    >
                      {loading ? (
                        <section className="custom-spiner-main-wrap">
                          <span className="loader-11"> </span>
                        </section>
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </AvForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword2;
