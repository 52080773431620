import {
  ADD_COUPON,
  ADD_COUPON_FAIL,
  ADD_COUPON_SUCCESS,
  ADD_FACILITATOR_ORDER,
  ADD_FACILITATOR_ORDER_FAIL,
  ADD_FACILITATOR_ORDER_SUCCESS,
  ADD_PACKAGE_ORDER,
  ADD_PACKAGE_ORDER_FAIL,
  ADD_PACKAGE_ORDER_SUCCESS,
  GET_PACKAGE_ORDER_FAIL,
  GET_PACKAGE_ORDER_SUCCESS,
} from "./actionTypes";

const initialState = {
  packageOrder: [],
  couponData: {},
  ordering: false,
  facilitatorOrdering: false,
  couponOrdering: false,
  reRender: false,
  error: "",
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PACKAGE_ORDER:
      return {
        ...state,
        ordering: true,
      };
    case ADD_PACKAGE_ORDER_SUCCESS:
      return {
        ...state,
        ordering: false,
      };
    case ADD_PACKAGE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        ordering: false,
      };
    case ADD_FACILITATOR_ORDER:
      return {
        ...state,
        facilitatorOrdering: true,
      };
    case ADD_FACILITATOR_ORDER_SUCCESS:
      return {
        ...state,
        facilitatorOrdering: false,
      };
    case ADD_FACILITATOR_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        facilitatorOrdering: false,
      };

    case GET_PACKAGE_ORDER_SUCCESS:
      return {
        ...state,
        packageOrder: action.payload,
        packageOrderLoading: false,
      };
    case GET_PACKAGE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        packageOrderLoading: true,
      };
    case ADD_COUPON:
      return {
        ...state,

        couponOrdering: true,
      };
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        couponData: action.payload,
        couponOrdering: false,
        reRender: !state.reRender,
      };
    case ADD_COUPON_FAIL:
      return {
        ...state,
        error: action.payload,
        couponOrdering: false,
      };
    default:
      return state;
  }
};

export default OrderReducer;
