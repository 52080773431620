import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import "./student-footer.css";

const StudentFooterCommon = () => {
  return (
    <div>
      <div className="footer-bottom-main-content-wrap">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="footer-left">
                <p>© {new Date().getFullYear()} XamPro</p>
              </div>
            </Col>
            <Col lg={8}>
              <div className="footer-right">
                <ul>
                  <li>
                    <Link to="/term-and-condition">Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refound-policy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    // <div>
    //   <div className="ssl-commerce-image-bg-wrap">
    //     {" "}
    //     <Container>
    //       <Row>
    //         <div className="ssl-commerce-image-wrap">
    //           <img src={sslImage} alt="" />
    //         </div>
    //       </Row>{" "}
    //     </Container>
    //   </div>
    //   <span className="ssl-commerce-image-devider" />
    //   <div className="footer-main-wrap">
    //     <Container>
    //       <Row>
    //         <Col lg={6}>
    //           <div className="footer-left">
    //             {/* <p>© {new Date().getFullYear()} MAAC all right reserve</p> */}
    //           </div>
    //         </Col>
    //         <Col lg={6}>
    //           <div className="footer-right">
    //             <ul>
    //               <li>
    //                 <Link to="/term-and-condition">Terms & Condition</Link>
    //               </li>
    //               <li>
    //                 <Link to="/privacy-policy">Privacy Policy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/refound-policy">Refund Policy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/about-us">About us</Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </div>
  );
};

export default StudentFooterCommon;
