import React from "react";
import { Container, Row } from "react-bootstrap";
import chooseBg from "./img/BG.png";
import icon1 from "./img/icon1.svg";
import icon2 from "./img/icon2.svg";
import icon3 from "./img/icon3.svg";
import icon4 from "./img/icon4.svg";
import "./peopleChoose.scss";
const ServiceProvide = () => {
  return (
    <div
      className="people-choose-wrap"
      style={{ backgroundImage: `url(${chooseBg})` }}
    >
      <Container>
        <div className="people-choose-inner-wrap-new-facilitor" id="feature">
          <div className="people-choose-title">
            <h3>We will provide you</h3>
          </div>
          <div className="why-choose-us-items-new-facilitor">
            <Row>
              <div className="single-home-facilitor-service">
                <div className="single-choose-single-item">
                  <div className="choose-item-icon-image">
                    <div className="inner-choose-bg-color">
                      <img src={icon1} alt="alt" />
                    </div>
                  </div>
                  <h4>
                    Complate <br />
                    exam package
                  </h4>
                </div>
              </div>{" "}
              <h3>+</h3>
              <div className="single-home-facilitor-service">
                <div className="single-choose-single-item">
                  <div className="choose-item-icon-image">
                    <div className="inner-choose-bg-color">
                      <img src={icon2} alt="alt" />
                    </div>
                  </div>
                  <h4>
                    Top <br />
                    facilitator
                  </h4>
                </div>
              </div>{" "}
              <h3>+</h3>
              <div className="single-home-facilitor-service">
                <div className="single-choose-single-item people-equal-item">
                  <div className="choose-item-icon-image">
                    <div className="inner-choose-bg-color">
                      <img src={icon3} alt="alt" />
                    </div>
                  </div>
                  <h4>
                    Best guidelines <br /> for the exam
                  </h4>
                </div>
              </div>{" "}
              <h3> =</h3>
              <div className="single-home-facilitor-service">
                <div className="single-choose-single-item people-last-child">
                  <div className="choose-item-icon-image">
                    <div className="inner-choose-bg-color">
                      <img src={icon4} alt="alt" />
                    </div>
                  </div>
                  <h4>
                    Desired <br /> Score
                  </h4>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServiceProvide;
