import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import googleMeetIcon from './img/Google_Meet 1.png';
import confirmIcon from './img/illustration.svg';
import './speaking.scss';
const SpeakingPage = () => {
  const [value, onChange] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  return (
    <React.Fragment>
      <Container>
        <div className="speaking-main-container-wrap">
          <div className="speaking-exam-main-wrap">
            <div className="header-top-button-title">
              <h4>
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>Select your Preferable Date</span>
              </h4>
            </div>
            <div className="speaking-exam-date-bottom-wrap">
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="speaking-exam-date-content-wrap">
                    <Calendar onChange={onChange} value={value} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>{' '}
          <div className="speaking-exam-main-wrap mt-5">
            <div className="header-top-button-title">
              <h4>
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>Select your Preferable Date</span>
              </h4>
            </div>
            <div className="speaking-exam-date-bottom-wrap">
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="speaking-exam-date-content-wrap">
                    <Calendar onChange={onChange2} value={value2} />
                  </div>
                </Col>{' '}
                <Col lg={6} className="my-auto">
                  <Form className="form-submit-main-time-wrap">
                    <div className="speaking-exam-selected-time">
                      <div className="speaking-selected-time-button">
                        10:00 <span>-</span> 10:30
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        10:30 <span>-</span> 11:00
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        11:00 <span>-</span> 11:30
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        11:30 <span>-</span> 12:00
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        12:00 <span>-</span> 12:30
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        12:30 <span>-</span> 01:00
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        01:00 <span>-</span> 01:30
                      </div>{' '}
                      <div className="speaking-selected-time-button">
                        01:30 <span>-</span> 02:00
                      </div>
                      <div className="speaking-selected-time-button">
                        02:00 <span>-</span> 02:30
                      </div>
                      <div className="speaking-selected-time-button">
                        02:30 <span>-</span> 03:00
                      </div>
                    </div>
                    <div className="time-selected-submit-btn">
                      <button>Confirm Time</button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>{' '}
          <div className="speaking-exam-main-wrap mt-5">
            <div className="header-top-button-title">
              <h4>
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>Select your Preferable Date</span>
              </h4>
            </div>
            <div className="speaking-exam-date-bottom-wrap">
              <div className="speaking-package-listed-confirmation">
                <div>
                  <img src={confirmIcon} alt="" />
                  <h3>Confirm</h3>
                  <p>
                    Your exam date & time confirmed. Please attend your exam on{' '}
                    <br />
                    time following link. you find it on your exam page.
                    <br /> Your exam link :{' '}
                    <a href="http://meet.google.com/jjy-ysbs-qay">
                      http://meet.google.com/jjy-ysbs-qay
                    </a>
                  </p>
                  <Link to="/">My Package</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="speaking-exam-main-wrap mt-5">
            <div className="header-top-button-title">
              <h4>
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
                <span>Set Test 2</span>
              </h4>
            </div>
            <div className="speaking-exam-date-bottom-wrap">
              <div className="speaking-package-listed-view-wrap">
                <h3>Your Exam Start In</h3>
                <h4>
                  <span>03D</span>
                  <span>:</span>
                  <span>22H</span>
                  <span>:</span>
                  <span>22M</span>
                  <span>:</span>
                  <span>40S</span>
                </h4>
                <p>
                  sed quia non numquam eius modi tempora incidunt ut labore et
                  dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
                  veniam.
                </p>
                <div className="exam-google-meet-link-button">
                  <Link to="http://meet.google.com/jjy-ysbs-qay">
                    <span>
                      <img src={googleMeetIcon} alt="" />
                    </span>
                    <span>Start exam</span>
                  </Link>
                </div>
                <Form.Text>Google meet</Form.Text>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default SpeakingPage;
