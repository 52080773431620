import React from "react";
import Timer from "react-compound-timer/build";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import arrowLeft from "../../assets/images/Icon/arrow-right.svg";

const QuestionHeader = ({ history }) => {
  const { saving, quesLoading } = useSelector((state) => ({
    saving: state.QuesReducer.saving,
    quesLoading: state.QuesReducer.quesLoading,
  }));
  return (
    <React.Fragment>
      <div className="exam-ques-header-main-wrap">
        <div className="exam-h-left">
          <div onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <img src={arrowLeft} alt="" />
          </div>
          <h4>Writing Exam</h4>{" "}
          <small
            style={{ fontSize: "10px", color: saving ? "yellow" : "green" }}
            className="ms-2"
          >
            {saving ? "Saving..." : "Saved"}
          </small>
        </div>
        <div className="exam-h-right">
          <div className="exam-h-marks me-2">{/* Mark :<span>40</span> */}</div>
          <div className="exam-h-time">
            Time :
            {quesLoading ? (
              "0:0"
            ) : (
              <span>
                <Timer
                  initialTime={60000 * 60}
                  // checkpoints={checkpoints}
                  direction="backward"
                >
                  <Timer.Minutes />:
                  <Timer.Seconds />
                </Timer>
              </span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(QuestionHeader);
